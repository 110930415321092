import styled from 'styled-components';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Hero from './hero';
import Header from './header';
import Breadcrumbs from './breadcrumbs';
import ProgramTabs from './programTabs';
import SkipLink from './structure/SkipLink';
import LearnMoreModal from './modals/learnMore';
import FavoriteProgramModal from './modals/favoriteProgramModal';
import LoadingScreen from '../../../styledComponents/styles/LoadingScreen';

import MobileTabs from './programTabs/MobileTabs';
import AcademicsTab from './programTabs/academics';
import ResourcesTab from './programTabs/resources';
import EligibilityTab from './programTabs/eligibility';
import AboutProgramTab from './programTabs/aboutProgram';
import WhatsIncludedTab from './programTabs/whatsIncluded';
import CostAndFundingTab from './programTabs/costAndFunding';
import ProgramOverviewTab from './programTabs/programOverview';
import TermInformationTab from './programTabs/termInformation';

import history from '../../../history';
import { breakpoints } from '../utils/breakpoints';
import checkTabNotEmpty from '../utils/checkTabNotEmpty';
import { getProgram } from '../../../actions/programBrochure';
import { toggleFavorite } from '../../../actions/programBrochureActions';
import programBrochureTabs from '../utils/programBrochureTabs';
import { getCurrentUser } from '../../travelerProfile/actions/travelerProfileActions';
import BlockPage from './blockPage';

const MainContainer = styled.div`
  padding: 0.70125rem 2.23125rem 0.70125rem 2.23125rem;
  background-color: var(--theme_color_dark);
  @media only screen and (min-width: 280px) and (max-width: 430px) {
    padding: 0.70125rem 1.2rem 0.70125rem 1.2rem;
  }
`;
const NavContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 60.375rem;
  margin: 0 auto;
  padding: 0 0.75rem;

  @media ${breakpoints.desktop} {
    padding: 0;
  }
`;

const InnerMainContainer = styled.main`
  background-color: #ffffff;
`;

export default function Brochure({ program_id, tab }) {
  const dispatch = useDispatch();
  const programId = Number(program_id);
  const [currentTab, setCurrentTab] = useState({
    title: 'Program Overview',
    path: '',
    component: 'programOverview',
  });
  const program = useSelector(state => state.programBrochure);
  const currentUserData = useSelector(state => state.profile.currentUser);
  const {
    showLearnMoreModal: isLearnMoreModalOpen,
  } = useSelector(state => state.programBrochure);
  const { 'is_traveler?': isTraveler, admin_sign_in: isAdmin } = useSelector(state => state.profile.currentUser);
  const programAlias = useSelector(state => state.auth.organization.info.alias_program);
  const userProfile = useSelector(state => state.profile);
  const favoriteDisclaimerUpdated = useSelector(state => state.programBrochure.update_favorite_disclaimer);
  const [isLoading, setIsLoading] = useState(false);
  const [showFavoriteModal, setShowFavoriteModal] = useState(false);
  const [activeTabs, setActiveTabs] = useState(programBrochureTabs(programAlias));
  const componentMap = {
    academics: AcademicsTab,
    resources: ResourcesTab,
    eligibility: EligibilityTab,
    aboutProgram: AboutProgramTab,
    whatsIncluded: WhatsIncludedTab,
    costAndFunding: CostAndFundingTab,
    programOverview: ProgramOverviewTab,
    termInformation: TermInformationTab,
  };
  const Component = componentMap[currentTab.component];

  const getNonEmptyTabs = () => {
    const nonEmptyTabs = ['programOverview'];
    let tabs = programBrochureTabs(programAlias);
    const isVisitor = !isTraveler && !isAdmin;

    if (isVisitor) {
      tabs = tabs.filter(tab => tab.component !== 'resources');
    }

    tabs.forEach(tab => {
      const tabIsNotEmpty = checkTabNotEmpty(program, tab.component);

      if (tabIsNotEmpty) {
        nonEmptyTabs.push(tab.component);
      }
    });
    setActiveTabs(tabs.filter(tab => nonEmptyTabs.includes(tab.component)));
  };

  useEffect(() => {
    let unmounted = false;
    setIsLoading(true);
    if (!unmounted) {
      if (isTraveler && !isAdmin) {
        dispatch(getProgram(programId, 'traveler'));
      } else if (isAdmin) {
        !window.location.href.includes('/client/')
          ? window.location.assign(`/client/program_brochure/${program_id}`)
          : null;
        dispatch(getProgram(programId, 'client'));
      } else {
        dispatch(getProgram(programId, 'visitor'));
      }
    }

    setTimeout(() => {
      setIsLoading(false);
    }, 1000);

    return () => {
      unmounted = true;
    };
  }, [currentUserData]);

  useEffect(() => {
    !program.loading ? getNonEmptyTabs() : null;
  }, [program]);

  useEffect(() => {
    window.scrollTo(0, localStorage.getItem('scrollY') || 0);

    const currentTab = activeTabs.find(brochureTab => brochureTab.path === (tab || ''));

    if (!currentTab) {
      history.push(`/not_found`);
    }

    setCurrentTab(activeTabs.find(brochureTab => brochureTab.path === (tab || '')));
  }, [tab]);

  const submitFavourite = () => {
    const isFavDisAccepted = true;
    dispatch(toggleFavorite(programId, userProfile.currentUser.id, isFavDisAccepted));
    //Add update user API here
  };

  useEffect(() => {
    if (favoriteDisclaimerUpdated) {
      dispatch(getCurrentUser(userProfile.currentUser.id));
    }
  }, [favoriteDisclaimerUpdated]);

  const getFavoriteDetails = () => {
    if (!showFavoriteModal) {
      setShowFavoriteModal(!showFavoriteModal);
    }
  };

  return (
    <>
      {isLoading ? (
        <LoadingScreen displayText="Loading..." />
      ) : (
        <>
          {(!isAdmin && (program.publishStatus === 'unpublished' || program.publishStatus === 'deleted')) ? (
            <>
              <BlockPage />
            </>
          ) : (
            <MainContainer>
              {!isLoading && (
                <>
                  {/* <SkipLink /> */}
                  {isLearnMoreModalOpen && 
                    <LearnMoreModal />
                  }
                  {showFavoriteModal &&
                    <FavoriteProgramModal
                      show={showFavoriteModal}
                      onSubmit={() => submitFavourite()}
                      onClose={() => setShowFavoriteModal(!showFavoriteModal)}
                    />
                  }
                  <Header
                    programId={programId}
                    programTitle={program.meta.title}
                    toggleFavoriteModal={getFavoriteDetails}
                  />
                  <InnerMainContainer id="main">
                    <Hero />
                    <NavContainer>
                      <Breadcrumbs isAdmin={isAdmin} isTraveler={isTraveler} programId={programId} />
                      <nav aria-label="Program Brochure Navigation" style={{ width: '50%' }}>
                        <MobileTabs
                          programId={programId}
                          currentTab={currentTab}
                          activeTabs={activeTabs}
                          forTablet
                          isAdmin={isAdmin}
                        />
                      </nav>
                    </NavContainer>
                    <ProgramTabs
                      programId={programId}
                      currentTab={currentTab}
                      activeTabs={activeTabs}
                      isAdmin={isAdmin}
                      isTraveler={isTraveler}
                    />
                    <Component program={program} isAdmin={isAdmin} />
                  </InnerMainContainer>
                </>
              )}
            </MainContainer>
          )}
        </>
      )}
    </>
  );
}

Brochure.propTypes = {
  tab: function(propValue, key, componentName) {
    if (!programBrochureTabs().find(brochureTab => brochureTab.path === (propValue[key] || ''))) {
      history.push(`/not_found`);

      return new Error(
        'Invalid tab `' + propValue[key] + '` supplied to' + ' `' + componentName + '`. Validation failed.',
      );
    }
  },
};
