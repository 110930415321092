import fetch from 'node-fetch';

import { keys } from '../../../config/keys';
import token from '../../../shared/helpers/token';

import {
  FETCH_INTAKE_FORM,
  ADD_INTAKE_FORM_GOAL,
  ADD_INTAKE_FORM_PROFILE,
  ADD_INTAKE_FORM_ADVISING,
  ADD_INTAKE_FORM_COMPLETE,
  ADD_INTAKE_FORM_PASSPORT,
  ADD_INTAKE_FORM_FINANCIAL_AID,
  ADD_INTAKE_FORM_TRAVELER_TYPE,
  ADD_INTAKE_FORM_FINANCIAL_INFO,
  ADD_INTAKE_FORM_HOME_INSTITUTION,
  ADD_INTAKE_FORM_TRAVEL_EXPERIENCE,
  ADD_INTAKE_FORM_PARTICIPATION_CERTAINTY,
  ADD_INTAKE_FORM_COUNTRIES_OF_CITIZENSHIP,
} from './types';

export function fetchIntake(userId) {
  return function(dispatch) {
    fetch(`${keys.baseUri}/api/traveler/intake_wizard/${userId}/get_intake`, { headers: token })
      .then(res => res.json())
      .then(data =>
        dispatch({
          type: FETCH_INTAKE_FORM,
          payload: data,
        }),
      );
  };
}

export function addIntakeProfile(userId, data) {
  return function(dispatch) {
    fetch(`${keys.baseUri}/api/traveler/intake_wizard/${userId}/update_traveler_profile/`, {
      method: 'PUT',
      body: JSON.stringify({ profile: data }),
      headers: token,
    })
      .then(res => res.json())
      .then(res => {
        if (res[0].status == 200) {
          dispatch({
            type: ADD_INTAKE_FORM_PROFILE,
            payload: data,
          });
        }
      });
  };
}

export function addIntakeCountriesOfCitizenship(userId, data) {
  return function(dispatch) {
    fetch(`${keys.baseUri}/api/traveler/intake_wizard/${userId}/update_traveler_info/`, {
      method: 'PUT',
      body: JSON.stringify({ traveler_info: data }),
      headers: token,
    })
      .then(res => res.json())
      .then(res => {
        if (res[0].status == 200) {
          dispatch({
            type: ADD_INTAKE_FORM_COUNTRIES_OF_CITIZENSHIP,
            payload: data,
          });
        }
      });
  };
}

export function addIntakeTravelerType(userId, data, travelerType) {
  return function(dispatch) {
    fetch(`${keys.baseUri}/api/traveler/intake_wizard/${userId}/update_traveler_info/`, {
      method: 'PUT',
      body: JSON.stringify({ traveler_info: data }),
      headers: token,
    })
      .then(res => res.json())
      .then(res => {
        if (res[0].status == 200) {
          dispatch({
            type: ADD_INTAKE_FORM_TRAVELER_TYPE,
            payload: travelerType,
          });
        }
      });
  };
}

export function addIntakeHomeInstitution(userId, data) {
  return function(dispatch) {
    fetch(`${keys.baseUri}/api/traveler/intake_wizard/${userId}/update_traveler_info/`, {
      method: 'PUT',
      body: JSON.stringify({ traveler_info: data }),
      headers: token,
    })
      .then(res => res.json())
      .then(res => {
        if (res[0].status == 200) {
          dispatch({
            type: ADD_INTAKE_FORM_HOME_INSTITUTION,
            payload: data,
          });
        }
      });
  };
}

export function addIntakeAdvising(userId, data) {
  return function(dispatch) {
    fetch(`${keys.baseUri}/api/traveler/intake_wizard/${userId}/update_advising/`, {
      method: 'PUT',
      body: JSON.stringify({ advising: data }),
      headers: token,
    })
      .then(res => res.json())
      .then(res => {
        if (res[0].status == 200) {
          dispatch({
            type: ADD_INTAKE_FORM_ADVISING,
            payload: data,
          });
        }
      });
  };
}

export function addIntakeFinancialInfo(userId, data) {
  return function(dispatch) {
    fetch(`${keys.baseUri}/api/traveler/intake_wizard/${userId}/update_financial_info/`, {
      method: 'PUT',
      body: JSON.stringify({ financial_info: data }),
      headers: token,
    })
      .then(res => res.json())
      .then(res => {
        if (res[0].status == 200) {
          dispatch({
            type: ADD_INTAKE_FORM_FINANCIAL_INFO,
            payload: data,
          });
        }
      });
  };
}

export function addIntakeFinancialAid(userId, selected) {
  return function(dispatch) {
    fetch(`${keys.baseUri}/api/traveler/intake_wizard/${userId}/update_financial_info/`, {
      method: 'PUT',
      body: JSON.stringify({
        financial_info: { receives_federal_financial_aid: selected },
      }),
      headers: token,
    })
      .then(res => res.json())
      .then(res => {
        if (res[0].status == 200) {
          dispatch({
            type: ADD_INTAKE_FORM_FINANCIAL_AID,
            payload: selected,
          });
        }
      });
  };
}

export function addIntakeGoal(userId, data) {
  return function(dispatch) {
    fetch(`${keys.baseUri}/api/traveler/intake_wizard/${userId}/update_travel_goal/`, {
      method: 'PUT',
      body: JSON.stringify({ travel_goal: data }),
      headers: token,
    })
      .then(res => res.json())
      .then(res => {
        if (res[0].status == 200) {
          dispatch({
            type: ADD_INTAKE_FORM_GOAL,
            payload: data,
          });
        }
      });
  };
}

export function addIntakeTravelExperience(userId, data) {
  return function(dispatch) {
    fetch(`${keys.baseUri}/api/traveler/intake_wizard/${userId}/update_travel_experience/`, {
      method: 'PUT',
      body: JSON.stringify({ travel_experience: data }),
      headers: token,
    })
      .then(res => res.json())
      .then(res => {
        if (res[0].status == 200) {
          dispatch({
            type: ADD_INTAKE_FORM_TRAVEL_EXPERIENCE,
            payload: data,
          });
        }
      });
  };
}

export function addIntakePassport(userId, data) {
  return function(dispatch) {
    fetch(`${keys.baseUri}/api/traveler/intake_wizard/${userId}/update_passport/`, {
      method: 'PUT',
      body: JSON.stringify({ passport: data }),
      headers: token,
    })
      .then(res => res.json())
      .then(res => {
        if (res[0].status == 200) {
          dispatch({
            type: ADD_INTAKE_FORM_PASSPORT,
            payload: data,
          });
        }
      });
  };
}

export function addIntakeParticipationCertainty(userId, data, intakeComplete) {
  return function(dispatch) {
    fetch(`${keys.baseUri}/api/traveler/intake_wizard/${userId}/update_traveler_info/`, {
      method: 'PUT',
      body: JSON.stringify({ traveler_info: { ...data, intake_complete: intakeComplete } }),
      headers: token,
    })
      .then(res => res.json())
      .then(res => {
        if (res[0].status == 200) {
          dispatch({
            type: ADD_INTAKE_FORM_PARTICIPATION_CERTAINTY,
            payload: {
              ...data,
              intake_complete: intakeComplete,
            },
          });
        }
      });
  };
}

export function addIntakeComplete(userId, data) {
  return function(dispatch) {
    fetch(`${keys.baseUri}/api/traveler/intake_wizard/${userId}/update_traveler_info/`, {
      method: 'PUT',
      body: JSON.stringify({ traveler_info: { intake_complete: data } }),
      headers: token,
    })
      .then(res => res.json())
      .then(res => {
        if (res[0].status == 200) {
          dispatch({
            type: ADD_INTAKE_FORM_COMPLETE,
            payload: data,
          });
        }
      });
  };
}
