import fetch from 'node-fetch';
import token from '../shared/helpers/token';
import { keys } from '../config/keys';

import {
  GET_PROGRAM_BROCHURE,
  TOGGLE_LEARN_MORE_MODAL,
  MARK_PROGRAM_BROCHURE_AMENITIES_PARSED,
  GET_AUTHORIZED_PROGRAM_ORGANIZATION_SETTINGS,
} from './types';

export const getProgram = (program_id, user_type) => dispatch => {
  fetch(`${keys.baseUri}/api/${user_type}/programs/${program_id}/get_program_hash`, {
    headers: token,
  }).then(response => {
    if (response.status === 401) {
      response.json().then(data => {
        dispatch({
          type: 'ADD_ALERT',
          text: data.message,
          style: 'danger',
        });
      });
    } else {
      response.json().then(data => {
        dispatch({
          type: GET_PROGRAM_BROCHURE,
          payload: data,
        });
      });
    }
  });
};

export const getAuthorizedProgramSettings = (id, user_type) => dispatch => {
  fetch(`${keys.baseUri}/api/${user_type}/programs/${id}/get_org_authorized_program_settings`, { headers: token }).then(
    response => {
      if (response.status === 401) {
        response.json().then(data => {
          dispatch({
            type: 'ADD_ALERT',
            text: data.message,
            style: 'danger',
          });
        });
      } else {
        response.json().then(data => {
          dispatch({
            type: GET_AUTHORIZED_PROGRAM_ORGANIZATION_SETTINGS,
            payload: data,
          });
        });
      }
    },
  );
};

export const toggleLearnMoreModal = () => dispatch => {
  dispatch({
    type: TOGGLE_LEARN_MORE_MODAL,
  });
};

export const markAmenitiesParsed = () => dispatch => {
  dispatch({
    type: MARK_PROGRAM_BROCHURE_AMENITIES_PARSED,
  });
};
