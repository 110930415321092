import React, { useState } from 'react';
import { Document, Page } from 'react-pdf';
import Arrow from '../../../public/img/arrow.svg';
import sReactPDF from './reactPDF.scss';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import CloseButton from '../CloseButton/CloseButton.js';

function ReactPDF(props) {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [show, setShow] = useState(true);
  const background = show ? sReactPDF.backgroundShow : sReactPDF.backgroundNone;
  const showHideClassName = show ? sReactPDF.modalDisplay : sReactPDF.modalDisplayNone;

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  return (
    <div id={background}>
      <div id={showHideClassName} className={sReactPDF.modal}>
        <div className={sReactPDF.header}>
          <p>Preview</p>
          <CloseButton
            isOnModal={true}
            clickHandler={() => {
              props.handleClose();
              setShow(false);
            }}
          />
        </div>
        <div className={sReactPDF['react-pdf']}>
          <Document file={props.file} onLoadSuccess={onDocumentLoadSuccess}>
            <Page pageNumber={pageNumber} height={560} className={sReactPDF.pdfPage} />
          </Document>
        </div>
        <div className={sReactPDF.footer}>
          <p className={sReactPDF.pageContainer}>
            {pageNumber === 1 || numPages === 1 ? null : (
              <img
                src={Arrow}
                className={sReactPDF.backArrow}
                onClick={() => {
                  setPageNumber(pageNumber - 1);
                }}
              />
            )}
            Page {pageNumber} of {numPages}
            {pageNumber === numPages ? null : (
              <img
                src={Arrow}
                className={sReactPDF.forwardArrow}
                onClick={() => {
                  setPageNumber(pageNumber + 1);
                }}
              />
            )}
          </p>
        </div>
      </div>
    </div>
  );
}

export default withStyles(sReactPDF)(ReactPDF);
