import React from 'react';
import { compose } from 'recompose';
import propTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import V2InputText from '../../../components/v2/inputs/text';
import Button from '../../../components/library/button';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import sStyles from './registerEventForm.scss';
import _get from 'lodash/get';

export const enhance = compose(withStyles(sStyles));

const schema = yup.object().shape({
  firstname: yup.string().required('Required'),
  lastname: yup.string().required('Required'),
  email: yup
    .string()
    .required('Required')
    .email('Must be a valid email'),
});

function RegisterEventForm(props) {
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({ resolver: yupResolver(schema) });

  const onSubmitHandler = data => {
    props.onSubmit(data);
    reset({
      firstname: '',
      lastname: '',
      email: '',
    });
  };

  return (
    <div className={sStyles.wrapper}>
      <div className={sStyles.header}>Enter your information below to confirm attendance</div>
      <div className={sStyles.form}>
        <form onSubmit={handleSubmit(onSubmitHandler)}>
          <div className={sStyles.input}>
            <label htmlFor="firstname">First Name</label>
            <Controller
              name="firstname"
              control={control}
              render={({ field: { ref, ...rest } }) => (
                <>
                  <V2InputText
                    arialLabel="Enter first name"
                    errorMessage={_get(errors.firstname, 'message') || ''}
                    id="firstname"
                    placeholder="Enter first name"
                    autofocus={true}
                    {...rest}
                  />
                </>
              )}
            />
          </div>
          <div className={sStyles.input}>
            <label htmlFor="lastname">Last Name</label>
            <Controller
              name="lastname"
              control={control}
              render={({ field: { ref, ...rest } }) => (
                <V2InputText
                  arialLabel="Enter last name"
                  errorMessage={_get(errors.lastname, 'message') || ''}
                  id="lastname"
                  placeholder="Enter last name"
                  {...rest}
                />
              )}
            />
          </div>
          <div className={sStyles.input}>
            <label htmlFor="email">Email</label>
            <Controller
              name="email"
              control={control}
              render={({ field: { ref, ...rest } }) => (
                <V2InputText
                  arialLabel="Enter email"
                  errorMessage={_get(errors.email, 'message') || ''}
                  id="email"
                  placeholder="Enter email"
                  {...rest}
                />
              )}
            />
          </div>
          <div className={sStyles.button}>
            <Button
              className={sStyles.confirmButton}
              display="clientAccent"
              ariaLabel="Confirm Registration"
              kind="solid"
              size="normal"
              type="submit"
            >
              Confirm Registration
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
}

RegisterEventForm.propTypes = {
  eventId: propTypes.string.isRequired,
  onSubmit: propTypes.func.isRequired,
};

export default enhance(RegisterEventForm);
