import React from 'react';
import { Modal } from 'react-responsive-modal';
import styled, { ThemeProvider }  from 'styled-components';
import StyledIcon from '../../../../../styledComponents/styles/Icon.styled';
import { themeViaForms } from '../../../../../styledComponents/settings/theme';

const FlexContainer = styled.div``;

const Header = styled.h1`
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 400;
  font-size: 25px;
  line-height: 120%;
  color: #373737;
  padding-left: 16px;
`;

const ChildContainer = styled.div`
  margin-top: 20px;
  margin-left: -19px;
  margin-right: -19px;
  border-top: 1px solid #f0f1f1;
`;

const GenericStyledModal = (props) => {
  const { children } = props;

  return (
    <ThemeProvider theme={themeViaForms}>
      <FlexContainer>
        <Modal
          blockScroll={false}
          open={props.showing}
          onClose={() => props.onClose(false)}
          center
          styles={{
            overlay: { background: 'rgba(0, 0, 0, 0.65)' },
            modal: {
              borderRadius: '15px',
              maxWidth: !!props.maxWidth ? props.maxWidth : '1000px',
              //minWidth: !!props.width ? props.width : '1000px',
              width: !!props.width ? props.width : '1000px',
              height: !!props.height ? props.height : null,
              padding: !!props.padding ? props.padding : "1.2rem",
              paddingBottom: !!props.paddingBottom ? props.paddingBottom : "1.2rem",
            },
            closeButton: {
              marginTop: '5.8px',
              marginRight: '20.8px',
              width: '24px',
              height: '24px',
            },
          }}
          closeOnEsc={props.closeable}
          closeOnOverlayClick={props.closeable}
          showCloseIcon={props.closeable}
          closeIcon={<StyledIcon type={'Close'} color={'#9FABAE'} size={'24px'} />}
        >
          <Header>{props.header} </Header>
          <ChildContainer>{children}</ChildContainer>
        </Modal>
      </FlexContainer>
    </ThemeProvider>
  );
}
GenericStyledModal.displayName = 'GenericStyledModal';

export default GenericStyledModal;
