import fetch from 'node-fetch';
import history from '../history';
import token from '../shared/helpers/token';
import { keys } from '../config/keys';
import _clone from 'lodash/clone';
import {
  FETCH_PROGRAM,
  GET_PROGRAM,
  FETCH_PROGRAM_FOR_TRAVELER,
  FETCH_TRAVELER_PERCENT_MATCH_ONE,
  TOGGLE_FAVORITE,
  REQUEST_TO_APPLY,
  GET_AUTHORIZED_PROGRAM_SETTINGS,
  ASK_QUESTION,
  FETCH_PROGRAM_CONTACTS,
  TOGGLE_PROGRAM_PUBLISH,
  FETCH_AMMENITIES,
  FETCH_SUBJECTS,
  PROGRAM_ERRORS,
  CONTACT_PROGRAM_CONTACT,
  CLEAR_IS_CONTACTED,
  CLEAR_QUESTION_CODE,
  UPDATE_PROGRAM_LOADER,
  SAVE_NEW_DOCUMENT,
  DELETE_PROGRAM_ATTACHMENT,
  UPDATE_PROGRAM_ATTACHMENT,
  FAVORITE_DISCLAIMER_ACCEPTED
} from './types';

export function fetchProgram(id) {
  return function(dispatch) {
    fetch(`${keys.baseUri}/api/client/programs/${id}/get_program`, {
      headers: token,
    })
      .then(res => res.json())
      .then(data =>
        dispatch({
          type: FETCH_PROGRAM,
          payload: data,
        }),
      );
  };
}

export function fetchAuthorizedProgramSettings(id, userType) {
  if (!token && userType !== 'visitor') {
    client.log('No authentication token; please login');
    window.location.assign(`/`);
  }
  return function(dispatch) {
    fetch(`${keys.baseUri}/api/${userType}/programs/${id}/get_org_authorized_program_settings`, { headers: token })
      .then(res => res.json())
      .then(data =>
        dispatch({
          type: GET_AUTHORIZED_PROGRAM_SETTINGS,
          payload: data,
        }),
      );
  };
}

export function fetchTravelerPercent(id) {
  return function(dispatch) {
    fetch(`${keys.baseUri}/api/traveler/programs/${id}/get_traveler_percent_match_one`, { headers: token })
      .then(res => res.json())
      .then(data =>
        dispatch({
          type: FETCH_TRAVELER_PERCENT_MATCH_ONE,
          payload: data,
        }),
      );
  };
}

export function toggleFavorite(id, userId, isFavDisAccepted) {
  return function(dispatch) {
    fetch(`${keys.baseUri}/api/traveler/programs/${id}/favorite_program`, {
      method: 'POST',
      headers: token,
    })
      .then(res => res.json())
      .then(data => {
          dispatch({
            type: TOGGLE_FAVORITE,
            payload: data,
          })
          if(isFavDisAccepted){
            dispatch(addIntakeTravelerInfoUpdateFavourite(userId))
          }
        }
      );
  };
}

export function addIntakeTravelerInfoUpdateFavourite(id) {
  return function(dispatch) {
    fetch(`${keys.baseUri}/api/traveler/intake_wizard/${id}/update_traveler_info`, {
      method: 'PUT',
      body: JSON.stringify({ traveler_info: { program_favorite_disclaimer_accepted: true } }),
      headers: token,
    })
      .then(res => res.json())
      .then(res => {
        if (res[0].status == 200) {
          dispatch({
            type: FAVORITE_DISCLAIMER_ACCEPTED,
            payload: true,
          })
        } else {
          dispatch({
            type: FAVORITE_DISCLAIMER_ACCEPTED,
            payload: false,
          });
        }
      });
  };
}

export function requestToApply(id) {
  return function(dispatch) {
    fetch(`${keys.baseUri}/api/traveler/programs/${id}/request_to_apply`, {
      method: 'POST',
      headers: token,
    })
      .then(res => res.json())
      .then(data => {
        dispatch({
          type: REQUEST_TO_APPLY,
          payload: data,
        });
      });
  };
}

export function askQuestion(id, data) {
  return function(dispatch) {
    fetch(`${keys.baseUri}/api/traveler/programs/${id}/ask_question`, {
      method: 'POST',
      headers: token,
      body: JSON.stringify({
        subject: data.subject,
        question: data.question,
      }),
    })
      .then(res => res.json())
      .then(data => {
        dispatch({
          type: ASK_QUESTION,
          payload: data,
        });
      });
  };
}

export function fetchProgramContacts(id) {
  if (!token) {
    window.location.assign(`/`);
  }
  return function(dispatch) {
    fetch(`${keys.baseUri}/api/traveler/programs/${id}/get_program_contacts`, {
      headers: token,
    })
      .then(res => res.json())
      .then(data =>
        dispatch({
          type: FETCH_PROGRAM_CONTACTS,
          payload: data,
        }),
      );
  };
}

export function toggleProgramPublish(id) {
  return function(dispatch) {
    fetch(`${keys.baseUri}/api/client/programs/${id}/toggle_program_publish`, {
      method: 'POST',
      headers: token,
    })
      .then(res => res.json())
      .then(data =>
        dispatch({
          type: TOGGLE_PROGRAM_PUBLISH,
          payload: data,
        }),
      );
  };
}

export function fetchSubjects() {
  return function(dispatch) {
    fetch(`${keys.baseUri}/api/client/programs/new`, { headers: token })
      .then(res => res.json())
      .then(data =>
        dispatch({
          type: FETCH_SUBJECTS,
          payload: data,
        }),
      );
  };
}

export function createProgramDraft(data, route = 'setting') {
  return function(dispatch) {
    fetch(`${keys.baseUri}/api/client/programs`, {
      method: 'POST',
      headers: token,
      body: JSON.stringify(data),
    })
      .then(res => res.json())
      .then(data => {
        if (data.id) {
          if (route == 'setting') {
            window.location.assign(`/client/programs/${data.id}/program_settings`);
          } else {
            window.location.assign(`/client/programs`);
          }
        } else {
          dispatch({
            type: PROGRAM_ERRORS,
            payload: data,
          });
        }
      });
  };
}

export const updateProgram = (program_id, data, route = 'setting', status = '') => dispatch => {
  fetch(`${keys.baseUri}/api/client/programs/${program_id}`, {
    method: 'PATCH',
    headers: token,
    body: JSON.stringify(data),
  })
    .then(res => res.json())
    .then(data => {
      if (data.status === 400) {
        dispatch({
          type: PROGRAM_ERRORS,
          payload: data,
        });
      } else {
        if (route == 'setting') {
          window.location.assign(`/client/programs/${program_id}/program_settings`);
        } else if (status == 'draft') {
          window.location.assign(`/client/programs?q=draft`);
        } else {
          window.location.assign(`/client/programs/${program_id}`);
        }
      }
    });
};

export const updateProgramLatLng = (program_id, data) => dispatch => {
  fetch(`${keys.baseUri}/api/client/programs/${program_id}/update_lat_lng`, {
    method: 'PATCH',
    headers: token,
    body: JSON.stringify(data),
  })
    .then(res => res.json())
    .then(data => {
      if (data.status === 400) {
        dispatch({
          type: PROGRAM_ERRORS,
          payload: data,
        });
      }
    });
};

export const publishProgram = program_id => dispatch => {
  fetch(`${keys.baseUri}/api/client/programs/${program_id}/publish_program`, {
    method: 'PATCH',
    headers: token,
  })
    .then(res => res.json())
    .then(data => {
      if (data.status === 400) {
        dispatch({
          type: PROGRAM_ERRORS,
          payload: data,
        });
      } else {
        window.location.assign(`/client/programs/${program_id}`);
      }
    });
};

export const getAmenities = () => dispatch => {
  fetch(`${keys.baseUri}/api/client/amenities`, {
    method: 'GET',
    headers: token,
  })
    .then(res => res.json())
    .then(data => {
      dispatch({
        type: FETCH_AMMENITIES,
        payload: data,
      });
    });
};
/* Program Hash For Both Types */
export const getProgram = (program_id, user_type) => dispatch => {
  fetch(`${keys.baseUri}/api/${user_type}/programs/${program_id}/get_program_hash`, {
    headers: token,
  }).then(response => {
    if (response.status == 401) {
      response.json().then(data => {
        dispatch({
          type: 'ADD_ALERT',
          text: data.message,
          style: 'danger',
        });
        history.push('/client/program-dashboard/internal');
      });
    } else {
      response.json().then(data => {
        dispatch({
          type: GET_PROGRAM,
          payload: data,
        });
      });
    }
  });
};
export const contactProgramContact = programId => dispatch => {
  fetch(`${keys.baseUri}/api/traveler/programs/${programId}/contact_program_contact`, {
    method: 'POST',
    headers: token,
  })
    .then(response => response.json())
    .then(data =>
      dispatch({
        type: CONTACT_PROGRAM_CONTACT,
        payload: data.code,
      }),
    )
    .catch(error =>
      dispatch({
        type: CONTACT_PROGRAM_CONTACT,
        payload: data.code,
      }),
    );
};

export const clearIsContacted = () => dispatch => {
  dispatch({
    type: CLEAR_IS_CONTACTED,
  });
};

export const clearQuestionCode = () => dispatch => {
  dispatch({
    type: CLEAR_QUESTION_CODE,
  });
};

export const updateProgramLoader = flag => dispatch => {
  dispatch({
    type: UPDATE_PROGRAM_LOADER,
    payload: flag,
  });
};

export const saveNewDocument = data => dispatch => {
  const getProgramData = data.program_id;
  const formData = new FormData();
  const objectKeys = Object.keys(data);

  let userToken = _clone(token);
  delete userToken['Accept'];
  delete userToken['Content-Type'];

  objectKeys.map(object => {
    formData.append(`program_attachment[${object}]`, data[object]);
  });

  fetch(`${keys.baseUri}/api/client/program_attachments`, {
    method: 'POST',
    headers: userToken,
    body: formData,
  })
    .then(res => res.json())
    .then(data => {
      dispatch({
        type: SAVE_NEW_DOCUMENT,
        payload: data,
      });
      dispatch(getProgram(getProgramData, 'client'));
    })
    .catch(error => console.error(error));
};

export const deleteProgramAttachment = data => {
  const getProgramData = data.program_id;

  return function(dispatch) {
    fetch(`${keys.baseUri}/api/client/program_attachments/${data.id}`, {
      method: 'DELETE',
      headers: token,
      body: JSON.stringify(data),
    }).then(data => {
      dispatch({
        type: DELETE_PROGRAM_ATTACHMENT,
        body: data,
      });
      dispatch(getProgram(getProgramData, 'client'));
    });
  };
};

export const updateProgramAttachment = data => {
  const getProgramData = data.program_id;

  return function(dispatch) {
    fetch(`${keys.baseUri}/api/client/program_attachments/${data.id}`, {
      method: 'PATCH',
      headers: token,
      body: JSON.stringify(data),
    }).then(data => {
      dispatch({
        type: UPDATE_PROGRAM_ATTACHMENT,
        body: data,
      });
      dispatch(getProgram(getProgramData, 'client'));
    });
  };
};
