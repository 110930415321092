import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import SelectInput from '../../base/inputs/SelectInput';
import TravelerFormQuestionContainer from '../../base/TravelerFormQuestionContainer';

import userId from '../../../../../shared/helpers/userId';
import countries from '../../../../../../public/data/countries.json';
import { addIntakeCountriesOfCitizenship } from '../../../actions/intakeFormActions';

export default function IntakeCountriesOfCitizenship() {
  const dispatch = useDispatch();
  const intake = useSelector(state => state.intakeForm);

  const [value, setValue] = useState([]);
  const [error, setError] = useState('');
  const [disabled, setDisabled] = useState(false);

  const question = {
    overline: 'Countries of Citizenship',
    heading: 'Great, what are your countries of citizenship?',
    subheading: 'Select all countries of which you are a citizen.',
    isRequired: true,
  };

  useEffect(
    () => {
      const initialCountries = countries.filter(country => intake.countries_of_citizenship.includes(country.value));

      setValue(initialCountries);

      setDisabled(intake.ssoTraveler && intake.readonly.includes('countries_of_citizenship'));
    },
    [intake],
  );

  const handleNext = () => {
    setError("");
    if (!disabled && value.length === 0) {
      setTimeout(()=>{
        setError('Required, please select an option.');
      },0) 
      throw new Error();
    }

    if (!disabled) {
      const countries = value.map(country => country.value.trim());

      dispatch(addIntakeCountriesOfCitizenship(userId, { countries_of_citizenship: countries }));
    }
  };

  const handleOnChange = event => {
    if (event) {
      setValue(event);
    } else {
      setValue([]);
    }
  };

  return (
    <TravelerFormQuestionContainer question={question} handleNext={handleNext} disabled={disabled} canBeSynced>
      <SelectInput
        value={value}
        defaultValue={value}
        id="countries_of_citizenship"
        label="Countries of Citizenship"
        placeholder="Select country"
        options={countries}
        error={error}
        disabled={disabled}
        isMulti
        required
        handleOnChange={handleOnChange}
      />
    </TravelerFormQuestionContainer>
  );
}
