import React from 'react';
import customPersonIcon from '../../../../shared/images/custom-icons-person-circle.png';
import customPeopleIcon from '../../../../shared/images/custom-icons-people-circle.png';

const customLowerCaseComparator = (valueA, valueB) => {
  if (typeof valueA === 'string') {
    return valueA?.toLowerCase().localeCompare(valueB?.toLowerCase());
  }

  return valueA > valueB ? 1 : valueA < valueB ? -1 : 0;
};

const travelTypeIcon = params => {
  const iconName = params.data.type.toLowerCase();

  return (
    <div style={{ width: '100%', textAlign: 'center' }}>
      {iconName === 'solo' ? (
        <img src={customPersonIcon} alt={'Person Icon'} width={'30px'} />
      ) : (
        <img src={customPeopleIcon} alt={'People Icon'} width={'30px'} />
      )}
    </div>
  );
};

const defaultColDefaults = () => {
  return {
    minWidth: 5,
    width: 175,
    resizable: true,
    enableValue: true,
    enableRowGroup: false,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    sortable: true,
    enablePivot: true,
    suppressHorizontalScroll: false,
    comparator: customLowerCaseComparator,
    newRowsAction: 'keep',
    suppressRowClickSelection: true,
  };
};

const defaultSidebarDefaults = () => {
  return {
    toolPanels: [
      {
        id: 'columns',
        labelDefault: 'Add Columns',
        labelKey: 'columns',
        iconKey: 'columns',
        toolPanel: 'agColumnsToolPanel',
        minWidth: 360,
        maxWidth: 360,
        width: 360,
        toolPanelParams: {
          suppressRowGroups: true,
          suppressPivots: true,
          suppressPivotMode: true,
          suppressValues: true,
        },
      },
      {
        id: 'filters',
        labelDefault: 'Custom Filters',
        labelKey: 'filters',
        iconKey: 'filter',
        toolPanel: 'agFiltersToolPanel',
        minWidth: 180,
        maxWidth: 400,
        width: 250,
        toolPanelParams: {
          suppressRowGroups: true,
          suppressPivots: true,
          suppressPivotMode: true,
          suppressValues: true,
        },
      },
    ],
    position: 'right',
  };
};

const defaultStatusBarDefaults = () => {
  return {
    statusPanels: [
      { statusPanel: 'agTotalAndFilteredRowCountComponent', align: 'left' },
      { statusPanel: 'agTotalRowCountComponent', align: 'center' },
      { statusPanel: 'agFilteredRowCountComponent' },
      { statusPanel: 'agSelectedRowCountComponent' },
      // { statusPanel: 'agAggregationComponent' },
    ],
  };
};

export { defaultColDefaults, defaultSidebarDefaults, defaultStatusBarDefaults, travelTypeIcon };
