import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import DatesFilterInputs from './DatesFilterInputs';
import ProgramFilterDropdown from '../ProgramFilterDropdown';

import useDatesOptions from '../../../../hooks/useDatesOptions';
import { toggleProgramsFilter } from '../../../../actions/programDashboardActions';

export default function DesktopDatesFilter({ handleClose }) {
  const dispatch = useDispatch();

  const [
    endDate,
    setEndDate,
    startDate,
    setStartDate,
    endDateInclusive,
    setEndDateInclusive,
    startDateInclusive,
    setStartDateInclusive,
    endRange,
    setEndRange,
    startRange,
    setStartRange,
    resetDateValues,
  ] = useDatesOptions();

  const handleClearAll = () => {
    resetDateValues();

    dispatch(
      toggleProgramsFilter({
        name: 'startDate',
        value: {
          date: null,
          range: null,
          dateInclusive: null,
        },
      }),
    );

    dispatch(
      toggleProgramsFilter({
        name: 'endDate',
        value: {
          date: null,
          range: null,
          dateInclusive: null,
        },
      }),
    );
  };

  const handleFilter = () => {
    dispatch(
      toggleProgramsFilter({
        name: 'startDate',
        value: {
          date: startDate,
          range: startRange,
          dateInclusive: startDateInclusive,
        },
      }),
    );

    dispatch(
      toggleProgramsFilter({
        name: 'endDate',
        value: {
          date: endDate,
          range: endRange,
          dateInclusive: endDateInclusive,
        },
      }),
    );

    handleClose();
  };

  return (
    <ProgramFilterDropdown handleFilter={handleFilter} handleClearAll={handleClearAll} handleClose={handleClose}>
      <DatesFilterInputs
        endRange={endRange}
        startRange={startRange}
        startDate={startDate}
        endDate={endDate}
        startDateInclusive={startDateInclusive}
        endDateInclusive={endDateInclusive}
        handleEndRangeChange={setEndRange}
        handleStartRangeChange={setStartRange}
        handleStartDateChange={setStartDate}
        handleEndDateChange={setEndDate}
        handleStartDateInclusiveChange={setStartDateInclusive}
        handleEndDateInclusiveChange={setEndDateInclusive}
      />
    </ProgramFilterDropdown>
  );
}

DesktopDatesFilter.propTypes = {
  handleClose: PropTypes.func.isRequired,
};
