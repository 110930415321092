import styled from 'styled-components';

import { breakpoints } from '../../../utils/breakpoints';

export const CheckboxGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 0.5rem;
  max-height: 250px;
  padding: 1rem 1.25rem;
  border: 1px solid #eeeeee;
  overflow-y: scroll;
  scrollbar-gutter: stable;

  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 3px;
  }

  ::-webkit-scrollbar-track {
    background: #eeeeee;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 50px;
    background-color: #c4c4c4;
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
    box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }

  .error-message {
    justify-self: center;
  }

  @media ${breakpoints.tablet} {
    max-height: none;
    margin-top: 1.75rem;
    padding: 0;
    border: none;
    overflow-y: none;

    ::-webkit-scrollbar {
      width: 0;
    }
  }
`;

export const RadioGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 0.9375rem;
  max-height: 250px;
  margin-top: 2rem;
  padding: 1rem 1.25rem;
  border: 1px solid #eeeeee;
  overflow-y: scroll;
  scrollbar-gutter: stable;

  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 3px;
  }

  ::-webkit-scrollbar-track {
    background: #eeeeee;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 50px;
    background-color: #c4c4c4;
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
    box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }

  .error-message {
    justify-self: center;
  }

  @media ${breakpoints.tablet} {
    max-height: none;
    margin-top: 1.6875rem;
    margin-right: 3.3rem;
    margin-left: 2.9375rem;
    padding: 0;
    border: none;
    overflow-y: none;

    ::-webkit-scrollbar {
      width: 0;
    }
  }

  @media ${breakpoints.desktop} {
    margin-right: 8.10125rem;
  }
`;
