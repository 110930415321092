import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import isEmpty from '../../../../../utils/isEmpty';
import { breakpoints } from '../../../../../utils/breakpoints';

const Container = styled.section`
  max-width: 60.375rem;
  margin: 1.5rem auto 3rem;
  padding: 1.5rem 2.4375rem;
  display: flex;
  flex-direction: column;
  font-family: 'Nunito', sans-serif;
  gap: 0.75rem;

  @media ${breakpoints.tablet} {
    gap: 1.5rem;
  }

  @media ${breakpoints.desktop} {
    padding-left: 0;
    padding-right: 0;
  }

  p {
    margin-bottom: 0;
    font-style: normal;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 120%;
    letter-spacing: 0.0025em;
    color: #000000;
  }
`;

const Heading = styled.h2`
  margin-bottom: 0;
  font-style: normal;
  font-weight: 300;
  line-height: 120%;
  letter-spacing: 0.0025em;
  color: #373737;
  font-size: 1.3125rem;

  @media ${breakpoints.tablet} {
    font-size: 1.75rem;
  }
`;

export default function OrganizationalInformation() {
  const description = useSelector(state => state.programBrochure.organization.description);

  if (isEmpty(description)) return null;

  return (
    <Container>
      <Heading>Organization Information</Heading>
      <div dangerouslySetInnerHTML={{ __html: description }} />
    </Container>
  );
}
