import React, { useState } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';

import Modal from './Modal';

import { ModalButton } from './Modal.styled';
import hearticon from './../../../../../shared/images/heart-icon.png';

const FlexContainer = styled.div`
  display: flex;
  flex-direction: column;
  font-family: Open Sans, sans-serif;
  font-size: 16px;
  font-weight: 400;
`;

const FooterContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  background-color: #ebebeb;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;

  button {
    margin: 15px;
  }
`;

const FooterButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-right: 20px;
  button {
    margin: 15px;
  }
`;
const Header = styled.h1`
  color: #0b3544;
  font-size: 25px;
  padding: 20px 30px;
  padding-bottom: 20px;
  border-bottom: 3px solid #ebebeb;
  font-weight: 300;
`;

const Instructions = styled.div`
  font-family: Open Sans;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: #373737;
  width: 90%;
  margin: 15px auto;
`;

const ImageTag = styled.img`
  width: 135px;
`;
const LeftContainer = styled.div`
  display: flex;
  float: left;
  width: 30%;
  justify-content: center;
  align-items: center;
  height: 118px;
`;
const RightContainer = styled.div`
  display: block;
  float: right;
  width: 70%;
`;
const Clear = styled.div`
  clear: both;
`;
const BoldInstructions = styled.div`
  font-weight: 600;
  margin-bottom: 20px;
`;
const NormalInstructions = styled.div`
  margin-bottom: 20px;
`;

const SmallText = styled.div`
  font-size: 12px;
`;

const LinkTag = styled.a`
  text-decoration: none;
  color: #124a67;
  &:hover {
    text-decoration: none;
  }
`;

export default function FavoriteProgramModal(props) {
  const customAliases = useSelector(state => state.profile.customAliases);

  const handleFavoriteSubmit = () => {
    props.onSubmit();
    props.onClose(false);
  };

  const handleClose = () => {
    props.onClose(false);
  };

  return (
    <Modal
      show={props.show}
      title={`About ${customAliases.alias_favorite || 'Favorite'} ${customAliases.alias_programs}`}
      close={handleClose}
      styles={{ width: '519px' }}
    >
      <FlexContainer>
        <Instructions>
          <div>
            <LeftContainer>
              <ImageTag src={hearticon} alt='Add to favorite' />
            </LeftContainer>
            <RightContainer>
              <BoldInstructions>
                By selecting "{customAliases.alias_favorite || 'Favorite'}" you can save programs to filter, revisit or
                let {customAliases.alias_program} admins know you're interested.
              </BoldInstructions>
              <NormalInstructions>
                This tool will help you find {customAliases.alias_programs} you like, and plan your study abroad journey
                effectively.
              </NormalInstructions>
            </RightContainer>
            <Clear />
          </div>
          <SmallText>
            By using the favorite tool, you consent to share your contact information to your selected{' '}
            {customAliases.alias_program} administrators to receive additional {customAliases.alias_program}{' '}
            information. Learn more in our{' '}
            <LinkTag href="https://www.viatrm.com/privacy-policy/" target="_blank">
              privacy policy
            </LinkTag>
            .
          </SmallText>
        </Instructions>
        <FooterContainer>
          <FooterButtonContainer>
            <ModalButton type="button" className="secondary" onClick={handleClose}>
              Cancel
            </ModalButton>
            <ModalButton type="button" className="primary" onClick={handleFavoriteSubmit}>
              Okay
            </ModalButton>
          </FooterButtonContainer>
        </FooterContainer>
      </FlexContainer>
    </Modal>
  );
}
