import React, { useEffect, useState, memo } from 'react';
import { compose } from 'recompose';
import { useDispatch, useSelector } from 'react-redux';
import propTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import { fetchClientAdmins } from '../../../../../actions/lookupActions';
import { buildSelectOptions } from '../../../../../shared/helpers/General';
import FilterMultiSelect from '../../filterTypes/filterMultiSelect';
import sFilterAccordion from '../../../../../../public/css/filterAccordion.scss';
import sModal from 'react-responsive-modal/styles.css';

export const enhance = compose(withStyles(sFilterAccordion));

function ProgramClientAdminsFilter(props) {
  const dispatch = useDispatch();
  const clientAdmins = useSelector(state => state.lookup.allClientAdmins);
  const [expanded, setExpanded] = useState(false);
  const [manualExpanded, setManualExpanded] = useState(false);
  const [sourceOptions, setSourceOptions] = useState([]);

  useEffect(() => {
    if (props.selectedValues) {
      const calcExpanded = manualExpanded || props.selectedValues.length > 0 ? true : false;
      setExpanded(calcExpanded);
    }
  }, [props]);

  useEffect(() => {
    let unmounted = false;
    if (!unmounted) {
      if (!clientAdmins.clientAdmins) {
        if (!props.skipDataload) {
          dispatch(fetchClientAdmins());
        }
      } else {
        let activeAdmins = [];
        let archivedAdmins = [];

        clientAdmins.clientAdmins.map(item => {
          if (!item.attributes.archived) {
            activeAdmins.push(item.attributes.full_name_or_email);
          } else {
            archivedAdmins.push(item.attributes.full_name_or_email);
          }
        });
        activeAdmins = buildSelectOptions(activeAdmins, true, true);
        archivedAdmins = buildSelectOptions(archivedAdmins, true, true);

        const groupOptions = [
          {
            label: 'Active',
            options: activeAdmins,
          },
          {
            label: 'Archived',
            options: archivedAdmins,
          },
        ];

        setSourceOptions(groupOptions);
      }
    }
    return () => {
      unmounted = true;
    };
  }, [clientAdmins]);

  const selectedValues = values => {
    props.change(values);
  };

  return (
    <Accordion
      allowMultipleExpanded
      allowZeroExpanded
      onChange={() => {
        setExpanded(!expanded);
        setManualExpanded(!manualExpanded);
      }}
    >
      <AccordionItem uuid={props.id} dangerouslySetExpanded={expanded}>
        <AccordionItemHeading>
          <AccordionItemButton>{props.displayText}</AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel>
          <FilterMultiSelect
            id={props.id}
            sourceData={sourceOptions}
            selectedValues={props.selectedValues}
            change={e => selectedValues(e)}
            labelText={''}
          />
        </AccordionItemPanel>
      </AccordionItem>
    </Accordion>
  );
}

ProgramClientAdminsFilter.propTypes = {
  change: propTypes.func.isRequired,
  displayText: propTypes.string,
  id: propTypes.string.isRequired,
  selectedValues: propTypes.array,
  skipDataload: propTypes.bool,
};

ProgramClientAdminsFilter.defaultProps = {
  displayText: 'Program Client Administrators',
  selectedValues: [],
  skipDataload: false,
};

export default enhance(ProgramClientAdminsFilter);

export const ProgramContactClientAdminsFilterMemo = memo(
  compose(withStyles(sFilterAccordion))(function ProgramContactClientAdminsFilterMemo({
    selectedValues,
    onFilterChange,
    skipDataload = false,
  }) {
    const customAliases = useSelector(state => state.profile.customAliases);

    return (
      <ProgramClientAdminsFilter
        id={'program_contact'}
        selectedValues={selectedValues}
        change={values => {
          onFilterChange('program_contact', values);
        }}
        displayText={`Internal ${customAliases.alias_program} Contact`}
        skipDataload={skipDataload}
      />
    );
  }),
);

export const InternalProgramClientAdminsFilterMemo = memo(
  compose(withStyles(sFilterAccordion))(function InternalProgramClientAdminsFilterMemo({
    selectedValues,
    onFilterChange,
    skipDataload = false,
  }) {
    const customAliases = useSelector(state => state.profile.customAliases);

    return (
      <ProgramClientAdminsFilter
        id={'program_administrators'}
        selectedValues={selectedValues}
        change={values => {
          onFilterChange('program_administrators', values);
        }}
        displayText={`Internal ${customAliases.alias_program} Administrator`}
        skipDataload={skipDataload}
      />
    );
  }),
);

export const MessageRecipientsMemo = memo(
  compose(withStyles(sFilterAccordion))(function MessageRecipientsMemo({
    selectedValues,
    onFilterChange,
    skipDataload = false,
  }) {
    return (
      <ProgramClientAdminsFilter
        id={'message_recipients'}
        selectedValues={selectedValues}
        change={values => {
          onFilterChange('message_recipients', values);
        }}
        displayText={`Message Recipients`}
        skipDataload={skipDataload}
      />
    );
  }),
);
