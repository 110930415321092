import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import ProfileFormGrid from '../../base/ProfileFormGrid.styled';
import ProfileTextInput from '../../base/inputs/ProfileTextInput';
import ProfileSelectInput from '../../base/inputs/ProfileSelectInput';

import travelerTypeOptions from '../../../utils/options/travelerTypeOptions';
import yearInSchoolOptions from '../../../utils/options/yearInSchoolOptions';
import graduationYearOptions from '../../../utils/options/graduationYearOptions';

export default function CurrentAcademicInfoForm({ form, setForm }) {
  const { info, readonly } = useSelector(state => state.travelerProfile);
  const travelerAlias = useSelector(state => state.currentOrganization.customAliases.alias_traveler);
  const [graduationYearOptionList, setGraduationYearOptionList] = useState([...graduationYearOptions]);
  const [yearInSchoolOptionList, setYearInSchoolOptionList] = useState([...yearInSchoolOptions]);
  const [travelerTypeOptionList, setTravelerTypeOptionList] = useState([...travelerTypeOptions]);

  useEffect(() => {
    // mapping graduation year

    let newGraduationYearOptionList = [...graduationYearOptionList];

    if (info.grad_year) {
      let graduation = graduationYearOptionList.find(
        option => option.value === info.grad_year || option.label === info.grad_year,
      );

      if (!graduation) {
        newGraduationYearOptionList = [
          ...newGraduationYearOptionList,
          {
            id: Date.now(),
            label: info.grad_year,
            value: info.grad_year,
          },
        ];

        setGraduationYearOptionList([...newGraduationYearOptionList]);
      }
    }

    // mapping traveler type

    let newTravelerTypeOptionList = [...travelerTypeOptionList];

    if (info.traveler_type) {
      let travelerType = travelerTypeOptionList.find(
        option => option.value === info.traveler_type || option.label === info.traveler_type,
      );

      if (!travelerType) {
        newTravelerTypeOptionList = [
          ...newTravelerTypeOptionList,
          {
            id: Date.now(),
            label: info.traveler_type,
            value: info.traveler_type,
          },
        ];

        setTravelerTypeOptionList([...newTravelerTypeOptionList]);
      }
    }

    //mapping year in school

    let newYearInSchoolOptionList = [...yearInSchoolOptionList];

    if (info.year_in_school) {
      let yearInSchool = yearInSchoolOptionList.find(
        option => option.value === info.year_in_school || option.label === info.year_in_school,
      );

      if (!yearInSchool) {
        newYearInSchoolOptionList = [
          ...newYearInSchoolOptionList,
          {
            id: Date.now(),
            label: info.year_in_school,
            value: info.year_in_school,
          },
        ];

        setYearInSchoolOptionList([...newYearInSchoolOptionList]);
      }
    }

    setForm({
      advisor: info.advisor,
      student_id: info.student_id,
      school_name: info.school_name,
      hours_earned: info.hours_earned || '',
      cumulative_gpa: info.cumulative_gpa || '',
      places_of_study: info.places_of_study[0],
      grad_year: newGraduationYearOptionList.find(option => option.value === info.grad_year) || '',
      traveler_type: newTravelerTypeOptionList.find(option => option.value === info.traveler_type) || '',
      year_in_school: newYearInSchoolOptionList.find(option => option.value === info.year_in_school) || '',
    });
  }, [info]);

  return (
    <ProfileFormGrid>
      <ProfileTextInput
        id="student_id"
        label="Student ID"
        placeholder="Enter student ID"
        value={form.student_id}
        synced={readonly.includes('student_id')}
        handleOnChange={event => {
          setForm({ ...form, student_id: event.target.value });
        }}
      />
      <ProfileTextInput
        id="home_campus"
        label="Home Campus"
        placeholder="Enter home campus"
        value={form.school_name}
        synced={readonly.includes('school_name')}
        handleOnChange={event => {
          setForm({ ...form, school_name: event.target.value });
        }}
      />
      <ProfileTextInput
        id="college_or_faculty"
        label="College or Faculty"
        placeholder="Enter college or faculty"
        value={form.places_of_study}
        synced={readonly.includes('places_of_study')}
        handleOnChange={event => {
          setForm({ ...form, places_of_study: event.target.value });
        }}
      />
      <ProfileSelectInput
        id="traveler_type"
        label={`${travelerAlias} Type`}
        placeholder={`Select ${travelerAlias.toLowerCase()} type`}
        value={form.traveler_type}
        options={travelerTypeOptionList}
        synced={readonly.includes('traveler_type')}
        handleOnChange={event => {
          setForm({ ...form, traveler_type: event });
        }}
      />
      <ProfileSelectInput
        id="year_in_school"
        label="Year in School"
        placeholder="Select year in school"
        value={form.year_in_school}
        options={yearInSchoolOptionList}
        synced={readonly.includes('year_in_school')}
        handleOnChange={event => {
          setForm({ ...form, year_in_school: event });
        }}
      />
      <ProfileSelectInput
        id="graduation_year"
        label="Anticipated Graduation Year"
        placeholder="Select anticipated graduation year"
        value={form.grad_year}
        options={graduationYearOptionList}
        synced={readonly.includes('grad_year')}
        handleOnChange={event => {
          setForm({ ...form, grad_year: event });
        }}
      />
      <ProfileTextInput
        id="advisor"
        label="Advisor"
        placeholder="Enter advisor"
        value={form.advisor}
        synced={readonly.includes('advisor')}
        handleOnChange={event => {
          setForm({ ...form, advisor: event.target.value });
        }}
      />
      <ProfileTextInput
        id="cumulative_gpa"
        type="number"
        label="GPA - Cumulative"
        placeholder="Enter cumulative GPA"
        value={form.cumulative_gpa}
        synced={readonly.includes('cumulative_gpa')}
        handleOnChange={event => {
          setForm({ ...form, cumulative_gpa: event.target.value });
        }}
      />
      <ProfileTextInput
        id="hours_earned"
        type="number"
        label="Hours Earned"
        placeholder="Enter hours earned"
        value={form.hours_earned}
        synced={readonly.includes('hours_earned')}
        handleOnChange={event => {
          setForm({ ...form, hours_earned: event.target.value });
        }}
      />
    </ProfileFormGrid>
  );
}

CurrentAcademicInfoForm.propTypes = {
  form: PropTypes.shape({
    advisor: PropTypes.string,
    student_id: PropTypes.string,
    school_name: PropTypes.string,
    hours_earned: PropTypes.string,
    cumulative_gpa: PropTypes.string,
    grad_year: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    traveler_type: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    year_in_school: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    places_of_study: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  }).isRequired,
  setForm: PropTypes.func.isRequired,
};
