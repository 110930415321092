import { createTheme } from '@material-ui/core/styles';
import { getContrastColor } from './General';

const viaMuiTheme = (hideChip = false, pageSubNav = true) => {
  const colorDark = document.documentElement.style.getPropertyValue(`--theme_color_dark`);
  const colorLight = document.documentElement.style.getPropertyValue(`--theme_color_light`);
  const darkTextColor = getContrastColor(colorDark);
  const heightOffset = pageSubNav ? 'calc(100vh - 465px) !important' : 'calc(100vh - 405px) !important';

  return createTheme({
    overrides: {
      MUIDataTable: {
        root: {},
        paper: {
          boxShadow: 'none',
          border: '1px #ebebeb solid',
          height: 'inherit',
        },
        responsiveScroll: {
          minHeight: heightOffset,
          maxHeight: heightOffset,
        },
      },
      MuiCheckbox: {
        colorPrimary: {
          color: colorLight,
          '&$checked': {
            color: colorLight,
          },
        },
      },
      MUIDataTableSelectCell: {
        headerCell: {
          // zIndex: 0,
          backgroundColor: colorDark,
        },
      },
      MuiGrid: {
        container: {
          padding: '12px 8px',
        },
      },
      MuiFormControl: {
        root: {
          fontSize: '16px',
          fontFamily: 'AvenirNextRegular',
          color: '#043544',
        },
      },
      MuiInputBase: {
        input: {
          fontSize: '14px',
          fontFamily: 'AvenirNextRegular',
          color: '#043544',
        },
      },
      MuiPickersToolbar: {
        toolbar: {
          backgroundColor: colorLight,
        },
      },
      MUIDataTableHeadRow: {
        root: {
          backgroundColor: colorDark,
        },
      },
      MUIDataTableFilterList: {
        chip: {
          marginBottom: '5px',
          display: hideChip ? 'none' : 'flex',
        },
      },
      MUIDataTableBodyRow: {
        root: {
          '&.Mui-selected, &.Mui-selected:hover': {
            backgroundColor: '#DEEBFF !important',
          },
          '&:nth-child(odd)': {
            backgroundColor: '#ebebeb',
          },
        },
      },
      MuiButton: {
        root: {
          fontSize: '16px',
          fontFamily: 'AvenirNextRegular',
          color: '#043544',
        },
        label: {
          fontSize: '16px',
          fontFamily: 'AvenirNextRegular',
          color: darkTextColor,
        },
      },
      MUIDataTableHeadCell: {
        root: {
          fontSize: '16px',
          fontFamily: 'AvenirNextMedium',
          color: '#043544',
          border: 'none',
        },
        fixedHeader: {
          backgroundColor: colorDark,
          color: darkTextColor,
          border: 'none',
        },
        sortAction: {
          '& path': {
            color: darkTextColor,
          },
        },
        sortActive: {
          color: darkTextColor,
        },
      },
      MUIDataTableBodyCell: {
        root: {
          fontSize: '16px',
          fontFamily: 'AvenirNextRegular',
          color: '#043544',
          padding: '6px',
        },
      },
      MuiTableRow: {
        root: {
          '&.MuiTableRow-hover': {
            '&:hover': {
              backgroundColor: '#DEEBFF',
              cursor: 'default',
            },
          },
        },
      },
      MuiTablePagination: {
        caption: {
          fontFamily: 'AvenirNextRegular',
          color: '#043544',
          fontSize: '14px',
        },
        actions: {
          fontFamily: 'AvenirNextRegular',
          color: '#043544',
          fontSize: '14px',
        },
      },
      MUIDataTablePagination: {
        tableCellContainer: {
          paddingRight: '60px',
        },
      },
      MUIDataTableJumpToPage: {
        caption: {
          fontFamily: 'AvenirNextRegular',
          color: '#043544',
          fontSize: '14px',
        },
      },
      MuiSelect: {
        selectMenu: {
          minHeight: 'none',
          color: '#043544',
        },
      },
      MuiTypography: {
        root: {
          fontFamily: 'AvenirNextRegular',
          color: '#043544',
          fontSize: '16px',
        },
        body1: {
          fontFamily: 'AvenirNextRegular',
          color: '#043544',
          fontSize: '16px',
        },
      },
      MUIDataTableViewCol: {
        label: {
          fontFamily: 'AvenirNextRegular',
          color: '#043544',
          fontSize: '16px',
        },
        title: {
          fontFamily: 'AvenirNextRegular',
          color: '#043544',
          fontSize: '16px',
        },
      },
    },
  });
};

export { viaMuiTheme };
