import fetch from 'node-fetch';
import { keys } from '../config/keys';
import token from '../shared/helpers/token';
import {
  CREATE_NEW_PROGRAM_CLEAR,
  CREATE_NEW_PROGRAM_REQUEST,
  CREATE_NEW_PROGRAM_SUCCESS,
  CREATE_NEW_PROGRAM_FAILURE,
  DELETE_DRAFT_PROGRAM_REQUEST,
  DELETE_DRAFT_PROGRAM_SUCCESS,
  DELETE_DRAFT_PROGRAM_FAILURE,
  GET_PROGRAM_REQUEST,
  GET_PROGRAM_SUCCESS,
  GET_PROGRAM_FAILURE,
  MODIFY_PROGRAM_CLEAR,
  MODIFY_PROGRAM_REQUEST,
  MODIFY_PROGRAM_SUCCESS,
  MODIFY_PROGRAM_FAILURE,
} from './types';

export function createNewProgramModify() {
  return function(dispatch) {
    dispatch({ type: CREATE_NEW_PROGRAM_REQUEST });
    fetch(`${keys.baseUri}/api/programs`, {
      method: 'POST',
      headers: token,
    })
      .then(res => {
        if (res.status !== 201 && res.status !== 204) {
          res.json().then(data => {
            dispatch({
              type: CREATE_NEW_PROGRAM_FAILURE,
            });
          });
        } else {
          res.json().then(data => {
            dispatch({
              type: CREATE_NEW_PROGRAM_SUCCESS,
              payload: data,
            });
          });
        }
      });
  };
}

export function createProgramModifyClear() {
  return function (dispatch) {
    dispatch({type: CREATE_NEW_PROGRAM_CLEAR});
  };
}

export function deleteProgramModify(programId) {
  return function(dispatch) {
    dispatch({ type: DELETE_DRAFT_PROGRAM_REQUEST });
    fetch(`${keys.baseUri}/api/programs/${programId}`, {
      method: 'DELETE',
      headers: token,
    })
      .then(res => {
        if (res.status !== 200 && res.status !== 204) {
          dispatch({
            type: DELETE_DRAFT_PROGRAM_FAILURE,
          });
        } else {
          dispatch({
            type: DELETE_DRAFT_PROGRAM_SUCCESS,
          });
        }
      });
  };
}

export function getProgramModify(programId) {
  return function(dispatch) {
    dispatch({ type: GET_PROGRAM_REQUEST });
    fetch(`${keys.baseUri}/api/programs/${programId}`, {
      method: 'GET',
      headers: token,
    })
      .then(res => {
        if (res.status !== 200) {
          res.json().then(data => {
            dispatch({
              type: GET_PROGRAM_FAILURE,
            });
          });
        } else {
          res.json().then(data => {
            dispatch({
              type: GET_PROGRAM_SUCCESS,
              payload: data,
            });
          });
        }
      });
  };
}

export function updateProgramModify(programId, data) {
  return function(dispatch) {
    dispatch({ type: MODIFY_PROGRAM_REQUEST });
    fetch(`${keys.baseUri}/api/programs/${programId}`, {
      method: 'PATCH',
      headers: token,
      body: JSON.stringify({ program: data }),
    })
      .then(res => res.json())
      .then(data => {
        dispatch({
          type: MODIFY_PROGRAM_SUCCESS,
          payload: data,
        });
      })
      .catch(error => {
        dispatch({
          type: MODIFY_PROGRAM_FAILURE,
          payload: error.toString(),
        });
      });
  };
}

export function updateProgramModifyClear() {
  return function (dispatch) {
    dispatch({type: MODIFY_PROGRAM_CLEAR});
  };
}
