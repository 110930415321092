import React from 'react';
import PropTypes from 'prop-types';

import SignPostIcon from '../../icons/SignPostIcon';
import { CardDetailItem } from './ProgramCard.styled';

export default function CardTypes({ types }) {
  return (
    <CardDetailItem tabIndex={0}>
      <SignPostIcon />
      <span>{types}</span>
    </CardDetailItem>
  );
}

CardTypes.propTypes = {
  types: PropTypes.string.isRequired,
};
