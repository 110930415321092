const parseURI = (str = '') => {
  const keys = [
    'source',
    'protocol',
    'authority',
    'userInfo',
    'user',
    'password',
    'host',
    'port',
    'relative',
    'path',
    'directory',
    'file',
    'query',
    'anchor'
  ]

  const parser = /^(?:(?![^:@]+:[^:@/]*@)([^:/?#.]+):)?(?:\/\/)?((?:(([^:@]*)(?::([^:@]*))?)?@)?([^:/?#]*)(?::(\d*))?)(((\/(?:[^?#](?![^?#/]*\.[^?#/.]+(?:[?#]|$)))*\/?)?([^?#/]*))(?:\?([^#]*))?(?:#(.*))?)/
  const matches = parser.exec(str)
  const uri = {}
  let i = 14

  while (i--) uri[keys[i]] = matches[i] || ''

  uri.queryKey = {}
  uri[keys[12]].replace(/(?:^|&)([^&=]*)=?([^&]*)/g, ($0, $1, $2) => {
    if ($1) uri.queryKey[$1] = $2
  })

  return uri
}

export { parseURI }
