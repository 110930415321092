import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import TextInput from '../../base/inputs/TextInput';
import TravelerFormQuestionContainer from '../../base/TravelerFormQuestionContainer';

import userId from '../../../../../shared/helpers/userId';
import { addIntakeProfile } from '../../../actions/intakeFormActions';

export default function IntakeLastName() {
  const dispatch = useDispatch();
  const intake = useSelector(state => state.intakeForm);

  const [value, setValue] = useState('');
  const [error, setError] = useState('');
  const [disabled, setDisabled] = useState(false);

  const question = {
    overline: 'Name',
    heading: 'And, your last name?',
    subheading: 'Type in your last name / surname only.',
    isRequired: true,
  };

  useEffect(
    () => {
      setValue(intake.last_name);

      setDisabled(intake.ssoTraveler && intake.readonly.includes('last_name'));
    },
    [intake],
  );

  const handleNext = () => {
    setError("");
    if (!disabled && !value) {
      setTimeout(()=>{
        setError('Required, please enter your last name.');
      },0)  
      throw new Error();
    }

    if (!disabled) {
      dispatch(addIntakeProfile(userId, { first_name: intake.first_name, last_name: value }));
    }
  };

  return (
    <TravelerFormQuestionContainer question={question} handleNext={handleNext} disabled={disabled} canBeSynced>
      <TextInput
        value={value}
        id="last_name"
        label="Last Name"
        placeholder="Enter last name"
        error={error}
        disabled={disabled}
        required
        handleOnChange={event => {
          setValue(event.target.value);
        }}
      />
    </TravelerFormQuestionContainer>
  );
}
