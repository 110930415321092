import styled from 'styled-components';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import RadioInput from '../../base/inputs/RadioInput';
import { RadioGrid } from '../../base/inputs/InputGrids.styled';
import TravelerFormQuestionContainer from '../../base/TravelerFormQuestionContainer';

import userId from '../../../../../shared/helpers/userId';
import passportStatuses from '../../../utils/intake/passportStatuses';
import { addIntakePassport } from '../../../actions/intakeFormActions';

const RadioGridColumn = styled.div`
  grid-column: span 2 / span 2;
  display: flex;
  justify-content: center;
  width: 100%;
`;

export default function IntakePassport() {
  const dispatch = useDispatch();
  const intake = useSelector(state => state.intakeForm);

  const [value, setValue] = useState('');
  const [error, setError] = useState('');

  const question = {
    overline: 'Passport',
    heading: 'Ok, what is the current status of your passport?',
    subheading: 'Select one option that applies.',
    isRequired: true,
  };

  useEffect(
    () => {
      const initialValue = intake.passport.status === 'not_provided' ? '' : intake.passport.status;

      setValue(initialValue);
    },
    [intake],
  );

  const handleNext = () => {
    setError("");
    if (!value) {
      setTimeout(()=>{
        setError('Required, please select an option.');
      },0) 
      throw new Error();
    }

    dispatch(addIntakePassport(userId, { status: value }));
  };

  return (
    <TravelerFormQuestionContainer question={question} handleNext={handleNext} error={error} moreErrorMargin>
      <RadioGrid role="radiogroup" aria-labelledby="role-label">
        <label id="role-label" style={{display: "none"}}>Select one passport option that applies Required</label>
        {passportStatuses.map(status => (
          <RadioGridColumn key={`passport-status-${status.id}`}>
            <RadioInput
              value={status.value}
              label={status.label}
              icon={status.icon}
              selected={value}
              handleOnChange={() => {
                setValue(status.value);
              }}
            />
          </RadioGridColumn>
        ))}
      </RadioGrid>
    </TravelerFormQuestionContainer>
  );
}
