import React from 'react';
import styled from 'styled-components';
import _get from 'lodash/get';
import TextInput from '../../../../../styledComponents/styles/TextInput.styled';

const Container = styled.div`
  font-family: 'Nunito', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 120%;
  letter-spacing: 0.015em;
  color: #373737;
`;

const InputContainer = styled.div`
  flex: 1;
  margin: 13px 0;
  text-align: left;
`;

export default function QuestionShortTextDisplayForm({
  question,
  formState,
  isReadonly = false,
  disabled = false,
  isSynced = false,
  setValue,
  name,
  errors = {},
  ...rest
}) {
  const id = question.question_id.toString();
  let isDisabled = false;
  if(question.sourceable_type === 'TravelerInfoField' && question.sourceable_attributes.name === 'email')
  {
    isDisabled = true;
  } else if (isSynced || isReadonly){
    isDisabled = true;
  } else {
    isDisabled = disabled;
  }
  return (
    <Container>
      <InputContainer>
        <TextInput
          aria-label={question.label}
          id={id}
          instructions={question.instructions}
          label={question.label}
          openSans
          placeholder={`Enter ${question.label}`}
          required={question.required}
          errorMsg={_get(errors[id], 'message') || ''}
          charLimit={question.character_limit}
          onKeyUp={(e)=>{
            if(question.required){
              setValue(name, e.target.value.trimStart(), {
                shouldValidate: true,
                shouldDirty: true,
                shouldTouch: true,
              })
            }
          }}
          readOnly={isReadonly}
          disabled={isDisabled}
          icon={isSynced ? 'Sync' : isReadonly ? 'LockClosed' : ''}
          autoComplete={question.label}
          {...rest}
        />
      </InputContainer>
    </Container>
  );
}
