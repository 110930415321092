import React, { useEffect } from 'react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from './index.css';

function ScreenReaderHeadingComponent({ text }) {

    useEffect(() => {
        document.getElementById("heading").focus();
    }, []);

    return (
        <h1 id="heading" tabIndex={0} className={s.heading}>{text}</h1>
    );

}

export default withStyles(s)(ScreenReaderHeadingComponent); 