import React, { useState, useEffect } from 'react';
import { NotificationSection } from './components/sections/notification.section';
import { replaceCustomAliasesHandlebars } from '../../../../../shared/helpers/General';
import { PageHeader } from '../../../../../styledComponents/styles/PageHeader.styled';
import { Paragraph } from '../../../../../styledComponents/styles/Paragraph.styled';
import propTypes from 'prop-types';
import styled from 'styled-components';
import { fetchMailNotifications, fetchMailNotificationsOptOuts } from '../../../../../actions/orgSettingsActions';
import { useDispatch, useSelector } from 'react-redux';
import ToastMessage from '../../toastMessage';

const PageHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Container = styled.div``;

const ParagraphContainer = styled.div`
  margin: 20px 60px;
`;

export default function NotificationsForm(props) {
  const dispatch = useDispatch();
  const clientFeatureList = useSelector(state => state.profile.clientFeatureList);
  const customAliases = useSelector(state => state.profile.customAliases);
  const freemium = useSelector(state => state.profile.freemium);
  const mailers = useSelector(state => state.orgSettings.mailerOptions);
  const mailerOptOuts = useSelector(state => state.orgSettings.mailerOptOuts);
  const userId = useSelector(state => state.profile.currentUser.id);
  const archivedMailers = useSelector(state => state.orgSettings.mailersArchived);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showError, setShowError] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  useEffect(
    () => {
      if (mailerOptOuts && mailerOptOuts.loading) {
        setIsSaving(true);
      }

      if (mailerOptOuts && !mailerOptOuts.loading) {
        setIsSaving(false);
        if (!!mailerOptOuts.data && !!mailerOptOuts.data.data) {
          setShowSuccess(true);
        }
        if (mailerOptOuts.error) {
          setShowError(true);
        }
      }
    },
    [mailerOptOuts],
  );

  useEffect(() => {
    let unmounted = false;
    if (!unmounted) {
      dispatch(fetchMailNotifications());
      dispatch(fetchMailNotificationsOptOuts());
    }
    return () => {
      unmounted = true;
    };
  }, []);

  return (
    <form id="notifications-form">
      <PageHeaderContainer>
        <PageHeader>
          {!clientFeatureList.legacy && clientFeatureList.legacy ? 'My Via Global Notifications' : 'My Notifications'}
        </PageHeader>
      </PageHeaderContainer>
      <ParagraphContainer>
        <Paragraph>
          Below lists all notifications that you are able to receive by email. For any notification you do not wish to
          receive turn the notification setting off; turning the setting off will cease all future notifications for
          that type from that day forward. For any notifications you do wish to receive, turn the notification setting
          on; turning the setting on will resume sending the notifications from that day forward.
        </Paragraph>
      </ParagraphContainer>
      {!freemium &&
        mailers.length > 0 &&
        mailers.map(category => (
          <Container key={category.id}>
            <NotificationSection
              customAliases={customAliases}
              category={category}
              freemium={freemium}
              header={replaceCustomAliasesHandlebars(category.attributes.display_name, customAliases)}
              userId={userId}
              mailers={mailers}
              freemium={freemium}
              archivedMailers={archivedMailers}
              isSaving={isSaving}
            />
          </Container>
        ))}
      {showSuccess && (
        <ToastMessage
          message={'Notification settings saved'}
          show={showSuccess}
          id={'success-toast'}
          isSuccess
          onClose={() => setShowSuccess(false)}
        />
      )}
      {showError && (
        <ToastMessage
          message={'Unable to update notification settings'}
          show={showError}
          id={'failure-toast'}
          isSuccess={false}
          onClose={() => setShowError(false)}
        />
      )}
    </form>
  );
}

NotificationsForm.propTypes = {};
