import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { fetchAuthorizedProgramSettings } from '../../../../actions/orgSettingsActions';
import propTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import sEventSubHeader from './eventlSubHeader.scss';

function EventSubHeader(props) {
  const dispatch = useDispatch();

  const freemium = useSelector(state => state.profile.freemium);
  const authFlag = useSelector(state => state.orgSettings.authorized_program_settings.account);
  const userId = useSelector(state => state.profile.currentUser.id);

  useEffect(() => {
    let unmounted = false;
    if (!unmounted) {
      dispatch(fetchAuthorizedProgramSettings(userId));
    }
    return () => {
      unmounted = true;
    };
  }, []);

  return (
    <>
      <div className={sEventSubHeader.subheader}>
        <div className={sEventSubHeader['subheader-content']}>
          <div className={sEventSubHeader.item}>
            <NavLink
              className={sEventSubHeader.selection}
              to="/client/event-dashboard"
              activeClassName={sEventSubHeader.selected}
            >
              Events
            </NavLink>
          </div>
          <div className={sEventSubHeader.item}>
            <NavLink
              className={sEventSubHeader.selection}
              to="/client/event-participant-dashboard"
              activeClassName={sEventSubHeader.selected}
            >
              Participants
            </NavLink>
          </div>
        </div>
      </div>
    </>
  );
}

export default withStyles(sEventSubHeader)(EventSubHeader);
