import styled from 'styled-components';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import CheckboxInput from '../../base/inputs/CheckboxInput';
import { CheckboxGrid } from '../../base/inputs/InputGrids.styled';
import ScreenReaderLabel from '../../base/inputs/ScreenReaderLabel.styled';
import NoBorderTextInput from '../../base/inputs/NoBorderTextInput.styled';
import TravelerFormQuestionContainer from '../../base/TravelerFormQuestionContainer';

import { breakpoints } from '../../../utils/breakpoints';
import userId from '../../../../../shared/helpers/userId';
import travelGoals from '../../../utils/intake/travelGoals';
import { addIntakeGoal } from '../../../actions/intakeFormActions';
import ScreenReaderLegend from '../../../../travelerProfile/components/base/ScreenReaderLegend.styled';

const CheckboxGridColumn = styled.div`
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  grid-column: span 2 / span 2;
  gap: 0.5rem;

  div,
  input[type='text'] {
    grid-column: span 1 / span 1;
  }

  @media ${breakpoints.tablet} {
    grid-template-rows: repeat(7, minmax(0, 1fr));
    grid-column: span 1 / span 1;
  }
`;

export default function IntakeTravelGoals() {
  const dispatch = useDispatch();
  const intake = useSelector(state => state.intakeForm);

  const [value, setValue] = useState([]);
  const [error, setError] = useState('');
  const [otherOption, setOtherOption] = useState('');

  const question = {
    overline: 'Travel Goals / Motivations',
    heading: 'Now to some fun stuff. What global opportunities are you interested in?',
    subheading: 'Select all that apply.',
    isRequired: true,
  };

  useEffect(
    () => {
      if (intake.travel_goals) {
        const selectedGoals = [];

        for (const [key, value] of Object.entries(intake.travel_goals)) {
          if (key === 'other_goal_text') {
            setOtherOption(value || '');
          }

          if (value === true || value === 't') {
            selectedGoals.push(key);
          }
        }

        setValue(selectedGoals);
      }
    },
    [intake],
  );

  const handleOnChange = goal => {
    if (value.includes(goal)) {
      setValue(value.filter(element => element !== goal));

      if (goal === 'other') {
        setOtherOption('');
      }
    } else {
      setValue([...value, goal]);
    }
  };

  const handleNext = () => {
    setError("");
    if (value.length === 0) {
      setTimeout(()=>{
        setError('Required, please select an option.');
      },0)
      throw new Error();
    }

    if (value.includes('other') && !otherOption) {
      setTimeout(()=>{
        setError('Please enter text for other option or uncheck "Other".');
      },0)
      throw new Error();
    }

    let goals = {};

    travelGoals.forEach(goal => {
      goals[goal.value] = value.includes(goal.value);
    });

    goals['other_goal_text'] = otherOption;

    dispatch(addIntakeGoal(userId, goals));
  };

  return (
    <TravelerFormQuestionContainer question={question} handleNext={handleNext} error={error}>
      <CheckboxGrid>
        <fieldset>
        <ScreenReaderLegend>Select all Travel Goals or Motivations that apply Required</ScreenReaderLegend>
        <CheckboxGridColumn>
          {travelGoals.slice(0, 7).map(travelGoal => (
            <CheckboxInput
              key={`travel-goal-${travelGoal.id}`}
              id={`travel_goal_${travelGoal.id}`}
              value={travelGoal.value}
              label={travelGoal.label}
              checked={value.includes(travelGoal.value)}
              handleOnChange={() => {
                handleOnChange(travelGoal.value);
              }}
            />
            
          ))}
        </CheckboxGridColumn>
        </fieldset>
        <fieldset>
        <ScreenReaderLegend>Select all Travel Goals or Motivations that apply</ScreenReaderLegend>
        <CheckboxGridColumn>
          {travelGoals.slice(7).map(travelGoal => (
            <CheckboxInput
              key={`travel-goal-${travelGoal.id}`}
              id={`travel_goal_${travelGoal.id}`}
              value={travelGoal.value}
              label={travelGoal.label}
              checked={value.includes(travelGoal.value)}
              handleOnChange={() => {
                handleOnChange(travelGoal.value);
              }}
            />
          ))}
          {value.includes('other') && (
            <>
              <ScreenReaderLabel htmlFor="other_option_text">Other Option</ScreenReaderLabel>
              <NoBorderTextInput
                id="other_option_text"
                type="text"
                placeholder="Enter other option"
                value={otherOption}
                onChange={event => {
                  setOtherOption(event.target.value);
                }}
              />
            </>
          )}
        </CheckboxGridColumn>
        </fieldset>
      </CheckboxGrid>
    </TravelerFormQuestionContainer>
  );
}
