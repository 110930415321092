import React,{useRef, useEffect} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import Modal from 'react-responsive-modal';
import modalCss from 'react-responsive-modal/styles.css';
import { compose } from 'recompose';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import MountainFlagIcon from '../icons/MountainFlagIcon';
import { ModalBody, ModalButton, ModalFooter } from '../base/Modal.styled';

import { breakpoints } from '../../utils/breakpoints';
import getFormattedEventDate from '../../utils/events/getFormattedEventDate';
import { clearActiveEvent, toggleEventRegistrationModal } from '../../actions/travelerEventsActions';
import CloseIcon from '../icons/CloseIcon';

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  padding: 25px 25px;
  border-bottom: 0.0625rem solid rgb(231, 231, 231);
`;
const Heading = styled.div`
  color: #373737;
  text-align: center;

  /* Headline 5 */
  font-family: Nunito;
  font-size: 25px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 30px */
`;
const CloseButton = styled.button`
  outline: none;
  border: none;
  padding: 0;
  background: none;
  &:focus-visible {
    outline: 2px solid #255a94;
    outline-offset: 1px;
  }
`;
const BodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3.125rem;

  @media ${breakpoints.tablet} {
    flex-direction: row;
  }
`;

const InfoText = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  font-size: 1rem;
  color: #373737;
  letter-spacing: 0.005em;
  line-height: 140%;
`;
export const enhance = compose(withStyles(modalCss));
function EventRegistrationModal({ handleRegister }) {
  const dispatch = useDispatch();
  const modalRef = useRef(null);
  const { activeEvent, showEventRegistrationModal: show } = useSelector(state => state.travelerEvents);

  const close = () => {
    dispatch(clearActiveEvent());
    dispatch(toggleEventRegistrationModal());
  };

  useEffect(() => {
    if (show && modalRef.current) {
      modalRef.current.focus();
    }
  }, [show]);

  return (
    <Modal
        blockScroll={false}
        open={show}
        onClose={close}
        center
        closeOnEsc
        closeOnOverlayClick
        showCloseIcon={false}
        styles={{
          modal: {
            padding: '0px',
           // borderRadius: '5px',
            width: '520px',
          },
        }}
      >
        <div
          ref={modalRef}
          role="dialog"
          aria-labelledby="modal-title"
          tabIndex="-1">
      <Header>
        <Heading id="modal-title">Registration Confirmation</Heading>
        <CloseButton aria-label="Close button" onClick={close} onKeyUp={(event)=>{
            if (event.key === 'Enter') {
              onClick();
            }
          }}>
          <CloseIcon fillColor={`#9FABAE`} />
        </CloseButton>
      </Header>
      <ModalBody>
        <BodyContainer>
          <MountainFlagIcon />
          <InfoText>
            <p>
              You are about to register for {activeEvent.name} at {getFormattedEventDate(activeEvent, true)}.
            </p>
            <p>You will receive a reminder for this event 1 day before the event start date.</p>
          </InfoText>
        </BodyContainer>
      </ModalBody>
      </div>
      <ModalFooter>
        <ModalButton  type="button" className="secondary" onClick={close}>
          Cancel
        </ModalButton>
        <ModalButton
          type="button"
          className="primary"
          onClick={() => {
            handleRegister(activeEvent);
          }}
        >
          Register
        </ModalButton>
      </ModalFooter>
      
    </Modal>
  );
}

EventRegistrationModal.propTypes = {
  handleRegister: PropTypes.func.isRequired,
};
export default enhance(EventRegistrationModal);