import React from 'react';
import styled from 'styled-components';
import ArchiveFolder from '../../shared/images/svgs/archiveFolder.svg';
import AcademicSvg from '../../shared/images/svgs/academics.svg';
import AccommodationSvg from '../../shared/images/svgs/accommodation.svg';
import ActivePassportSvg from '../../shared/images/svgs/active_passport.svg';
import ArchivedSvg from '../../shared/images/svgs/archived_icon.svg';
import ApplicationSvg from '../../shared/images/svgs/application.svg';
import CongratsSvg from '../../shared/images/svgs/congrats.svg';
import FormCompleteSvg from '../../shared/images/svgs/formcomplete.svg';
import CuriousSvg from '../../shared/images/svgs/curious.svg';
import CustomTimeSvg from '../../shared/images/svgs/custom_time_calendar.svg';
import ErrorSvg from '../../shared/images/svgs/error_icon.svg';
import ErrorRoadSvg from '../../shared/images/svgs/error_road_icon.svg';
import ErrorXSvg from '../../shared/images/svgs/error_x.svg';
import ExpiredPassportSvg from '../../shared/images/svgs/expired_passport.svg';
import FacultySvg from '../../shared/images/svgs/faculty_icon.svg';
import FacultyLeaderSvg from '../../shared/images/svgs/faculty_leader.svg';
import FacultyDisabledSvg from '../../shared/images/svgs/faculty_disabled_icon.svg';
import FeeSvg from '../../shared/images/svgs/fees.svg';
import FreemiumSvg from '../../shared/images/svgs/freemium_icon.svg';
import GeneralFormSvg from '../../shared/images/svgs/general_form.svg';
import GeneralFileSvg from '../../shared/images/svgs/general_file.svg';
import GraduateSvg from '../../shared/images/svgs/graduate.svg';
import HighSchoolSvg from '../../shared/images/svgs/high_school.svg';
import InfoSvg from '../../shared/images/svgs/info_icon.svg';
import IntakeSvg from '../../shared/images/svgs/intake.svg';
import LanguageSvg from '../../shared/images/svgs/language.svg';
import LongTermSvg from '../../shared/images/svgs/long_term.svg';
import LuggageSvg from '../../shared/images/svgs/luggage_icon.svg';
import LuggageDisabledSvg from '../../shared/images/svgs/luggage_disabled_icon.svg';
import MediumTermSvg from '../../shared/images/svgs/medium_term.svg';
import MiscSvg from '../../shared/images/svgs/misc.svg';
import NoSvg from '../../shared/images/svgs/no.svg';
import NoPassportSvg from '../../shared/images/svgs/no_passport.svg';
import OtherSvg from '../../shared/images/svgs/other.svg';
import PermissionBlockSvg from '../../shared/images/svgs/permission_block_icon.svg';
import PlaneSvg from '../../shared/images/svgs/plane.svg';
import PlansSvg from '../../shared/images/svgs/plans.svg';
import ProtectedKeySvg from '../../shared/images/svgs/protected_key.svg';
import QuestionsSvg from '../../shared/images/svgs/questions.svg';
import RecommendationSvg from '../../shared/images/svgs/recommendation.svg';
import ShortTermSvg from '../../shared/images/svgs/short_term.svg';
import SignInSvg from '../../shared/images/svgs/signin_icon.svg';
import SignUpSvg from '../../shared/images/svgs/signup_icon.svg';
import StaffSvg from '../../shared/images/svgs/staff.svg';
import SuccessGreenCheckSvg from '../../shared/images/svgs/success_green_check_icon.svg';
import SuccessSvg from '../../shared/images/svgs/success_icon.svg';
import SuitcasesSvg from '../../shared/images/svgs/suitcases.svg';
import TrashCanSvg from '../../shared/images/svgs/trash_can.svg';
import TrashSvg from '../../shared/images/svgs/TrashIcon.svg';
import TransportationSvg from '../../shared/images/svgs/transportation.svg';
import ThumbsUpSvg from '../../shared/images/svgs/thumbs_up.svg';
import TimingSvg from '../../shared/images/svgs/timing.svg';
import UnsureSvg from '../../shared/images/svgs/unsure.svg';
import UniversitySvg from '../../shared/images/svgs/university.svg';
import WarningSvg from '../../shared/images/svgs/warning_icon.svg';
import ViaLogoCircleSvg from '../../shared/images/svgs/viaLogo-Circle.svg';
import YesSvg from '../../shared/images/svgs/yes.svg';
import ArrowSvg from '../../shared/images/svgs/arrow_icon.svg';
import LeftArrowSvg from '../../shared/images/svgs/left_arrow_icons.svg';
import FileTransferSvg from '../../shared/images/svgs/FileTransferIcon.svg';
import DownloadSvg from '../../shared/images/svgs/DownloadIcon.svg';

import SettingIconSvg from '../../shared/images/svgs/SettingIcon.svg';
import PreviewIconSvg from '../../shared/images/svgs/PreviewIcon.svg';
import DuplicateIconSvg from '../../shared/images/svgs/DuplicateIcon.svg';
import CopyIconSvg from '../../shared/images/svgs/CopyIcon.svg';
import ArchiveIconSvg from '../../shared/images/svgs/ArchiveIcon.svg';
import TrashIconSvg from '../../shared/images/svgs/TrashIcon.svg';
import WarningIconSvg from '../../shared/images/svgs/WarningIcon.svg';

import ViaGlobalIconSvg from '../../shared/images/svgs/ViaGlobalIcon.svg';
import ViaInternationalIconSvg from '../../shared/images/svgs/ViaInternationalIcon.svg';
import MenuIconSvg from '../../shared/images/svgs/MenuIcon.svg';
import EventIconSvg from '../../shared/images/svgs/EventIcon.svg';
import LoadingIndicatorSvg from '../../shared/images/svgs/LoadingIndicator.svg';
import FileUploadSvg from '../../shared/images/svgs/FileUpload.svg';
import UploadIconSvg from '../../shared/images/svgs/UploadIcon.svg';
import FileIconSvg from '../../shared/images/svgs/FileIcon.svg';
import DownloadIconSvg from '../../shared/images/svgs/Download_Icon.svg';
import InfoIconModalSvg from '../../shared/images/svgs/InfoIconModal.svg';

const StyledIconBase = styled.img``;

// Generic handling
export const StyledIconCustom = ({ svg, size = 24, ...props }) => {
  return <StyledIconBase width={size} height={props.height ? props.height : size} {...props} src={svg} />;
};

// Exported Custom icons
export const ArchiveFolderIcon = props => (
  <StyledIconCustom {...props} svg={ArchiveFolder} alt={'Archive folder icon'} />
);
export const AcademicsIcon = props => <StyledIconCustom {...props} svg={AcademicSvg} alt={'Academics icon'} />;
export const AccommodationIcon = props => (
  <StyledIconCustom {...props} svg={AccommodationSvg} alt={'Accommodation icon'} />
);
export const ActivePassportIcon = props => (
  <StyledIconCustom {...props} svg={ActivePassportSvg} alt={'Active Passport icon'} />
);
export const ArchivedIcon = props => <StyledIconCustom {...props} svg={ArchivedSvg} alt={'Archive icon'} />;
export const ApplicationIcon = props => <StyledIconCustom {...props} svg={ApplicationSvg} alt={'Application icon'} />;
export const CongratsIcon = props => <StyledIconCustom {...props} svg={CongratsSvg} alt={'Congrats icon'} />;
export const FormCompleteIcon = props => (
  <StyledIconCustom {...props} svg={FormCompleteSvg} alt={'Form Submit Success icon'} />
);
export const CuriousIcon = props => <StyledIconCustom {...props} svg={CuriousSvg} alt={'Curious icon'} />;
export const CustomTimeIcon = props => <StyledIconCustom {...props} svg={CustomTimeSvg} alt={'Custom Time icon'} />;
export const ErrorIcon = props => <StyledIconCustom {...props} svg={ErrorSvg} alt={'Error icon'} />;
export const ErrorRoadIcon = props => <StyledIconCustom {...props} svg={ErrorRoadSvg} alt={'Error road icon'} />;
export const ErrorXIcon = props => <StyledIconCustom {...props} svg={ErrorXSvg} alt={'Error icon'} />;
export const ExpiredPassportIcon = props => (
  <StyledIconCustom {...props} svg={ExpiredPassportSvg} alt={'Expired Passport icon'} />
);
export const FacultyIcon = props => <StyledIconCustom {...props} svg={FacultySvg} alt={'Faculty icon'} />;
export const FacultyLeaderIcon = props => (
  <StyledIconCustom {...props} svg={FacultyLeaderSvg} alt={'Faculty Leader icon'} />
);
export const FacultyDisabledIcon = props => (
  <StyledIconCustom {...props} svg={FacultyDisabledSvg} alt={'Faculty disabled icon'} />
);
export const FeeIcon = props => <StyledIconCustom {...props} svg={FeeSvg} alt={'Fees icon'} />;
export const FreemiumIcon = props => <StyledIconCustom {...props} svg={FreemiumSvg} alt={'Freemium icon'} />;
export const GeneralFileIcon = props => <StyledIconCustom {...props} svg={GeneralFileSvg} alt={'General File icon'} />;
export const GeneralFormIcon = props => <StyledIconCustom {...props} svg={GeneralFormSvg} alt={'General Form icon'} />;
export const GraduateIcon = props => <StyledIconCustom {...props} svg={GraduateSvg} alt={'Graduate icon'} />;
export const HighSchoolIcon = props => <StyledIconCustom {...props} svg={HighSchoolSvg} alt={'High School icon'} />;
export const InfoIcon = props => <StyledIconCustom {...props} svg={InfoSvg} alt={'Info icon'} />;
export const IntakeIcon = props => <StyledIconCustom {...props} svg={IntakeSvg} alt={'Intake icon'} />;
export const LanguageIcon = props => <StyledIconCustom {...props} svg={LanguageSvg} alt={'Language icon'} />;
export const LongTermIcon = props => <StyledIconCustom {...props} svg={LongTermSvg} alt={'Long Term icon'} />;
export const LuggageIcon = props => <StyledIconCustom {...props} svg={LuggageSvg} alt={'Luggage icon'} />;
export const LuggageDisabledIcon = props => (
  <StyledIconCustom {...props} svg={LuggageDisabledSvg} alt={'Luggage disabled icon'} />
);
export const MediumTermIcon = props => <StyledIconCustom {...props} svg={MediumTermSvg} alt={'Medium Term icon'} />;
export const MiscIcon = props => <StyledIconCustom {...props} svg={MiscSvg} alt={'Misc icon'} />;
export const NoIcon = props => <StyledIconCustom {...props} svg={NoSvg} alt={'No icon'} />;
export const NoPassportIcon = props => <StyledIconCustom {...props} svg={NoPassportSvg} alt={'No Passport icon'} />;
export const OtherIcon = props => <StyledIconCustom {...props} svg={OtherSvg} alt={'Other icon'} />;
export const PermissionBlockIcon = props => (
  <StyledIconCustom {...props} svg={PermissionBlockSvg} alt={'Permission blocked icon'} />
);
export const PlaneIcon = props => <StyledIconCustom {...props} svg={PlaneSvg} alt={'Plane icon'} />;
export const PlansIcon = props => <StyledIconCustom {...props} svg={PlansSvg} alt={'Plans icon'} />;
export const ProtectedKeyIcon = props => (
  <StyledIconCustom {...props} svg={ProtectedKeySvg} alt={'Protected Page icon'} />
);
export const QuestionsIcon = props => <StyledIconCustom {...props} svg={QuestionsSvg} alt={'Questions Page icon'} />;
export const RecommendationIcon = props => (
  <StyledIconCustom {...props} svg={RecommendationSvg} alt={'Recommendation icon'} />
);
export const ShortTermIcon = props => <StyledIconCustom {...props} svg={ShortTermSvg} alt={'Short Term icon'} />;
export const SignInIcon = props => <StyledIconCustom {...props} svg={SignInSvg} alt={'Sign In icon'} />;
export const SignUpIcon = props => <StyledIconCustom {...props} svg={SignUpSvg} alt={'Sign Up icon'} />;
export const StaffIcon = props => <StyledIconCustom {...props} svg={StaffSvg} alt={'Staff icon'} />;
export const SuccessGreenCheckIcon = props => (
  <StyledIconCustom {...props} svg={SuccessGreenCheckSvg} alt={'Success green check icon'} />
);
export const SuccessIcon = props => <StyledIconCustom {...props} svg={SuccessSvg} alt={'Success icon'} />;
export const SuitcasesIcon = props => <StyledIconCustom {...props} svg={SuitcasesSvg} alt={'Suitcases icon'} />;
export const TrashCanIcon = props => <StyledIconCustom {...props} svg={TrashCanSvg} alt={'Trash Can icon'} />;
export const TransportationIcon = props => (
  <StyledIconCustom {...props} svg={TransportationSvg} alt={'Transportation icon'} />
);
export const ThumbsUpIcon = props => <StyledIconCustom {...props} svg={ThumbsUpSvg} alt={'Thumbs Up icon'} />;
export const TimingIcon = props => <StyledIconCustom {...props} svg={TimingSvg} alt={'Timing icon'} />;
export const UnsureIcon = props => <StyledIconCustom {...props} svg={UnsureSvg} alt={'Unsure icon'} />;
export const UniversityIcon = props => <StyledIconCustom {...props} svg={UniversitySvg} alt={'University icon'} />;
export const ViaLogoCircleIcon = props => (
  <StyledIconCustom {...props} svg={ViaLogoCircleSvg} alt={'Via Logo Circle'} />
);
export const WarningIcon = props => <StyledIconCustom {...props} svg={WarningSvg} alt={'Warning icon'} />;
export const YesIcon = props => <StyledIconCustom {...props} svg={YesSvg} alt={'Yes Icon'} />;
export const ArrowIcon = props => <StyledIconCustom {...props} svg={ArrowSvg} alt={'Arrow Icon'} />;
export const LeftArrowIcon = props => <StyledIconCustom {...props} svg={LeftArrowSvg} alt={'Arrow Icon'} />;

export const FileTransferIcon = props => <StyledIconCustom {...props} svg={FileTransferSvg} alt={'File Icon'} />;
export const DownloadIcon = props => <StyledIconCustom {...props} svg={DownloadSvg} alt={'Download Icon'} />;

export const SettingIcon = props => <StyledIconCustom {...props} svg={SettingIconSvg} alt={'Setting Icon'} />;
export const PreviewIcon = props => <StyledIconCustom {...props} svg={PreviewIconSvg} alt={'Preview Icon'} />;
export const DuplicateIcon = props => <StyledIconCustom {...props} svg={DuplicateIconSvg} alt={'Duplicate Icon'} />;
export const ArchiveIcon = props => <StyledIconCustom {...props} svg={ArchiveIconSvg} alt={'Archive Icon'} />;
export const TrashIcon = props => <StyledIconCustom {...props} svg={TrashIconSvg} alt={'Trash Icon'} />;
export const WarningIcons = props => <StyledIconCustom {...props} svg={WarningIconSvg} alt={'Warning Icon'} />;
export const CopyIcon = props => <StyledIconCustom {...props} svg={CopyIconSvg} alt={'Warning Icon'} />;


export const ViaGlobalIcon = props => <StyledIconCustom {...props} svg={ViaGlobalIconSvg} alt={'Via Global Icon'} />;
export const ViaInternationalIcon = props => <StyledIconCustom {...props} svg={ViaInternationalIconSvg} alt={'Via International Icon'} />;
export const MenuIcon = props => <StyledIconCustom {...props} svg={MenuIconSvg} alt={'Menu Icon'} />;
export const EventIcon = props => <StyledIconCustom {...props} svg={EventIconSvg} alt={'Event Icon'} />;
export const LoadingIndicatorIcon = props => <StyledIconCustom {...props} svg={LoadingIndicatorSvg} alt={'Loading Indicator Icon'} />;
export const FileUploadIcon = props => <StyledIconCustom {...props} svg={FileUploadSvg} alt={'File Upload Icon'} />;
export const UploadIcon = props => <StyledIconCustom {...props} svg={UploadIconSvg} alt={'File Upload Icon'} />;
export const FileIcon = props => <StyledIconCustom {...props} svg={FileIconSvg} alt={'File Icon'} />;
export const DownloadIcons = props => <StyledIconCustom {...props} svg={DownloadIconSvg} alt={'Download Icon'} />;
export const InfoModalIcons = props => <StyledIconCustom {...props} svg={InfoIconModalSvg} alt={'Download Icon'} />;
