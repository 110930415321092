/*
  When adding new icon, you must add to allSupportedIcons and
  define the export statement. (alphabetical)

  allSupportedIcons is used to build out the style guide.
  exports are used to enable Icons to be used on pages.

  Master list:
  https://docs.google.com/spreadsheets/d/1_z1c9wMIlDWDLwO672Fr_ICV0-LSdQczGewEvwyoEl0/edit#gid=0

  Source:
  https://styled-icons.dev
*/

export const allSupportedIcons = [
  { name: 'Add', path: '@styled-icons/ionicons-outline/Add' },
  { name: 'AddCircle', path: '@styled-icons/ionicons-sharp/AddCircle' },
  { name: 'Airplane', path: '@styled-icons/ionicons-outline/Airplane' },
  { name: 'Alert', path: '@styled-icons/ionicons-outline/Alert' },
  { name: 'Archive', path: '@styled-icons/ionicons-outline/Archive' },
  { name: 'ArrowSort', path: '@styled-icons/fluentui-system-regular/ArrowSort' },
  { name: 'ArrowIosForward', path: '@styled-icons/evaicons-solid/ArrowIosForward' },
  { name: 'ArrowIosBack', path: '@styled-icons/evaicons-solid/ArrowIosBack' },
  { name: 'ArrowSyncCircle', path: '@styled-icons/fluentui-system-filled/ArrowSyncCircle' },
  { name: 'Attach', path: '@styled-icons/ionicons-outline/Attach' },
  { name: 'Bicycle', path: '@styled-icons/ionicons-outline/Bicycle' },
  { name: 'Bookmarks', path: '@styled-icons/ionicons-outline/Bookmarks' },
  { name: 'ChatBubblesQuestion', path: '@styled-icons/fluentui-system-filled/ChatBubblesQuestion' },
  { name: 'ChatQuote', path: '@styled-icons/bootstrap/ChatQuote' },
  { name: 'Checkmark', path: '@styled-icons/icomoon/Checkmark' },
  { name: 'ChevronBack', path: '@styled-icons/ionicons-sharp/ChevronBack' },
  { name: 'ChevronDown', path: '@styled-icons/ionicons-outline/ChevronDown' },
  { name: 'ChevronForward', path: '@styled-icons/ionicons-outline/ChevronForward' },
  { name: 'ChevronUp', path: '@styled-icons/ionicons-outline/ChevronUp' },
  { name: 'Crown', path: '@styled-icons/boxicons-regular/Crown' },
  { name: 'Close', path: '@styled-icons/ionicons-outline/Close' },
  { name: 'CloseCircle', path: '@styled-icons/ionicons-sharp/CloseCircle' },
  { name: 'CaretDownFill', path: '@styled-icons/bootstrap/CaretDownFill' },
  { name: 'DoubleQuotesL', path: '@styled-icons/remix-editor/DoubleQuotesL' },
  { name: 'Download', path: '@styled-icons/ionicons-outline/Download' },
  { name: 'Dot', path: '@styled-icons/bootstrap/Dot' },
  { name: 'Duplicate', path: '@styled-icons/ionicons-solid/Duplicate' },
  { name: 'EllipsisHorizontal', path: '@styled-icons/ionicons-solid/EllipsisHorizontal' },
  { name: 'Envelope', path: '@styled-icons/bootstrap/Envelope' },
  { name: 'Expand', path: '@styled-icons/ionicons-outline/Expand' },
  { name: 'Eye', path: '@styled-icons/ionicons-outline/Eye' },
  { name: 'EyeOff', path: '@styled-icons/ionicons-outline/EyeOff' },
  { name: 'FastFood', path: '@styled-icons/ionicons-outline/FastFood' },
  { name: 'Facebook', path: '@styled-icons/material-sharp/Facebook' },
  { name: 'FolderOpen', path: '@styled-icons/ionicons-outline/FolderOpen' },
  { name: 'Home', path: '@styled-icons/ionicons-outline/Home' },
  { name: 'Image', path: '@styled-icons/ionicons-outline/Image' },
  { name: 'InformationCircle', path: '@styled-icons/ionicons-outline/InformationCircle' },
  { name: 'Language', path: '@styled-icons/ionicons-outline/Language' },
  { name: 'Link', path: '@styled-icons/ionicons-solid/Link' },
  { name: 'Library', path: '@styled-icons/ionicons-outline/Library' },
  { name: 'Location', path: '@styled-icons/ionicons-outline/Location' },
  { name: 'LockClosed', path: '@styled-icons/ionicons-outline/LockClosed' },
  { name: 'Map', path: '@styled-icons/ionicons-outline/Map' },
  { name: 'Pencil', path: '@styled-icons/remix-line/Pencil' },
  { name: 'PencilSolid', path: '@styled-icons/ionicons-solid/Pencil' },
  { name: 'People', path: '@styled-icons/ionicons-outline/People' },
  { name: 'Person', path: '@styled-icons/ionicons-outline/Person' },
  { name: 'Pricetag', path: '@styled-icons/ionicons-outline/Pricetag' },
  { name: 'Prohibited', path: '@styled-icons/fluentui-system-filled/Prohibited' },
  { name: 'Plus', path: '@styled-icons/bootstrap/Plus' },
  { name: 'School', path: '@styled-icons/ionicons-outline/School' },
  { name: 'Search', path: '@styled-icons/ionicons-outline/Search' },
  { name: 'Settings', path: '@styled-icons/ionicons-outline/Settings' },
  { name: 'ShareSocial', path: '@styled-icons/ionicons-outline/ShareSocial' },
  { name: 'SortAlphaDown', path: '@styled-icons/bootstrap/SortAlphaDown' },
  { name: 'SortAlphaUpAlt', path: '@styled-icons/bootstrap/SortAlphaUpAlt' },
  { name: 'Spinner', path: '@styled-icons/evil/Spinner' },
  { name: 'StatusOnline', path: '@styled-icons/heroicons-outline/StatusOnline' },
  { name: 'Sync', path: '@styled-icons/ionicons-outline/Sync' },
  { name: 'TrailSign', path: '@styled-icons/ionicons-outline/TrailSign' },
  { name: 'Trash', path: '@styled-icons/ionicons-outline/Trash' },
  { name: 'UniversalAccess', path: '@styled-icons/fa-solid/UniversalAccess' },
  { name: 'UserRemove', path: '@styled-icons/heroicons-outline/UserRemove' },
  { name: 'Warning', path: '@styled-icons/ionicons-outline/Warning' },
  { name: 'ErrorWarning', path: '@styled-icons/remix-fill/ErrorWarning' },
  { name: 'File_download', path: '@styled-icons/ionicons-solid/Download' },
  { name: 'File_eye', path: '@styled-icons/ionicons-solid/Eye' },
  { name: 'Form_divider', path: '@styled-icons/bootstrap/FileEarmarkBreakFill'},
  { name: 'Form_dropdown', path: '@styled-icons/material-rounded/ArrowDropDownCircle' },
  { name: 'Form_cash_net', path: '@styled-icons/ionicons-solid/Card' },
  { name: 'Form_cash_net_with_code', path: '@styled-icons/ionicons-solid/Card' },
  { name: 'Form_date', path: '@styled-icons/ionicons-solid/Calendar' },
  { name: 'Form_fee', path: '@styled-icons/ionicons-solid/Card' },
  { name: 'Form_file_download', path: '@styled-icons/ionicons-solid/ArrowDownCircle' },
  { name: 'Form_file_upload', path: '@styled-icons/ionicons-solid/ArrowUpCircle' },
  { name: 'Form_flywire', path: '@styled-icons/ionicons-solid/Card' },
  { name: 'Form_header', path: '@styled-icons/open-iconic/Header' },
  { name: 'Form_long_text', path: '@styled-icons/ionicons-solid/Menu' },
  { name: 'Form_multiple_choice', path: '@styled-icons/ionicons-solid/Checkbox' },
  { name: 'Form_multi_line_text', path: '@styled-icons/bootstrap/CardText' },
  { name: 'Form_paragraph', path: '@styled-icons/ionicons-solid/Text' },
  { name: 'Form_payflow', path: '@styled-icons/ionicons-solid/Card' },
  { name: 'Form_cybersource', path: '@styled-icons/ionicons-solid/Card' },
  { name: 'Form_quik_pay', path: '@styled-icons/ionicons-solid/Card' },
  { name: 'Form_converge_pay', path: '@styled-icons/ionicons-solid/Card' },
  { name: 'Form_recommendation', path: '@styled-icons/material/Recommend' },
  { name: 'Form_short_text', path: '@styled-icons/bootstrap/InputCursorText' },
  { name: 'Form_single_choice', path: '@styled-icons/ionicons-solid/RadioButtonOn' },
  { name: 'Form_single_line_text', path: '@styled-icons/bootstrap/InputCursorText' },
  { name: 'Form_signature', path: '@styled-icons/fluentui-system-regular/Signature' },
  { name: 'Form_star_rating', path: '@styled-icons/ionicons-solid/StarHalf' },
  { name: 'Star', path: '@styled-icons/bootstrap/Star' },
  { name: 'StarFill', path: '@styled-icons/bootstrap/StarFill' },
  { name: 'Form_text', path: '@styled-icons/ionicons-solid/Text' },
  { name: 'Form_text_input', path: '@styled-icons/bootstrap/InputCursorText' },
  { name: 'Form_text_paragraph', path: '@styled-icons/fluentui-system-filled/TextParagraph' },
  { name: 'Form_traveler_info', path: '@styled-icons/ionicons-sold/Person' },
  { name: 'Form_touch_net', path: '@styled-icons/ionicons-solid/Card' },
  { name: 'Form_video', path: '@styled-icons/ionicons-solid/Play' },
  { name: 'Form_video', path: '@styled-icons/ionicons-solid/Play' },
  { name: 'Reply', path: '@styled-icons/entypo/Reply' },
];

export { Add } from '@styled-icons/ionicons-outline/Add';
export { AddCircle } from '@styled-icons/ionicons-sharp/AddCircle';
export { Airplane } from '@styled-icons/ionicons-outline/Airplane';
export { Alert } from '@styled-icons/ionicons-outline/Alert';
export { Archive } from '@styled-icons/ionicons-outline/Archive';
export { ArrowSort } from '@styled-icons/fluentui-system-regular/ArrowSort';
export { ArrowIosForward } from '@styled-icons/evaicons-solid/ArrowIosForward';
export { ArrowIosBack } from '@styled-icons/evaicons-solid/ArrowIosBack';
export { ArrowSyncCircle } from '@styled-icons/fluentui-system-filled/ArrowSyncCircle';
export { Attach } from '@styled-icons/ionicons-outline/Attach';
export { Bicycle } from '@styled-icons/ionicons-outline/Bicycle';
export { Bookmarks } from '@styled-icons/ionicons-outline/Bookmarks';
export { ChatBubblesQuestion } from '@styled-icons/fluentui-system-filled/ChatBubblesQuestion';
export { ChatQuote } from '@styled-icons/bootstrap/ChatQuote';
export { Checkmark } from '@styled-icons/icomoon/Checkmark';
export { ChevronBack } from '@styled-icons/ionicons-sharp/ChevronBack';
export { ChevronDown } from '@styled-icons/ionicons-outline/ChevronDown';
export { ChevronForward } from '@styled-icons/ionicons-outline/ChevronForward';
export { ChevronUp } from '@styled-icons/ionicons-outline/ChevronUp';
export { Crown } from '@styled-icons/boxicons-regular/Crown';
export { Close } from '@styled-icons/ionicons-outline/Close';
export { CloseCircle } from '@styled-icons/ionicons-sharp/CloseCircle';
export { CaretDownFill } from '@styled-icons/bootstrap/CaretDownFill';
export { DoubleQuotesL } from '@styled-icons/remix-editor/DoubleQuotesL';
export { Download } from '@styled-icons/ionicons-outline/Download';
export { Dot } from '@styled-icons/bootstrap/Dot';
export { Download as File_download } from '@styled-icons/ionicons-solid/Download';
export { Duplicate } from '@styled-icons/ionicons-solid/Duplicate';
export { Eye as File_eye } from '@styled-icons/ionicons-solid/Eye';
export { EllipsisHorizontal } from '@styled-icons/ionicons-solid/EllipsisHorizontal';
export { Envelope } from '@styled-icons/bootstrap/Envelope';
export { Expand } from '@styled-icons/ionicons-outline/Expand';
export { Eye } from '@styled-icons/ionicons-outline/Eye';
export { EyeOff } from '@styled-icons/ionicons-outline/EyeOff';
export { FastFood } from '@styled-icons/ionicons-outline/FastFood';
export { Facebook } from '@styled-icons/material-sharp/Facebook';
export { FolderOpen } from '@styled-icons/ionicons-outline/FolderOpen';
export { Home } from '@styled-icons/ionicons-outline/Home';
export { InformationCircle } from '@styled-icons/ionicons-outline/InformationCircle';
export { Image } from '@styled-icons/ionicons-outline/Image';
export { Language } from '@styled-icons/ionicons-outline/Language';
export { Library } from '@styled-icons/ionicons-outline/Library';
export { Link } from '@styled-icons/ionicons-solid/Link';
export { Location } from '@styled-icons/ionicons-outline/Location';
export { LockClosed } from '@styled-icons/ionicons-outline/LockClosed';
export { Map } from '@styled-icons/ionicons-outline/Map';
export { Pencil } from '@styled-icons/remix-line/Pencil';
export { Pencil as PencilSolid } from '@styled-icons/ionicons-solid/Pencil';
export { People } from '@styled-icons/ionicons-outline/People';
export { Person } from '@styled-icons/ionicons-outline/Person';
export { Pricetag } from '@styled-icons/ionicons-outline/Pricetag';
export { Prohibited } from '@styled-icons/fluentui-system-filled/Prohibited';
export { Plus } from '@styled-icons/bootstrap/Plus';
export { School } from '@styled-icons/ionicons-outline/School';
export { Search } from '@styled-icons/ionicons-outline/Search';
export { Settings } from '@styled-icons/ionicons-outline/Settings';
export { ShareSocial } from '@styled-icons/ionicons-outline/ShareSocial';
export { SortAlphaDown } from '@styled-icons/bootstrap/SortAlphaDown';
export { SortAlphaUpAlt } from '@styled-icons/bootstrap/SortAlphaUpAlt';
export { Spinner } from '@styled-icons/evil/Spinner';
export { StatusOnline } from '@styled-icons/heroicons-outline/StatusOnline';
export { Sync } from '@styled-icons/ionicons-outline/Sync';
export { TrailSign } from '@styled-icons/ionicons-outline/TrailSign';
export { Trash } from '@styled-icons/ionicons-outline/Trash';
export { UserRemove } from '@styled-icons/heroicons-outline/UserRemove';
export { Warning } from '@styled-icons/ionicons-outline/Warning';
export { ErrorWarning } from '@styled-icons/remix-fill/ErrorWarning';
export { ArrowDropDownCircle as Form_dropdown } from '@styled-icons/material-rounded/ArrowDropDownCircle';
export { ArrowDownCircle as Form_file_download } from '@styled-icons/ionicons-solid/ArrowDownCircle';
export { ArrowUpCircle as Form_file_upload } from '@styled-icons/ionicons-solid/ArrowUpCircle';
export { Calendar as Form_date } from '@styled-icons/ionicons-solid/Calendar';
export { Card as Form_fee } from '@styled-icons/ionicons-solid/Card';
export { Card as Form_cash_net } from '@styled-icons/ionicons-solid/Card';
export { Card as Form_cash_net_with_code } from '@styled-icons/ionicons-solid/Card';
export { Card as Form_flywire } from '@styled-icons/ionicons-solid/Card';
export { Card as Form_payflow } from '@styled-icons/ionicons-solid/Card';
export { Card as Form_cybersource } from '@styled-icons/ionicons-solid/Card';
export { Card as Form_quik_pay } from '@styled-icons/ionicons-solid/Card';
export { Card as Form_converge_pay } from '@styled-icons/ionicons-solid/Card';
export { Card as Form_touch_net } from '@styled-icons/ionicons-solid/Card';
export { CardText as Form_multi_line_text } from '@styled-icons/bootstrap/CardText';
export { Checkbox as Form_multiple_choice } from '@styled-icons/ionicons-solid/Checkbox';
export { InputCursorText as Form_single_line_text } from '@styled-icons/bootstrap/InputCursorText';
export { InputCursorText as Form_short_text } from '@styled-icons/bootstrap/InputCursorText';
export { InputCursorText as Form_text_input } from '@styled-icons/bootstrap/InputCursorText';
export { Menu as Form_long_text } from '@styled-icons/ionicons-solid/Menu';
export { Signature as Form_signature } from '@styled-icons/fluentui-system-regular/Signature';
export { Person as Form_traveler_info } from '@styled-icons/ionicons-solid/Person';
export { Play as Form_video } from '@styled-icons/ionicons-solid/Play';
export { RadioButtonOn as Form_single_choice } from '@styled-icons/ionicons-solid/RadioButtonOn';
export { Recommend as Form_recommendation } from '@styled-icons/material/Recommend';
export { StarHalf as Form_star_rating } from '@styled-icons/ionicons-solid/StarHalf';
export { Star } from '@styled-icons/bootstrap/Star';
export { StarFill } from '@styled-icons/bootstrap/StarFill';
export { FileEarmarkBreakFill as Form_divider } from '@styled-icons/bootstrap/FileEarmarkBreakFill';
export { Header as Form_header } from '@styled-icons/open-iconic/Header';
export { Text as Form_paragraph } from '@styled-icons/ionicons-solid/Text';
export { Text as Form_text } from '@styled-icons/ionicons-solid/Text';
export { TextParagraph as Form_text_paragraph } from '@styled-icons/fluentui-system-filled/TextParagraph';
export { UniversalAccess } from '@styled-icons/fa-solid/UniversalAccess';
export { Reply } from '@styled-icons/entypo/Reply';
