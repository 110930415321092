import React from 'react';
import { useSelector } from 'react-redux';

import ContentBlock from '../../structure/ContentBlock';
import { TextBlockRichContentSection } from '../../structure/ContentBlock.styled';

import { TabCard, TabHeading, TabCardPanel, TabContentContainer } from '../TabContent.styled';

import isEmpty from '../../../utils/isEmpty';

export default function Academics() {
  const {
    academic: {
      notes,
      subjects,
      course: { notes: courseNotes },
    },
  } = useSelector(state => state.programBrochure);

  return (
    <TabContentContainer>
      <TabHeading level={2}>Academics</TabHeading>
      <TabCard>
        <TabCardPanel className="full" tabindex={0}>
          <ContentBlock >
            {!isEmpty(subjects) && (
              <TextBlockRichContentSection
                headingText="Subject Areas"
                headingWeight="medium"
                plainParagraph
                sectionContent={subjects.map(subject => subject.name).join('; ')}
              />
            )}
            {!isEmpty(courseNotes) && (
              <TextBlockRichContentSection headingText="Courses" headingWeight="medium" sectionContent={courseNotes} />
            )}
            {!isEmpty(notes) && (
              <TextBlockRichContentSection
                headingText="Academics Information"
                headingWeight="medium"
                sectionContent={notes}
              />
            )}
          </ContentBlock>
        </TabCardPanel>
      </TabCard>
    </TabContentContainer>
  );
}
