import fetch from 'node-fetch';
import _clone from 'lodash/clone';
import { keys } from '../config/keys';
import {
  SET_RISK_LEVELS,
  FETCH_RISK_LEVELS_REQUEST,
  FETCH_RISK_LEVELS_FAILURE,
  FETCH_RISK_LEVELS_CLEAR,
  SET_RISK_TYPES,
  FETCH_RISK_TYPES_REQUEST,
  FETCH_RISK_TYPES_FAILURE,
  FETCH_RISK_TYPES_CLEAR,
  SAVE_FILTER_REQUEST,
  SAVE_FILTER_CLEAR,
  SAVE_FILTER_FAILURE,
  FETCH_FILTER_CONFIG_REQUEST,
  FETCH_FILTER_CONFIG_CLEAR,
  FETCH_FILTER_CONFIG_FAILURE,
  SET_FILTER_CONFIG,
  TOGGLE_SEND_MESSAGE_DIALOG,
  TOGGLE_SEND_RISK_ALERT_DIALOG,
  FETCH_RISK_ALERTS_REQUEST,
  FETCH_RISK_ALERTS_FAILURE,
  FETCH_RISK_ALERTS_CLEAR,
  SET_RISK_ALERTS,
  MARK_ALL_READ_CLEAR,
  MARK_ALL_READ_REQUEST,
  MARK_ALL_READ_FAILURE,
  FETCH_RISK_DETAIL_REQUEST,
  FETCH_RISK_DETAIL_FAILURE,
  FETCH_RISK_DETAIL_CLEAR,
  SET_RISK_DETAIL,
  FETCH_RISK_ALERT_HISTORY_REQUEST,
  FETCH_RISK_ALERT_HISTORY_CLEAR,
  FETCH_RISK_ALERT_HISTORY_FAILURE,
  SET_RISK_ALERT_HISTORY,
  FETCH_RISK_ALERT_HISTORY_CSV_REQUEST,
  FETCH_RISK_ALERT_HISTORY_CSV_FAILURE,
  SET_RISK_ALERT_HISTORY_CSV_DATA,
  FETCH_RISK_ALERT_HISTORY_CSV_CLEAR,
  FETCH_ALERT_TRAVELERS_REQUEST,
  FETCH_ALERT_TRAVELERS_FAILURE,
  FETCH_ALERT_TRAVELERS_CLEAR,
  SET_ALERT_TRAVELERS,
  FETCH_COUNTRIES_REQUEST,
  FETCH_COUNTRIES_FAILURE,
  FETCH_COUNTRIES_CLEAR,
  SET_COUNTRIES,
  SEND_NOTIFICATION_REQUEST,
  SEND_NOTIFICATION_SUCCESS,
  SEND_NOTIFICATION_FAILURE,
  SEND_ALERT_REQUEST,
  SEND_ALERT_SUCCESS,
  SEND_ALERT_FAILURE,
  FETCH_TRAVELER_ALERT_INFO_REQUEST,
  FETCH_TRAVELER_ALERT_INFO_CLEAR,
  FETCH_TRAVELER_ALERT_INFO_FAILURE,
  SET_TRAVELER_ALERT_INFO,
  SEND_CUSTOM_MESSAGE_REQUEST,
  SEND_CUSTOM_MESSAGE_FAILURE,
  SEND_CUSTOM_MESSAGE_SUCCESS,
  TOGGLE_EMERGENCY_NUMBER_DIALOG,
  CREATE_RISK_ALERTS_NOTE_REQUEST,
  CREATE_RISK_ALERTS_NOTE_SUCCESS,
  CREATE_RISK_ALERTS_NOTE_CLEAR,
  CREATE_RISK_ALERTS_NOTE_FAILURE,
  UPDATE_RISK_ALERTS_NOTE_REQUEST,
  UPDATE_RISK_ALERTS_NOTE_SUCCESS,
  UPDATE_RISK_ALERTS_NOTE_CLEAR,
  UPDATE_RISK_ALERTS_NOTE_FAILURE,
  DELETE_RISK_ALERTS_NOTE_REQUEST,
  DELETE_RISK_ALERTS_NOTE_SUCCESS,
  DELETE_RISK_ALERTS_NOTE_CLEAR,
  DELETE_RISK_ALERTS_NOTE_FAILURE
} from './types';
import token from '../shared/helpers/token';
import { generateQueryString, isUnreadAlert } from '../helpers/RiskAlertHelper';

export function getRiskLevels() {
  return function(dispatch) {
    dispatch({ type: FETCH_RISK_LEVELS_REQUEST });

    fetch(`${keys.baseUri}/api/risk_line/alert_configs/risk_levels`, {
      method: 'GET',
      headers: token,
    })
      .then(res => res.json())
      .then(data => {
        dispatch({
          type: SET_RISK_LEVELS,
          payload: data.data,
        });
      })
      .catch(error => {
        dispatch({
          type: FETCH_RISK_LEVELS_FAILURE,
          payload: error.toString(),
        });
      })
      .finally(() => {
        dispatch({
          type: FETCH_RISK_LEVELS_CLEAR,
        });
      });
  };
}

export function getRiskTypes() {
  return function(dispatch) {
    dispatch({ type: FETCH_RISK_TYPES_REQUEST });

    fetch(`${keys.baseUri}/api/risk_line/alert_configs/risk_types`, {
      method: 'GET',
      headers: token,
    })
      .then(res => res.json())
      .then(data => {
        dispatch({
          type: SET_RISK_TYPES,
          payload: data.data,
        });
      })
      .catch(error => {
        dispatch({
          type: FETCH_RISK_TYPES_FAILURE,
          payload: error.toString(),
        });
      })
      .finally(() => {
        dispatch({
          type: FETCH_RISK_TYPES_CLEAR,
        });
      });
  };
}

export function saveRiskFilter(filterData, callback) {
  return function(dispatch) {
    dispatch({ type: SAVE_FILTER_REQUEST });

    fetch(`${keys.baseUri}/api/risk_line/alert_configs`, {
      method: 'POST',
      headers: token,
      body: JSON.stringify(filterData),
    })
      .then(res => res.json())
      .then(data => {
        dispatch({
          type: SAVE_FILTER_CLEAR,
          payload: data.data,
        });
        dispatch(getRiskAlerts());
        dispatch(getFilterConfig());
        callback(true);
      })
      .catch(error => {
        callback(false);
        dispatch({
          type: SAVE_FILTER_FAILURE,
          payload: error.toString(),
        });
      });
  };
}

export function getFilterConfig() {
  return function(dispatch) {
    dispatch({ type: FETCH_FILTER_CONFIG_REQUEST });

    fetch(`${keys.baseUri}/api/risk_line/alert_configs/alert_config`, {
      method: 'GET',
      headers: token,
    })
      .then(res => res.json())
      .then(data => {
        dispatch({
          type: SET_FILTER_CONFIG,
          payload: data.data.attributes,
        });
      })
      .catch(error => {
        dispatch({
          type: FETCH_FILTER_CONFIG_FAILURE,
          payload: error.toString(),
        });
      })
      .finally(() => {
        dispatch({
          type: FETCH_FILTER_CONFIG_CLEAR,
        });
      });
  };
}

export function toggleSendMessageDialog(flag, data = null) {
  return {
    type: TOGGLE_SEND_MESSAGE_DIALOG,
    payload: {
      flag,
      data,
    },
  };
}

export function toggleSendRiskAlertDialog(flag, data = null) {
  return {
    type: TOGGLE_SEND_RISK_ALERT_DIALOG,
    payload: {
      flag,
      data,
    },
  };
}

export function getRiskAlerts() {
  return function(dispatch) {
    dispatch({ type: FETCH_RISK_ALERTS_REQUEST });

    fetch(`${keys.baseUri}/api/risk_line/alerts`, {
      method: 'GET',
      headers: token,
    })
      .then(res => res.json())
      .then(data => {
        let isNewAlert = isUnreadAlert(data.data);

        dispatch({
          type: SET_RISK_ALERTS,
          payload: {
            data: data.data,
            isNewAlert,
          },
        });
      })
      .catch(error => {
        dispatch({
          type: FETCH_RISK_ALERTS_FAILURE,
          payload: error.toString(),
        });
      })
      .finally(() => {
        dispatch({
          type: FETCH_RISK_ALERTS_CLEAR,
        });
      });
  };
}

export function getRiskAlertDetail(id) {
  return function(dispatch) {
    dispatch({ type: FETCH_RISK_DETAIL_REQUEST });

    fetch(`${keys.baseUri}/api/risk_line/alerts/${id}`, {
      method: 'GET',
      headers: token,
    })
      .then(res => res.json())
      .then(data => {
        if (data.data) {
          dispatch({
            type: SET_RISK_DETAIL,
            payload: data.data,
          });
        } else {
          window.location.href = '/404';
        }
      })
      .catch(error => {
        window.location.href = '/404';

        dispatch({
          type: FETCH_RISK_DETAIL_FAILURE,
          payload: error.toString(),
        });
      })
      .finally(() => {
        dispatch({
          type: FETCH_RISK_DETAIL_CLEAR,
        });
      });
  };
}

export function markAlertRead(alert_ids) {
  return function(dispatch) {
    dispatch({ type: MARK_ALL_READ_REQUEST });

    let postData = {
      alerts: {
        alert_ids,
      },
    };

    fetch(`${keys.baseUri}/api/risk_line/alerts/mark_all_as_read`, {
      method: 'PATCH',
      headers: token,
      body: JSON.stringify(postData),
    })
      .then(res => res.json())
      .then(data => {
        dispatch(getRiskAlerts());
      })
      .catch(error => {
        dispatch({
          type: MARK_ALL_READ_FAILURE,
          payload: error.toString(),
        });
      })
      .finally(() => {
        dispatch({
          type: MARK_ALL_READ_CLEAR,
        });
      });
  };
}

export function getRiskAlertHistory(filterObj, order_by = "", dir = "", callback = null) {
  return function(dispatch) {
    if (filterObj?.all_data === 1) {
      dispatch({ type: FETCH_RISK_ALERT_HISTORY_CSV_REQUEST });
    } else {
      dispatch({ type: FETCH_RISK_ALERT_HISTORY_REQUEST });
    }

    if (filterObj?.travelers_impacted) {
      let travelers_impacted = JSON.parse(filterObj.travelers_impacted);
      if (travelers_impacted?.length === 2) {
        delete filterObj.travelers_impacted;
      } else if (travelers_impacted.length === 1 && travelers_impacted.includes(1)) {
        filterObj.travelers_impacted = true;
      } else if (travelers_impacted.length === 1 && travelers_impacted.includes(2)) {
        filterObj.travelers_impacted = false;
      }
    }

    let url = `${keys.baseUri}/api/risk_line/alerts/alert_history`;
    let queryStr = generateQueryString(filterObj);

    if (queryStr) {
      url += `?${queryStr}`;
    }

    if(order_by && dir){
      if(queryStr){
        url += `&order_by=${order_by}&dir=${dir}`
      }
      else{
        url += `?order_by=${order_by}&dir=${dir}`
      }
    }

    fetch(url, {
      method: 'GET',
      headers: token,
    })
      .then(res => res.json())
      .then(data => {
        if (callback && typeof callback === 'function') {
          callback(true, 'Data fetched', data.data);
        }

        if (filterObj?.all_data === 1) {
          dispatch({
            type: SET_RISK_ALERT_HISTORY_CSV_DATA,
            payload: {
              data: data.data,
            },
          });
        } else {
          dispatch({
            type: SET_RISK_ALERT_HISTORY,
            payload: {
              data: data.data,
              pagination: data?.meta?.pagination,
              country_count: data?.meta?.country_count || 0,
              alert_count: data?.meta?.alert_count || 0,
            },
          });
        }
      })
      .catch(error => {
        if (callback && typeof callback === 'function') {
          callback(false, error.message);
        }

        if (filterObj?.all_data === 1) {
          dispatch({
            type: FETCH_RISK_ALERT_HISTORY_CSV_FAILURE,
            payload: error.toString(),
          });
        } else {
          dispatch({
            type: FETCH_RISK_ALERT_HISTORY_FAILURE,
            payload: error.toString(),
          });
        }
      })
      .finally(() => {
        if (filterObj?.all_data === 1) {
          dispatch({
            type: FETCH_RISK_ALERT_HISTORY_CSV_CLEAR,
          });
        } else {
          dispatch({
            type: FETCH_RISK_ALERT_HISTORY_CLEAR,
          });
        }
      });
  };
}

export function getAlertTravelers({ alert_id, plan_or_program = 'both' }) {
  return function(dispatch) {
    dispatch({ type: FETCH_ALERT_TRAVELERS_REQUEST });

    fetch(
      `${keys.baseUri}/api/risk_line/travelers/traveler_list?alert_id=${alert_id}&plan_or_program=${plan_or_program}`,
      {
        method: 'GET',
        headers: token,
      },
    )
      .then(res => res.json())
      .then(data => {
        data = data.map(obj => {
          obj.checked = false;
          return obj;
        });

        dispatch({
          type: SET_ALERT_TRAVELERS,
          payload: data,
        });
      })
      .catch(error => {
        dispatch({
          type: FETCH_ALERT_TRAVELERS_FAILURE,
          payload: error.toString(),
        });
      })
      .finally(() => {
        dispatch({
          type: FETCH_ALERT_TRAVELERS_CLEAR,
        });
      });
  };
}

export function getCountries() {
  return function(dispatch) {
    dispatch({ type: FETCH_COUNTRIES_REQUEST });

    fetch(`${keys.baseUri}/api/risk_line/alert_configs/countries`, {
      method: 'GET',
      headers: token,
    })
      .then(res => res.json())
      .then(data => {
        dispatch({
          type: SET_COUNTRIES,
          payload: data.data,
        });
      })
      .catch(error => {
        dispatch({
          type: FETCH_COUNTRIES_FAILURE,
          payload: error.toString(),
        });
      })
      .finally(() => {
        dispatch({
          type: FETCH_COUNTRIES_CLEAR,
        });
      });
  };
}

export function sendNotification(postData, uuid, callback) {
  return function(dispatch) {
    dispatch({ type: SEND_NOTIFICATION_REQUEST });

    fetch(`${keys.baseUri}/api/risk_line/travelers/update_traveler_response?uuid=${uuid}`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(postData),
    })
      .then(res => res.json())
      .then(data => {
        dispatch({
          type: SEND_NOTIFICATION_SUCCESS,
          payload: data.data,
        });
        callback(true, 'Message sent successfully');
      })
      .catch(error => {
        callback(false, error.message);
        dispatch({
          type: SEND_NOTIFICATION_FAILURE,
          payload: error.toString(),
        });
      });
  };
}

export function sendAlert(user_ids, alert_id, callback) {
  return function(dispatch) {
    dispatch({ type: SEND_ALERT_REQUEST });

    let body = {
      send_alerts: {
        user_ids,
        alert_id,
      },
    };

    fetch(
      `${keys.baseUri}/api/risk_line/alerts/send_alerts?alert_id=${alert_id}&user_ids=${JSON.stringify(user_ids)}`,
      {
        method: 'POST',
        headers: token,
        body: JSON.stringify(body),
      },
    )
      .then(res => res.json())
      .then(data => {
        dispatch({
          type: SEND_ALERT_SUCCESS,
          payload: data.message,
        });

        let message = 'Alert sent to travelers';

        if (user_ids.length === 1) {
          message = 'Alert sent to traveler';
        }
        callback(true, message);
      })
      .catch(error => {
        callback(false, error.message);
        dispatch({
          type: SEND_ALERT_FAILURE,
          payload: error.toString(),
        });
      });
  };
}

export function getTravelerAlertInfo(uuid) {
  return function(dispatch) {
    dispatch({ type: FETCH_TRAVELER_ALERT_INFO_REQUEST });

    fetch(`${keys.baseUri}/api/risk_line/travelers/traveler_alert_info?uuid=${uuid}`, {
      method: 'GET',
      headers: token,
    })
      .then(res => res.json())
      .then(data => {
        dispatch({
          type: SET_TRAVELER_ALERT_INFO,
          payload: data,
        });
      })
      .catch(error => {
        window.location.href = '/404';

        dispatch({
          type: FETCH_TRAVELER_ALERT_INFO_FAILURE,
          payload: error.toString(),
        });
      })
      .finally(() => {
        dispatch({
          type: FETCH_TRAVELER_ALERT_INFO_CLEAR,
        });
      });
  };
}

export function sendCustomMessage(formData, user_ids, callback) {
  let cloneToken = _clone(token);
  delete cloneToken['Accept'];
  delete cloneToken['Content-Type'];

  return function(dispatch) {
    dispatch({ type: SEND_CUSTOM_MESSAGE_REQUEST });

    fetch(`${keys.baseUri}/api/risk_line/alerts/send_custom_message`, {
      method: 'POST',
      headers: cloneToken,
      body: formData,
    })
      .then(res => res.json())
      .then(data => {
        dispatch({
          type: SEND_CUSTOM_MESSAGE_SUCCESS,
          payload: data.message,
        });

        let message = 'Message sent to travelers';

        if (user_ids.length === 1) {
          message = 'Message sent to traveler';
        }

        callback(true, message);
      })
      .catch(error => {
        callback(false, error.message);
        dispatch({
          type: SEND_CUSTOM_MESSAGE_FAILURE,
          payload: error.toString(),
        });
      });
  };
}

export function toggleEmergencyNumbersDialog(show = false, data = [], countryName = '') {
  return {
    type: TOGGLE_EMERGENCY_NUMBER_DIALOG,
    payload: {
      show,
      data,
      countryName,
    },
  };
}

export function saveOrganizationNote(requestData,alertIDParam,callback) {
  return function(dispatch) {
    dispatch({ type: CREATE_RISK_ALERTS_NOTE_REQUEST });

    fetch(`${keys.baseUri}/api/risk_line/alert_notes`, {
      method: 'POST',
      headers: token,
      body: JSON.stringify(requestData),
    }).then(response => {
        if (response.status === 200) {
          response.json().then(data => {
            if(callback && typeof callback === "function"){
              callback(true, `Organization note created`); 
            }
            dispatch({
              type: CREATE_RISK_ALERTS_NOTE_SUCCESS,
              payload: data,
            });
            dispatch({
              type: CREATE_RISK_ALERTS_NOTE_CLEAR,
            });
            dispatch(getRiskAlertDetail(alertIDParam))
          });
        } else {
          response.json().then(message => {
            let error = "Something went wrong, please try again later"           
            if(callback && typeof callback === "function"){
              callback(false, error); 
            }
            dispatch({
              type: CREATE_RISK_ALERTS_NOTE_FAILURE,
              message: message,
            });
            dispatch({
              type: CREATE_RISK_ALERTS_NOTE_CLEAR,
            });
          });
        }
      })
      .catch(error => {
        if(callback && typeof callback === "function"){
          callback(false);
        }
        dispatch({
          type: CREATE_RISK_ALERTS_NOTE_FAILURE,
          payload: error.toString(),
        });
      });
  };
}

export function updateOrganizationNote(requestData,noteID,alertIDParam, callback) {
  return function(dispatch) {
    dispatch({ type: UPDATE_RISK_ALERTS_NOTE_REQUEST });

    fetch(`${keys.baseUri}/api/risk_line/alert_notes/${noteID}`, {
      method: 'PATCH',
      headers: token,
      body: JSON.stringify(requestData),
    }).then(response => {
        if (response.status === 200) {
          response.json().then(data => {
            if(callback && typeof callback === "function"){
              callback(true, `Organization note saved`); 
            }
            dispatch({
              type: UPDATE_RISK_ALERTS_NOTE_SUCCESS,
              payload: data,
            });
            dispatch({
              type: UPDATE_RISK_ALERTS_NOTE_CLEAR,
            });
            dispatch(getRiskAlertDetail(alertIDParam))
          });
        } else {
          response.json().then(message => {
            let error = "Something went wrong, please try again later"           
            if(callback && typeof callback === "function"){
              callback(false, error); 
            }
            dispatch({
              type: UPDATE_RISK_ALERTS_NOTE_FAILURE,
              message: message,
            });
            dispatch({
              type: UPDATE_RISK_ALERTS_NOTE_CLEAR,
            });
          });
        }
      })
      .catch(error => {
        callback(false);
        dispatch({
          type: UPDATE_RISK_ALERTS_NOTE_FAILURE,
          payload: error.toString(),
        });
      });
  };
}

export const deleteOrganizationNote = (noteID,alertIDParam, callback) => dispatch => {
  dispatch({ type: DELETE_RISK_ALERTS_NOTE_REQUEST });
  let url = `${keys.baseUri}/api/risk_line/alert_notes/${noteID}`;
  fetch(url, {
    method: 'DELETE',
    headers: token,
  })
    .then(res => {
      if (res.status === 200 || res.status === 201) {
        res.json().then(data => {
          if(callback && typeof callback === "function"){
            callback(true, `Organization Note deleted`); 
          }

          dispatch({
            type: DELETE_RISK_ALERTS_NOTE_SUCCESS,
            payload: data,
          });
          //dispatch(getRiskAlertDetail(alertIDParam))
        });
      } else {
        res.json().then(message => {
          if(callback && typeof callback === "function"){
            callback(false, 'Something went wrong, please try again later'); 
          }
          dispatch({
            type: DELETE_RISK_ALERTS_NOTE_FAILURE,
            payload: message,
          });
        });
      }
    })
    .catch(error => {

      if(callback && typeof callback === "function"){
        callback(false, 'Something went wrong, please try again later'); 
      }

      dispatch({
        type: DELETE_RISK_ALERTS_NOTE_FAILURE,
        payload: error.toString(),
      });
    });
};