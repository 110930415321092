import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import FocusTrap from 'focus-trap-react';
import { useSelector } from 'react-redux';

import { breakpoints } from '../../utils/breakpoints';

import { ViaInternationalIcon, ViaGlobalIcon } from '../../../../styledComponents/styles/IconCustom.styled';
const Container = styled.nav`
  position: absolute;
  top: 20%;
  left: 2%;
  z-index: 120;
  min-width: 300px;
  
  display: flex;
  width: 370px;
  height: 188px;
  padding: 28px 0px;
  flex-direction: column;
  align-items: center;
  gap: 36px;
  flex-shrink: 0;
  border-radius: 15px;
  background: var(--white, #FFF);
  box-shadow: 0px 4px 20px 12px rgba(0, 0, 0, 0.16);

  @media ${breakpoints.tablet} {
    top: 12%;
    right: 0;
    border-radius: 15px;
  }
`;

const Content = styled.div`
  display: flex;
  align-items: center;
  margin:auto;
  gap:30px;
`;

const IconContainer = styled.div`
    display:flex;
    flex-direction:column;
    align-items:center;
`;

const IconLabel = styled.div`
  color: var(--gray-dark, #474747);
  text-align: center;

  /* Headline 6 */
  font-family: Nunito;
  font-size: 21px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%; /* 25.2px */
  letter-spacing: 0.032px;
  margin-top:10px;
`;

const LinkContainer = styled.a`
  text-decoration : none;
  text-align:center;
`;

export default function ViaMenuDropdown({ isOpen, menuButtonRef, onClickOutside }) {
  const ref = useRef(null);

  useEffect(() => {
    const handleClickOutside = event => {
      if (
        ref.current &&
        !ref.current.contains(event.target) &&
        menuButtonRef.current &&
        !menuButtonRef.current.contains(event.target)
      ) {
        onClickOutside && onClickOutside();
      }
    };

    document.addEventListener('click', handleClickOutside, true);

    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [onClickOutside]);

    const currentUrl = window.location.href;
    // Regular expression to extract subdomain and domain
    const subdomainRegex = /\/\/([^/]+)\//;
    // Extract the subdomain and domain
    const match = currentUrl.match(subdomainRegex);
    const subdomain = match ? match[1] : null;
    // Split the domain into second-level domain and top-level domain
    const domainParts = subdomain ? subdomain.split('.') : [];
    const secondLevelDomain = domainParts.length >= 2 ? domainParts[0] : null;
    const topLevelDomain = domainParts.length >= 2 ? domainParts.slice(1).join('.') : null;

  return (
    <>   
      <Container ref={ref}>
        <Content>
          <IconContainer>
            <LinkContainer href={`https://${secondLevelDomain}.${topLevelDomain}`} target="_blank" style={{textDecoration:"none"}}>
              <ViaGlobalIcon width="60" height="60" />
              <IconLabel>Via Global</IconLabel>
            </LinkContainer>
          </IconContainer>
          <IconContainer>
            <LinkContainer href={`https://${secondLevelDomain}.inbound.${topLevelDomain}`} target="_blank" style={{textDecoration:"none"}}>
              <ViaInternationalIcon width="60" height="60" />
              <IconLabel>Via International</IconLabel>
            </LinkContainer>
          </IconContainer>
        </Content>
      </Container>
    </>
  );
}

ViaMenuDropdown.propTypes = {
  isOpen: PropTypes.bool,
  onClickOutside: PropTypes.func,
};

ViaMenuDropdown.defaultProps = {
  isOpen: false,
  onClickOutside: () => {},
};
