import React from 'react';
import s from './TravelerApplicationForm.css';
import ReactHtmlParser from 'react-html-parser';

export const EmbedVideoOnPreview = (header, video, details, videoWidth = 560) => {
  let embedVideoUrl = video.replace('watch?v=', 'embed/', video);
  let fixYoutubeUrl = video.replace('youtu.be', 'youtube.com/embed', video);
  let fixPreziVideo = video.concat('embed');
  let vimeoEmbedUrl = video.replace(
    'vimeo.com',
    'player.vimeo.com/video',
    video,
  );

  if (video.includes('youtube.com')) {
    return (
      <div className={s.toggleWrapper}>
        <h4 className={s.downloadHeader}>{header}</h4>
        {details !== '<p><br></p>' ? (
          <p className={s.downloadDetails}>
            {details ? ReactHtmlParser(details) : null}
          </p>
        ) : null}
        <iframe
          tabIndex={0}
          width={videoWidth}
          height="315"
          src={embedVideoUrl}
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      </div>
    );
  } else if (video.includes('youtu.be')) {
    return (
      <div className={s.toggleWrapper}>
        <h4 className={s.downloadHeader}>{header}</h4>
        {details !== '<p><br></p>' ? (
          <p className={s.downloadDetails}>
            {details ? ReactHtmlParser(details) : null}
          </p>
        ) : null}
        <iframe
          width={videoWidth}
          height="315"
          src={fixYoutubeUrl}
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      </div>
    );
  } else if (video.includes('prezi.com/')) {
    return (
      <div className={s.toggleWrapper}>
        <h4 className={s.downloadHeader}>{header}</h4>
        {details !== '<p><br></p>' ? (
          <p className={s.downloadDetails}>
            {details ? ReactHtmlParser(details) : null}
          </p>
        ) : null}
        <iframe
          width={videoWidth}
          height="315"
          src={fixPreziVideo}
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      </div>
    );
  } else if (video.includes('vimeo.com')) {
    return (
      <div className={s.toggleWrapper}>
        <h4 className={s.downloadHeader}>{header}</h4>
        {details !== '<p><br></p>' ? (
          <p className={s.downloadDetails}>
            {details ? ReactHtmlParser(details) : null}
          </p>
        ) : null}
        <iframe
          width={videoWidth}
          height="315"
          src={vimeoEmbedUrl}
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      </div>
    );
  } else {
    return (
      <div className={s.toggleWrapper}>
        <h4 className={s.downloadHeader}>{header}</h4>
        {details !== '<p><br></p>' ? (
          <p className={s.downloadDetails}>
            {details ? ReactHtmlParser(details) : null}
          </p>
        ) : null}
        <iframe
          width={videoWidth}
          height="315"
          src={video}
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      </div>
    );
  }
};
