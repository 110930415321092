import React, { useEffect, useState } from 'react';
import { compose } from 'recompose';
import { useSelector } from 'react-redux';
import propTypes from 'prop-types';
import _orderBy from 'lodash/orderBy';
import FilterSingleSelect from '../../filterTypes/filterSingleSelect';

export const enhance = compose();

function TemplateFilter(props) {
  const templates = useSelector(state => state.lookup.allTemplateOptions);
  const [sourceOptions, setSourceOptions] = useState([]);
  const [selected, setSelected] = useState([]);

  useEffect(
    () => {
      if (templates?.templateOptions) {
        let archived = [];
        let draft = [];
        let published = [];
        let unpublished = [];

        templates?.templateOptions?.map(item => {
          if (item?.attributes?.name?.length > 0) {
            const option = {
              label: item?.attributes?.name,
              value: item?.id,
            };
            switch (item?.attributes?.status) {
              case 'archived':
                archived.push(option);
                break;
              case 'draft':
                draft.push(option);
                break;
              case 'published':
                published.push(option);
                break;
              case 'unpublished':
                unpublished.push(option);
                break;
              default:
                break;
            }
          }
        });

        draft = _orderBy(draft, [o => o.label.toLowerCase()], ['asc']);
        published = _orderBy(published, [o => o.label.toLowerCase()], ['asc']);
        unpublished = _orderBy(unpublished, [o => o.label.toLowerCase()], ['asc']);
        archived = _orderBy(archived, [o => o.label.toLowerCase()], ['asc']);

        const groupOptions = [
          // {label: 'Draft', options: draft},
          { label: 'Published', options: published },
          { label: 'Unpublished', options: unpublished },
          { label: 'Archived', options: archived },
        ];

        let defValue = [];
        if (props.selectedValues) {
          let selectedProgram = templates.templateOptions.find(t => t.id == props.selectedValues[0]);
          if (selectedProgram) {
            defValue = [{ label: selectedProgram.attributes.name, value: selectedProgram.id }];
            setSelected(defValue);
          }
        }

        setSelected(defValue);
        setSourceOptions(groupOptions);
      }
    },
    [templates, props.selectedValues],
  );

  const selectedValues = values => {
    let selectedProgram = templates.templateOptions.find(t => t.id == values[0]);
    const defValue = [{ label: selectedProgram.attributes.name, value: selectedProgram.id }];
    setSelected(defValue);
    props.change(values);
  };

  return (
    <FilterSingleSelect
      id={props.id}
      sourceData={sourceOptions}
      selectedTemplate={selected}
      // selectedValues={selected}
      change={e => selectedValues(e)}
      labelText={props.displayText}
      isRequired
    />
  );
}

TemplateFilter.propTypes = {
  change: propTypes.func.isRequired,
  displayText: propTypes.string,
  id: propTypes.string.isRequired,
  selectedValues: propTypes.array,
  type: propTypes.oneOf(['Application', 'General Form']).isRequired,
};

TemplateFilter.defaultProps = {
  displayText: 'Template Name',
  selectedValues: [],
};

export default enhance(TemplateFilter);
