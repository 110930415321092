export function getIntakeStepIndicators(state, currentStep) {
  const steps = [
    {
      step: 1,
      current: currentStep === 1,
      completed: !!state.first_name,
    },
    {
      step: 2,
      current: currentStep === 2,
      completed: !!state.last_name,
    },
    {
      step: 3,
      current: currentStep === 3,
      completed: !!state.traveler_type,
    },
    {
      step: 4,
      current: currentStep === 4,
      completed: !!state.school_name,
    },
    {
      step: 5,
      current: currentStep === 5,
      completed: !!state.travel_goals,
    },
    {
      step: 6,
      current: currentStep === 6,
      completed: !!state.travel_experience,
    },
    {
      step: 7,
      current: currentStep === 7,
      completed: state.passport.status !== 'not_provided',
    },
    {
      step: 8,
      current: currentStep === 8,
      completed: state.countries_of_citizenship.length > 0,
    },
    {
      step: 9,
      current: currentStep === 9,
      completed: !!state.advising,
    },
    {
      step: 10,
      current: currentStep === 10,
      completed: !!state.financial_info,
    },
    {
      step: 11,
      current: currentStep === 11,
      completed: !!state.receives_federal_financial_aid && state.receives_federal_financial_aid !== 'not_provided',
    },
    {
      step: 12,
      current: currentStep === 12,
      // The API returns travel_certainty: 5 by default.
      // Submitted values are in the range 0-4.
      completed: (state.travel_certainty !== null && state.travel_certainty !== "not_provided")  && state.travel_certainty !== 5,
    },
  ];

  return steps;
}

export function getProgramMatchStepIndicators(state, currentStep) {
  const steps = [
    {
      step: 1,
      current: currentStep === 1,
      completed: state.subjectAreas.length > 0,
    },
    {
      step: 2,
      current: currentStep === 2,
      completed: state.programCountries.length > 0,
    },
    {
      step: 3,
      current: currentStep === 3,
      completed: state.programHousing.length > 0,
    },
    {
      step: 4,
      current: currentStep === 4,
      completed: !!state.programLength && !!state.programLength.minWeeks && !!state.programLength.maxWeeks,
    },
    {
      step: 5,
      current: currentStep === 5,
      completed: state.programTerms.length > 0,
    },
    {
      step: 6,
      current: currentStep === 6,
      completed: !!state.languageImmersion,
    },
    {
      step: 7,
      current: currentStep === 7,
      completed: state.programTypes.length > 0,
    },
    {
      step: 8,
      current: currentStep === 8,
      completed: state.priorities.length > 0,
    },
  ];

  return steps;
}
