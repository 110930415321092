import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import history from '../../../../history';
import { breakpoints } from '../../utils/breakpoints';
import { ACCENT_DEFAULT } from '../../../../shared/helpers/ViaDefaults';
import { handleBack, handleContinue } from '../../utils/handleStepNavigation';
import { withRouter } from 'react-router-dom';

import { intakeSteps, programMatchSteps } from '../../utils/stepComponentMaps';

const Container = styled.div`
  display: flex;
  justify-content: end;
  margin-bottom: 1.5rem;

  @media ${breakpoints.tablet} {
    margin-bottom: 3.345625rem;
  }

  @media ${breakpoints.desktop} {
    padding-right: 4.375rem;
  }
`;

const Button = styled.button`
  background-color: transparent;
  border: none;

  &:first-of-type {
    span {
      margin-right: 1.09375rem;
    }
  }

  &:last-of-type {
    span {
      margin-left: 1.09375rem;
      margin-right: 0;
    }
  }

  span {
    font-family: 'Nunito', sans-serif;
    font-size: 0.875rem;
    font-weight: 600;
    color: #446372;
    line-height: 120%;
    letter-spacing: 0.015em;
  }

  svg {
    border: 2px solid transparent;
  }

  &:focus {
    outline: none;
  }

  &:focus-visible {
    svg {
      border-color: #255a94;
    }
  }

  @media ${breakpoints.tablet} {
    &:first-of-type {
      margin-right: 1.125rem;
    }
  }
`;

export default function TravelerFormButtons({ showBack, handleNext, toProcessing }) {
  const colorAccent = document.documentElement.style.getPropertyValue('--theme_color_accent') || ACCENT_DEFAULT;
  const step = history.location.pathname.split('/').pop();
  let backStepName = "";
  let nextStepName = "";
  if(history.location.pathname.includes('/traveler/program-match')) {
    backStepName = programMatchSteps[Number(step-1)];
    nextStepName = programMatchSteps[Number(step)+1];
  } else {
    backStepName = intakeSteps[Number(step-1)];
    nextStepName = intakeSteps[Number(step)+1];
  }
  
  
  return (
    <Container>
      {showBack && (
        <Button
          type="button"
          onClick={() => {
            handleBack(history);
          }}
          aria-label={`Back to ${backStepName} step`}
        >
          <span>Back</span>
          <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M11.836 21.4137L11.8361 21.4139L20.832 30.4097C21.0149 30.6009 21.2339 30.7539 21.4764 30.8599C21.7211 30.9668 21.9846 31.0236 22.2515 31.027C22.5185 31.0304 22.7834 30.9804 23.0307 30.8798C23.278 30.7792 23.5026 30.6301 23.6914 30.4414C23.8801 30.2526 24.0292 30.028 24.1298 29.7807C24.2304 29.5334 24.2804 29.2685 24.277 29.0015C24.2736 28.7346 24.2168 28.4711 24.1099 28.2264C24.0039 27.9839 23.8509 27.7649 23.6597 27.582L16.0786 20L23.6639 12.4138L23.664 12.414L23.6728 12.4047C24.032 12.0266 24.2294 11.5231 24.2227 11.0015C24.216 10.48 24.0059 9.98174 23.6371 9.61294C23.2683 9.24414 22.77 9.034 22.2485 9.02732C21.7269 9.02064 21.2234 9.21796 20.8453 9.5772L20.8452 9.57708L20.8361 9.58613L11.8361 18.5861L11.836 18.5863C11.4612 18.9613 11.2507 19.4698 11.2507 20C11.2507 20.5302 11.4612 21.0387 11.836 21.4137ZM1 20C1 9.50708 9.50708 1 20 1C30.4929 1 39 9.50708 39 20C39 30.4929 30.4929 39 20 39C9.50708 39 1 30.4929 1 20Z"
              fill="#EEEEEE"
              stroke="#474747"
            />
          </svg>
        </Button>
      )}
      <Button
        type="button"
        onClick={event => {
          handleContinue(event, history, handleNext, toProcessing);
        }}
        aria-label={`Go to ${nextStepName} step`}
      >
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M28.164 18.5862L28.1639 18.5861L19.168 9.59031C18.9851 9.39911 18.7661 9.24608 18.5236 9.14012C18.2789 9.03325 18.0154 8.97643 17.7485 8.97301C17.4815 8.96959 17.2166 9.01964 16.9693 9.12022C16.722 9.2208 16.4974 9.36987 16.3086 9.55863C16.1199 9.74739 15.9708 9.97204 15.8702 10.2193C15.7696 10.4666 15.7196 10.7315 15.723 10.9984C15.7264 11.2654 15.7833 11.5289 15.8901 11.7735C15.9961 12.0161 16.1491 12.2351 16.3403 12.418L23.9214 20L16.3361 27.5862L16.336 27.586L16.3272 27.5953C15.968 27.9734 15.7706 28.4769 15.7773 28.9984C15.784 29.52 15.9941 30.0183 16.3629 30.3871C16.7317 30.7559 17.23 30.966 17.7515 30.9727C18.2731 30.9794 18.7766 30.782 19.1547 30.4228L19.1548 30.4229L19.1639 30.4139L28.1639 21.4139L28.164 21.4137C28.5388 21.0387 28.7493 20.5302 28.7493 20C28.7493 19.4698 28.5388 18.9613 28.164 18.5862ZM39 20C39 30.4929 30.4929 39 20 39C9.50708 39 1 30.4929 1 20C1 9.50708 9.50708 0.999997 20 0.999998C30.4929 0.999999 39 9.50708 39 20Z"
            fill={colorAccent}
            stroke={colorAccent}
          />
        </svg>
        <span>Next</span>
      </Button>
    </Container>
  );
}

TravelerFormButtons.propTypes = {
  showBack: PropTypes.bool,
  toProcessing: PropTypes.bool,
  handleNext: PropTypes.func.isRequired,
};

TravelerFormButtons.defaultProps = {
  showBack: true,
  toProcessing: false,
};
