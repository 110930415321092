import fetch from 'node-fetch';
import token from '../shared/helpers/token';
import { keys } from '../config/keys';
import _clone from 'lodash/clone';
import {
  FETCH_TRAVELER_SETTINGS_HEADER,
  SELECT_TRAVELER_SETTINGS,
  SELECT_TRAVELER_SETTINGS_TAB,
  UPDATE_TRAVELER_ACCOUNT,
} from '../actions/types';

export function selectOrgSettingsTab(tab) {
  return {
    type: SELECT_TRAVELER_SETTINGS,
    tab,
  };
}

export function selectTravelerSettingsTab(tab) {
  return {
    type: SELECT_TRAVELER_SETTINGS_TAB,
    tab,
  };
}

export function fetchTravelerHeader(id) {
  return function(dispatch) {
    fetch(`${keys.baseUri}/api/traveler/users/${id}/traveler_header`, {
      headers: token,
    })
      .then(res => res.json())
      .then(data =>
        dispatch({
          type: FETCH_TRAVELER_SETTINGS_HEADER,
          payload: data,
        }),
      )
      .catch(error => console.error(error));
  };
}

export function updateTravelerAccount(id, data) {
  const formPayLoad = new FormData();
  formPayLoad.append('profile[first_name]', data.profile.first_name);
  formPayLoad.append('profile[last_name]', data.profile.last_name);
  formPayLoad.append('profile[avatar]', data.profile.avatar);

  let cloneToken = _clone(token);
  delete cloneToken['Accept'];
  delete cloneToken['Content-Type'];

  return function(dispatch) {
    fetch(
      `${keys.baseUri}/api/traveler/users/${id}/update_user_info`,
      {
        method: 'PUT',
        body: formPayLoad,
        headers: cloneToken,
      },
    )
      .then(res => res.json())
      .then(data =>
        dispatch({
          type: UPDATE_TRAVELER_ACCOUNT,
          payload: data,
        }),
      );
  };
}
