import styled from 'styled-components';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import CheckboxInput from '../../base/inputs/CheckboxInput';
import { CheckboxGrid } from '../../base/inputs/InputGrids.styled';
import ScreenReaderLabel from '../../base/inputs/ScreenReaderLabel.styled';
import NoBorderTextInput from '../../base/inputs/NoBorderTextInput.styled';
import TravelerFormQuestionContainer from '../../base/TravelerFormQuestionContainer';

import { breakpoints } from '../../../utils/breakpoints';
import userId from '../../../../../shared/helpers/userId';
import { fetchProgramTypes } from '../../../../../actions/lookupActions';
import { addProgramMatchProgramType } from '../../../actions/programMatchFormActions';
import ScreenReaderLegend from '../../../../travelerProfile/components/base/ScreenReaderLegend.styled';

const CheckboxGridColumn = styled.div`
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  grid-column: span 2 / span 2;
  gap: 0.5rem;

  div,
  input[type='text'] {
    grid-column: span 1 / span 1;
  }

  @media ${breakpoints.tablet} {
    grid-column: span 1 / span 1;

    ${props =>
      `
        grid-template-rows: repeat(${props.rowNumber}, minmax(0, 1fr));
      `};
  }
`;

export default function ProgramMatchType() {
  const dispatch = useDispatch();
  const programMatch = useSelector(state => state.programMatchForm);
  const programTypes = useSelector(state => state.lookup.allProgramTypes.programTypes);
  const programAlias = useSelector(state => state.auth.organization.info.alias_program);

  const [value, setValue] = useState([]);
  const [error, setError] = useState('');
  const [types, setTypes] = useState([]);
  const [otherOption, setOtherOption] = useState('');
  const [otherOptionId, setOtherOptionId] = useState('');

  const question = {
    overline: `${programAlias} Type`,
    heading: `What type(s) of ${programAlias.toLowerCase()}s interest you?`,
    subheading: `Select one or more ${programAlias.toLowerCase()} types that interest you.`,
    isRequired: true,
  };

  useEffect(() => {
    dispatch(fetchProgramTypes());
  }, []);

  useEffect(
    () => {
      if (programTypes) {
        const programTypesMapped = programTypes.map(programType => ({
          value: Number(programType.id),
          label: programType.attributes.name,
        }));

        const otherOptionIndex = programTypesMapped.findIndex(type => type.label.toLowerCase() === 'other');

        if (otherOptionIndex > -1) {
          const otherOption = programTypesMapped.splice(otherOptionIndex, 1)[0];

          programTypesMapped.push(otherOption);

          setOtherOptionId(otherOption.value);
        }

        setTypes(programTypesMapped);

        setValue(programMatch.programTypes);

        setOtherOption(programMatch.otherProgramTypeText || '');
      }
    },
    [programMatch.programTypes, programTypes],
  );

  const handleOnChange = type => {
    if (value.includes(type)) {
      setValue(value.filter(element => element !== type));

      if (type === otherOptionId) {
        setOtherOption('');
      }
    } else {
      setValue([...value, type]);
    }
  };

  const handleNext = () => {
    setError("");
    if (value.length === 0) {
      setTimeout(()=>{
        setError('Please select an option.');
      },0)
      throw new Error();
    }

    if (value.includes(otherOptionId) && !otherOption) {
      setTimeout(()=>{
        setError('Please enter text for other option or uncheck "Other".');
      },0)
      throw new Error();
    }

    dispatch(
      addProgramMatchProgramType(userId, {
        types: value,
        otherText: otherOption,
      }),
    );
  };

  return (
    <TravelerFormQuestionContainer question={question} handleNext={handleNext} error={error}>
      <CheckboxGrid>
      <fieldset>
      <ScreenReaderLegend>Select one or more programs types that interest you. Required</ScreenReaderLegend>
        <CheckboxGridColumn rowNumber={value.includes(otherOptionId) ? types.length / 2 + 1 : types.length / 2}>
          {types.slice(0, types.length / 2).map(type => (
            <CheckboxInput
              key={`program-type-${type.value}`}
              id={`program_type_${type.value}`}
              value={type.value}
              label={type.label}
              checked={value.includes(type.value)}
              handleOnChange={() => {
                handleOnChange(type.value);
              }}
            />
          ))}
        </CheckboxGridColumn>
        </fieldset>
        <fieldset>
      <ScreenReaderLegend>Select one or more programs types that interest you. Required</ScreenReaderLegend>
        <CheckboxGridColumn rowNumber={value.includes(otherOptionId) ? types.length / 2 + 1 : types.length / 2}>
          {types.slice(types.length / 2).map(type => (
            <CheckboxInput
              key={`program-type-${type.value}`}
              id={`program_type_${type.value}`}
              value={type.value}
              label={type.label}
              checked={value.includes(type.value)}
              handleOnChange={() => {
                handleOnChange(type.value);
              }}
            />
          ))}
          {value.includes(otherOptionId) && (
            <>
              <ScreenReaderLabel htmlFor="other_option_text">Other Option</ScreenReaderLabel>
              <NoBorderTextInput
                id="other_option_text"
                type="text"
                placeholder="Enter other option"
                value={otherOption}
                onChange={event => {
                  setOtherOption(event.target.value);
                }}
              />
            </>
          )}
        </CheckboxGridColumn>
        </fieldset>
      </CheckboxGrid>
    </TravelerFormQuestionContainer>
  );
}
