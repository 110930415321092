import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import * as yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { NavLink } from 'react-router-dom';
import GetLogo from '../../components/GetLogo';
import TextInput from '../../../../styledComponents/styles/TextInput.styled';
import { AccentButtonMediumOpenSans } from '../../../../styledComponents/styles/Buttons.styled';
import { getLoginPath, setAuthCookie } from '../../../../shared/helpers/sessionManagement';
import { loginAdmin, resetAuthState } from '../../../../actions/authActions';
import _get from 'lodash/get';
import ScreenReaderHeadingComponent from '../../../travelerProfile/components/heading/ScreenReaderHeadingComponent';


const Action = styled.div`
  color: #373737;
  font-size: 14px;
  font-weight: 400;
  line-height: 32px;
  text-align: center;

  a {
    color: #373737;
    font-weight: 600;
    text-decoration: underline;
  }
`;

const Header = styled.span`
  color: #0b3544;
  font-size: 28px;
  font-weight: 600;
  line-height: 74px;
`;

const Instructions = styled.div`
  color: #373737;
  font-size: 14px;
  font-weight: 400;
  line-height: 140%;
  text-align: center;

  span {
    font-weight: 600;
  }
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 20px 0 23px 0;
  width: 100%;
`;

const InputContainer = styled.div`
  flex: 1;
  margin: 13px 0;
  text-align: left;
`;

const SubmitContainer = styled.div`
  flex: 1;
  text-align: right;
`;

export default function WelcomeEmailForm() {
  const dispatch = useDispatch();
  const [lockField, setLockField] = useState(false);
  const [emailParam, setEmailParam] = useState('');
  const [SSOHost, setSSOHost] = useState(true);

  const auth = useSelector(state => state.auth);

  const schema = yup.object().shape({
    email: yup.string().email('Invalid email address format').required('Email must be entered'),
    password: yup.string().required('Password must be entered'),
  });
  const { control, handleSubmit, formState, setValue, setError } = useForm({
    shouldUnregister: false,
    resolver: yupResolver(schema),
    defaultValues: {
      email: '',
      password: '',
    },
    mode: 'onChange',
  });

  const onSubmitHandler = data => {
    dispatch(loginAdmin(data));
  };

  useEffect(() => {
    dispatch(resetAuthState());
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const email = urlParams?.get('email')?.trim() || '';
    const emailParam = email?.length > 0 ? `?email=${encodeURIComponent(email)}` : '';
    if (email?.length > 0) {
      setLockField(true);
    }
    const host = window.location.href.toLowerCase();
    setSSOHost(host.search('non_sso_sign_in') > -1);

    setEmailParam(emailParam);
    setValue('email', email, {});
  }, []);

  useEffect(() => {
      setError('password', {
        type: 'manual',
        message: '',
      });

      if (!auth?.isLoading) {
        if (auth?.user_id) {
          const isCookieSet = setAuthCookie({
            user_id: auth.user_id,
            email: auth.email,
            token: auth.token,
            client_id: auth.client_id,
            subdomain: auth.subdomain
          });

          if (isCookieSet) {

            const accountPath = getLoginPath({
              admin_sign_in: auth.admin_sign_in,
              feature_legacy: auth.clientFeatureList.legacy,
              feature_vt: auth.clientFeatureList.travel_plans,
              freemium: auth.organization.status.toLowerCase() === 'free',
              lead: auth.organization.status.toLowerCase() === 'lead',
              redirect_jmp: '',
              tap_agreement: auth.tap_agreement,
              check_agreement: true
            });

            window.location = accountPath;
          } else {
            setError('password', {
              type: 'manual',
              message: 'Invalid credentials, try again',
            });
          }
        } else if (!auth?.user?.is_success) {
          setError('password', {
            type: 'manual',
            message: 'Invalid credentials, try again',
          });
        }
      }
    },
    [auth],
  );

  return (
    <>
      <GetLogo />
      <ScreenReaderHeadingComponent text={'Welcome!'} />
      <Header>Welcome!</Header>
      <Instructions>Sign in to your account</Instructions>
      <FormContainer>
        <form id={'sign-in'} onSubmit={handleSubmit(onSubmitHandler)}>
          <Controller
            control={control}
            name={'email'}
            render={({ field: { ref, ...rest } }) => (
              <InputContainer>
                <TextInput
                  errorMsg={_get(formState?.errors?.email, 'message') || ''}
                  id={'email'}
                  openSans
                  placeholder={'Enter your email'}
                  required
                  disabled={lockField}
                  icon={lockField ? 'LockClosed' : ''}
                  label={'Enter your email'}
                  {...rest}
                />
              </InputContainer>
            )}
          />
          <Controller
            control={control}
            name={'password'}
            render={({ field: { ref, ...rest } }) => (
              <InputContainer>
                <TextInput
                  errorMsg={_get(formState?.errors?.password, 'message') || ''}
                  id={'password'}
                  openSans
                  placeholder={'Enter your password'}
                  label={'Enter your password'}
                  required
                  sensitive
                  {...rest}
                />
              </InputContainer>
            )}
          />
          <SubmitContainer>
            <AccentButtonMediumOpenSans
              aria-label="Sign In"
              form="sign-in"
              type={'submit'}
            >
              Sign In
            </AccentButtonMediumOpenSans>
          </SubmitContainer>
        </form>
      </FormContainer>
      <Action>
        <NavLink to={`/forgot-password${emailParam}`}>Forgot password</NavLink><br />
        {!SSOHost && (
          <>
            Not you? <NavLink to="/">Switch account</NavLink>
          </>
        )}
      </Action>
    </>
  );
}
