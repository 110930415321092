import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { breakpoints } from '../../utils/breakpoints';

import { InfoRounded } from '@material-ui/icons';

const HeroSection = styled.section`
  display: flex;
  justify-content: center;
  height: 20.875rem;
  position: relative;

  @media ${breakpoints.tablet} {
    height: 23.6875rem;
  }

  .bg {
    height: 100%;
    width: 100%;
    position: absolute;
    inset: 0;
    background: rgba(11, 10, 10, 0.4);
    z-index: 10;
  }

  .image {
    height: 100%;
    width: 100%;
    object-fit: cover;
    inset: 0;
    position: absolute;
    z-index: 5;
  }

  .info-icon {
    color: #ddaf65;
    font-size: 40px;
    margin-right: 15px;
    margin-top: 3px;
  }

  .title {
    color: #fff;
    font-family: 'Nunito', sans-serif;
    align-self: center;
    line-height: 120%;
    font-size: 1.5625rem;
    font-weight: 400;
    text-shadow: 0 0.25rem 0.25rem rgba(0, 0, 0, 0.25);
    z-index: 15;
    padding: 1.125rem 2.125rem;
    //background-color: rgba(55, 55, 55, 0.8);
    background-color: #373737;
    border-radius: 5px;

    @media ${breakpoints.tablet} {
      font-size: 3.125rem;
    }
  }
`;

const UnauthorizedContent = styled.div`
  background-color: #ffefd5;
  border-left: 13px solid #ddaf65;
  border-radius: 6px;
  padding: 5px;
  z-index: 16;
  position: absolute;
  bottom: 10px;
  margin: 0 10px;
  display: flex;
  align-items: center;
  @media ${breakpoints.tablet} {
    padding: 20px 20px 20px 15px;
  }
`;
const UnauthorizedTextDiv = styled.div``;
const UnauthorizedHeadText = styled.div`
  font-family: Open Sans;
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 0.005em;
  text-align: left;
`;
const UnauthorizedContentText = styled.div`
  font-family: Open Sans;
  font-size: 15px;
  letter-spacing: 0.005em;
  text-align: left;
`;
const LinkToMessage = styled.a`
  font-family: 'Open Sans', sans-serif;
  color: #4f879d;

  &:hover {
    color: #4f879d;
  }
`;

export default function Hero() {
  const {
    meta: { title, id, is_authorizable },
    images: { background: backgroundImage },
  } = useSelector(state => state.programBrochure);

  const isAdminSignin = useSelector(state => state.profile.admin_sign_in);
  const freemium = useSelector(state => state.profile.freemium);
  const lead = useSelector(state => state.profile.lead);
  const programAlias = useSelector(state => state.profile.customAliases.alias_program || 'program');

  return (
    <HeroSection>
      <div className="bg" aria-hidden="true" />
      <img src={backgroundImage} alt="Program Banner Image" className="image" />
      <span tabIndex={0} className="title">{title}</span>
      {isAdminSignin &&
        (freemium || lead) &&
        (is_authorizable !== undefined && !is_authorizable) && (
          <UnauthorizedContent>
            <InfoRounded className="info-icon" />
            <UnauthorizedTextDiv>
              <UnauthorizedHeadText>
                Your {programAlias.toLowerCase()} is not set as Authorizable, partner organizations will not be able to
                find this {programAlias.toLowerCase()}.
              </UnauthorizedHeadText>
              <UnauthorizedContentText>
                Allow authorization by other institutions from{' '}
                <LinkToMessage href={`/client/programs/${id}/program_settings`}>{programAlias} Settings</LinkToMessage>
              </UnauthorizedContentText>
            </UnauthorizedTextDiv>
          </UnauthorizedContent>
        )}
    </HeroSection>
  );
}
