import IntakeLastName from '../components/intakeForm/steps/IntakeLastName';
import IntakePassport from '../components/intakeForm/steps/IntakePassport';
import IntakeFirstName from '../components/intakeForm/steps/IntakeFirstName';
import IntakeTravelGoals from '../components/intakeForm/steps/IntakeTravelGoals';
import IntakeFinancialAid from '../components/intakeForm/steps/IntakeFinancialAid';
import IntakeTravelerType from '../components/intakeForm/steps/IntakeTravelerType';
import IntakeFinancialInfo from '../components/intakeForm/steps/IntakeFinancialInfo';
import IntakeHomeInstitution from '../components/intakeForm/steps/IntakeHomeInstitution';
import IntakePreviousExperience from '../components/intakeForm/steps/IntakePreviousExperience';
import IntakeQuestionsAndConcerns from '../components/intakeForm/steps/IntakeQuestionsAndConcerns';
import IntakeCountriesOfCitizenship from '../components/intakeForm/steps/IntakeCountriesOfCitizenship';
import IntakeParticipationCertainty from '../components/intakeForm/steps/IntakeParticipationCertainty';

import ProgramMatchTerm from '../components/programMatchForm/steps/ProgramMatchTerm';
import ProgramMatchType from '../components/programMatchForm/steps/ProgramMatchType';
import ProgramMatchLength from '../components/programMatchForm/steps/ProgramMatchLength';
import ProgramMatchCountry from '../components/programMatchForm/steps/ProgramMatchCountry';
import ProgramMatchHousing from '../components/programMatchForm/steps/ProgramMatchHousing';
import ProgramMatchPriorities from '../components/programMatchForm/steps/ProgramMatchPriorities';
import ProgramMatchSubjectAreas from '../components/programMatchForm/steps/ProgramMatchSubjectAreas';
import ProgramMatchLanguageImmersion from '../components/programMatchForm/steps/ProgramMatchLanguageImmersion';

export const intakeStepsComponentMap = {
  1: IntakeFirstName,
  2: IntakeLastName,
  3: IntakeTravelerType,
  4: IntakeHomeInstitution,
  5: IntakeTravelGoals,
  6: IntakePreviousExperience,
  7: IntakePassport,
  8: IntakeCountriesOfCitizenship,
  9: IntakeQuestionsAndConcerns,
  10: IntakeFinancialInfo,
  11: IntakeFinancialAid,
  12: IntakeParticipationCertainty,
};

export const intakeSteps = {
  1: "First Name",
  2: "Last Name",
  3: "Traveler Type",
  4: "Home Institution",
  5: "Travel Goals",
  6: "Previous Experience",
  7: "Passport",
  8: "Countries Of Citizenship",
  9: "Questions And Concerns",
  10: "Financial Info",
  11: "Financial Aid",
  12: "Participation Certainty",
};


export const programMatchStepsComponentMap = {
  1: ProgramMatchSubjectAreas,
  2: ProgramMatchCountry,
  3: ProgramMatchHousing,
  4: ProgramMatchLength,
  5: ProgramMatchTerm,
  6: ProgramMatchLanguageImmersion,
  7: ProgramMatchType,
  8: ProgramMatchPriorities,
};

export const programMatchSteps = {
  1: "Program Match Subject Areas",
  2: "Program Match Country",
  3: "Program Match Housing",
  4: "Program Match Length",
  5: "Program Match Term",
  6: "Program Match Language Immersion",
  7: "Program Match Type",
  8: "Program Match Priorities",
};