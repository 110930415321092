import fetch from 'node-fetch';
import token from '../../../../shared/helpers/token';
import { keys } from '../../../../config/keys';

async function fetchCountryList() {
  return await fetch(`${keys.baseUri}/api/via_countries`, {
    method: 'GET',
    headers: token,
  })
    .then(res => res.json())
    .then(data => {
      let dataList = data?.data?.map(c => c?.attributes?.name) || [];
      return dataList;
    });
}

async function fetchProgramTypeList() {
  return await fetch(`${keys.baseUri}/api/program_types`, {
    method: 'GET',
    headers: token,
  })
    .then(res => res.json())
    .then(data => {
      let dataList = data?.data?.map(c => c?.attributes?.name) || [];
      return dataList;
    });
}

async function fetchApplicationStatusList() {
  return await fetch(`${keys.baseUri}/api/application_status_options`, {
    method: 'GET',
    headers: token,
  })
    .then(res => res.json())
    .then(data => {
      return data || [];
    });
}

async function fetchApplicationTemplateList() {
  return await fetch(`${keys.baseUri}/api/form_templates/thin?template_type=Application`, {
    method: 'GET',
    headers: token,
  })
    .then(res => res.json())
    .then(data => {
      let dataList = data?.data?.map(c => c?.attributes?.name).sort() || [];
      return dataList;
    });
}

async function fetchTrackingStepList() {
  return await fetch(`${keys.baseUri}/api/tracking_step_options`, {
    method: 'GET',
    headers: token,
  })
    .then(res => res.json())
    .then(data => {
      return data || [];
    });
}

async function fetchTermTagsList() {
  return await fetch(`${keys.baseUri}/api/client/tags`, {
    method: 'GET',
    headers: token,
  })
    .then(res => res.json())
    .then(data => {
      let dataList = data?.map(c => c?.name).sort() || [];
      return dataList;
    });
}

async function fetchTermNameList() {
  return await fetch(`${keys.baseUri}/api/term_names`, {
    method: 'GET',
    headers: token,
  })
    .then(res => res.json())
    .then(data => {
      let dataList = data?.data?.map(c => c?.attributes?.name).sort() || [];
      return dataList;
    });
}

export async function getFilterCountries() {
  const filterOptions = await fetchCountryList();
  return filterOptions;
}

export async function getFilterProgramTypes() {
  const filterOptions = await fetchProgramTypeList();
  return filterOptions;
}

export async function getFilterApplicationStatusOptions() {
  const filterOptions = await fetchApplicationStatusList();
  return filterOptions;
}

export async function getApplicationTemplateOptions() {
  const filterOptions = await fetchApplicationTemplateList();
  return filterOptions;
}

export async function getFilterTrackingStepOptions() {
  const filterOptions = await fetchTrackingStepList();
  return filterOptions;
}

export async function getFilterTermTimingOptions() {
  const filterOptions = ['Past', 'Upcoming', 'On-site'];
  return filterOptions;
}

export async function getFilterTermTagOptions() {
  const filterOptions = await fetchTermTagsList();
  return ['(none)', ...filterOptions];
}

export async function getFilterTermNameOptions() {
  const filterOptions = await fetchTermNameList();
  return filterOptions;
}
