import React, { useEffect, useState } from 'react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import styles from './index.module.css';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import CreatePlan from './components/CreatePlan';
import ConfigurePlanDetails from './components/ConfigurePlanDetails';
import Confirm from './components/Confirm';
import Stepper from './components/Stepper';
import { useDispatch, useSelector } from 'react-redux';
import closeIcon from './closeIcon.png';
import { useForm } from 'react-hook-form';
import { fetchPlanStatusTypes, fetchPlanTypes, fetchRegistrationStatusTypes } from '../../actions/lookupActions';
import { createTravelPlanFromTerm } from '../../actions/plans';

function CreateTravelPlanModal({ show, handleClose, terms = [], headerTitle="" }) {
  const dispatch = useDispatch();
  const [locationWarning, setLocationWarning] = useState('');
  const [disableBtn, setDisableBtn] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const { createPlanTerm } = useSelector(state => state.plans);
  const customAliases = useSelector(state => state.profile.customAliases); 
  const { handleSubmit, control } = useForm({
    values: {
      planTypeId: '',
      planStatusId: '',
      registrationStatusId: '',
      planAvailability: '',
    },
  });

  useEffect(() => {
    dispatch(fetchPlanTypes());
    dispatch(fetchPlanStatusTypes());
    dispatch(fetchRegistrationStatusTypes());
  }, []);

  useEffect(() => {
    checkTermsLocation(terms);
  }, [terms]);

  const checkTermsLocation = terms => {
    setLocationWarning('');
    setDisableBtn(false);
    let missingTermsCount = terms.filter(i => i.location === null || i.location === '' || i.location === 'Unspecified Location')?.length;

    if (missingTermsCount === 0) {
      return;
    }

    if (missingTermsCount === terms.length) {
      setDisableBtn(true);
      setLocationWarning(`Term(s) selected have missing ${customAliases?.alias_program} location(s).`);
    }

    if (missingTermsCount < terms?.length) {
      setLocationWarning(`One or more term(s) selected have missing ${customAliases?.alias_program} location(s).`);
    }
  };

  const submitHandler = data => {
    let payload = {
      term_ids: terms.map(i => i.id),
      plan_status_id: data.planStatusId.value,
      plan_type_id: data.planTypeId.value,
      plan_registration_status_id: data.registrationStatusId.value,
      plan_availability: data.planAvailability.value,
    };
    dispatch(
      createTravelPlanFromTerm(payload, (status, message) => {
        if (status) {
          setCurrentStep(2);
        }
      }),
    );
  };

  const goNext = () => {
    if (currentStep === 0) {
      setCurrentStep(1);
    }

    if (currentStep === 1) {
      handleSubmit(submitHandler)();
    }

    if (currentStep === 2) {
      handleClose();
    }
  };

  const goBack = () => {
    if (currentStep === 1) {
      setCurrentStep(0);
    }
  };

  return (
    <Modal size="lg" aria-labelledby="contained-modal-title-vcenter" centered show={show} onHide={handleClose}>
      <Modal.Header>
        <div className={styles.title}>
          <h1 className={styles.headingtext}>{headerTitle ? headerTitle : `Create Travel Plan`}</h1>
          <a
            href="#"
            role="button"
            onClick={e => {
              e.preventDefault();
              handleClose();
            }}
          >
            <img src={closeIcon} alt="Close" />
          </a>
        </div>
      </Modal.Header>

      <Modal.Body className={styles.customBody}>
        <Stepper currentStep={currentStep} />

        {currentStep === 0 && <CreatePlan locationWarning={locationWarning} planCount={terms.length} />}

        {currentStep === 1 && (
          <form>
            <ConfigurePlanDetails control={control} />
          </form>
        )}

        {currentStep === 2 && <Confirm />}
      </Modal.Body>
      <Modal.Footer className={styles.btnDiv}>
        {currentStep === 1 && (
          <button className={styles.cancelBtn} onClick={goBack}>
            Back
          </button>
        )}

        {currentStep === 0 && <button className={styles.cancelBtn}>Cancel</button>}

        {currentStep === 0 && (
          <Button
            onClick={goNext}
            className={styles.nextBtn}
            type="button"
            size="lg"
            disabled={disableBtn || createPlanTerm?.loading}
          >
            Next
          </Button>
        )}

        {currentStep === 1 && (
          <Button
            onClick={goNext}
            className={styles.nextBtn}
            type="button"
            size="lg"
            disabled={disableBtn || createPlanTerm?.loading}
          >
            Create
          </Button>
        )}

        {currentStep === 2 && (
          <Button
            onClick={goNext}
            className={styles.nextBtn}
            type="button"
            size="lg"
            disabled={disableBtn || createPlanTerm?.loading}
          >
            Close
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
}

export default withStyles(styles)(CreateTravelPlanModal);
