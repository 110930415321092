import React from 'react';
import { useSelector } from 'react-redux';
import { orderBy } from 'lodash';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import CardTerms from './cards/CardTerms';
import CardTypes from './cards/CardTypes';
import CardBanner from './cards/CardBanner';
import CardLocations from './cards/CardLocations';
import FilledHeartIcon from '../icons/FilledHeartIcon';
import CardTrackingStep from './cards/CardTrackingStep';
import ScreenReaderText from '../base/ScreenReaderText.styled';
import CardApplicationInfoItem from './cards/CardApplicationInfoItem';

import programType from '../../types/program';
import { breakpoints } from '../../utils/breakpoints';
import { buttonFocus } from '../../utils/shared/focusStyles';
import programApplication from '../../types/programApplication';
import getFormStatus from '../../utils/my-programs/getFormStatus';
import { getProgramTermsFromArray, getProgramTermsFromRange } from '../../utils/getProgramTerms';

const AdminCard = styled.div`
  grid-column: span 6 / span 6;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 350px;
  background-color: #ffffff;
  box-shadow: 0px 10px 20px rgba(132, 131, 131, 0.3);
  border-radius: 20px;

  @media ${breakpoints.desktop} {
    grid-column: span 2 / span 2;
  }

  @media (min-width: 1024px) and (max-width: 1399px) {
    grid-column: span 3 / span 3;
  }
`;

const Card = styled.div`
  margin: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 440px;
  width: 320px;
  background-color: #ffffff;
  box-shadow: 0px 10px 20px rgba(132, 131, 131, 0.3);
  border-radius: 20px;

  @media (min-width: 320px) {
    width: 265px;
    margin: 8px;
  }
  @media ${breakpoints.mobile} {
    //width: 288px;
    margin: 10px;
  }
  @media ${breakpoints.tablet} {
    width: 320px;
    margin: 10px;
  }
`;

const CardBody = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1.4375rem 1.75rem 1.5rem 1.75rem;
`;

const CardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 1.125rem;
  flex-wrap: wrap;
`;

const CardImage = styled.div`
  width: 65px;
  min-width: 65px;
  height: 65px;
  background-image: ${props => `url(${props.image})`};
  background-position: center;
  background-size: cover;
  border-radius: 50px;
`;

const CardTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-bottom: 1.125rem;
  padding-bottom: ${({ isFavorite }) => (isFavorite ? '1.125rem' : '0.625rem')};
  border-bottom: 1px solid #cccccc;
`;

const CardTitle = styled.div`
  margin: 0;
  font-family: 'Nunito', sans-serif;
  font-weight: 600;
  font-size: 1rem;
  color: #373737;
  letter-spacing: 0.0015em;
  line-height: 120%;
`;

const CardDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
  // max-height: 100px;
  height: 125px;
  overflow-y: auto;
`;

const CardFooter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 1.625rem 0;
  background-color: #e7e7e7;
  border-radius: 0px 0px 20px 20px;

  a {
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 120%;
    color: #446372;
    letter-spacing: 0.0015em;
    text-decoration-line: underline;

    &:hover {
      text-decoration-line: none;
    }

    ${buttonFocus};
  }

  ${({ isFavorite, isAccepted }) => {
    if (isFavorite) {
      return `
        background-color: var(--theme_color_accent);

        a {
          color: var(--theme_color_accent_font);
        }
      `;
    }

    if (isAccepted) {
      return `
        background-color: #DAFDD7;

        a {
          color: #0E5B2F;
        }
      `;
    }
  }};
`;

export default function ProgramCard({ program, programsType, isAdmin,id,ref }) {
  const isFavorite = isAdmin ? programsType === 'favorites' : program.program_favorite;
  const isAccepted = program.submission_status?.name === 'Accepted';
  const programName = isFavorite
    ? isAdmin
      ? program.title
      : program.program_name
    : program.program_range.program_title;
  const programImage = isFavorite
    ? isAdmin
      ? program.background_photo
      : program.background_large
    : program.program_range.program_image.url;
  const customAliases = useSelector(state => state.profile.customAliases);
  const orgTimeZone = useSelector(state => state.profile.orgTimezone);

  const buildUrl = isFavorite => {
    let path = '';

    if (isFavorite) {
      path += `${isAdmin ? '/client' : ''}/program_brochure/${isAdmin ? program.id : program.program_id}`;
    } else {
      if (isAdmin) {
        const pathname = window.location.pathname.split('/');
        const travelerId = pathname[3];
        path = `/client/travelers/${travelerId}/view-programs/details/${
          program.program_id
        }?viewDetails=true&tab=programs`;
      } else {
        path = `/traveler/my-programs/details/${program.program_id}`;
      }
      // path += `/my-programs/details/${program.program_id}`;
    }

    return path;
  };

  let viewLink = buildUrl(isFavorite);

  const programTerms = isFavorite
    ? getProgramTermsFromArray(isAdmin ? program.terms : program.program_terms).join('; ')
    : getProgramTermsFromRange(program.program_range);

  const getBannerType = () => {
    if (isAccepted) {
      return 'accepted';
    }

    if (
      program.submission_status.name === 'Incomplete' &&
      moment(program.deadline)
        .tz(orgTimeZone)
        .isBefore(
          moment()
            .tz(orgTimeZone)
            .add(14, 'days'),
        )
    ) {
      return 'Application';
    }

    if (program.corresponding_forms.length > 0) {
      return 'Form';
    }

    return '';
  };

  const getBannerDate = () => {
    const type = getBannerType();

    if (type === 'Form') {
      const formDeadlines = orderBy(program.corresponding_forms, ['deadline'], ['asc']).filter(formDeadline =>
        moment(formDeadline.deadline)
          .tz(orgTimeZone)
          .isAfter(moment().tz(orgTimeZone)),
      );

      return formDeadlines.length > 0 ? formDeadlines[0].deadline : '';
    } else {
      let deadline = program.deadline ? moment(program.deadline).tz(orgTimeZone) : null;
      return deadline;
    }
  };

  if (isAdmin) {
    return (
      <AdminCard>
        {programsType === 'upcoming' && (
          <CardBanner
            date={getBannerDate()}
            programType={getBannerType()}
            status={program.submission_status.name}
            isAdmin={isAdmin}
          />
        )}
        <CardBody>
          <CardHeader>
            <CardImage image={programImage} />
            {isFavorite ? (
              <FilledHeartIcon fillColor="#373737" />
            ) : (
              <CardTrackingStep trackingStep={program.submission_tracking_step.name} />
            )}
          </CardHeader>
          <CardTitleContainer isFavorite={isFavorite}>
            <CardTitle>{programName}</CardTitle>
            {!isFavorite && <CardTerms terms={programTerms} />}
          </CardTitleContainer>
          <CardDetailsContainer tabIndex={0}>
            {isFavorite ? (
              <>
                <CardLocations locations={program.locations} />
                {programTerms.length > 0 && <CardTerms terms={programTerms} />}
                {program?.types && <CardTypes types={program.types} />}
              </>
            ) : (
              <>
                <CardApplicationInfoItem count={1} type="Application" status={program.submission_status.name} />
                {program.corresponding_forms.length > 0 && (
                  <CardApplicationInfoItem
                    count={program.corresponding_forms.length}
                    type={program.corresponding_forms.length === 1 ? 'Form' : 'Forms'}
                    status={getFormStatus(program.corresponding_forms)}
                  />
                )}
              </>
            )}
          </CardDetailsContainer>
        </CardBody>
        <CardFooter isFavorite={isFavorite} isAccepted={isAccepted} status={program.submission_status?.name}>
          <Link to={viewLink}>
            {isFavorite ? `View ${customAliases.alias_program} ` : ` View Details `}
            <ScreenReaderText>
              {` for ${programName} `}
            </ScreenReaderText>
          </Link>
        </CardFooter>
      </AdminCard>
    );
  }
  return (
    <Card tabIndex={0} id={id} ref={ref}>
      {programsType === 'upcoming' && (
        <CardBanner
          date={getBannerDate()}
          programType={getBannerType()}
          status={program.submission_status.name}
          isAdmin={isAdmin}
        />
      )}
      <CardBody>
        <CardHeader>
          <CardImage image={programImage} />
          {isFavorite ? (
            <FilledHeartIcon fillColor="#373737" />
          ) : (
            <CardTrackingStep trackingStep={program.submission_tracking_step.name} />
          )}
        </CardHeader>
        <CardTitleContainer isFavorite={isFavorite}>
          <CardTitle>{programName}</CardTitle>
          {!isFavorite && <CardTerms terms={programTerms} />}
        </CardTitleContainer>
        <CardDetailsContainer>
          {isFavorite ? (
            <>
              <CardLocations locations={program.locations} />
              {programTerms.length > 0 && <CardTerms terms={programTerms} />}
              {program?.types && <CardTypes types={program.types} />}
            </>
          ) : (
            <>
              <CardApplicationInfoItem count={1} type="Application" status={program.submission_status.name} />
              {program.corresponding_forms.length > 0 && (
                <CardApplicationInfoItem
                  count={program.corresponding_forms.length}
                  type={program.corresponding_forms.length === 1 ? 'Form' : 'Forms'}
                  status={getFormStatus(program.corresponding_forms)}
                />
              )}
            </>
          )}
        </CardDetailsContainer>
      </CardBody>
      <CardFooter isFavorite={isFavorite} isAccepted={isAccepted} status={program.submission_status?.name}>
        <Link to={viewLink}>
          {isFavorite ? `View ${customAliases.alias_program}` : 'View Details'}
          <ScreenReaderText> for {programName}</ScreenReaderText>
        </Link>
      </CardFooter>
    </Card>
  );
}

ProgramCard.propTypes = {
  program: PropTypes.oneOfType([programType, programApplication]).isRequired,
  programsType: PropTypes.string.isRequired,
  isAdmin: PropTypes.bool,
};

ProgramCard.defaultProps = {
  isAdmin: false,
};
