import React from 'react';
import { getFilterCountries } from '../customFilters/AgGridViewHelpers';

const filterParamsCountries = {
  values: params => {
    getFilterCountries().then(data => {
      setTimeout(() => {
        data.unshift('(Blanks)');
        params.success(data);
      }, 1);
    });
  },
};

const filterPlanStatus = {
  suppressSorting: true,
  values: params => {
    params.success(['(Blanks)', 'Cancelled', 'Closed', 'Complete', 'Open']);
  },
};

const filterRegistrationStatus = {
  suppressSorting: true,
  values: params => {
    params.success(['(Blanks)', 'Cancelled', 'Closed', 'Complete', 'Open']);
  },
};

const filterAvailability = {
  suppressSorting: true,
  values: params => {
    params.success(['Private', 'Public']);
  },
};

const filterTravelType = {
  suppressSorting: true,
  values: params => {
    params.success(['Group', 'Solo']);
  },
};

const filterContinent = {
  suppressSorting: true,
  values: params => {
    params.success(['(Blanks)', 'Africa', 'Antarctica', 'Asia', 'Europe', 'North America', 'Oceania', 'South America']);
  },
};

const filterTiming = {
  suppressSorting: true,
  values: params => {
    params.success(['Upcoming', 'On-site', 'Past']);
  },
};

export {
  filterTiming,
  filterContinent,
  filterTravelType,
  filterAvailability,
  filterRegistrationStatus,
  filterPlanStatus,
  filterParamsCountries,
};
