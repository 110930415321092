import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import propTypes from 'prop-types';
import moment from 'moment-timezone';
import styled from 'styled-components';
import StyledIcon from '../../../../styledComponents/styles/Icon.styled';
import SimpleClockIcon from '../../../travelerProfile/components/icons/SimpleClockIcon';
import CardApplicationStatus from '../../../travelerProfile/components/my-programs/cards/CardApplicationStatus';
import { breakpoints } from '../../../travelerProfile/utils/breakpoints';
import ScreenReaderHeadingComponent from '../../../travelerProfile/components/heading/ScreenReaderHeadingComponent';
const ProgramDiv = styled.div`
  border-bottom: 1px solid #bbbbbb;
  display: flex;
  flex-direction: row;
  margin-bottom: 30px;
  padding-bottom: 30px;

  @media ${breakpoints.mobile} {
    padding-left: 10px;
    padding-right: 10px;
  }
`;

const ImageDiv = styled.div`
  align-self: center;
  flex: 85px 0;

  img {
    border-radius: 50%;
    height: 66px;
    width: 66px;
  }
`;

const Deadline = styled.div`
  display: flex;
  align-items: center;
  gap: 0.543125rem;
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  font-size: 0.875rem;
  color: #373737;
  letter-spacing: 0.0025em;
  line-height: 120%;

  svg {
    width: 16px;
    height: 16px;
    color: var(--theme_color_accent);
  }

  margin-top: 10px; 
`;

const DetailsDiv = styled.div`
  flex: 1;
  flex-direction: row;
`;

const DetailType = styled.div`
  color: #373737;
  flex: 1;
  font-family: 'Nunito', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;

  span {
    ${({ customBranding }) =>
      customBranding ? `border-bottom: 1px ${customBranding} solid;` : ` border-bottom: 1px #516c4f solid;`};
  }
`;

const DetailProgramTitle = styled.div`
  color: #373737;
  flex: 1;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  padding-top: 10px;

  span {
    ${({ customBranding }) =>
      customBranding ? `border-bottom: 1px ${customBranding} solid;` : ` border-bottom: 1px #516c4f solid;`};
  }
`;

const DetailRangeDates = styled.div`
  color: #373737;
  display: inline-block;
  flex: 1;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  padding-top: 10px;
  text-align: left;

  span {
    ${({ customBranding }) =>
      customBranding ? `border-bottom: 1px ${customBranding} solid;` : ` border-bottom: 1px #516c4f solid;`};
  }

  svg {
    align-self: center;
    display: inline-flex;
    margin-bottom: 3px;
    margin-right: 5px;
    position: relative;
  }
`;

const StatusDiv = styled.div`
  flex: 155px 0;
  text-align: end;

  p {
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    margin-bottom: 5px;
  }
`;

export default function SubmissionProgramInfo({ programInfo, isAdmin, submissionType }) {
  const [dateRange, setDateRange] = useState('');
  const [deadline, setDeadline] = useState('');
  const orgTimeZone = useSelector(state => state.profile.orgTimezone);
  const customBranding = useSelector(state => state.profile.customBranding.themes.theme_color_dark);
  const customBrandingAccent = useSelector(state => state.profile.customBranding.themes.theme_color_accent);

  useEffect(() => {
    if (programInfo) {
      let sDate = null;
      let eDate = null;
      let formatDeadline = '';

      if (programInfo?.program_range.exact_dates == true) {
        if (programInfo?.program_range.start_date) {
          sDate = moment(new Date(programInfo?.program_range.start_date)).format('MMM D[,] YYYY');
        }
        if (programInfo?.program_range.end_date) {
          eDate = moment(new Date(programInfo?.program_range.end_date)).format('MMM D[,] YYYY');
        }
      } else {
        if (programInfo?.program_range.start_date) {
          sDate = moment(new Date(programInfo.program_range.start_date)).format('MMM [] YYYY');
        }
        if (programInfo?.program_range.end_date) {
          eDate = moment(new Date(programInfo?.program_range.end_date)).format('MMM [] YYYY');
        }
      }

      if (submissionType === 'General Form' || submissionType === 'Form') {
        formatDeadline = moment(programInfo?.deadline).format(`MMM D, YYYY`);
      } else {
        if (orgTimeZone) {
          formatDeadline = moment(programInfo?.deadline)
            .tz(orgTimeZone)
            .format(`MMM D, YYYY [at] h:mma z`);
        }
      }

      setDeadline(formatDeadline);
      setDateRange(`${sDate}${eDate ? ' - ' + eDate : ''}`);
    }
  }, [programInfo, orgTimeZone]);

  return programInfo ? (
    <ProgramDiv>
      <ImageDiv>
        <img
          src={programInfo?.program_range.program_image.program_medium.url}
          alt={programInfo?.program_range.program_title}
        />
      </ImageDiv>
      <DetailsDiv>
        <ScreenReaderHeadingComponent text={`${programInfo?.submission_type.name === 'Form' || isAdmin ? programInfo?.name : programInfo?.submission_type.name} ${programInfo?.program_range.program_title}`}/>
        <DetailType customBranding={customBranding}>
          <span tabIndex={0}>
            {programInfo?.submission_type.name === 'Form' || isAdmin
              ? programInfo?.name
              : programInfo?.submission_type.name}
          </span>
        </DetailType>
        <DetailProgramTitle customBranding={customBranding} tabIndex={0}>
          {programInfo?.program_range.program_title}
        </DetailProgramTitle>
        <DetailRangeDates customBranding={customBranding}>
          <StyledIcon type="Form_date" color={customBrandingAccent} size="20px" />
          <span tabIndex={0}>
          {programInfo?.program_range.term_name ? `${programInfo?.program_range.term_name}: ` : null}
          {dateRange}
          </span>
        </DetailRangeDates>
        {programInfo?.deadline ? (
          <Deadline>
            <SimpleClockIcon />
            <span tabIndex={0}>
            Deadline: {deadline}
            </span>
          </Deadline>
        ) : null}
      </DetailsDiv>
      <StatusDiv>
        <p>Current Status:</p>
        <CardApplicationStatus status={programInfo?.submission_status.name} />
      </StatusDiv>
    </ProgramDiv>
  ) : null;
}

SubmissionProgramInfo.defaultProps = {
  isAdmin: false,
};

SubmissionProgramInfo.propTypes = {
  programInfo: propTypes.object,
  isAdmin: propTypes.boolean,
  submissionType: propTypes.string,
};
