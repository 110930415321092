import React from 'react';
import styled from 'styled-components';
import NothingCalendarIcon from '../../../../../travelerProfile/components/icons/NothingCalendarIcon';
import LearnMoreModal from '../../../modals/learnMore';
import { toggleLearnMoreModal } from '../../../../../../actions/programBrochure';
import { useDispatch, useSelector } from 'react-redux';

const MainContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Container = styled.div`
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 15px;
  display: flex;
  width: 450px;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 50px;
  @media only screen and (max-width: 575.98px)  {
      width: 100%
  }
`;

const IconContainer = styled.div``;

const Heading = styled.h2`
  color: #666;
  font-family: Nunito;
  font-size: 21px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%; /* 25.2px */
  letter-spacing: 0.032px;
  padding-bottom: 5px;
  border-bottom-style: solid;
  border-bottom-width: 3.1px;
  width: fit-content;
`;

const Message = styled.p`
  color: #373737;
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.2px;
  width: 350px;
  text-align: center;
  margin-bottom: 25px;
  @media only screen and (max-width: 575.98px)  {
    width: 100%
  }
`;

const LearnMoreButton = styled.a`
  border-radius: 5px;
  background: var(--theme_color_accent);
  color: var(--theme_color_accent_font);
  font-family: Open Sans;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  border: none;
  padding: 8px 16px;
  text-decoration: none;

  &:hover {
    opacity: 0.7;
    text-decoration: none;
    color: #fff;
  }
`;

export default function NoDataCard({ isAdmin = false, programId }) {
  const dispatch = useDispatch();
  const { 'is_traveler?': isTraveler } = useSelector(state => state.profile.currentUser);

  return (
    <MainContainer >
      <Container tabIndex={0}>
        <IconContainer>
          <NothingCalendarIcon />
        </IconContainer>
        <Heading>There are no upcoming terms</Heading>
        <Message>Click below to learn more, or check back later for future terms for this program</Message>
        {isAdmin && (
          <LearnMoreButton href={`/client/programs/${programId}/program_settings`}>Learn More</LearnMoreButton>
        )}

        {isTraveler && (
          <LearnMoreButton
            href={`#`}
            onClick={e => {
              e.preventDefault();
              dispatch(toggleLearnMoreModal());
            }}
          >
            Learn More
          </LearnMoreButton>
        )}

        {!isTraveler && !isAdmin && <LearnMoreButton href={`/`}>Learn More</LearnMoreButton>}
      </Container>

      <LearnMoreModal />
    </MainContainer>
  );
}
