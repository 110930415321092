import styled from 'styled-components';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import RadioInput from '../../base/inputs/RadioInput';
import SelectInput from '../../base/inputs/SelectInput';
import CheckboxInput from '../../base/inputs/CheckboxInput';
import CustomTermLength from '../../icons/CustomTermLength';
import { RadioGrid } from '../../base/inputs/InputGrids.styled';
import TravelerFormQuestionContainer from '../../base/TravelerFormQuestionContainer';

import { breakpoints } from '../../../utils/breakpoints';
import userId from '../../../../../shared/helpers/userId';
import { addProgramMatchProgramLength } from '../../../actions/programMatchFormActions';
import { programLengthRadioOptions, customLengthSelectOptions } from '../../../utils/programMatch/programLengthOptions';

const RadioGridColumn = styled.div`
  grid-column: span 2 / span 2;
  display: flex;
  justify-content: center;
  width: 100%;
`;

const Fieldset = styled.fieldset`
  grid-column: span 2 / span 2;

  legend {
    display: none;
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
    font-size: 0.875rem;
    color: #373737;
    text-align: center;
    line-height: 140%;
  }

  @media ${breakpoints.tablet} {
    margin-top: -3rem;
    margin-right: 3.3rem;
    margin-left: 2.9375rem;

    legend {
      display: block;
    }
  }

  @media ${breakpoints.desktop} {
    margin-right: 8.10125rem;
  }
`;

const TabletSelectContainer = styled.div`
  display: none;

  @media ${breakpoints.tablet} {
    display: flex;
    flex-direction: row;
    gap: 1.625rem;
  }
`;

const MobileSelectContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.625rem;

  @media ${breakpoints.tablet} {
    display: none;
  }
`;

export default function ProgramMatchLength() {
  const dispatch = useDispatch();
  const programMatch = useSelector(state => state.programMatchForm);
  const programAlias = useSelector(state => state.auth.organization.info.alias_program);

  const [value, setValue] = useState('');
  const [error, setError] = useState({
    general: '',
    minWeeks: '',
    maxWeeks: '',
  });
  const [haveNotDecided, setHaveNotDecided] = useState(false);
  const [customLength, setCustomLength] = useState({ minWeeks: 0, maxWeeks: 0 });

  const question = {
    overline: `${programAlias} Length`,
    heading: `Great, and how long would you like your ${programAlias.toLowerCase()} to be?`,
    subheading: 'Select an option or set custom length.',
    isRequired: true,
  };

  useEffect(
    () => {
      let initialValue = '';

      if (programMatch.programLength && programMatch.programLength.minWeeks) {
        const lengthOption = programLengthRadioOptions.find(
          option =>
            option.value.minWeeks === programMatch.programLength.minWeeks &&
            option.value.maxWeeks === programMatch.programLength.maxWeeks,
        );

        initialValue = lengthOption ? lengthOption.value : 'custom';

        if (!lengthOption) {
          const minValue = customLengthSelectOptions.find(
            option => option.value === programMatch.programLength.minWeeks,
          );
          const maxValue = customLengthSelectOptions.find(
            option => option.value === programMatch.programLength.maxWeeks,
          );

          setCustomLength({ minWeeks: minValue, maxWeeks: maxValue });
        }
      }

      setValue(initialValue);
    },
    [programMatch.programLength],
  );

  const handleNext = () => {
    setError("");
    if (!value && !haveNotDecided) {
      setTimeout(()=>{
        setError({ general: 'Select at least one option.' });
      },0)
      throw new Error();
    }

    if (value === 'custom') {
      if (!customLength.minWeeks.value && !customLength.maxWeeks.value) {
        setTimeout(()=>{
          setError({
            minWeeks: 'Select a minimum number of weeks.',
            maxWeeks: 'Select a maximum number of weeks.',
          });
        },0)

        throw new Error();
      } else if (!customLength.minWeeks.value) {
        setTimeout(()=>{
          setError({ minWeeks: 'Select a minimum number of weeks.' });
        },0)
        throw new Error();
      } else if (!customLength.maxWeeks.value) {
        setTimeout(()=>{
          setError({ maxWeeks: 'Select a maximum number of weeks.' });
        },0)
        throw new Error();
      } else if (
        customLength.minWeeks.value &&
        customLength.maxWeeks.value &&
        customLength.maxWeeks.value < customLength.minWeeks.value
      ) {
        setTimeout(()=>{
          setError({ maxWeeks: 'Select a number of weeks greater than the minimum.' });
        },0)
        throw new Error();
      }
    }

    if (!haveNotDecided) {
      const payload =
        value === 'custom' ? { minWeeks: customLength.minWeeks.value, maxWeeks: customLength.maxWeeks.value } : value;

      dispatch(addProgramMatchProgramLength(userId, payload));
    }
  };

  const handleOnChange = (event, forMin) => {
    if (forMin) {
      setCustomLength({ ...customLength, minWeeks: event });
    } else {
      setCustomLength({ ...customLength, maxWeeks: event });
    }
  };

  return (
    <TravelerFormQuestionContainer question={question} handleNext={handleNext} error={error.general} moreErrorMargin>
      <RadioGrid role="radiogroup" aria-labelledby="role-label">
        <label id="role-label" style={{display: "none"}}>Select an option or set custom length. Required</label>
        {programLengthRadioOptions.map(lengthOption => (
          <RadioGridColumn key={`program-length-option-${lengthOption.id}`}>
            <RadioInput
              value={lengthOption.value}
              label={lengthOption.label}
              icon={lengthOption.icon}
              selected={value}
              handleOnChange={() => {
                setValue(lengthOption.value);
              }}
            />
          </RadioGridColumn>
        ))}
        <RadioGridColumn>
          <RadioInput
            value="custom"
            label="Custom length"
            icon={CustomTermLength}
            selected={value}
            handleOnChange={() => {
              setValue('custom');
            }}
          />
        </RadioGridColumn>
        {value !== 'custom' && (
          <RadioGridColumn>
            <CheckboxInput
              id="have_not_decided"
              value={haveNotDecided}
              label="I haven't decided yet"
              checked={haveNotDecided}
              tabletWidth="60%"
              desktopWidth="40%"
              noBackground
              handleOnChange={() => {
                setHaveNotDecided(!haveNotDecided);
              }}
            />
          </RadioGridColumn>
        )}
      </RadioGrid>
      {value === 'custom' && (
        <Fieldset>
          <legend>Select how long you would like your {programAlias.toLowerCase()} to be:</legend>
          <TabletSelectContainer>
            <SelectInput
              id="minimum_weeks"
              value={customLength.minWeeks}
              label="Min"
              placeholder="Select weeks"
              options={customLengthSelectOptions}
              error={error.minWeeks}
              required
              labelToSide="left"
              noMarginTop
              handleOnChange={event => {
                handleOnChange(event, true);
              }}
            />
            <SelectInput
              id="maximum_weeks"
              value={customLength.maxWeeks}
              label="Max"
              placeholder="Select weeks"
              options={customLengthSelectOptions}
              error={error.maxWeeks}
              required
              labelToSide="right"
              noMarginTop
              handleOnChange={event => {
                handleOnChange(event);
              }}
            />
          </TabletSelectContainer>
          <MobileSelectContainer>
            <SelectInput
              id="minimum_weeks"
              value={customLength.minWeeks}
              label="Min"
              placeholder="Select weeks"
              options={customLengthSelectOptions}
              error={error.minWeeks}
              required
              noMarginTop
              handleOnChange={event => {
                handleOnChange(event, true);
              }}
            />
            <SelectInput
              id="maximum_weeks"
              value={customLength.maxWeeks}
              label="Max"
              placeholder="Select weeks"
              options={customLengthSelectOptions}
              error={error.maxWeeks}
              required
              noMarginTop
              handleOnChange={event => {
                handleOnChange(event);
              }}
            />
          </MobileSelectContainer>
        </Fieldset>
      )}
    </TravelerFormQuestionContainer>
  );
}
