import React from 'react';
import propTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import sSwitchDashboardBanner from './switchDashboardBanner.scss';

function SwitchDashboardBanner({ linkPath, dashboardType }) {
  return (
    <div className={sSwitchDashboardBanner['switch-banner']}>
      <div className={sSwitchDashboardBanner['switch-left-div']} />
      🚀 New Via {dashboardType} Dashboards are now in beta!
      <a href={linkPath} className={sSwitchDashboardBanner['switch-link']}>
        Switch now to try out the new dashboard features.
      </a>
    </div>
  );
}

SwitchDashboardBanner.propTypes = {
  linkPath: propTypes.string.isRequired,
  dashboardType: propTypes.string.isRequired,
};
export default withStyles(sSwitchDashboardBanner)(SwitchDashboardBanner);
