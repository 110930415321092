import PropTypes from 'prop-types';
import styled from 'styled-components';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ProgramFilterDropdown from './ProgramFilterDropdown';
import ProgramNameFilter from './advanced/ProgramNameFilter';
import ScreenReaderText from '../../base/ScreenReaderText.styled';
import { ToggleSlider, ToggleSwitch } from '../../base/Toggle.styled';
import ProgramHousingTypeFilter from './advanced/ProgramHousingTypeFilter';
import ProgramOrganizationNameFilter from './advanced/ProgramOrganizationNameFilter';
import ProgramLanguageOfInstructionFilter from './advanced/ProgramLanguageOfInstructionFilter';

import { toggleProgramsFilter } from '../../../actions/programDashboardActions';

const OuterContainer = styled.div`
  padding: 0 9.25rem 3.875rem 2.625rem;
`;

const InnerContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  column-gap: 8.625rem;
  padding: 1.75rem 0 0 0.625rem;
`;

const Heading = styled.legend`
  font-family: 'Nunito', sans-serif;
  font-weight: 600;
  font-size: 0.875rem;
  color: #373737;
  line-height: 120%;
`;

const FilterSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2.875rem;
`;

const ToggleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  span {
    font-family: 'Nunito', sans-serif;
    font-weight: 600;
    font-size: 0.875rem;
    color: #373737;
    line-height: 120%;
  }
`;

export default function AdvancedFiltersDropdown({ handleClose }) {
  const dispatch = useDispatch();
  const { selectedFilters } = useSelector(state => state.programDashboard);

  const [programNames, setProgramNames] = useState([]);
  const [housingTypes, setHousingTypes] = useState([]);
  const [organizationNames, setOrganizationNames] = useState([]);
  const [languageOfInstruction, setLanguageOfInstruction] = useState([]);
  const [languageImmersion, setLanguageImmersion] = useState(selectedFilters.languageImmersion);

  const handleProgramNamesChange = event => {
    if (event) {
      setProgramNames(event);
    } else {
      setProgramNames([]);
    }
  };

  const handleProgramHousingTypesChange = event => {
    if (event) {
      setHousingTypes(event);
    } else {
      setHousingTypes([]);
    }
  };

  const handleProgramOrganizationNamesChange = event => {
    if (event) {
      setOrganizationNames(event);
    } else {
      setOrganizationNames([]);
    }
  };

  const handleProgramLanguageOfInstructionChange = event => {
    if (event) {
      setLanguageOfInstruction(event);
    } else {
      setLanguageOfInstruction([]);
    }
  };

  const handleLanguageImmersionToggle = event => {
    setLanguageImmersion(event.target.checked ? 'yes' : 'no');
  };

  const handleClearAll = () => {
    const filters = [
      { name: 'programName', value: [] },
      { name: 'housingType', value: [] },
      { name: 'organizationName', value: [] },
      { name: 'languageImmersion', value: 'no' },
      { name: 'languageOfInstruction', value: [] },
    ];

    filters.forEach(filter => {
      dispatch(toggleProgramsFilter(filter));
    });

    setHousingTypes([]);
    setProgramNames([]);
    setOrganizationNames([]);
    setLanguageImmersion('no');
    setLanguageOfInstruction([]);
  };

  const handleFilter = () => {
    const filters = [
      { name: 'programName', value: programNames },
      { name: 'housingType', value: housingTypes },
      { name: 'organizationName', value: organizationNames },
      { name: 'languageImmersion', value: languageImmersion },
      { name: 'languageOfInstruction', value: languageOfInstruction },
    ];

    filters.forEach(filter => {
      dispatch(toggleProgramsFilter(filter));
    });

    handleClose();
  };

  return (
    <ProgramFilterDropdown
      handleFilter={handleFilter}
      handleClearAll={handleClearAll}
      handleClose={handleClose}
      minWidth="836px"
      right="-150%"
      justifyButtonsEnd
    >
      <OuterContainer>
        <Heading>Advanced Filters</Heading>
        <InnerContainer>
          <FilterSection>
            <ProgramNameFilter value={programNames} handleOnChange={handleProgramNamesChange} />
            <ProgramHousingTypeFilter value={housingTypes} handleOnChange={handleProgramHousingTypesChange} />
          </FilterSection>
          <FilterSection>
            <ProgramOrganizationNameFilter
              value={organizationNames}
              handleOnChange={handleProgramOrganizationNamesChange}
            />
            <ProgramLanguageOfInstructionFilter
              value={languageOfInstruction}
              handleOnChange={handleProgramLanguageOfInstructionChange}
            />
            <ToggleContainer>
              <span aria-hidden="true">Language Immersion:</span>
              <ToggleSwitch htmlFor="language_immersion">
                <ScreenReaderText>Language Immersion</ScreenReaderText>
                <input
                  id="language_immersion"
                  type="checkbox"
                  checked={languageImmersion === 'yes'}
                  onChange={handleLanguageImmersionToggle}
                />
                <ToggleSlider className="toggle-slider" />
              </ToggleSwitch>
            </ToggleContainer>
          </FilterSection>
        </InnerContainer>
      </OuterContainer>
    </ProgramFilterDropdown>
  );
}

AdvancedFiltersDropdown.propTypes = {
  handleClose: PropTypes.func.isRequired,
};
