import PropTypes from 'prop-types';
import styled from 'styled-components';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ProgramFilterDropdown from '../ProgramFilterDropdown';

import { buttonFocus } from '../../../../utils/shared/focusStyles';
import useProgramTypeOptions from '../../../../hooks/useProgramTypeOptions';
import { toggleProgramsFilter } from '../../../../actions/programDashboardActions';

const Heading = styled.span`
  font-family: 'Nunito', sans-serif;
  font-weight: 600;
  font-size: 0.875rem;
  color: #373737;
  line-height: 120%;
`;

const OptionsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 0.9375rem;
  margin-top: 1.625rem;
  margin-bottom: 2.75rem;
`;

const Option = styled.button`
  padding: 0.25rem 0.4375rem;
  background-color: ${({ isSelected }) => (isSelected ? '#D2E4ED' : '#e7e7e7')};
  border: none;
  border-radius: 50px;
  font-family: 'Nunito', sans-serif;
  font-weight: 600;
  font-size: 0.875rem;
  color: #373737;
  letter-spacing: 0.015em;
  line-height: 120%;

  ${buttonFocus};
`;

export default function DesktopProgramTypeFilter({ handleClose }) {
  const dispatch = useDispatch();
  const { loading } = useSelector(state => state.lookup.allProgramTypes);
  const { selectedFilters } = useSelector(state => state.programDashboard);
  const {
    customAliases: { alias_program: programAlias },
  } = useSelector(state => state.currentOrganization);

  const [allOptions] = useProgramTypeOptions();
  const [value, setValue] = useState([]);

  useEffect(() => {
    if (selectedFilters.programType.length > 0) {
      setValue(selectedFilters.programType);
    }
  }, []);

  const handleClick = event => {
    const type = event.currentTarget.dataset.programType;
    if (value.includes(type)) {
      setValue(value.filter(element => element !== type));
    } else {
      setValue([...value, type]);
    }
  };

  const handleClearAll = () => {
    setValue([]);

    dispatch(toggleProgramsFilter({ name: 'programType', value: [] }));
  };

  const handleFilter = () => {
    dispatch(toggleProgramsFilter({ name: 'programType', value }));

    handleClose();
  };

  return (
    <ProgramFilterDropdown
      loading={loading}
      handleFilter={handleFilter}
      handleClearAll={handleClearAll}
      handleClose={handleClose}
    >
      <Heading>Select {programAlias} Types:</Heading>
      <OptionsContainer>
        {allOptions.map(type => (
          <Option
            key={`program-type-${type.value.split(' ').join('_')}`}
            data-program-type={type.value}
            onClick={handleClick}
            isSelected={value.includes(type.value)}
            aria-label={value.includes(type.value) ? `${type.label} Selected` : type.label}
          >
            {type.label}
          </Option>
        ))}
      </OptionsContainer>
    </ProgramFilterDropdown>
  );
}

DesktopProgramTypeFilter.propTypes = {
  handleClose: PropTypes.func.isRequired,
};
