import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import TextInput from '../../base/inputs/TextInput';
import TravelerFormQuestionContainer from '../../base/TravelerFormQuestionContainer';

import userId from '../../../../../shared/helpers/userId';
import { addIntakeProfile } from '../../../actions/intakeFormActions';

export default function IntakeFirstName() {
  const dispatch = useDispatch();
  const intake = useSelector(state => state.intakeForm);

  const [value, setValue] = useState('');
  const [error, setError] = useState('');
  const [disabled, setDisabled] = useState(false);

  const question = {
    overline: 'Name',
    heading: `Let's start with your first name.`,
    subheading: 'Type in your legal first name only.',
    isRequired: true,
  };

  useEffect(
    () => {
      setValue(intake.first_name);
      setDisabled(intake.ssoTraveler && intake.readonly.includes('first_name'));
    },
    [intake],
  );

  const handleNext = () => {
    setError("");
    if (!disabled && !value) {
      setTimeout(()=>{
        setError('Required, please enter your first name.'); 
      },0)
      
      throw new Error();
      
    }

    if (!disabled) {
      dispatch(addIntakeProfile(userId, { first_name: value, last_name: intake.last_name || '' }));
    }
  };
 
  return (
    <TravelerFormQuestionContainer
      question={question}
      handleNext={handleNext}
      showBack={false}
      disabled={disabled}
      canBeSynced
    >
     
      <TextInput
        value={value}
        id="first_name"
        label="First Name"
        placeholder="Enter first name"
        error={error}
        disabled={disabled}
        required
        handleOnChange={event => {
          setValue(event.target.value);
        }}
      />
    </TravelerFormQuestionContainer>
  );
}
