import React from 'react';

import { MonthYearFormat } from './MonthYearFormat';
import { FullDateFormat } from './FullDateFormat';

const TermDate = props => {
  return props.exactDates ? (
    <FullDateFormat startDate={props.startDate} endDate={props.endDate} addBr={props.addBr} />
  ) : (
    <MonthYearFormat startDate={props.startDate} endDate={props.endDate} addBr={props.addBr} />
  );
};

export default TermDate;
