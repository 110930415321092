import fetch from 'node-fetch';
import token from '../shared/helpers/token';
import { keys } from '../config/keys';
import {
  CREATE_TAG,
  GET_TAGS,
  TAGS_LOADING,
  ARCHIVE_TAG,
  GET_ARCHIVED_TAGS,
  RESTORE_TAG,
  CLEAR_TAG_FLASH,
  GET_PAGINATED_TAGS,
  EDIT_TAG_REQUEST,
  EDIT_TAG_SUCCESS,
  TAG_CLEAR,
  EDIT_TAG_FAILURE,
  CREATE_TAG_REQUEST,
  CREATE_TAG_SUCCESS,
  CREATE_TAG_FAILURE,
} from './types';

export const createTags = data => dispatch => {
  fetch(`${keys.baseUri}/api/client/tags`, {
    method: 'POST',
    headers: token,
    body: JSON.stringify(data),
  })
    .then(response => response.json())
    .then(data =>
      dispatch({
        type: CREATE_TAG,
        payload: data.tags,
      }),
    );
};

export const getTags = (all = false, callback = null) => dispatch => {
  dispatch(tagsLoading());

  let url = `${keys.baseUri}/api/client/tags`; 

  if(all){
    url = url + `?all=true`; 
  }

  fetch(url, {
    method: 'GET',
    headers: token,
  })
    .then(response => response.json())
    .then(data => {
      dispatch({
        type: GET_TAGS,
        payload: data,
      });

      if (callback && typeof callback === 'function') {
        callback(true, data);
      }
    });
};

export const tagsLoading = () => {
  return {
    type: TAGS_LOADING,
  };
};

export const archiveTag = tag_id => dispatch => {
  const tag = { tag: { archive: true } };
  fetch(`${keys.baseUri}/api/client/tags/${tag_id}`, {
    method: 'PATCH',
    headers: token,
    body: JSON.stringify(tag),
  })
    .then(response => response.json())
    .then(
      data =>
        dispatch({
          type: ARCHIVE_TAG,
          payload: data,
        }),
      dispatch(clearTagData()),
    );
};

export const getArchivedTags = () => dispatch => {
  dispatch(tagsLoading());
  fetch(`${keys.baseUri}/api/client/tags/archived`, {
    method: 'GET',
    headers: token,
  })
    .then(response => response.json())
    .then(data =>
      dispatch({
        type: GET_ARCHIVED_TAGS,
        payload: data,
      }),
    );
};

export const restoreTag = tag_id => dispatch => {
  const tag = { tag: { archive: false } };
  fetch(`${keys.baseUri}/api/client/tags/${tag_id}`, {
    method: 'PATCH',
    headers: token,
    body: JSON.stringify(tag),
  })
    .then(response => response.json())
    .then(data => {
      dispatch({
        type: RESTORE_TAG,
        payload: data,
      });
    });
};

export const clearTagFlash = () => dispatch => {
  dispatch({
    type: CLEAR_TAG_FLASH,
  });
};

export const getPaginatedTags = (pageNumber = 1) => dispatch => {
  dispatch(tagsLoading());
  fetch(`${keys.baseUri}/api/client/tags/paginated_tags?page=${pageNumber}`, {
    method: 'GET',
    headers: token,
  })
    .then(response => response.json())
    .then(data =>
      dispatch({
        type: GET_PAGINATED_TAGS,
        payload: data,
      }),
    );
};

export const editTags = (data, tag_id) => dispatch => {
  dispatch({ type: EDIT_TAG_REQUEST });
  fetch(`${keys.baseUri}/api/client/tags/${tag_id}`, {
    method: 'PUT',
    headers: token,
    body: JSON.stringify(data),
  })
    .then(response => response.json())
    .then(data =>
      dispatch({
        type: EDIT_TAG_SUCCESS,
        payload: data,
      }),
    )
    .catch(error => {
      dispatch({
        type: EDIT_TAG_FAILURE,
        payload: error.toString(),
      });
    });
};

export const createTag = data => dispatch => {
  dispatch({ type: CREATE_TAG_REQUEST });

  fetch(`${keys.baseUri}/api/client/tags`, {
    method: 'POST',
    headers: token,
    body: JSON.stringify(data),
  })
    .then(response => response.json())
    .then(data =>
      dispatch({
        type: CREATE_TAG_SUCCESS,
        payload: data.tags,
      }),
    )
    .catch(error => {
      dispatch({
        type: CREATE_TAG_FAILURE,
        payload: error.toString(),
      });
    });
};

export const clearTagData = () => dispatch => {
  dispatch({
    type: TAG_CLEAR,
  });
};
