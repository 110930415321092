import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import Apply from './Apply';
import Share from './Share';
import Favorite from './Favorite';
import Question from './Question';

import { Header } from './Header.styled';
import { programIdType } from '../../utils/types';

export default function Index({ programId, programTitle, showSharing = true, toggleFavoriteModal }) {
  const { 'is_traveler?': isTraveler, admin_sign_in: adminSignIn } = useSelector(state => state.profile.currentUser);
  return (
    <Header>
      <div className="section section--left">
        <Question programId={programId} isTraveler={isTraveler} isAdmin={adminSignIn} />
        {showSharing && <Share programId={programId} programTitle={programTitle} />}
      </div>
      <div className="section section--right">
        {isTraveler && showSharing && <Favorite programId={programId} onFavoriteClick={toggleFavoriteModal} />}
        {showSharing && <Apply />}
      </div>
    </Header>
  );
}

Index.propTypes = {
  programId: programIdType,
  showSharing: PropTypes.bool,
  programTitle: PropTypes.string,
};

Index.defaultProps = {
  programId: '',
  showSharing: true,
  programTitle: '',
};
