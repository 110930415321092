exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "@-webkit-keyframes _30wzo{to{-webkit-transform:rotate(1turn)}}@keyframes _30wzo{to{-webkit-transform:rotate(1turn);transform:rotate(1turn)}}:root{--dark:var(--theme_color_dark);--accent:var(--theme_color_accent);--light:var(--theme_color_light)}.SShx8{display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column;position:relative}.SShx8 ._8c_DZ{-ms-flex:none;flex:none;-ms-flex-order:0;order:0;-ms-flex-positive:0;flex-grow:0;margin:8px 0;font-family:AvenirNextRegular,sans-serif;font-size:16px;color:#040404}.SShx8 ._1jSbO{display:-ms-flexbox;display:flex;-ms-flex-pack:center;justify-content:center;width:100%}.SShx8 ._1jSbO ._1wjpq{background:#fff;border:1px solid #bbb;padding:10px 16px;border-radius:6px;-webkit-box-sizing:border-box;box-sizing:border-box;color:#767676;height:44px;line-height:140%;font-family:AvenirNextRegular,sans-serif;font-size:14px;width:100%}.SShx8 ._1jSbO ._1wjpq::-webkit-input-placeholder{font-style:italic;font-weight:400;line-height:16.8px;font-size:14px}.SShx8 ._1jSbO ._1wjpq::-moz-placeholder{font-style:italic;font-weight:400;line-height:16.8px;font-size:14px}.SShx8 ._1jSbO ._1wjpq::-ms-input-placeholder{font-style:italic;font-weight:400;line-height:16.8px;font-size:14px}.SShx8 ._1jSbO ._1wjpq::placeholder{font-style:italic;font-weight:400;line-height:16.8px;font-size:14px}.SShx8 ._1jSbO ._1wjpq:disabled{background:#f6f7f9;color:#767676;cursor:not-allowed}.SShx8 ._1jSbO ._1wjpq:focus{border:1px solid #007bff;outline:none;-webkit-box-shadow:none;box-shadow:none}.SShx8 ._1jSbO ._1wjpq:invalid{outline-color:#8b1313!important;border:1px solid #8b1313}.SShx8 ._1jSbO ._3vQWu{width:0;position:relative;right:25px;top:12px}.SShx8 ._28fuV{color:#8b1313;-ms-flex:none;flex:none;-ms-flex-order:2;order:2;-ms-flex-positive:0;flex-grow:0;margin:8px 0;font-family:AvenirNextRegular,sans-serif;font-size:14px}", ""]);

// exports
exports.locals = {
	"wrapper": "SShx8",
	"label": "_8c_DZ",
	"inputWrapper": "_1jSbO",
	"inputText": "_1wjpq",
	"errorIcon": "_3vQWu",
	"error": "_28fuV",
	"spin": "_30wzo"
};