exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "@-webkit-keyframes _3_GEG{to{-webkit-transform:rotate(1turn)}}@keyframes _3_GEG{to{-webkit-transform:rotate(1turn);transform:rotate(1turn)}}.GoooY{display:-ms-inline-flexbox;display:inline-flex;padding:25px 73px 20px 0;-ms-flex-direction:column;flex-direction:column;-ms-flex-align:start;align-items:flex-start;gap:10px;background:#fff;background:var(--ag-data-color,#fff);width:100%}.VMW-H{-ms-flex-pack:justify;justify-content:space-between;width:100%}.tcEth,.VMW-H{gap:20px}._288XN,.tcEth,.VMW-H{display:-ms-flexbox;display:flex;-ms-flex-align:center;align-items:center}._288XN{padding:6px 12px;-ms-flex-pack:center;justify-content:center;gap:8px;border-radius:3.2px;border:1px solid #446372;border:1px solid var(--Theme-Primary,#446372);color:#446372;color:var(--Theme-Primary,#446372);font-family:Open Sans;font-size:14px;font-style:normal;font-weight:700;line-height:150%}._22lEt,._288XN{background:rgba(191,41,41,0);background:var(--HitBox,rgba(191,41,41,0))}._22lEt{border-radius:3.2px;border:1px solid #a82425;border:1px solid var(--Theme-Danger,#a82425);color:#a82425;color:var(--Theme-Danger,#a82425)}._22lEt:hover{background-color:#861d1e;color:#fff}._2a0D4:hover{background-color:#446372;color:#fff;color:var(--Gray-White,var(--ag-data-color,#fff))}._288XN:disabled{opacity:.6}._3Goli{width:100%;display:-ms-flexbox;display:flex;gap:20px}._1x0Mr{width:250px}.EpWxE{gap:20px}.EpWxE,.wlyY7{display:-ms-flexbox;display:flex}.wlyY7{margin:auto;padding:4.2px 7.8px;-ms-flex-pack:center;justify-content:center;-ms-flex-align:center;align-items:center;border-radius:4px;-ms-flex-direction:column;flex-direction:column;text-align:center;font-family:Open sans;font-size:12px;font-weight:700;line-height:100%;text-transform:capitalize}.HG_IN,.wlyY7{font-style:normal}.HG_IN{color:#373737;color:var(--black,#373737);font-family:Nunito;font-size:25px;font-weight:600;line-height:120%}", ""]);

// exports
exports.locals = {
	"header": "GoooY",
	"buttonContainer": "VMW-H",
	"buttonContainerSetting": "tcEth",
	"button": "_288XN",
	"buttonArchive": "_22lEt",
	"buttonHover": "_2a0D4",
	"fieldContainer": "_3Goli",
	"externalLinkContainer": "_1x0Mr",
	"pageHeaderContainer": "EpWxE",
	"programStatus": "wlyY7",
	"programTitle": "HG_IN",
	"spin": "_3_GEG"
};