import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ProfileFormGrid from '../../base/ProfileFormGrid.styled';
import ProfileTextInput from '../../base/inputs/ProfileTextInput';
import ProfileSelectInput from '../../base/inputs/ProfileSelectInput';

import userId from '../../../../../shared/helpers/userId';
import titleOptions from '../../../utils/options/titleOptions';
import { getGenderIdentities } from '../../../actions/travelerProfileActions';
import assignedGenderOptions from '../../../utils/options/assignedGenderOptions';

export default function NameAndIdentityForm({ profileForm, setProfileForm, infoForm, setInfoForm }) {
  const dispatch = useDispatch();
  const { info, profile, readonly, genderIdentities } = useSelector(state => state.travelerProfile);

  const [genderIdentityOptions, setGenderIdentityOptions] = useState([]);

  useEffect(() => {
    dispatch(getGenderIdentities(userId));
  }, []);

  useEffect(() => {
    setProfileForm({
      last_name: profile.last_name,
      first_name: profile.first_name,
      middle_name: profile.middle_name,
    });
  }, [profile]);

  useEffect(() => {
    if (genderIdentities.length > 0) {
      let mappedGenderIdentities = genderIdentities.map(genderIdentity => ({
        value: Number(genderIdentity.id),
        label: genderIdentity.attributes.name,
      }));

      setGenderIdentityOptions(mappedGenderIdentities);

      // mapping of mismatch titles
      if (info.title) {
        let titleInfo = titleOptions.find(i => i.value === info.title || i.label === info.title);

        if (!titleInfo) {
          titleOptions = [
            ...titleOptions,
            {
              id: Date.now(),
              label: info.title,
              value: info.title,
            },
          ];
        }
      }
      // end mapping

      setInfoForm({
        pronoun: info.pronoun,
        preferred_last_name: info.preferred_last_name,
        preferred_first_name: info.preferred_first_name,
        title: titleOptions.find(option => option.value === info.title) || '',
        gender_identity_additional_text: info.gender_identity_additional_text || '',
        gender: assignedGenderOptions.find(option => option.value === info.gender) || '',
        gender_identity_id: mappedGenderIdentities.find(option => option.value === info.gender_identity_id) || '',
      });
    }
  }, [info, genderIdentities]);

  return (
    <ProfileFormGrid>
      <ProfileTextInput
        id="first_name"
        label="First Name"
        placeholder="Enter first name"
        value={profileForm.first_name}
        synced={readonly.includes('first_name')}
        handleOnChange={event => {
          setProfileForm({ ...profileForm, first_name: event.target.value });
        }}
      />
      <ProfileTextInput
        id="middle_name"
        label="Middle Name"
        placeholder="Enter middle name"
        value={profileForm.middle_name}
        synced={readonly.includes('middle_name')}
        handleOnChange={event => {
          setProfileForm({ ...profileForm, middle_name: event.target.value });
        }}
      />
      <ProfileTextInput
        id="last_name"
        label="Last Name"
        placeholder="Enter last name"
        value={profileForm.last_name}
        synced={readonly.includes('last_name')}
        handleOnChange={event => {
          setProfileForm({ ...profileForm, last_name: event.target.value });
        }}
      />
      <ProfileSelectInput
        id="title"
        label="Title"
        placeholder="Select title"
        value={infoForm.title}
        options={titleOptions}
        synced={readonly.includes('title')}
        handleOnChange={event => {
          setInfoForm({ ...infoForm, title: event });
        }}
      />
      <ProfileTextInput
        id="preferred_first_name"
        label="Preferred First Name"
        placeholder="Enter preferred first name"
        value={infoForm.preferred_first_name}
        synced={readonly.includes('preferred_first_name')}
        handleOnChange={event => {
          setInfoForm({ ...infoForm, preferred_first_name: event.target.value });
        }}
      />
      <ProfileTextInput
        id="preferred_last_name"
        label="Preferred Last Name"
        placeholder="Enter preferred last name"
        value={infoForm.preferred_last_name}
        synced={readonly.includes('preferred_last_name')}
        handleOnChange={event => {
          setInfoForm({ ...infoForm, preferred_last_name: event.target.value });
        }}
      />
      <ProfileSelectInput
        id="gender"
        label="Sex Assigned at Birth"
        placeholder="Select sex assigned at birth"
        value={infoForm.gender}
        options={assignedGenderOptions}
        synced={readonly.includes('gender')}
        handleOnChange={event => {
          setInfoForm({ ...infoForm, gender: event });
        }}
      />
      <ProfileTextInput
        id="pronouns"
        label="Pronouns"
        placeholder="Enter pronouns"
        value={infoForm.pronoun}
        synced={readonly.includes('pronoun')}
        handleOnChange={event => {
          setInfoForm({ ...infoForm, pronoun: event.target.value });
        }}
      />
      <ProfileSelectInput
        id="gender_identity"
        label="Gender Identity"
        placeholder="Select gender identity"
        value={infoForm.gender_identity_id}
        options={genderIdentityOptions}
        synced={readonly.includes('gender_identity_id')}
        handleOnChange={event => {
          setInfoForm({ ...infoForm, gender_identity_id: event, gender_identity_additional_text: '' });
        }}
      />
      {infoForm.gender_identity_id &&
        infoForm.gender_identity_id.label === 'Additional gender category/identity: please specify' && (
          <ProfileTextInput
            id="gender_identity_additional_text"
            label="Enter Gender Identity"
            placeholder="Enter gender identity"
            value={infoForm.gender_identity_additional_text}
            synced={readonly.includes('gender_identity_id')}
            handleOnChange={event => {
              setInfoForm({ ...infoForm, gender_identity_additional_text: event.target.value });
            }}
          />
        )}
    </ProfileFormGrid>
  );
}

NameAndIdentityForm.propTypes = {
  profileForm: PropTypes.shape({
    last_name: PropTypes.string,
    first_name: PropTypes.string,
    middle_name: PropTypes.string,
  }).isRequired,
  setProfileForm: PropTypes.func.isRequired,
  infoForm: PropTypes.shape({
    pronoun: PropTypes.string,
    preferred_last_name: PropTypes.string,
    preferred_first_name: PropTypes.string,
    gender_identity_additional_text: PropTypes.string,
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    gender: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    gender_identity_id: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  }).isRequired,
  setInfoForm: PropTypes.func.isRequired,
};
