import React, { useState } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';

import Modal from '../../structure/Modal';
import SubmissionSuccess from './SubmissionSuccess';

import { ModalBody, ModalButton, ModalFooter } from '../../structure/Modal.styled';
import { toggleLearnMoreModal } from '../../../../../actions/programBrochure';
import { requestToApply } from '../../../../../actions/programBrochureActions';

const LearnMoreContainer = styled.div`
  text-align: center;

  span {
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 400;
    font-size: 1.3125rem;
    line-height: 120%;
    color: #373737;
    margin-bottom: 3rem;
  }

  p {
    margin-bottom: 1rem;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 120%;
    letter-spacing: 0.0025em;
    color: #000000;
  }
  
`;

export default function LearnMoreModal() {
  const dispatch = useDispatch();
  const {
    meta: { id },
    showLearnMoreModal: isOpen,
  } = useSelector(state => state.programBrochure);
  const programAlias = useSelector(state => state.auth.organization.info.alias_program);

  const [hasBeenSubmitted, setHasBeenSubmitted] = useState(false);

  const close = () => {
    hasBeenSubmitted ? setHasBeenSubmitted(false) : null;
    dispatch(toggleLearnMoreModal());
  };

  const handleSubmit = () => {
    dispatch(requestToApply(id));

    setHasBeenSubmitted(true);
  };

  return (
    <Modal show={isOpen} title="" close={close}>
      <ModalBody>
        <LearnMoreContainer tabIndex={0}>
          {!hasBeenSubmitted && (
            <>
              <span>Learn More Request</span>
              <p>
                There are currently no open terms for this {programAlias.toLowerCase()}, but it is possible that future
                terms are available.
              </p>
              <p>
                Request to learn more to let this {programAlias.toLowerCase()} provider know you are interested in
                taking the next step.
              </p>
              <p>
                When you request to learn more, a message will be sent letting the contacts for this{' '}
                {programAlias.toLowerCase()} know you are ready to get started.
              </p>
            </>
          )}
          {hasBeenSubmitted && <SubmissionSuccess />}
        </LearnMoreContainer>
      </ModalBody>
      <ModalFooter>
        {!hasBeenSubmitted && (
          <>
            <ModalButton type="button" className="secondary" onClick={close}>
              Cancel
            </ModalButton>
            <ModalButton type="button" className="primary" onClick={handleSubmit}>
              Request to Learn More
            </ModalButton>
          </>
        )}
        {hasBeenSubmitted && (
          <ModalButton type="button" className="primary" onClick={close}>
            Okay
          </ModalButton>
        )}
      </ModalFooter>
    </Modal>
  );
}
