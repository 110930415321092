import React from 'react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import FileSaver from 'file-saver';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { checkTravelerMessage } from '../../../../../../../actions/profileActions';
import { withCookies } from 'react-cookie';
import token from '../../../../../../../shared/helpers/token';
import s from './Message.css';
import ReactHtmlParser from 'react-html-parser';
import attachmentIcon from '../../../../../../../../public/img/ico-page.svg';
import downloadIcon from '../../../../../../../../public/img/ico-download.svg';
import ReactPDF from '../../../../../../ReactPDF';
import ImageViewer from '../../../../../../ImageViewer';
import eye from '../../../../../../../shared/images/eye.png';
import Attachment from '../../../../../../../../public/img/ico-clip.svg';

class Message extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isActive: false,
      viewPDF: false,
      viewImage: false,
    };
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    this.setState({ isActive: !this.state.isActive, read: true });
    this.props.checkTravelerMessage(this.props.traveler_id, this.props.message_id, this.props.role);
  }

  download = (link, label) => {
    fetch(`${link.url}`, {
      headers: token,
    })
      .then(function(response) {
        return response.blob();
      })
      .then(function(blob) {
        FileSaver.saveAs(blob, label.replace(/ /g, '_'));
      });
  };

  render() {
    const sender = this.props.message.sender.email === this.props.user.email ? true : false;
    const backgroundColor = !sender ? { background: '#ebebeb' } : { background: '#a1d8e6' };
    const avatar = this.props.avatar;
    const attachments = this.props.attachments.map((attach, index) => (
      <div className={s.attachment} key={index}>
        <div className={s.fileInfoContainer}>
          <img src={Attachment} className={s.attachmentIcon} />
          <div className={s.fileInfo}>
            <p className={s.fileName}>{attach.name}</p>
          </div>
          {attach.name.split('.').pop() === 'pdf' ? (
            <div className={s.previewContainer}>
              <p
                onClick={() => {
                  this.setState({ viewPDF: !this.state.viewPDF });
                }}
                className={s.downloadLink}
              >
                <img src={eye} className={s.eye} />
                Preview
              </p>
              {this.state.viewPDF ? (
                <ReactPDF
                  file={attach}
                  width="300px"
                  show={this.state.viewPDF}
                  handleClose={() => this.setState({ viewPDF: false })}
                />
              ) : null}
            </div>
          ) : (
            <div className={s.previewContainer}>
              {attach.url.includes('.jpeg', '.png', '.jpg', '.gif', '.raw') ? (
                <p
                  onClick={() => {
                    this.setState({ viewImage: !this.state.viewImage });
                  }}
                  className={s.downloadLink}
                >
                  <img src={eye} className={s.eye} />
                  Preview
                </p>
              ) : null}
              {this.state.viewImage ? (
                <ImageViewer
                  file={attach.url}
                  show={this.state.viewImage}
                  handleClose={() => this.setState({ viewImage: false })}
                />
              ) : null}
            </div>
          )}
          <p
            className={s.downloadLink}
            onClick={() => {
              this.download(attach, attach.name);
            }}
          >
            <svg
              width="16px"
              height="16px"
              viewBox="0 0 20 24"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              className="mr-2"
            >
              <g id="Traveler-View" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g
                  id="Program-Application-(Traveler-View)"
                  transform="translate(-529.000000, -3685.000000)"
                  fill="#043544"
                  stroke="#043544"
                >
                  <g id="2.-Academic-and-Professional-Info" transform="translate(125.000000, 2493.000000)">
                    <g id="Download-file" transform="translate(20.000000, 1123.000000)">
                      <g id="btn" transform="translate(1.000000, 48.000000)">
                        <g id="SMALL-TEXT" transform="translate(27.000000, 21.000000)">
                          <path
                            d="M366.840563,19.3679667 L373.751745,11.992427 L374.946081,13.119079 L366.011284,22.6541321 L357.294722,13.1037877 L358.503821,11.9930242 L365.199786,19.3293687 L365.199786,0.5 L366.840563,0.5 L366.840563,19.3679667 Z"
                            id="Path"
                          />
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </svg>
            Download{' '}
          </p>
        </div>
      </div>
    ));

    return this.props.allowMarkRead ? (
      <div className={this.state.isActive ? `${s.activeContainer}` : `${s.container}`} onClick={this.handleClick}>
        <img src={avatar} className={s.avatar} alt="Sender avatar" />
        <div className="row ml-0 col-12 p-0">
          <div className={`${s.messageContent} ml-4`} style={backgroundColor}>
            <div className={s.header}>
              <span className={s.author}>{this.props.author},</span>
              <span className={s.dateTime}>{`${this.props.date} ${this.props.time}`}</span>
              <span className={s.new}>{this.props.new == false ? 'NEW' : ''}</span>
            </div>
            <span className={s.subject}>{this.props.subject}</span>
            <span className={s.body}>{ReactHtmlParser(this.props.body)}</span>
            <div className={s.attachmentContainer}>
              <div>Attachments: {this.props.attachments.length}</div>
              <div>{attachments}</div>
            </div>
          </div>
        </div>
      </div>
    ) : (
      <div className={this.state.isActive ? `${s.activeContainer}` : `${s.container}`}>
        <img src={avatar} className={s.avatar} alt="User avatar" />
        <div className="row ml-0 col-12 p-0">
          <div className={`${s.messageContent} ml-4`} style={backgroundColor}>
            <div className={s.header}>
              <span className={s.author}>{this.props.author},</span>
              <span className={s.dateTime}>{`${this.props.date} ${this.props.time}`}</span>
            </div>
            <div className={s.subject}>{this.props.subject}</div>
            <span className={s.body}>{ReactHtmlParser(this.props.body)}</span>
            <div className={s.attachmentContainer}>
              <div>Attachments: {this.props.attachments.length}</div>
              <div>{attachments}</div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  messageRead: state.profile.messageRead,
  user: state.profile.currentUser,
});

export default compose(
  withCookies,
  withStyles(s),
  connect(
    mapStateToProps,
    { checkTravelerMessage },
  ),
)(Message);
