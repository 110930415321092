import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import CalendarIcon from '../../icons/CalendarIcon';
import { CardDetailItem } from './ProgramCard.styled';

export default function CardTerms({ terms }) {
  const customBrandingAccent = useSelector(state => state.profile.customBranding.themes.theme_color_accent);

  return (
    <CardDetailItem tabIndex={0}>
      <CalendarIcon fillColor={customBrandingAccent} />
      <span>{terms}</span>
    </CardDetailItem>
  );
}

CardTerms.propTypes = {
  terms: PropTypes.string.isRequired,
};
