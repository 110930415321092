const currentStep = history => Number(history.location.pathname.split('/').pop());
const form = history => (history.location.pathname.includes('intake') ? 'intake' : 'program-match');

export function handleBack(history) {
  history.push(`/traveler/${form(history)}/steps/${currentStep(history) - 1}`);
}

export function handleContinue(event, history, handleNext, toProcessing) {
  event.preventDefault();

  try {
    handleNext();
  } catch (error) {
    return;
  }

  setTimeout(() => {
    if (toProcessing) {
      history.push(`/traveler/${form(history)}/processing`);

      return;
    }

    history.push(`/traveler/${form(history)}/steps/${currentStep(history) + 1}`);
  }, 750);
}
