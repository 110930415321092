import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import Plane from '../icons/stepper/Plane';
import CompletedStep from '../icons/stepper/CompletedStep';
import IncompleteStep from '../icons/stepper/IncompleteStep';

import { breakpoints } from '../../utils/breakpoints';
import { getIntakeStepIndicators, getProgramMatchStepIndicators } from '../../utils/getStepIndicators';

const ScreenReaderQuestionHeading = styled.div`
  position: absolute;
  width: 0.0625rem;
  height: 0.0625rem;
  padding: 0;
  margin: -0.0625rem;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
`;

const StepperContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.55rem;
  margin-top: 1.91375rem;
  margin-bottom: 1.8125rem;

  @media ${breakpoints.tablet} {
    justify-content: start;
    gap: 0.9375rem;
    margin-top: 7rem;
    margin-bottom: 7rem;
    margin-right: auto;
    margin-left: auto;
  }

  @media ${breakpoints.desktop} {
    margin-top: 5rem;
    margin-bottom: 5rem;
  }
`;

const StepperItem = styled.div`
  svg {
    width: 14px;
    height: 14px;
  }

  .plane-icon {
    width: 26px;
    height: 24px;
  }

  @media ${breakpoints.tablet} {
    svg {
      width: 24px;
      height: 24px;
    }

    .plane-icon {
      width: 46px;
      height: 41px;
    }
  }
`;

export default function TravelerFormStepper({ step, forProgramMatch }) {
  const intakeState = useSelector(state => state.intakeForm);
  const programMatchState = useSelector(state => state.programMatchForm);
  const [stepType, setStepType] = useState();
  useEffect(() => {
    setStepType(forProgramMatch);
  }, [])

  const steps = stepType
    ? getProgramMatchStepIndicators(programMatchState, Number(step))
    : getIntakeStepIndicators(intakeState, Number(step));

  const currentStepNumber = steps.find(step => step.current).step;
  return (
    <>
      <ScreenReaderQuestionHeading>
        Step {currentStepNumber} of {steps.length}
      </ScreenReaderQuestionHeading>
      <StepperContainer aria-hidden="true">
        {steps.map(step => (
          <StepperItem key={`step-${step.step}`}>
            {step.current && <Plane />}
            {!step.current && step.completed && <CompletedStep />}
            {!step.current && !step.completed && <IncompleteStep />}
          </StepperItem>
        ))}
      </StepperContainer>
    </>
  );
}

TravelerFormStepper.propTypes = {
  step: PropTypes.string.isRequired,
  forProgramMatch: PropTypes.bool.isRequired,
};
