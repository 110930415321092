exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "@-webkit-keyframes _1P4ta{to{-webkit-transform:rotate(1turn)}}@keyframes _1P4ta{to{-webkit-transform:rotate(1turn);transform:rotate(1turn)}}._2TB1b{font-family:AvenirNextRegular,sans-serif;font-size:16px}._2TB1b p{margin-top:0;margin-bottom:0;line-height:140%}._2TB1b ._3kgWR{font-style:italic}._2TB1b ._2vwb8{text-align:center}._2TB1b ._27DP4{font-family:AvenirNextBold,sans-serif}._2TB1b ._3nRTV{font-family:AvenirNextDemiBold,sans-serif}._2TB1b ._3dUWU{font-family:AvenirNextMedium,sans-serif}._2TB1b .l9m2W{font-size:24px}._2TB1b .HFpT9{font-size:22px}._2TB1b .DLAIh{font-size:16px}._2TB1b ._2aY92{font-size:14px}._2TB1b ._3kvYJ{font-size:12px}._2TB1b ._2snw2{color:#fff}._2TB1b ._3lsZS{color:#043544}._2TB1b ._33rlT{color:#767676}._2TB1b ._1KP9w{color:#fff}._2TB1b ._1CN3i{color:#8b1313}._2TB1b ._3lu2U{color:#1c748a}._2TB1b ._1Xn33{color:#56a3ba}._2TB1b ._3B-Hr{color:#df235d}._2TB1b ._1XNFe{color:#8b1313}", ""]);

// exports
exports.locals = {
	"paragraph": "_2TB1b",
	"italic": "_3kgWR",
	"centered": "_2vwb8",
	"bold": "_27DP4",
	"demiBold": "_3nRTV",
	"avenirNextMedium": "_3dUWU",
	"x-large": "l9m2W",
	"large": "HFpT9",
	"medium": "DLAIh",
	"normal": "_2aY92",
	"small": "_3kvYJ",
	"light": "_2snw2",
	"dark": "_3lsZS",
	"gray": "_33rlT",
	"white": "_1KP9w",
	"error": "_1CN3i",
	"secondary": "_3lu2U",
	"secondaryLight": "_1Xn33",
	"primary": "_3B-Hr",
	"warning": "_1XNFe",
	"spin": "_1P4ta"
};