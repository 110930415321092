import React from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import InputError from './InputError';
import LockIcon from '../../icons/LockIcon';
import SyncIcon from '../../icons/SyncIcon';
import InputErrorRequired from './InputErrorRequired';
import ScreenReaderLabel from '../../../../travelerForms/components/base/inputs/ScreenReaderLabel.styled';
import { breakpoints } from '../../../utils/breakpoints';
import useMediaQuery from '../../../../../hooks/useMediaQuery';
import TooltipArrow from '../../../../../styledComponents/styles/TooltipArrow.styled';
import StyledIcon from '../../../../../styledComponents/styles/Icon.styled';

const SelectContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media ${breakpoints.tablet} {
    ${props => {
      if (props.labelOnLeft) {
        return `
          flex-direction: row;
          align-items: center;

          label {
            margin-bottom: 0;
            margin-right: 0.8125rem;
          }
        `;
      }
    }};
  }
`;

const SelectLabel = styled.label`
  font-family: 'Nunito', sans-serif;
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 120%;
  letter-spacing: 0.015em;
  color: #373737;

  svg {
    margin-top: -2px;
    margin-left: 7px;
  }

  ${props => {
    if (props.hideLabel) {
      return `
        position: absolute;
        width: 0.0625rem;
        height: 0.0625rem;
        padding: 0;
        margin: -0.0625rem;
        overflow: hidden;
        clip: rect(0, 0, 0, 0);
        white-space: nowrap;
        border-width: 0;
      `;
    }
  }};
`;

const Astrik = styled.span`
  color: #8b1313;
  font-family: Nunito;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  letter-spacing: 0.21px;
`;
const TooltipContainer = styled.span`
  padding-left: 5px;
`;
export default function ProfileSelectInput({
  id,
  value,
  error,
  label,
  ariaLabel = '',
  locked,
  ignoreShowLockIcon = false,
  isLoading = false,
  disabled = false,
  menuPlacement = "auto",
  synced,
  isMulti,
  options,
  required,
  className,
  hideLabel,
  labelOnLeft,
  placeholder,
  defaultValue,
  handleOnChange,
  alwaysShowRequired,
  showAstrik,
  style,
  fullWidth = false,
  tooltip,
}) {
  const { isMobile, isDesktop, isTablet } = useMediaQuery();
  const customStyles = {
    container: styles => ({
      ...styles,
      flexGrow: '1',
    }),
    control: (styles, state) => ({
      ...styles,
      padding: '0.25rem',
      backgroundColor: state.isDisabled ? '#eeeeee' : '#ffffff',
      borderWidth: '1px',
      borderStyle: 'solid',
      borderColor: state.isFocused ? '#5a869b' : error ? '#8b1313' : '#c2c2c2',
      borderRadius: '2px',
      boxShadow: state.isFocused ? '0px 5px 10px rgba(94, 140, 190, 0.25)' : 'none',
      fontFamily: "'Open Sans', sans-serif",
      fontWeight: '400',
      fontSize: '0.875rem',
      color: state.isDisabled ? '#555555' : '#373737',
      lineHeight: '140%',
      outline: 'none',
      pointerEvents: 'auto',
      cursor: state.isDisabled ? 'not-allowed' : 'auto',
    }),
    clearIndicator: () => ({
      display: 'none',
    }),
    dropdownIndicator: styles => ({
      ...styles,
      color: '#373737',
    }),
    indicatorSeparator: () => ({
      display: 'none',
    }),
    menu: styles => ({
      ...styles,
      zIndex: '20',
      backgroundColor: '#ffffff',
    }),
    multiValue: styles => ({
      ...styles,
      backgroundColor: '#D2E4ED',
      borderRadius: '50px',
      fontFamily: "'Nunito', sans-serif",
      fontWeight: '600',
      fontSize: '0.875rem',
      color: '#373737',
      lineHeight: '120%',
    }),
    option: (styles, state) => ({
      ...styles,
      backgroundColor: (isDesktop && state.isFocused) || state.isSelected ? '#D2E4ED' : '#ffffff',
      fontFamily: "'Open Sans', sans-serif",
      fontWeight: '400',
      fontSize: '0.875rem',
      color: '#373737',
      lineHeight: '140%',
    }),
    placeholder: styles => ({
      ...styles,
      fontStyle: 'italic',
      color: '#666666',
    }),
  };

  const title = synced ? 'Synced Field' : locked ? 'Locked Field' : null;

  return (
    <SelectContainer className={className} style={fullWidth === true ? { width: '100%' } : {}}>
      <InputContainer title={title} labelOnLeft={labelOnLeft}>
        {label ? 
          <SelectLabel htmlFor={id} hideLabel={hideLabel}>
            {label} {showAstrik && <Astrik>*</Astrik>}
            {(alwaysShowRequired || (error && required)) && <InputErrorRequired />}
            {synced && <SyncIcon />}
            {locked && !ignoreShowLockIcon && <LockIcon />}
            {tooltip && (
              <TooltipArrow toolTipText={tooltip} >
                <StyledIcon type={'InformationCircle'} size={'14px'} />
              </TooltipArrow>
            )}
          </SelectLabel>
          : <ScreenReaderLabel htmlFor={id}>{ariaLabel}</ScreenReaderLabel>
        }
        <Select
          value={value}
          defaultValue={defaultValue}
          aria-label={label || ariaLabel}
          isMulti={isMulti}
          name={id}
          inputId={id}
          options={options}
          styles={
            style
              ? {
                  ...customStyles,
                  ...style,
                }
              : customStyles
          }
          isLoading={isLoading}
          isClearable={true}
          placeholder={placeholder}
          isDisabled={synced || locked || disabled}
          onChange={handleOnChange}
          menuPlacement={menuPlacement}
          theme={theme => ({
            ...theme,
            colors: {
              primary: '#255a94',
            },
          })}
        />
      </InputContainer>
      {error && <InputError message={error} />}
    </SelectContainer>
  );
}

ProfileSelectInput.propTypes = {
  locked: PropTypes.bool,
  synced: PropTypes.bool,
  error: PropTypes.string,
  isMulti: PropTypes.bool,
  required: PropTypes.bool,
  hideLabel: PropTypes.bool,
  className: PropTypes.string,
  labelOnLeft: PropTypes.bool,
  placeholder: PropTypes.string,
  id: PropTypes.string.isRequired,
  alwaysShowRequired: PropTypes.bool,
  label: PropTypes.string.isRequired,
  handleOnChange: PropTypes.func.isRequired,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.number, PropTypes.object]),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  ).isRequired,
};

ProfileSelectInput.defaultProps = {
  error: '',
  className: '',
  locked: false,
  synced: false,
  isMulti: false,
  placeholder: '',
  required: false,
  defaultValue: '',
  hideLabel: false,
  labelOnLeft: false,
  alwaysShowRequired: false,
};
