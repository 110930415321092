import React, { useEffect, useState } from 'react';
import propTypes from 'prop-types';
import { compose } from 'recompose';
import { useDispatch, useSelector } from 'react-redux';
import _get from 'lodash/get';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import sModal from 'react-responsive-modal/styles.css';
import Header from '../../../../components/Header/Header';
import Footer from '../../../components/footer';
import { fetchCurrentTravelerUser, fetchCurrentUser } from '../../../../actions/profileActions';
import userId from '../../../../shared/helpers/userId';
import { Spinner } from '../../../components/library/spinner';
import Hubspot from '../../../../components/Hubspot';
import sViaGlobalPage from './viaGlobalPage.scss';
import { Userpilot } from 'userpilot';

export const enhance = compose(withStyles(sModal, sViaGlobalPage));

function ViaGlobalPage(props) {
  const { children } = props;
  const userProfile = useSelector(state => state.profile);
  const orgInfoName = useSelector(state => state.auth.organization.org_name);
  const orgStatus = useSelector(state => state.auth.organization.status);
  const clientAccountState = useSelector(state => state.profile.clientAccountState);

  const dispatch = useDispatch();
  const [profile, setProfile] = useState();
  const [loading, setLoading] = useState(true);
  const [traveler, setTraveler] = useState(true);

  if (!userId || typeof userId === 'undefined') {
    window.location.assign(`/`);
  }

  useEffect(() => {
    setLoading(userProfile.userLoading);
    if (userProfile && userProfile.userLoading) {
      if (props.authAdmin) {
        Userpilot.initialize('NX-8e5c5323');
        dispatch(fetchCurrentUser(userId));
      } else {
        dispatch(fetchCurrentTravelerUser(userId));
      }
    }
  }, []);

  useEffect(() => {
    if (userProfile && !userProfile.userLoading) {
      const isTraveler = _get(userProfile.currentUser, ['is_traveler?']) || false;
      setTraveler(isTraveler);
      if (isTraveler && props.authAdmin) {
        window.location.assign(`/`);
      }
      if (!isTraveler && props.authTraveler) {
        window.location.assign(`/`);
      }

      if (!isTraveler && props.authAdmin) {
        const cDateUser = new Date(userProfile?.currentUser?.created_at).toISOString().split('T')[0];

        Userpilot.identify(userProfile.currentUser.id, {
          name: `${userProfile.currentUser.first_name} ${userProfile.currentUser.last_name}`,
          email: userProfile.currentUser.email,
          created_at: cDateUser,
          user_role: userProfile.role,
          company: {
            id: userProfile.currentUser.client_account_id, // Company Unique ID
            name: orgInfoName,
            type: orgStatus,
          },
        });
      }
    }

    setLoading(userProfile.userLoading);
    setProfile(userProfile);
  }, [userProfile]);

  return (
    <>
      {!props.flexLayout ? (
        <div className={sViaGlobalPage.wrapper}>
          {!loading ? (
            <>
              <div className={sViaGlobalPage.header}>
                <Header role={traveler ? 'Traveler' : 'Admin'} />
              </div>
              {clientAccountState?.toLowerCase() !== 'closed' && clientAccountState?.toLowerCase() !== 'suspended' && (
                <div
                  className={sViaGlobalPage.content}
                  style={props.riskDetail ? null : { height: 'calc(100vh - 134px)' }}
                >
                  {props.authAdmin ? <Hubspot /> : ''}
                  <div style={{ height: '100%' }}>{children}</div>
                </div>
              )}
              {!props.noFooter && <Footer />}
            </>
          ) : (
            <Spinner />
          )}
        </div>
      ) : (
        <div className={sViaGlobalPage.flexWrapper}>
          {!loading ? (
            <>
              <div className={sViaGlobalPage.header}>
                <Header role={traveler ? 'Traveler' : 'Admin'} />
              </div>
              {clientAccountState?.toLowerCase() !== 'closed' && clientAccountState?.toLowerCase() !== 'suspended' && (
                <div
                  className={sViaGlobalPage.content}
                  style={props.riskDetail ? null : { height: 'calc(100vh - 134px)' }}
                >
                  {props.authAdmin ? <Hubspot /> : ''}
                  <div style={{ height: '100%' }}>{children}</div>
                </div>
              )}
              <div className={sViaGlobalPage.footer}>
                <Footer />
              </div>
            </>
          ) : (
            <Spinner />
          )}
        </div>
      )}
    </>
  );
}

ViaGlobalPage.propTypes = {
  children: propTypes.node,
  authAdmin: propTypes.bool,
  authTraveler: propTypes.bool,
  subHeader: propTypes.bool,
  flexLayout: propTypes.bool,
  riskDetail: propTypes.bool,
};

ViaGlobalPage.defaultProps = {
  subHeader: false,
  flexLayout: false,
  riskDetail: false,
};

export default enhance(ViaGlobalPage);
