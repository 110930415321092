import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { compose } from 'recompose';
import withStyles from 'isomorphic-style-loader/lib/withStyles';

import ProgramCard from './ProgramCard';

import programType from '../../types/program';
import { breakpoints } from '../../utils/breakpoints';
import programApplication from '../../types/programApplication';

import Slider from 'react-slick';

import s1 from 'slick-carousel/slick/slick.css';
import s2 from 'slick-carousel/slick/slick-theme.css';
import customSlickCss from './customSlickCss.scss';

import leftarrow from '../../../../shared/images/leftarrow.png';
import rightarrow from '../../../../shared/images/rightarrow.png';
import useMediaQuery from '../../../../hooks/useMediaQuery';

export const enhance = compose(withStyles(s1, s2, customSlickCss));

const AdminGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  column-gap: 1.125rem;
  row-gap: 2.1875rem;
  margin: 0 1.8125rem 3.75rem 1.8125rem;

  @media ${breakpoints.tablet} {
    margin: 0;
  }
`;

const Grid = styled.div`
  width: 64vw;
  height: 474px;
  position: relative;

  @media (min-width: 320px) {
    width: 88vw;
  }

  @media ${breakpoints.mobile} {
    width: 82vw;
    height: 474px;
    margin: 0 auto;
  }

  @media ${breakpoints.tablet} {
    width: 64vw;
    height: 474px;
  }
`;

const ArrowPrevButton = styled.button`
  position: absolute;
  top: 200px;
  left: -40px;
  z-index: 1;
  cursor: ${props => (props.disabled ? 'default' : 'pointer')};
  background: none;
  border: none;

  filter: ${props => (props.disabled ? 'grayscale(100%)' : 'grayscale(0)')};

  @media (min-width: 320px) {
    top: unset;
    bottom: -45px;
    left: 0;
  }

   @media (min-width: 667px) {
    top: unset;
    bottom: -22px;
    left: 0;
  }

  @media ${breakpoints.mobile} {
    top: unset;
    bottom: -35px;
    left: 0;
  }

  @media ${breakpoints.tablet} {
    top: 200px;
    left: -40px;
    bottom: unset;
  }
`;

const ArrowNextButton = styled.button`
  position: absolute;
  top: 200px;
  right: -40px;
  z-index: 1;
  cursor: ${props => (props.disabled ? 'default' : 'pointer')};
  background: none;
  border: none;
  filter: ${props => (props.disabled ? 'grayscale(100%)' : 'grayscale(0)')};

  @media (min-width: 320px) {
    top: unset;
    bottom: -45px;
    right: 0;
  }

  @media (min-width: 667px) {
    bottom: -22px;
  }

  @media ${breakpoints.mobile} {
    top: unset;
    bottom: -35px;
    right: 0;
  }

  @media ${breakpoints.tablet} {
    top: 200px;
    right: -40px;
    bottom: unset;
  }
`;

const ArrowImage = styled.img`
  height: 30px;
  width: 30px;
`;

function ProgramsGrid({ programs, programsType, isAdmin }) {
  const sliderRef = useRef(null);
  const cardRefs = useRef([]);
  const [nextIndex, setNextIndex] = useState(0);
  const { isMobile } = useMediaQuery();

  useEffect(() => {
    handleRemoveAriaHidden();
  }, []);

  const handleRemoveAriaHidden = () => {
    let slickList = document.querySelector('.slick-list');
    let slickSlides = slickList?.querySelectorAll('.slick-slide');

    for (let i = 0; i < slickSlides?.length; i++) {
      slickSlides[i].removeAttribute('aria-hidden');
    }
  };

  const handlePrevSlide = () => {
    if (nextIndex !== 1) sliderRef.current.slickPrev();
  };
  const handleNextSlide = () => {
    if (nextIndex !== programs.length) sliderRef.current.slickNext();
  };

  const beforeChange = (prev, next, counter) => {
    let counterData = next === 0 ? next : Math.floor(next + counter);
    setNextIndex(counterData);
  };

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: Math.min(3, programs.length), // Show a maximum of 3 cards, but if there are fewer, show the actual number
    slidesToScroll: 1,
    initialSlide: 0,
    arrows: false,
    variableWidth: true,
    focusOnSelect: true,
    beforeChange: function(prev, next) {
      beforeChange(prev, next, 1);
    },
    responsive: [
      {
        breakpoint: 1590,
        settings: {
          slidesToShow: Math.min(2, programs.length),
          slidesToScroll: 1,
          beforeChange: function(prev, next) {
            beforeChange(prev, next, 1);
          },
        },
      },
      {
        breakpoint: 1260,
        settings: {
          slidesToShow: Math.min(2, programs.length),
          slidesToScroll: 1,
          beforeChange: function(prev, next) {
            beforeChange(prev, next, 1);
          },
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          beforeChange: function(prev, next) {
            beforeChange(prev, next, 1);
          },
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          beforeChange: function(prev, next) {
            beforeChange(prev, next, 1);
          },
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          beforeChange: function(prev, next) {
            beforeChange(prev, next, 1);
          },
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          beforeChange: function(prev, next) {
            beforeChange(prev, next, 1);
          },
        },
      },
      {
        breakpoint: 667, // small screens (iPhone SE)
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          beforeChange: function(prev, next) {
            beforeChange(prev, next, 1);
          },
        }
      },
      {
        breakpoint: 375, // small screens (iPhone SE)
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          beforeChange: function(prev, next) {
            beforeChange(prev, next, 1);
          },
        }
      }
    ],
  };

  if (isAdmin) {
    return (
      <AdminGrid>
        {programs.map(program => (
          <ProgramCard key={program.program_id} program={program} programsType={programsType} isAdmin={isAdmin} />
        ))}
      </AdminGrid>
    );
  }

  return (
    <Grid className={customSlickCss.sliderCoverDiv}>
      <ArrowPrevButton onClick={handlePrevSlide} disabled={nextIndex === 0}>
        <ArrowImage src={leftarrow} alt="Previous" longdesc="Previous"  />
      </ArrowPrevButton>
        <Slider  {...settings} ref={sliderRef}>
          {programs.map((program, index) => (
            <ProgramCard  ref={el => (cardRefs.current[index] = el)} key={program.program_id} program={program} programsType={programsType} isAdmin={isAdmin} />
          ))}
        </Slider>
      <ArrowNextButton onClick={handleNextSlide} disabled={nextIndex === programs.length}>
        <ArrowImage src={rightarrow} alt="Next" longdesc="Next" />
      </ArrowNextButton>
    </Grid>
  );
}

ProgramsGrid.propTypes = {
  programsType: PropTypes.string.isRequired,
  programs: PropTypes.arrayOf(PropTypes.oneOfType([programType, programApplication])).isRequired,
  isAdmin: PropTypes.bool,
};

ProgramsGrid.defaultProps = {
  isAdmin: false,
};

export default enhance(ProgramsGrid);
