exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "@-webkit-keyframes _2QWJk{to{-webkit-transform:rotate(1turn)}}@keyframes _2QWJk{to{-webkit-transform:rotate(1turn);transform:rotate(1turn)}}:root{--dark:var(--theme_color_dark);--accent:var(--theme_color_accent);--light:var(--theme_color_light);--btn-color:#d74b73}._2GC87{display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column}._2GC87 ._2dHXI{font-family:AvenirNextRegular,sans-serif;font-size:14px;color:#373737;text-align:center;padding-bottom:21px}@media (min-width:768px){._2GC87 ._2jDeg{padding:0 80px}}._2GC87 ._2jDeg .uNZb9{padding-bottom:16px}._2GC87 ._2jDeg .NOIPp{width:214px;margin:0 0 0 auto}._2GC87 ._2jDeg .NOIPp button{background-color:#d74b73!important;background-color:var(--btn-color)!important;color:#fff;border:none;padding:10px 20px;font-size:1em;cursor:pointer;border-radius:4px;-webkit-transition:background-color .3s;-o-transition:background-color .3s;transition:background-color .3s}._2GC87 ._2jDeg .NOIPp button:active,._2GC87 ._2jDeg .NOIPp button:hover{background-color:#d74b73;background-color:var(--btn-color)}._2GC87 ._2jDeg .NOIPp button:disabled{background-color:#9e9e9e;cursor:not-allowed}", ""]);

// exports
exports.locals = {
	"wrapper": "_2GC87",
	"header": "_2dHXI",
	"form": "_2jDeg",
	"input": "uNZb9",
	"button": "NOIPp",
	"spin": "_2QWJk"
};