import React, { useEffect, useState } from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import { compose } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import sModal from 'react-responsive-modal/styles.css';
import StyledIcon from '../../../../../../../styledComponents/styles/Icon.styled';
import { AccentButtonMedium, ButtonMedium } from '../../../../../../../styledComponents/styles/Buttons.styled';
import { AccentButtonSpinner } from '../../../../../../../styledComponents/styles/spinner.styled';
import { updateProgramModify } from '../../../../../../../actions/programModifyActions';
import { Paragraph } from '../../../../../../../styledComponents/styles/Paragraph.styled';
import { WarningIcons } from '../../../../../../../styledComponents/styles/IconCustom.styled';

import Modal from 'react-responsive-modal';
import CloseIcon from '../../../../../../travelerProfile/components/icons/CloseIcon';


const Header = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  padding: 25px 25px;
  border-bottom: 5px solid #DDAF64;
`;

const Heading = styled.h2`
  color: #373737;
  text-align: center;

  /* Headline 5 */
  font-family: Nunito;
  font-size: 25px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 30px */
`;
const CloseButton = styled.button`
  outline: none;
  border: none;
  padding: 0;
  background: none;
`;
const Content = styled.div`
  align-self: center;
  margin: 0;
  padding: 0;
  width: 75%;
  text-align: center;
  display:flex;
`;

const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const ButtonWrapper = styled.div`
  background: #ebebeb;
  display: flex;
  height: 111px;
  justify-content: center;
  left: 0;
  top: 273px;
  width: 100%;
  margin-bottom: -20px;
  gap : 30px;
`;

const ButtonItem = styled.div`
  margin-top: auto;
  margin-bottom:auto;
`;

const ErrorWrapper = styled.div`
  align-self: center;
  color: #a82425;
  font-family: AvenirNextRegular, sans-serif;
  font-size: 14px;
  svg {
    justify-content: center;
    margin-bottom: 2px;
    margin-right: 5px;
  }
`;

const IconWrapper = styled.div`
  padding-bottom: 25px;
  margin:auto;
`;

const ParagraphContainer = styled.div`
  margin-left:38px;
  padding-top:20px;
`;
const ParagraphWarning = styled(Paragraph)`
  color: #373737;
  font-family: "Open Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 22.4px */
  letter-spacing: 0.08px;
  text-align:left;
`;

function ArchiveModal(props) {
  const dispatch = useDispatch();
  const archivedProgram = useSelector(state => state.programModify.programModify);
  const [isSaving, setIsSaving] = useState(false);
  const [isError, setIsError] = useState(false);

  useEffect(
    () => {
      if (archivedProgram && archivedProgram.loading) {
        setIsSaving(true);
      }

      if (archivedProgram && !archivedProgram.loading && (archivedProgram.error || archivedProgram.success)) {
        if (archivedProgram.error) {
          setIsError(true);
          setIsSaving(false);
        }

        if (archivedProgram.success) {
          setTimeout(() => {
            setIsSaving(false);
            if(props?.source === "TERM_SETTINGS"){
              props?.onSuccess();
            }
            props.onClose(false);
          }, 700);
        }
      }
    },
    [archivedProgram],
  );

  const archiveProgram = () => {
    if (props.programId) {
      setIsSaving(true);
      setIsError(false);
      const data = { status: 'inactive' };
      dispatch(updateProgramModify(props.programId, data));
    }
  };

  return (
    <Modal
      blockScroll={false}
      open={props.show}
      onClose={() => props.onClose(false)}
      center
      closeOnEsc
      closeOnOverlayClick
      showCloseIcon={false}
      styles={{
        modal: {
          padding: '0px',
          borderRadius: '5px',
          width: '550px',
        },
      }}
    >
      <Header>
        <Heading>Warning</Heading>
        <CloseButton onClick={()=>props.onClose(false)}>
          <CloseIcon fillColor={`#9FABAE`} />
        </CloseButton>
      </Header>
      <ModalContainer>
        <Content>
          <IconWrapper>
            <WarningIcons size='100px' />
          </IconWrapper>
          <ParagraphContainer>
            <ParagraphWarning>
              Archiving this {props.programsAlias ? props.programsAlias.toLowerCase() : "program"} makes it inaccessible to {props.customAliases.alias_travelers ? props.customAliases.alias_travelers.toLowerCase() : "travelers"} and prevents application submissions
            </ParagraphWarning>
            <ParagraphWarning>This action means it cannot be undone. All historical data, including past terms and applications, will remain accessible for reporting.</ParagraphWarning>
            <ParagraphWarning style={{fontWeight:"700"}}>
              Are you sure you want to archive?
            </ParagraphWarning>
          </ParagraphContainer>
        </Content>
        <ButtonWrapper>
          <ButtonItem>
            <ButtonMedium aria-label="Cancel" disabled={isSaving} onClick={() => props.onClose(false)}>
              Cancel
            </ButtonMedium>
          </ButtonItem>
          <ButtonItem>
            <AccentButtonMedium
              aria-label={`Delete ${props.programsAlias} Draft`}
              disabled={isSaving}
              onClick={archiveProgram}
              saving={isSaving}
            >
              {isSaving ? <AccentButtonSpinner displayText='Archive' /> : ' Continue '}
            </AccentButtonMedium>
          </ButtonItem>
        </ButtonWrapper>
        {isError && (
          <ErrorWrapper>
            <StyledIcon type='Warning' size='16px' /> Error archiving {props.programAlias.toLowerCase()}
          </ErrorWrapper>
        )}
      </ModalContainer>
    </Modal>
  );
}

ArchiveModal.propTypes = {
  programAlias: propTypes.string.isRequired,
  programsAlias: propTypes.string.isRequired,
  programId: propTypes.string.isRequired,
  onClose: propTypes.func.isRequired,
  show: propTypes.bool.isRequired,
};

export default compose(withStyles(sModal))(ArchiveModal);
