import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import MessageForm from './MessageForm';
import Modal from '../../structure/Modal';
import SubmissionSuccess from './SubmissionSuccess';

import { programIdType } from '../../../utils/types';
import characterLimits from '../../../utils/messageCharacterLimits';

import { askQuestion } from '../../../../../actions/programBrochureActions';
import { ModalBody, ModalFooter, ModalButton } from '../../structure/Modal.styled';
import GenericStyledModal from '../../../../forms/pages/admin/modals/GenericStyledModal.modal';
export default function SendMessageModal({ programId, isOpen, handleClose }) {
  const dispatch = useDispatch();

  const [form, setForm] = useState({
    subject: '',
    message: '',
  });
  const [hasBeenSubmitted, setHasBeenSubmitted] = useState(false);

  const disableNextButton =
    !form.subject ||
    !form.message ||
    form.subject.length > characterLimits.subject ||
    form.message.length > characterLimits.message;

  const handleChange = ({ subject, message }) => {
    setForm({
      subject,
      message,
    });
  };

  const handleSubmit = () => {
    dispatch(
      askQuestion(programId, {
        subject: form.subject,
        question: form.message,
      }),
    );

    setHasBeenSubmitted(true);
  };

  const close = () => {
    setHasBeenSubmitted(false);

    handleClose();
  };

  return (
    // <Modal show={isOpen} close={close} title={hasBeenSubmitted ? '' : 'Send Message'}>
      <GenericStyledModal header={hasBeenSubmitted ? '' : 'Send Message'} showing={isOpen} width="750px" paddingBottom="0px" onClose={handleClose}>
      <ModalBody className={hasBeenSubmitted ? 'success' : null}>
        {!hasBeenSubmitted && <MessageForm handleChange={handleChange} hasBeenSubmitted={hasBeenSubmitted} />}
        {hasBeenSubmitted && <SubmissionSuccess />}
      </ModalBody>
      <ModalFooter style={{borderBottomRightRadius:"15px",borderBottomLeftRadius:"15px"}}>
        {!hasBeenSubmitted && (
          <>
            <ModalButton type="button" className="secondary" onClick={close}>
              Cancel
            </ModalButton>
            <ModalButton
              type="button"
              className="primary"
              disabled={disableNextButton}
              aria-disabled={disableNextButton}
              onClick={handleSubmit}
            >
              Next
            </ModalButton>
          </>
        )}
        {hasBeenSubmitted && (
          <ModalButton type="button" className="primary" onClick={close}>
            Okay
          </ModalButton>
        )}
      </ModalFooter>
      {/* </Modal> */}
      </GenericStyledModal>
  );
}

SendMessageModal.propTypes = {
  programId: programIdType,
  isOpen: PropTypes.bool,
  handleClose: PropTypes.func.isRequired,
};

SendMessageModal.defaultProps = {
  programId: '',
  isOpen: false,
};
