import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import EventsList from '../events/EventsList';
import EventGroup from '../events/EventGroup.styled';
import EventsGroupHeading from '../events/EventsGroupHeading';
import eventicon from '../../../../shared/images/eventicon.png'

import eventType from '../../types/event';
import groupEvents from '../../utils/events/groupEvents';

const EmptyEventContainer = styled.div`
    text-align: center;
    margin: 20vh 0 41vh 0;
`;
const EmaptyEventImage = styled.img`
    width: 92px;
    margin-bottom: 30px;
`;
const EmptyEventsText = styled.p`
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
    font-size: 1rem;
    color: #373737;
`;


export default function CancelledEventsTab({ events }) {
  const [cancelledEvents, setCancelledEvents] = useState({});

  useEffect(
    () => {
      if (events.length > 0) {
        const groupedEvents = groupEvents(events);

        setCancelledEvents(groupedEvents);
      }
    },
    [events],
  );

  return (
    <div>
      {events.length === 0 && 
        <EmptyEventContainer>
          <EmaptyEventImage src={eventicon} alt="No cancelled events" />
          <EmptyEventsText>
            You have no cancelled events.
          </EmptyEventsText>
        </EmptyEventContainer>
        
      }
      {events.length > 0 &&
        Object.keys(cancelledEvents).map(eventGroup => {
          const eventsArray = cancelledEvents[eventGroup];

          return (
            <EventGroup key={`event-group-${eventGroup}`}>
              <EventsGroupHeading text={eventGroup} />
              <EventsList events={eventsArray} category="cancelled" />
            </EventGroup>
          );
        })}
    </div>
  );
}

CancelledEventsTab.propTypes = {
  events: PropTypes.arrayOf(eventType).isRequired,
};
