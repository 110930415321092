import React, {useState, useEffect} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import _get from 'lodash/get';
import { PropTypes } from 'prop-types';
import { AccentButtonMediumNunito } from '../../../../../styledComponents/styles/Buttons.styled';
import NameIdentityIcon from '../../../../../shared/images/svgs/name_identity.svg';
import {useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import TextInput from '../../../../../styledComponents/styles/TextInput.styled';
import { updateNameAndIdentity } from '../../../../travelerProfile/actions/travelerProfileActions';
import userId from '../../../../../shared/helpers/userId';
import ToastMessage from '../../../../viaGlobal/components/toastMessage';
const ContainerModalOverlay = styled.div`
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: start;
  align-items: flex-start;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 1000;
  padding: 19.2px;
  padding: 1.2rem;
  animation: 500ms ease 0s 1 normal none running react-responsive-modal-fadeIn;
  background: rgba(0, 0, 0, 0.65);
`;

const ContainerModal = styled.div`
  border-radius: 15px;
  max-width: 1000px;
  width: 1000px;
  margin: auto;
  position: relative;
  padding: 1.2rem 1.2rem 0 1.2rem;
  background: #ffffff;
  background-clip: padding-box;
  -webkit-box-shadow: 0 12px 15px 0 rgba(0, 0, 0, 0.25);
  box-shadow: 0 12px 15px 0 rgba(0, 0, 0, 0.25);
`;

const ModalHeader = styled.div`
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 400;
  font-size: 25px;
  line-height: 120%;
  color: #373737;
  padding-left: 16px;
`;

const ModalClose = styled.div`
  color: #9fabae;
  font-size: 20px;
  padding: 10px;
  position: absolute;
  top: 17px;
  right: 23px;
  width: 35px;
  height: 35px;
  line-height: 19px;
  cursor: pointer;
`;

const Container = styled.div`
  margin-top: 30px;
  margin-left: -19px;
  margin-right: -19px;
  border-top: 1px solid #f0f1f1;
`;

const Content = styled.div`
  display: flex;
  flex-direction: row;
 // padding-top: 25px;
`;

const LeftWrapper = styled.div`
  width:40%;
  background: #EEEEEE;
  border-bottom-left-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const ParagraphWrapper = styled.div`
  //styleName: Body 1;
  font-family: Open Sans;
  font-size: 16px;
  font-weight: 400;
  line-height: 19.2px;
  letter-spacing: 0.005em;
  text-align: center;
  display: flex;
  justify-content: center;
  padding: 0px 59px 0px 61px;
  margin-top:40px;
`;

const RightWrapper = styled.div`
  width: 60%;
  top: 35px;
  left: 362px;
  gap: 101px;
  opacity: 0px;
`;

const HeadingLable = styled.div`
  font-family: Open Sans;
  font-size: 16px;
  font-weight: 400;
  line-height: 22.4px;
  letter-spacing: 0.005em;
  text-align: left;
  margin-top: 50px;
  padding-left: 20px;
  padding-right: 60px;
`;

const FormContainer = styled.div`
  padding:20px;
  margin-top:25px;
`;

const RowContainer = styled.div`
  display:flex;
  margin-bottom:30px;
`;

const InputContainer = styled.div`
    padding-right: 20px;
    width:50%;
`;

const ButtonDiv = styled.div`
  margin-top: 65px;
  margin-right: 20px;
  display:flex;
  justify-content:end;
  padding-bo
`;

const NameAndIdentityModal = props => {
  const dispatch = useDispatch();
  const [error, setError] = useState("");
  const [showToastError, setShowToastError] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const {info, profile, readonly } = useSelector(state => state.travelerProfile);

  const schema = yup.object().shape({
    first_name: yup.string().trim().required('First name is required'),
    last_name: yup.string().trim().required('Last name is required'),
  });

  const { control, handleSubmit, formState, getValues, register, reset, setValue } = useForm({
    shouldUnregister: false,
    resolver: yupResolver(schema),
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  useEffect(()=>{
    setValue("first_name",profile.first_name);
    setValue("last_name",profile.last_name);
    setValue("preferred_first_name",info.preferred_first_name);
    setValue("preferred_last_name",info.preferred_last_name);
  },[profile, readonly,info])


  const handleClose = () => {
    props.onClose();
  };

  const onSubmitHandler = (data) => {
    dispatch (
      updateNameAndIdentity(userId, {
        profile: {
          first_name : data.first_name.trim(),
          last_name : data.last_name.trim(),
        },
        travelerProfile : {
          preferred_first_name : data.preferred_first_name.trim(),
          preferred_last_name : data.preferred_last_name.trim()
        }
      }, (status, message)=>{
        if (status) {
          props.handleApplicationSubmit();
        } else {
          setError(message);
          setShowToastError(true);
        }
      }),
    );
    
  };

  return (
    <ContainerModalOverlay>
      <ContainerModal>
        <ModalHeader>Name & Identity</ModalHeader>
        <ModalClose
          onClick={() => {
            handleClose();
          }}
        >
          X
        </ModalClose>
        <Container>
          <Content>
            <LeftWrapper>
              <IconWrapper>
                <img src={NameIdentityIcon} alt="" />
              </IconWrapper>
              <ParagraphWrapper>
                Before we continue, let’s get you setup for a seamless journey. Update your profile with your first and last names.
              </ParagraphWrapper>
            </LeftWrapper>
            <RightWrapper>
              <HeadingLable>
                Enter first, last, and preferred names. You can always update these details in your profile later.
              </HeadingLable>
              <form id="name-identity-form" onSubmit={handleSubmit(onSubmitHandler)}>
              <FormContainer>
                <RowContainer>
                  <InputContainer>
                  <Controller
                    control={control}
                    name={'first_name'}
                    render={({ field }) => (
                      <TextInput
                      id={'first_name'}           
                      name={"first_name"}           
                      placeholder={'Enter First Name'}
                      label={`First Name`}
                      placeholderFontStyle={"normal"}
                      inputBorderRadius={"6px"}
                      errors={formState.errors}
                      isDirty={formState.isDirty}
                      {...field}
                      errorMsg={_get(formState.errors.first_name, 'message') || ''}
                      customLabelStyle={{
                        color: "#373737",
                        fontSize: "16px",
                        fontStyle: "normal",
                        fontWeight: "400",
                        lineHeight: "120%",
                        letterSpacing: "0.08px",
                      }}
                      isTravelerAccount={true}
                      required                     
                    />
                  )}
                  />
                  </InputContainer>
                  <InputContainer>
                  <Controller
                    control={control}
                    name={'last_name'}
                    render={({ field }) => (
                      <TextInput
                      id={'last_name'}           
                      name={"last_name"}           
                      placeholder={'Enter Last Name'}
                      label={`Last Name`}
                      {...field}
                      placeholderFontStyle={"normal"}
                      inputBorderRadius={"6px"}
                      errors={formState.errors}
                      isDirty={formState.isDirty}
                      getValues={getValues}                     
                      errorMsg={_get(formState.errors.last_name, 'message') || ''}
                      customLabelStyle={{
                        color: "#373737",
                        fontSize: "16px",
                        fontStyle: "normal",
                        fontWeight: "400",
                        lineHeight: "120%",
                        letterSpacing: "0.08px",
                      }}
                      
                      isTravelerAccount={true}
                      required                     
                    />
                  )}
                  />
                  </InputContainer>
                </RowContainer>
                <RowContainer>
                  <InputContainer>
                  <Controller
                    control={control}
                    name={'preferred_first_name'}
                    render={({ field: { onChange, defaultValue, value, ref } }) => (
                      <TextInput
                      id={'preferred_first_name'}           
                      name={"preferred_first_name"}           
                      placeholder={'Enter Preferred First Name'}
                      label={`Preferred First Name`}
                      placeholderFontStyle={"normal"}
                      inputBorderRadius={"6px"}
                      errors={formState.errors}
                      isDirty={formState.isDirty}
                      disabled={readonly.includes('preferred_first_name')}
                      synced={readonly.includes('preferred_first_name')}
                      getValues={getValues}
                      register={register}
                      errorMsg={_get(formState.errors.preferred_first_name, 'message') || ''}
                      onChange={(value) => { 
                          setValue("preferred_first_name", value, {
                            shouldValidate: false,
                            shouldDirty: false,
                            shouldTouch: false,
                          });
                          onChange(value);
                      }}
                      value={value}
                      isTravelerAccount={true}
                      customLabelStyle={{
                        color: "#373737",
                        //fontFamily: "Open Sans",
                        fontSize: "16px",
                        fontStyle: "normal",
                        fontWeight: "400",
                        lineHeight: "120%",
                        letterSpacing: "0.08px",
                      }}
                                           
                    />
                  )}
                  />
                </InputContainer>
                <InputContainer>
                <Controller
                    control={control}
                    name={'preferred_last_name'}
                    render={({ field: { onChange, defaultValue, value, ref } }) => (
                      <TextInput
                      id={'preferred_last_name'}           
                      name={"preferred_last_name"}           
                      placeholder={'Enter Preferred Last Name'}
                      placeholderFontStyle={"normal"}
                      inputBorderRadius={"6px"}
                      label={`Preferred Last Name`}
                      isTravelerAccount={true}
                      errors={formState.errors}
                      isDirty={formState.isDirty}
                      disabled={readonly.includes('preferred_last_name')}
                      synced={readonly.includes('preferred_last_name')}
                      getValues={getValues}
                      register={register}
                      errorMsg={_get(formState.errors.preferred_last_name, 'message') || ''}
                      onChange={(value) => { 
                          setValue("preferred_last_name", value, {
                            shouldValidate: false,
                            shouldDirty: false,
                            shouldTouch: false,
                          });
                          onChange(value);
                      }}
                      value={value}
                      customLabelStyle={{
                        color: "#373737",
                       // fontFamily: "Open Sans",
                        fontSize: "16px",
                        fontStyle: "normal",
                        fontWeight: "400",
                        lineHeight: "120%",
                        letterSpacing: "0.08px",
                      }}
                                         
                    />
                  )}
                  />
                </InputContainer>
                </RowContainer>
                <ButtonDiv>
                  <AccentButtonMediumNunito
                    disabled={false}
                    form="name-identity-form"
                    type="submit"
                  >
                    Continue
                  </AccentButtonMediumNunito>
                </ButtonDiv>
              </FormContainer>
              </form>
            </RightWrapper>
          </Content>
        </Container>
      </ContainerModal>
      {showToastError && (
          <ToastMessage
            message={error}
            isSuccess={false}
            show={showToastError}
            onClose={() => {
              setShowToastError(false);
            }}
          />
        )}
    </ContainerModalOverlay>
  );
};

NameAndIdentityModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
};

export default NameAndIdentityModal;
