import fetch from 'node-fetch';

import { keys } from '../../../config/keys';
import token from '../../../shared/helpers/token';

import {
  TOGGLE_DEFER_MODAL,
  TOGGLE_COMMIT_MODAL,
  TOGGLE_WITHDRAW_MODAL,
  TOGGLE_REQUEST_TO_WITHDRAW_MODAL,
  STORE_PROGRAM_ID,
  GET_APPLICATION_STATUSES,
  GET_TRAVELER_FORM_SUBMISSION,
  RESET_TRAVELER_FORM_SUBMISSION,
  RESET_TRAVELER_APPLICATION_UPDATE,
  GET_TRAVELER_APPLICATION_SUBMISSION,
  GET_TRAVELER_APPLICATION_SUBMISSIONS,
  RESET_TRAVELER_APPLICATION_SUBMISSION,
  UPDATE_TRAVELER_APPLICATION_SUBMISSION,
} from './types';

export const getTravelerApplicationSubmissions = travelerId => async dispatch => {
  await fetch(`${keys.baseUri}/api/application_submissions?user_id=${travelerId}`, {
    method: 'GET',
    headers: token,
  })
    .then(res => {
      if (res.status === 200 || res.status === 201) {
        res.json().then(data => {
          dispatch({
            type: GET_TRAVELER_APPLICATION_SUBMISSIONS,
            payload: {
              data,
              error: false,
            },
          });
        });
      } else if (res.status === 403) {
        dispatch({
          type: GET_TRAVELER_APPLICATION_SUBMISSIONS,
          payload: {
            data: [],
            error: true,
          },
        });
      }
    })
    .catch(error => {
      dispatch({
        type: GET_TRAVELER_APPLICATION_SUBMISSIONS,
        payload: {
          data: [],
          error: true,
        },
      });
    });
};

export const getTravelerApplicationSubmission = submissionId => async dispatch => {
  dispatch({
    type: STORE_PROGRAM_ID,
    payload: {
      programId: submissionId,
    },
  });
  try {
    await fetch(`${keys.baseUri}/api/application_submissions/${submissionId}`, {
      method: 'GET',
      headers: token,
    }).then(res => {
      if (res.status === 200 || res.status === 201) {
        res.json().then(data => {
          dispatch({
            type: GET_TRAVELER_APPLICATION_SUBMISSION,
            payload: {
              ...data,
              error: false,
            },
          });
        });
      } else if (res.status === 401) {
        dispatch({
          type: GET_TRAVELER_APPLICATION_SUBMISSION,
          payload: {
            data: null,
            error: true,
          },
        });
      }
    });
  } catch (error) {
    dispatch({
      type: GET_TRAVELER_APPLICATION_SUBMISSION,
      payload: {
        data: null,
        error: true,
      },
    });
  }
};

export function resetApplicationSubmission() {
  return function(dispatch) {
    dispatch({
      type: RESET_TRAVELER_APPLICATION_SUBMISSION,
    });
  };
}

export function getApplicationStatuses() {
  return async function(dispatch) {
    const response = await fetch(`${keys.baseUri}/api/submission_statuses?type=application`, {
      method: 'GET',
    });

    const { data } = await response.json();

    dispatch({
      type: GET_APPLICATION_STATUSES,
      payload: data,
    });
  };
}

export function updateApplicationSubmission({ submissionId, type = 'application', statusId = null, body = null }) {
  return async function(dispatch) {
    const forApplication = type.toLowerCase() === 'application';
    const endpoint = type.toLowerCase() === forApplication ? 'application_submissions' : 'form_submissions';

    const bodyData = body || {};
    const payload = statusId ? { submission: { submission_status_id: statusId }, ...bodyData } : bodyData;

    try {
      const response = await fetch(`${keys.baseUri}/api/${endpoint}/${submissionId}`, {
        method: 'PATCH',
        headers: token,
        body: JSON.stringify(payload),
      });

      const { data } = await response.json();

      dispatch({
        type: forApplication ? GET_TRAVELER_APPLICATION_SUBMISSION : GET_TRAVELER_FORM_SUBMISSION,
        payload: {
          data,
          error: false,
        },
      });

      dispatch({
        type: UPDATE_TRAVELER_APPLICATION_SUBMISSION,
        payload: {
          error: false,
        },
      });

      dispatch({
        type: RESET_TRAVELER_APPLICATION_UPDATE,
      });
    } catch (error) {
      dispatch({
        type: UPDATE_TRAVELER_APPLICATION_SUBMISSION,
        payload: {
          error: true,
        },
      });
    }
  };
}

export function getTravelerFormSubmission(submissionId) {
  return async function(dispatch) {
    try {
      const response = await fetch(`${keys.baseUri}/api/form_submissions/${submissionId}`, {
        method: 'GET',
        headers: token,
      });

      const { data } = await response.json();

      dispatch({
        type: GET_TRAVELER_FORM_SUBMISSION,
        payload: {
          data,
          error: false,
        },
      });
    } catch (error) {
      dispatch({
        type: GET_TRAVELER_FORM_SUBMISSION,
        payload: {
          data: null,
          error: true,
        },
      });
    }
  };
}

export function resetFormSubmission() {
  return function(dispatch) {
    dispatch({
      type: RESET_TRAVELER_FORM_SUBMISSION,
    });
  };
}

export function toggleCommitModal() {
  return function(dispatch) {
    dispatch({
      type: TOGGLE_COMMIT_MODAL,
    });
  };
}

export function toggleWithdrawModal() {
  return function(dispatch) {
    dispatch({
      type: TOGGLE_WITHDRAW_MODAL,
    });
  };
}

export function toggleDeferModal() {
  return function(dispatch) {
    dispatch({
      type: TOGGLE_DEFER_MODAL,
    });
  };
}

export function toggleRequestToWithdrawModal() {
  return function(dispatch) {
    dispatch({
      type: TOGGLE_REQUEST_TO_WITHDRAW_MODAL,
    });
  };
}
