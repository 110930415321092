import fetch from 'node-fetch';
import _clone from 'lodash/clone';

import { keys } from '../../../config/keys';
import token from '../../../shared/helpers/token';
import constructUpdatePayload from '../utils/constructUpdatePayload';

import {
  GET_CURRENT_USER,
  GET_RACE_OPTIONS,
  UPDATE_TRAVELER_INFO,
  GET_GENDER_IDENTITIES,
  GET_NOTIFICATION_TYPES,
  GET_TRAVELER_PROFILE_DATA,
  GET_NOTIFICATION_OPT_OUTS,
} from './types';
import {
  SELECT_USER_ROLE_CLEAR,
  SELECT_USER_ROLE_FAILURE,
  SELECT_USER_ROLE_REQUEST,
  SELECT_USER_ROLE_SUCCESS,
} from '../../../actions/types';

export function getCurrentUser(userId) {
  if (!userId) {
    window.location.assign('/');

    return;
  }

  return async function(dispatch) {
    const response = await fetch(`${keys.baseUri}/api/traveler/users/${userId}/get_current_user`, {
      method: 'GET',
      headers: token,
    });

    if ([401, 403].includes(response.status)) {
      window.location.assign('/clear_cookies');

      return;
    }

    const data = await response.json();

    // if (data?.user?.tap_agreement === false && !window.location.href.includes('/terms')) {
    //   window.location.assign('/terms');
    // }

    dispatch({
      type: GET_CURRENT_USER,
      payload: data,
    });
  };
}

export function getTravelerProfile(userId, userType) {
  if (!userId) {
    window.location.assign('/');

    return;
  }

  return async function(dispatch) {
    const response = await fetch(`${keys.baseUri}/api/${userType}/traveler_profiles/${userId}`, {
      headers: token,
    });

    const data = await response.json();

    dispatch({
      type: GET_TRAVELER_PROFILE_DATA,
      payload: data[0],
    });
  };
}

export function getGenderIdentities(userId) {
  if (!userId) {
    window.location.assign('/');

    return;
  }

  return async function(dispatch) {
    const response = await fetch(`${keys.baseUri}/api/gender_identities`, {
      headers: token,
    });

    const { data } = await response.json();

    dispatch({
      type: GET_GENDER_IDENTITIES,
      payload: data,
    });
  };
}

export function getRaceOptions(userId) {
  if (!userId) {
    window.location.assign('/');

    return;
  }

  return async function(dispatch) {
    const response = await fetch(`${keys.baseUri}/api/race_options`, {
      headers: token,
    });

    const data = await response.json();

    dispatch({
      type: GET_RACE_OPTIONS,
      payload: data,
    });
  };
}

export function updateTravelerProfile(userId, payload, callback) {
  if (!userId) {
    window.location.assign('/');
    return;
  }

  return async function(dispatch, getState) {
    const state = getState().travelerProfile;

    const response = await fetch(`${keys.baseUri}/api/traveler/traveler_profiles/${userId}`, {
      method: 'PUT',
      headers: token,
      body: JSON.stringify(constructUpdatePayload(state, payload)),
    });

    const data = await response.json();

    dispatch({
      type: GET_TRAVELER_PROFILE_DATA,
      payload: data[0],
    });
  };
}

export function updateNameAndIdentity(userId, payload, callback) {
  if (!userId) {
    window.location.assign('/');
    return;
  }

  return async function(dispatch, getState) {
    const state = getState().travelerProfile;
    await fetch(`${keys.baseUri}/api/traveler/traveler_profiles/${userId}`, {
      method: 'PUT',
      headers: token,
      body: JSON.stringify(constructUpdatePayload(state, payload)),
    })
      .then(response => {
        if (response.status === 200) {
          response.json().then(data => {
            if (callback && typeof callback === 'function') {
              callback(true, `Profile Updated`);
            }
            dispatch({
              type: GET_TRAVELER_PROFILE_DATA,
              payload: data[0],
            });
          });
        } else {
          response.json().then(message => {
            if (callback && typeof callback === 'function') {
              callback(false, message.error);
            }
          });
        }
      })
      .catch(error => {
        if (callback && typeof callback === 'function') {
          callback(false, 'Something went wrong, please try again later');
        }
      });
  };
}

export function updateTravelerInfo(userId, payload) {
  if (!userId) {
    window.location.assign('/');

    return;
  }

  const clonedToken = _clone(token);

  delete clonedToken['Accept'];
  delete clonedToken['Content-Type'];

  return async function(dispatch) {
    const response = await fetch(`${keys.baseUri}/api/traveler/users/${userId}/update_user_info`, {
      method: 'PUT',
      headers: clonedToken,
      body: payload,
    });

    const data = await response.json();

    dispatch({
      type: UPDATE_TRAVELER_INFO,
      payload: data.profile,
    });
  };
}

export function getNotificationTypes() {
  return async function(dispatch) {
    const response = await fetch(`${keys.baseUri}/api/send_grid_mailers`, {
      method: 'GET',
      headers: token,
    });

    const { data } = await response.json();

    dispatch({
      type: GET_NOTIFICATION_TYPES,
      payload: data,
    });
  };
}

export function getNotificationOptOuts() {
  return async function(dispatch) {
    const response = await fetch(`${keys.baseUri}/api/mailer_opt_outs`, {
      method: 'GET',
      headers: token,
    });

    const { data } = await response.json();

    dispatch({
      type: GET_NOTIFICATION_OPT_OUTS,
      payload: data,
    });
  };
}

export function updateNotificationOptOut(payload) {
  return async function(dispatch) {
    await fetch(`${keys.baseUri}/api/mailer_opt_outs`, {
      method: 'POST',
      headers: token,
      body: JSON.stringify(payload),
    });

    dispatch(getNotificationOptOuts());
  };
}

export function updateTravelerPassword(payload, callback) {
  return async function() {
    await fetch(`${keys.baseUri}/api/password/update`, {
      method: 'POST',
      headers: token,
      body: JSON.stringify(payload),
    })
      .then(response => {
        if (response.status === 200) {
          response.json().then(data => {
            if (callback && typeof callback === 'function') {
              callback(true, `Password updated`);
            }
          });
        } else {
          response.json().then(message => {
            if (callback && typeof callback === 'function') {
              callback(false, message.error);
            }
          });
        }
      })
      .catch(error => {
        if (callback && typeof callback === 'function') {
          callback(false, 'Something went wrong, please try again later');
        }
      });
  };
}

export function switchCurrentRole(payload) {
  return function(dispatch) {
    dispatch({ type: SELECT_USER_ROLE_REQUEST });

    fetch(`${keys.baseUri}/api/users/change_role`, {
      method: 'PATCH',
      headers: token,
      body: JSON.stringify({ user: { admin_sign_in: payload } }),
    })
      .then(res => {
        if (res.status === 200 || res.status === 422) {
          res.json().then(data => {
            dispatch({
              type: SELECT_USER_ROLE_SUCCESS,
              payload: data,
            });
          });
        } else {
          res.json().then(message => {
            dispatch({
              type: SELECT_USER_ROLE_FAILURE,
              payload: message,
            });
          });
        }

        dispatch({
          type: SELECT_USER_ROLE_CLEAR,
        });
      })
      .catch(error => {
        dispatch({
          type: SELECT_USER_ROLE_FAILURE,
          payload: error.toString(),
        });
        dispatch({
          type: SELECT_USER_ROLE_CLEAR,
        });
      });
  };
}
