import fetch from 'node-fetch';
import token from '../shared/helpers/token';
import { keys } from '../config/keys';
import {
  FETCH_CONTINENTS,
  FETCH_TIMEZONES,
  FETCH_COUNTRIES,
  FETCH_ACTIVITY_TYPES,
  FETCH_HOUSING_TYPES,
  FETCH_TRANSPORTATION_TYPES,
  CLEAR_DETAIL_TYPES,
  FETCH_TRIP_TYPES,
  FETCH_PLAN_TYPES,
  FETCH_SAFECHECK_MESSAGE_TYPES,
  FETCH_PLAN_STATUS_TYPES,
  FETCH_REGISTRATION_STATUS_TYPES,
  FETCH_SUBJECT_AREAS,
  FETCH_PROGRAM_TYPES,
  FETCH_LANGUAGES,
  FETCH_PROGRAM_HOUSING_TYPES,
  FETCH_PROGRAM_CLIENT_ADMINS,
  FETCH_PROVIDER_CLIENT_ACCOUNTS,
  FETCH_RESIDENCY_STATUSES,
  FETCH_PASSPORT_STATUSES,
  FETCH_TRAVELER_TYPES,
  FETCH_YEAR_SCHOOL,
  FETCH_RACE_OPTIONS,
  FETCH_APPLICATION_STATUS_OPTIONS,
  FETCH_TRACKING_STEP_OPTIONS,
  FETCH_PROGRAM_PAYMENT_TYPES,
  FETCH_TRAVELER_BARRIERS_TYPES,
  FETCH_TRAVELER_CERTAINTY_TYPES,
  FETCH_TRAVELER_GOALS_TYPES,
  FETCH_TEMPLATES,
  FETCH_SUBMISSION_CONTENTS,
  FETCH_TEMPLATE_QUESTIONS,
} from './types';

export function fetchContinents() {
  return function(dispatch) {
    fetch(`${keys.baseUri}/api/continents`, {
      method: 'GET',
      headers: token,
    })
      .then(res => res.json())
      .then(data => {
        dispatch({
          type: FETCH_CONTINENTS,
          payload: data,
        });
      });
  };
}

export function fetchTimezones() {
  return function(dispatch) {
    fetch(`${keys.baseUri}/api/tp_time_zones`, {
      method: 'GET',
      headers: token,
    })
      .then(res => res.json())
      .then(data => {
        dispatch({
          type: FETCH_TIMEZONES,
          payload: data,
        });
      });
  };
}

export function fetchCountries() {
  return function(dispatch) {
    fetch(`${keys.baseUri}/api/via_countries`, {
      method: 'GET',
      headers: token,
    })
      .then(res => res.json())
      .then(data => {
        dispatch({
          type: FETCH_COUNTRIES,
          payload: data,
        });
      });
  };
}

export function fetchActivityTypes() {
  return function(dispatch) {
    fetch(`${keys.baseUri}/api/tp_activity_types`, {
      method: 'GET',
      headers: token,
    })
      .then(res => res.json())
      .then(data => {
        dispatch({
          type: FETCH_ACTIVITY_TYPES,
          payload: data,
        });
      });
  };
}

export function fetchHousingTypes() {
  return function(dispatch) {
    fetch(`${keys.baseUri}/api/tp_housing_types`, {
      method: 'GET',
      headers: token,
    })
      .then(res => res.json())
      .then(data => {
        dispatch({
          type: FETCH_HOUSING_TYPES,
          payload: data,
        });
      });
  };
}

export function fetchTransportationTypes() {
  return function(dispatch) {
    fetch(`${keys.baseUri}/api/tp_transportation_types`, {
      method: 'GET',
      headers: token,
    })
      .then(res => res.json())
      .then(data => {
        dispatch({
          type: FETCH_TRANSPORTATION_TYPES,
          payload: data,
        });
      });
  };
}

export function clearDetailTypes() {
  return function(dispatch) {
    dispatch({
      type: CLEAR_DETAIL_TYPES,
    });
  };
}

export function fetchTripTypes() {
  return function(dispatch) {
    fetch(`${keys.baseUri}/api/tp_trip_types`, {
      method: 'GET',
      headers: token,
    })
      .then(res => res.json())
      .then(data => {
        dispatch({
          type: FETCH_TRIP_TYPES,
          payload: data,
        });
      });
  };
}

export function fetchPlanTypes() {
  return function(dispatch) {
    fetch(`${keys.baseUri}/api/plan_types`, {
      method: 'GET',
      headers: token,
    })
      .then(res => res.json())
      .then(data => {
        dispatch({
          type: FETCH_PLAN_TYPES,
          payload: data,
        });
      });
  };
}

export function fetchSafecheckMessageTypes() {
  return function(dispatch) {
    fetch(`${keys.baseUri}/api/safe_check_message_types`, {
      method: 'GET',
      headers: token,
    })
      .then(res => res.json())
      .then(data => {
        dispatch({
          type: FETCH_SAFECHECK_MESSAGE_TYPES,
          payload: data,
        });
      });
  };
}

export function fetchPlanStatusTypes() {
  return function(dispatch) {
    fetch(`${keys.baseUri}/api/plan_statuses`, {
      method: 'GET',
      headers: token,
    })
      .then(res => res.json())
      .then(data => {
        dispatch({
          type: FETCH_PLAN_STATUS_TYPES,
          payload: data,
        });
      });
  };
}

export function fetchRegistrationStatusTypes() {
  return function(dispatch) {
    fetch(`${keys.baseUri}/api/plan_registration_statuses`, {
      method: 'GET',
      headers: token,
    })
      .then(res => res.json())
      .then(data => {
        dispatch({
          type: FETCH_REGISTRATION_STATUS_TYPES,
          payload: data,
        });
      });
  };
}

export function fetchSubjectAreas() {
  return function(dispatch) {
    fetch(`${keys.baseUri}/api/subject_areas`, {
      method: 'GET',
      headers: token,
    })
      .then(res => res.json())
      .then(data => {
        dispatch({
          type: FETCH_SUBJECT_AREAS,
          payload: data,
        });
      });
  };
}

export function fetchProgramTypes() {
  return function(dispatch) {
    fetch(`${keys.baseUri}/api/program_types`, {
      method: 'GET',
      headers: token,
    })
      .then(res => res.json())
      .then(data => {
        dispatch({
          type: FETCH_PROGRAM_TYPES,
          payload: data,
        });
      });
  };
}

export function fetchLanguages() {
  return function(dispatch) {
    fetch(`${keys.baseUri}/api/languages`, {
      method: 'GET',
      headers: token,
    })
      .then(res => res.json())
      .then(data => {
        dispatch({
          type: FETCH_LANGUAGES,
          payload: data,
        });
      });
  };
}

export function fetchProgramHousingTypes() {
  return function(dispatch) {
    fetch(`${keys.baseUri}/api/housing_types`, {
      method: 'GET',
      headers: token,
    })
      .then(res => res.json())
      .then(data => {
        dispatch({
          type: FETCH_PROGRAM_HOUSING_TYPES,
          payload: data,
        });
      });
  };
}

export function fetchClientAdmins() {
  return function(dispatch) {
    fetch(`${keys.baseUri}/api/client/admins`, {
      method: 'GET',
      headers: token,
    })
      .then(res => res.json())
      .then(data => {
        dispatch({
          type: FETCH_PROGRAM_CLIENT_ADMINS,
          payload: data,
        });
      });
  };
}

export function fetchProviderClientAccounts() {
  return function(dispatch) {
    fetch(`${keys.baseUri}/api/client_accounts?provider=true`, {
      method: 'GET',
      headers: token,
    })
      .then(res => res.json())
      .then(data => {
        dispatch({
          type: FETCH_PROVIDER_CLIENT_ACCOUNTS,
          payload: data,
        });
      });
  };
}

export function fetchResidencyStatuses() {
  return function(dispatch) {
    fetch(`${keys.baseUri}/api/residency_options`, {
      method: 'GET',
      headers: token,
    })
      .then(res => res.json())
      .then(data => {
        dispatch({
          type: FETCH_RESIDENCY_STATUSES,
          payload: data,
        });
      });
  };
}

export function fetchPassportStatuses() {
  return function(dispatch) {
    const options = ['Current', 'Expired', 'Do Not Know', 'Do Not Have'];
    dispatch({
      type: FETCH_PASSPORT_STATUSES,
      payload: options,
    });
  };
}

export function fetchTravelerTypes() {
  return function(dispatch) {
    fetch(`${keys.baseUri}/api/traveler_type_options`, {
      method: 'GET',
      headers: token,
    })
      .then(res => res.json())
      .then(data => {
        dispatch({
          type: FETCH_TRAVELER_TYPES,
          payload: data,
        });
      });
  };
}

export function fetchYearSchool() {
  return function(dispatch) {
    fetch(`${keys.baseUri}/api/year_in_school_options`, {
      method: 'GET',
      headers: token,
    })
      .then(res => res.json())
      .then(data => {
        dispatch({
          type: FETCH_YEAR_SCHOOL,
          payload: data,
        });
      });
  };
}

export function fetchRaceOptions() {
  return function(dispatch) {
    fetch(`${keys.baseUri}/api/race_options`, {
      method: 'GET',
      headers: token,
    })
      .then(res => res.json())
      .then(data => {
        dispatch({
          type: FETCH_RACE_OPTIONS,
          payload: data,
        });
      });
  };
}

export function fetchApplicationStatusOptions() {
  return function(dispatch) {
    fetch(`${keys.baseUri}/api/application_status_options`, {
      method: 'GET',
      headers: token,
    })
      .then(res => res.json())
      .then(data => {
        dispatch({
          type: FETCH_APPLICATION_STATUS_OPTIONS,
          payload: data,
        });
      });
  };
}

export function fetchTrackingStepOptions() {
  return function(dispatch) {
    fetch(`${keys.baseUri}/api/tracking_step_options`, {
      method: 'GET',
      headers: token,
    })
      .then(res => res.json())
      .then(data => {
        dispatch({
          type: FETCH_TRACKING_STEP_OPTIONS,
          payload: data,
        });
      });
  };
}

export function fetchProgramPaymentOptionTypes() {
  return function(dispatch) {
    fetch(`${keys.baseUri}/api/program_payment_options`, {
      method: 'GET',
      headers: token,
    })
      .then(res => res.json())
      .then(data => {
        dispatch({
          type: FETCH_PROGRAM_PAYMENT_TYPES,
          payload: data,
        });
      });
  };
}

export function fetchTravelerBarrierOptionTypes() {
  return function(dispatch) {
    fetch(`${keys.baseUri}/api/participation_barrier_options`, {
      method: 'GET',
      headers: token,
    })
      .then(res => res.json())
      .then(data => {
        dispatch({
          type: FETCH_TRAVELER_BARRIERS_TYPES,
          payload: data,
        });
      });
  };
}

export function fetchTravelerCertaintyOptionTypes() {
  return function(dispatch) {
    fetch(`${keys.baseUri}/api/travel_certainty_options`, {
      method: 'GET',
      headers: token,
    })
      .then(res => res.json())
      .then(data => {
        dispatch({
          type: FETCH_TRAVELER_CERTAINTY_TYPES,
          payload: data,
        });
      });
  };
}

export function fetchTravelerGoalsOptionTypes() {
  return function(dispatch) {
    fetch(`${keys.baseUri}/api/travel_goal_options`, {
      method: 'GET',
      headers: token,
    })
      .then(res => res.json())
      .then(data => {
        dispatch({
          type: FETCH_TRAVELER_GOALS_TYPES,
          payload: data,
        });
      });
  };
}

export function fetchTemplatesThin(type,template_status=null, callback = null) {
  return function(dispatch) {
    let url = `${keys.baseUri}/api/form_templates/thin?template_type=${type}`;
    if(template_status) {
      url+=`&template_status=${template_status}`
    }
    fetch(`${url}`, {
      method: 'GET',
      headers: token,
    })
      .then(res => res.json())
      .then(data => {

        if(callback && typeof callback === "function"){
          callback(true, data); 
        }

        dispatch({
          type: FETCH_SUBMISSION_CONTENTS,
          payload: data,
        });
      });
  };
}

export function fetchTemplatesQuestions(type, id, callback = null) {
  return function(dispatch) {
    fetch(`${keys.baseUri}/api/form_templates/fields?template_type=${type}&id=${id}`, {
      method: 'GET',
      headers: token,
    })
      .then(res => res.json())
      .then(data => {
        if (callback && typeof callback === 'function') {
          callback(true, 'Data fetched', data.data);
        }
      });
  };
}
