import React, { useState, useEffect, useRef } from 'react';
import styled, { css } from 'styled-components';
import { compose } from 'recompose';
import Tooltip from './Tooltip.styled';
import StyledIcon from '../styles/Icon.styled';
import { stripHtmlString } from '../../shared/helpers/General';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import ReactQuill, { Quill } from 'react-quill';
import wsy from 'react-quill/dist/quill.snow.css';
export const enhance = compose(withStyles(wsy));

const InputGroup = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

const InputInnerGroup = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

const InputLabel = styled.div`
  color: ${({ theme }) => theme.editTextArea.label.fontColor};
  font-family: ${({ theme }) => theme.editTextArea.label.fontFamily};
  font-size: ${({ theme }) => theme.editTextArea.label.fontSize};
  font-weight: 600;
`;

const Instructions = styled.div`
  color: ${({ theme }) => theme.textInput.instructions.fontColor};
  font-family: ${({ theme }) => theme.textInput.instructions.fontFamily};
  font-size: ${({ theme }) => theme.textInput.instructions.fontSize};
  font-weight: ${({ theme }) => theme.textInput.instructions.fontWeight};
  padding-left: 5px;
  overflow-wrap: anywhere;
  ${({ openSans }) => (openSans ? openSansFont : null)};
  line-height: normal;
  p {
    margin-bottom: 0px;
  }
  ol,
  ul {
    // white-space: pre-wrap;
  }
`;

const Error = styled.span`
  color: ${({ theme }) => theme.editTextArea.error.fontColor};
  font-family: ${({ theme }) => theme.editTextArea.error.fontFamily};
  font-size: ${({ theme }) => theme.editTextArea.error.fontSize};

  svg {
    justify-content: center;
    margin-right: 5px;
  }
`;

const IconDiv = styled.div`
  color: ${({ theme }) => theme.editTextArea.disabled.fontColor};
  position: relative;
  right: 28px;
  top: 9px;
  width: 0;
`;

const openSansFont = css`
  font-family: ${({ theme }) => theme.textInput.fontFamilyOpenSans};
`;

const Required = styled.span`
  color: ${({ theme }) => theme.defaults.errorColor};
  padding-left: 5px;
`;

const TooltipContainer = styled.span`
  padding-left: 5px;
`;

const Wrapper = styled.div`
  width: 100%;
  background-color: ${props =>
    props.readOnly ? ({ theme }) => theme.editTextArea.disabled.backgroundColor : '#ffffff'};
  border: ${props =>
    props.hasFocus
      ? ({ theme }) => theme.editTextArea.focus.border
      : props.errorMsg && props.errorMsg.length > 0
      ? ({ theme }) => theme.editTextArea.error.border
      : props.readOnly
      ? ({ theme }) => theme.editTextArea.disabled.border
      : ({ theme }) => theme.editTextArea.border};
  color: ${props =>
    props.readOnly
      ? ({ theme }) => theme.editTextArea.disabled.fontColor
      : ({ theme }) => theme.editTextArea.fontColor};
  cursor: ${props => (props.readOnly ? 'not-allowed' : 'pointer')};
  font-family: ${({ theme }) => theme.editTextArea.fontFamily};

  /* Rich text editor css */
  .ql-editor {
    height: 100px;
    resize: vertical;
    overflow: auto;
    overflow-wrap: anywhere;
    /* Add more custom styles as needed */
  }

`;

const CharLimit = styled.div`
  color: #555555;
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
`;

const CustomToolbar = ({id}) => (
  <div id={`toolbar_${id}`}>
    <button type="button" className="ql-bold" aria-label="bold"></button>
    <button type="button" className="ql-italic" aria-label="italic"></button>
    <button type="button" className="ql-underline" aria-label="underline"></button>
    <button type="button" className="ql-strike" aria-label="strike"></button>
    <button type="button" className="ql-list" value="ordered" aria-label="ordered list"></button>
    <button type="button" className="ql-list" value="bullet" aria-label="bullet list"></button>
    <button type="button" className="ql-link" aria-label="link"></button>
  </div>
);

function EditTextAreaQuill(props) {
  const [hasFocus, setHasFocus] = useState(false);
  const [openSansFont, setOpenSansFont] = useState(props.openSans);
  const [editorHtml, setEditorHtml] = useState(props.value);
  const [editorTextValue, setEditorTextValue] = useState('');
  const [textLength, setTextLength] = useState(0);
  const quillRef = useRef();
  let cleanseInstructions = null;
  if (props.instructions && stripHtmlString(props.instructions).trim().length > 0) {
    const updatedIntro = !!props.instructions ? props.instructions.replace(/<p><\/p>/g, '<br />') : '';
    cleanseInstructions = updatedIntro;
  }

  const handleChange = (content, delta, source, editor) => {
    if (content === '<p><br></p>') {
      content = content.replace('<p><br></p>', '');
      setEditorHtml(content);
      setEditorTextValue('');
      setTextLength(0);
    } else {
      setEditorHtml(content);
      setEditorTextValue(editor.getText());
    }
    props.onChange(content);
  };

  useEffect(() => {
    setEditorHtml(props?.value);
  },[props?.value])  

  useEffect(() => {
    const editor = quillRef.current.getEditor();

    //Removed tab key binding because it should work like default browser behaviour
    const keyboard = quillRef.current.getEditor().getModule('keyboard');
    delete keyboard.bindings[9];

    setTimeout(() => {
      setEditorTextValue(editor.getText());
    }, 0);
  }, []);
  const checkCharacterCount = event => {
    const unprivilegedEditor = quillRef.current.unprivilegedEditor;
    setTextLength(unprivilegedEditor.getLength());
    if(props.charLimit>0) {
      if (unprivilegedEditor.getLength() > props.charLimit && event.key !== 'Backspace') {
        event.preventDefault();
      }
    }
  };
  const formats = [
    'header',
    'font',
    'size',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'indent',
    'link',
    'image',
    'color',
  ];

  const modules = {
    toolbar: {
      container: `#toolbar_${props.id}`,
    },
  };

  return (
    <InputGroup>
      <InputLabel id={`label-longtext-${props.id}`}>
        {props.label}
        {props.required && <Required>*</Required>}
        {props.tooltip && (
          <TooltipContainer>
            <Tooltip toolTipText={props.tooltip}>
              <StyledIcon type={'InformationCircle'} size={'14px'} />
            </Tooltip>
          </TooltipContainer>
        )}
      </InputLabel>

      {cleanseInstructions && cleanseInstructions.length > 0 && (
        <Instructions
          openSans={openSansFont}
          dangerouslySetInnerHTML={{
            __html: cleanseInstructions,
          }}
        />
      )}
      <InputInnerGroup>
        <Wrapper errorMsg={props.errorMsg} hasFocus={hasFocus} readOnly={props.readOnly}>
          <CustomToolbar id={props.id} />
          <ReactQuill
            theme="snow"
            className={`customEditor`}
            onKeyDown={checkCharacterCount}
            ref={quillRef}
            defaultValue={editorHtml}
            value={editorHtml}
            modules={modules}
            formats={formats}
            readOnly={props.readOnly}
            onChange={handleChange}
          />
        </Wrapper>
        {props.icon && props.icon.length > 0 && (
          <IconDiv>
            <StyledIcon type={props.icon} size={'16px'} />
          </IconDiv>
        )}
      </InputInnerGroup>

      <div aria-live="polite">
      {!!props.charLimit && parseInt(props.charLimit) > 0 && (
        <CharLimit>{props.charLimit - textLength} Characters Remaining</CharLimit>
      )}
      </div>

      {props.errorMsg && props.errorMsg.length > 0 && (
        <Error>
          <StyledIcon type={'Warning'} size={'16px'} />
          {props.errorMsg}
        </Error>
      )}
    </InputGroup>
  );
}

export default enhance(EditTextAreaQuill);
