import { compose } from 'redux';
import PropTypes from 'prop-types';
import { ThemeProvider } from 'styled-components';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import withStyles from 'isomorphic-style-loader/lib/withStyles';

import LoadingScreen from  '../../../styledComponents/styles/LoadingScreen';
import token from '../../../shared/helpers/token';
import userId from '../../../shared/helpers/userId';
import loadedFonts from '../../../shared/fonts/loadFonts.css';
import { themeVia } from '../../../styledComponents/settings/theme';
import { useThemeColors } from '../../../shared/hooks/useThemeColors';
import GlobalStyles from '../../../styledComponents/styles/GlobalStyles';
import { getCurrentOrganization } from '../actions/currentOrganizationActions';
import { getCurrentUser, getTravelerProfile } from '../actions/travelerProfileActions';
import {
  resetFormSubmission,
  getTravelerFormSubmission,
  resetApplicationSubmission,
  getTravelerApplicationSubmission,
} from '../actions/travelerApplicationsActions';

function Layout({ children, submissionId, formId }) {
  const dispatch = useDispatch();
  const isBrandingSet = useThemeColors();
  const { travelerProfileLoading } = useSelector(state => state.travelerProfile);
  const { currentOrganizationLoading } = useSelector(state => state.currentOrganization);
  const { formSubmissionLoading, applicationSubmissionLoading } = useSelector(
    state => state.travelerApplicationSubmissions,
  );
  
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    dispatch(resetApplicationSubmission());
    dispatch(resetFormSubmission());

    if (!userId) {
      window.location.assign('/clear_cookies');
    }

    if (token && userId) {
      dispatch(getTravelerProfile(userId, 'traveler'));
      dispatch(getCurrentOrganization());
      dispatch(getCurrentUser(userId));
      dispatch(getTravelerApplicationSubmission(submissionId));
      dispatch(getTravelerFormSubmission(formId));
    }
  }, []);

  useEffect(
    () => {
      const authLoadingCheck = token && userId;
      const baseInfoLoadingCheck =
        travelerProfileLoading || currentOrganizationLoading || applicationSubmissionLoading || formSubmissionLoading;

      setLoading(authLoadingCheck ? baseInfoLoadingCheck : false);
    },
    [travelerProfileLoading, currentOrganizationLoading, applicationSubmissionLoading, formSubmissionLoading],
  );

  return (
    <ThemeProvider theme={themeVia}>
      <GlobalStyles />
      <div>{isBrandingSet && !loading ? children : <LoadingScreen displayText='Loading...' />}</div>
    </ThemeProvider>
  );
}

export default compose(withStyles(loadedFonts))(Layout);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  formId: PropTypes.string.isRequired,
  submissionId: PropTypes.string.isRequired,
};
