import React from 'react';
import Auxiliary from '../../hoc/Auxiliary';
import moment from 'moment-timezone';

export const FullDateFormat = props => {
  return (
    <Auxiliary>
      {moment(props.startDate).format('MMM[.] DD[,] YYYY')} - {props.addBr ? <br /> : null}
      {moment(props.endDate).format('MMM[.] DD[,] YYYY')}
    </Auxiliary>
  );
};
