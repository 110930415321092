import fetch from 'node-fetch';
import token from '../shared/helpers/token';
import { keys } from '../config/keys';
import {
  BATCH_INVITE_TRAVELERS_DASHBOARD,
  INVITE_SINGLE_TRAVELER_DASHBOARD,
  CLEAR_BATCH_INVITATIONS_MESSAGE,
  CLEAR_INVITATIONS_MESSAGE,
} from './types';

export function inviteSingleTraveler(user) {
  return function(dispatch) {
    fetch(`${keys.baseUri}/api/client/traveler_invitations/invite_single`, {
      method: 'POST',
      headers: token,
      body: JSON.stringify({
        traveler_invitation: {
          email: user.email,
          first_name: user.first_name,
          last_name: user.last_name,
        },
      }),
    })
      .then(res => res.json())
      .then(res => {
        dispatch({
          type: INVITE_SINGLE_TRAVELER_DASHBOARD,
          code: res.status,
          message: res.message,
        });
        dispatch(clearBatchInvitationsMessage());
      })
      .catch(error =>
        dispatch({
          type: INVITE_SINGLE_TRAVELER_DASHBOARD,
          code: 400,
          message: error.message,
        }),
      );
  };
}

export function batchInviteTravelers(file) {
  return function(dispatch) {
    fetch(`${keys.baseUri}/api/client/traveler_invitations/batch_invite`, {
      method: 'POST',
      headers: token,
      body: JSON.stringify({ file }),
    })
      .then(res => res.json())
      .then(res => {
        dispatch({
          type: BATCH_INVITE_TRAVELERS_DASHBOARD,
          code: res.status,
          message: res.message,
        });
        dispatch(clearInvitationsMessage());
      });
  };
}

export const clearBatchInvitationsMessage = () => dispatch => {
  dispatch({
    type: CLEAR_BATCH_INVITATIONS_MESSAGE,
  });
};

export const clearInvitationsMessage = () => dispatch => {
  dispatch({
    type: CLEAR_INVITATIONS_MESSAGE,
  });
};
