import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { PropTypes } from 'prop-types';
import moment from 'moment-timezone';
import { ButtonMediumNunito, AccentButtonMediumNunito } from '../../../../../styledComponents/styles/Buttons.styled';
import { getAvailableForms, addFormsToTravelerProfile } from '../../../../../actions/formsActions';
import Dropdown from '../../../../../styledComponents/styles/DropdownSelect.styled';
import GenericStyledModal from './GenericStyledModal.modal';

const Container = styled.div``;

const Content = styled.div`
  padding-top: 25px;
`;

const DropdownContainer = styled.div`
  margin: 1rem 4rem;
`;
const DeadlineContainer = styled.div`
  display: flex;
  margin-left: 4rem;
  margin-right: 4rem;
  gap: 20px;
`;
const DateContainer = styled.div`
  width: 33%;
`;

const ParagraphWrapper = styled.div`
  margin-left: 45px;
  margin-right: 37px;
`;

const Paragraph = styled.p`
  color: #373737;
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  font-weight: 400;
`;

const ButtonContainer = styled.div`
  background: #ebebeb;
  display: flex;
  height: 111px;
  justify-content: flex-end;
  left: 0;
  margin-top: 43px;
  top: 273px;
  width: 100%;
  margin-bottom: -20px;
  border-radius: 0px 0px 15px 15px;
`;

const ButtonDiv = styled.div`
  margin-top: 30px;
  margin-right: 20px;
`;

const ErrorContainer = styled.div`
  margin-left : 4rem;
  margin-top : 4px;
  span {
    color: var(--error-text, #8B1313);
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: "Open Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 19.6px */
  }
`;

const AddApplicationTravelerProfileModal = props => {
  const dispatch = useDispatch();
  const availableForms = useSelector(state => state.forms.availableForms);
  const [formOptions, setFormOptions] = useState([]);
  const [selectedForms, setSelectedForms] = useState([]);
  const [selectedTerm, setSelectedTerm] = useState([]);
  const [filteredForms, setFilteredForms] = useState([]);
  const [years, setYear] = useState([]);
  const [days, setDays] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState([]);
  const [selectedDate, setSelectedDate] = useState([]);
  const [selectedYear, setSelectedYear] = useState("");
  const [dateError, setDateError] = useState(false);
  const customAliases = useSelector(state => state.profile.customAliases);

  //To set year dropdown
  useEffect(() => {
    const date = new Date();
    let year = date.getFullYear();
    const yearArray = [];

    let pastYear = year - 5;
    let futureYear = parseInt(year) + 10;

    for (let i = pastYear; i <= futureYear; i++) {
      let yearObj = { label: i, value: i };
      yearArray.push(yearObj);
    }
    setYear(yearArray);
  }, []);

  useEffect(() => {
    let daysArray = [];
    let maxDay = 31;
    if (selectedMonth?.value == '02') {
      maxDay = 29;
    } else if (['04', '06', '09', '11'].includes(selectedMonth?.value)) {
      maxDay = 30;
    }

    for (let i = 1; i <= maxDay; i++) {
      let day = i <= 9 ? `0${i}` : i;
      let dayObj = { label: day, value: day };
      daysArray.push(dayObj);
    }
    setDays(daysArray);
  }, [selectedMonth]);

  const monthsArray = [
    { label: 'Jan', value: '01', ariaLabel: 'January' },
    { label: 'Feb', value: '02', ariaLabel: 'February' },
    { label: 'Mar', value: '03', ariaLabel: 'March' },
    { label: 'Apr', value: '04', ariaLabel: 'April' },
    { label: 'May', value: '05', ariaLabel: 'May' },
    { label: 'Jun', value: '06', ariaLabel: 'June' },
    { label: 'Jul', value: '07', ariaLabel: 'July' },
    { label: 'Aug', value: '08', ariaLabel: 'August' },
    { label: 'Sep', value: '09', ariaLabel: 'September' },
    { label: 'Oct', value: '10', ariaLabel: 'October' },
    { label: 'Nov', value: '11', ariaLabel: 'November' },
    { label: 'Dec', value: '12', ariaLabel: 'December' },
  ];

  useEffect(() => {
    let unmounted = false;
    if (!unmounted) {
      dispatch(getAvailableForms(props.travelerId));
    }
    return () => {
      unmounted = true;
    };
  }, []);

  useEffect(() => {
    let formList = [];

    if (!!availableForms.data && availableForms?.data.length > 0) {
      formList = availableForms.data.map(range => {
        const term = range.program_range;

        return {
          value: term.program_id,
          label: `${term.program_title.trim()}; ${term?.term_name ? `${term?.term_name}: ` : ''}${
            term?.use_exact_dates
              ? `${moment(new Date(term.start_date)).format('MMM D[,] YYYY')}
                  - ${moment(new Date(term.end_date)).format('MMM D[,] YYYY')}`
              : `${moment(new Date(term.start_date)).format('MMM [] YYYY')}
                  - ${moment(new Date(term.end_date)).format('MMM [] YYYY')}`
          }`,
          available_forms: range.form_templates,
          program_range_id: term.id,
        };
      });
    }

    setFilteredForms(formList);
  }, [availableForms]);

  useEffect(() => {
    setFormOptions(
      ...filteredForms
        ?.filter(term => selectedTerm === term.program_range_id)
        .map(term =>
          term.available_forms.map(form => {
            return { label: form.name, value: form.id };
          }),
        ),
    );
  }, [selectedTerm]);

  const handleClose = () => {
    props.onClose();
    setSelectedForms([]);
    setSelectedTerm([]);
  };

  return (
    <GenericStyledModal
      header="Add General Forms"
      showing={props.show}
      width="520px"
      onClose={() => {
        handleClose();
      }}
    >
      <Container>
        <Content>
          <ParagraphWrapper>
            <Paragraph>
              Select the {customAliases.alias_program.toLowerCase()} term and general form(s) to add to{' '}
              {customAliases.alias_traveler.toLowerCase()}. If a {customAliases.alias_traveler.toLowerCase()} already
              has a form, a duplicate form will not be sent.
            </Paragraph>
          </ParagraphWrapper>

          <DropdownContainer>
            <Dropdown
              aria-label={`Select ${customAliases.alias_program.toLowerCase()} term `}
              placeholder={`Select ${customAliases.alias_program.toLowerCase()} term`}
              id="term-dropdown"
              openSans
              label={`${customAliases.alias_program} Term`}
              fontColor="#373737"
              isRequired
              required={true}
              options={filteredForms
                .filter(term => selectedTerm !== term.program_range_id)
                .map(item => {
                  return { value: item.program_range_id, label: item.label };
                })
                .sort((a, b) => a.label.localeCompare(b.label))}
              onChange={e => {
                setSelectedForms([]);
                setSelectedTerm(e);
              }}
              fontWeight={600}
            />
          </DropdownContainer>
          <DropdownContainer>
            <Dropdown
              aria-label="Select forms to add to traveler"
              id="form-dropdown"
              placeholder="Select forms"
              isDisabled={selectedTerm.length === 0}
              value={selectedForms.length === 0 ? [] : formOptions.filter(form => selectedForms.includes(form.value))}
              label="General Forms"
              openSans
              isMulti
              isRequired
              required={true}
              fontColor="#373737"
              options={formOptions
                ?.filter(item => !selectedForms.includes(item.value))
                .sort((a, b) => a.label.localeCompare(b.label))}
              onChange={e => setSelectedForms(e)}
              fontWeight={600}
            />
          </DropdownContainer>
          <DeadlineContainer>
            <DateContainer>
              <Dropdown
                aria-label="Month"
                placeholder="Month"
                openSans
                label="Month"
                fontColor="#373737"
                options={monthsArray}
                onChange={e => setSelectedMonth(e)}
                fontWeight={600}
              />
            </DateContainer>
            <DateContainer>
              <Dropdown
                aria-label="Date"
                placeholder="Day"
                openSans
                label="Day"
                fontColor="#373737"
                options={days}
                onChange={e => setSelectedDate(e)}
                fontWeight={600}
              />
            </DateContainer>
            <DateContainer>
              <Dropdown
                aria-label="Year"
                placeholder="Year"
                openSans
                label="Year"
                fontColor="#373737"
                options={years}
                onChange={e => setSelectedYear(e)}
                fontWeight={600}
              />
            </DateContainer>
          </DeadlineContainer>
          {dateError && 
            <ErrorContainer>
              <span>Deadline must have a month, day, and year</span>
            </ErrorContainer>
          }
        </Content>
        <ButtonContainer>
          <ButtonDiv>
            <ButtonMediumNunito
              onClick={() => {
                handleClose();
              }}
            >
              Cancel
            </ButtonMediumNunito>
          </ButtonDiv>
          <ButtonDiv>
            <AccentButtonMediumNunito
              disabled={selectedForms.length === 0}
              onClick={() => {
                let deadlineDate = '';
                
                if (selectedMonth!="" && selectedDate!=""  && selectedYear!="") {
                  deadlineDate = selectedYear + '-' + selectedMonth + '-' + selectedDate;
                }

                if (selectedMonth!="" || selectedDate!="" || selectedYear!="") {
                  if(deadlineDate==='') {
                    setDateError(true);
                    return;
                  }
                }
                setDateError(false);

                const values = selectedForms.map(form => {
                  return {
                    program_range_id: selectedTerm,
                    template_id: form,
                    user_id: props.travelerId,
                    deadline: deadlineDate,
                  };
                });

                dispatch(addFormsToTravelerProfile(values));
                handleClose();
              }}
            >
              Add
            </AccentButtonMediumNunito>
          </ButtonDiv>
        </ButtonContainer>
      </Container>
    </GenericStyledModal>
  );
};

AddApplicationTravelerProfileModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
};

export default AddApplicationTravelerProfileModal;
