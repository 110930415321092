import fetch from 'node-fetch';
import {
  GET_TERM_NAMES,
  GET_TERM_NAMES_ACTIVE,
  CREATE_TERM_NAME_REQUEST,
  CREATE_TERM_NAME_SUCCESS,
  CREATE_TERM_NAME_FAILURE,
  CREATE_TERM_NAME_CLEAR,
  EDIT_TERM_NAME_REQUEST,
  EDIT_TERM_NAME_SUCCESS,
  EDIT_TERM_NAME_FAILURE,
  EDIT_TERM_NAME_CLEAR,
  BATCH_ADD_TERM_NAME_AUTH,
  BATCH_ADD_TERM_NAME_AUTH_SUCCESS,
  BATCH_ADD_TERM_NAME_AUTH_CLEAR,
  BATCH_ADD_TERM_NAME_AUTH_FAILURE,
} from '../actions/types';
import { fetchProgramTerms } from '../actions/reportsActions';
import token from '../shared/helpers/token';
import { keys } from '../config/keys';

export function getTermNames(type = '',client_account_id="") {
  return function(dispatch) {
    let url = `${keys.baseUri}/api/term_names`;

    if (type) {
      url += `?active=${type}`;
    }
    if (client_account_id && !token) {
      if(type) {
        url += `&client_account_id=${client_account_id}`;
      }else{
        url += `?client_account_id=${client_account_id}`;
      }
    }
    fetch(url, {
      method: 'GET',
      headers: token,
    })
      .then(res => res.json())
      .then(data => {
        dispatch({
          type: GET_TERM_NAMES,
          payload: data,
        });
      });
  };
}

export function getTermNamesActive() {
  return function(dispatch) {
    let url = `${keys.baseUri}/api/term_names?active=true`;

    fetch(url, {
      method: 'GET',
      headers: token,
    })
      .then(res => res.json())
      .then(data => {
        dispatch({
          type: GET_TERM_NAMES_ACTIVE,
          payload: data,
        });
      });
  };
}

export function createTermName(name) {
  const saveData = JSON.stringify({ term_name: { name: name } });
  return function(dispatch) {
    dispatch({ type: CREATE_TERM_NAME_REQUEST });
    fetch(`${keys.baseUri}/api/term_names`, {
      method: 'POST',
      headers: token,
      body: saveData,
    })
      .then(res => res.json())
      .then(data => {
        dispatch({
          type: CREATE_TERM_NAME_SUCCESS,
          payload: { data: data },
        });
        dispatch({
          type: CREATE_TERM_NAME_CLEAR,
        });
      })
      .catch(error => {
        dispatch({
          type: CREATE_TERM_NAME_FAILURE,
          payload: error.toString(),
        });
      });
  };
}

export function editTermName(values) {
  const saveData = JSON.stringify({ term_name: values });

  return function(dispatch) {
    dispatch({ type: EDIT_TERM_NAME_REQUEST });
    fetch(`${keys.baseUri}/api/term_names/${values.id}`, {
      method: 'PATCH',
      headers: token,
      body: saveData,
    })
      .then(data => {
        dispatch({
          type: EDIT_TERM_NAME_SUCCESS,
          payload: { data: data },
        });
        dispatch({
          type: EDIT_TERM_NAME_CLEAR,
        });
      })
      .catch(error => {
        dispatch({
          type: EDIT_TERM_NAME_FAILURE,
          payload: error.toString(),
        });
      });
  };
}

// export function getTermNameData(type) {
//   return function(dispatch) {
//     fetch(`${keys.baseUri}/api/client/trm_templates.json?type=Trm${type}Template`, {
//       method: 'GET',
//       headers: token,
//     })
//       .then(res => res.json())
//       .then(data => {
//         dispatch({
//           type: GET_TERM_NAME_DATA,
//           payload: data,
//         });
//       });
//   };
// }

export function batchAddTermNameAuth(values) {
  return function(dispatch) {
    dispatch({ type: BATCH_ADD_TERM_NAME_AUTH });
    fetch(`${keys.baseUri}/api/program_range_batch_actions/add_suitcase`, {
      method: 'POST',
      headers: token,
      body: JSON.stringify(values),
    })
      .then(res => res.json())
      .then(data => {
        dispatch({
          type: BATCH_ADD_TERM_NAME_AUTH_SUCCESS,
          payload: { data: data },
        });
        dispatch({
          type: BATCH_ADD_TERM_NAME_AUTH_CLEAR,
        });
        dispatch(fetchProgramTerms('authorized'));
      })
      .catch(error => {
        dispatch({
          type: BATCH_ADD_TERM_NAME_AUTH_FAILURE,
          payload: error.toString(),
        });
      });
  };
}
