import React from 'react';
import styled from 'styled-components';
import _get from 'lodash/get';
import moment from 'moment-timezone';

import TextInput from '../../../../../styledComponents/styles/TextInput.styled';
import { Paragraph } from '../../../../../styledComponents/styles/Paragraph.styled';

const Container = styled.div`
  font-family: 'Nunito', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 120%;
  letter-spacing: 0.015em;
  color: #373737;
`;

const InputContainer = styled.div`
  flex: 1;
  margin: 13px 0;
  text-align: left;
`;

const SignatureDate = styled.span`
  padding-left: 10px;
  padding-top: 10px;
`;

const QuestionSignatureDisplayForm = ({ question, formState, errors, response, ...rest }) => {
  const id = question.question_id.toString();
  // eslint-disable-next-line no-extra-boolean-cast
  const signatureDate = !!response.signed_at ? moment(response.signed_at).format('MMM D, YYYY[ at ]h:mm:ss A') : null;
  return (
    <Container>
      <InputContainer>
        <TextInput
          aria-label={question.label}
          id={id}
          instructions={question.instructions}
          label={question.label}
          openSans
          placeholder={`Enter ${question.label}`}
          required={question.required}
          errorMsg={_get(errors[id], 'message') || ''}
          showSignature
          {...rest}
        />
        {!!response.signed_at && (
          <SignatureDate>
            <Paragraph>Signed {signatureDate}</Paragraph>
          </SignatureDate>
        )}
      </InputContainer>
    </Container>
  );
}
QuestionSignatureDisplayForm.displayName = 'QuestionSignatureDisplayForm';

export default QuestionSignatureDisplayForm;
