export const errorFileNameBlank = 'File name cannot be blank.';
export const errorFileExtension =
  'The file uploaded does not have a valid document type. It must be one of the following: csv, doc, docx, gif, jpeg, jpg, mov, mp3, mp4, mpeg, mpg, msg, pdf, png, ppt, pptx, rtf, txt, xls, xlsx, wmv, zip ';
export const errorFileSize = 'This file is too large to upload. Please select a file that is 10MB or smaller.';
export const fileType = [
  'csv',
  'doc',
  'docx',
  'gif',
  'jpeg',
  'jpg',
  'mov',
  'mp3',
  'mp4',
  'mpeg',
  'mpg',
  'msg',
  'pdf',
  'png',
  'ppt',
  'pptx',
  'rtf',
  'txt',
  'xls',
  'xlsx',
  'wmv',
  'zip',
];
export const fileTypeAccept = [
  '.csv',
  '.doc',
  '.docx',
  '.gif',
  '.jpeg',
  '.jpg',
  '.mov',
  '.mp3',
  '.mp4',
  '.mpeg',
  '.mpg',
  '.msg',
  '.pdf',
  '.png',
  '.ppt',
  '.pptx',
  '.rtf',
  '.txt',
  '.xls',
  '.xlsx',
  '.wmv',
  '.zip',
];
export const errorImageFileExtension =
  'The file uploaded does not have a valid document type. It must be one of the following: gif, jpeg, jpg, png ';
export const errorImageFileSize = 'This file is too large to upload. Please select a file that is 10MB or smaller.';
export const imageFileType = ['gif', 'jpeg', 'jpg', 'png'];
export const maxFileSize = 10485760;
