import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import _each from 'lodash/each';
import _get from 'lodash/get';
import PlacesAutocomplete, { geocodeByAddress, getLatLng, geocodeByPlaceId } from 'react-places-autocomplete';
import StyledIcon from '../styles/Icon.styled';
import Tooltip from './Tooltip.styled';
import { Chip } from './Chip.styled';

const Error = styled.span`
  color: ${({ theme }) => theme.locationSearch.error.fontColor};
  font-family: ${({ theme }) => theme.locationSearch.error.fontFamily};
  font-size: ${({ theme }) => theme.locationSearch.error.fontSize};

  svg {
    justify-content: center;
    margin-right: 5px;
  }
`;

const DisabledIconDiv = styled.div`
  color: ${({ theme }) => theme.locationSearch.disabled.fontColor};
  position: relative;
  right: 28px;
  top: 9px;
  width: 0;
`;

const IconDiv = styled.div`
  color: ${({ theme }) => theme.locationSearch.disabled.fontColor};
  position: relative;
  right: -10px;
  top: 9px;
  width: 0;
`;

const InputField = styled.input`
  border: ${props =>
    props.errorMsg && props.errorMsg.length > 0
      ? ({ theme }) => theme.locationSearch.error.border
      : ({ theme }) => theme.locationSearch.border};
  border-radius: ${({ theme }) => theme.locationSearch.borderRadius};
  color: ${({ theme }) => theme.locationSearch.fontColor};
  font-family: ${({ theme }) => theme.locationSearch.fontFamily};
  font-size: ${({ theme }) => theme.locationSearch.fontSize};
  height: ${({ theme }) => theme.locationSearch.height};
  margin-bottom: 0;
  line-height: ${({ theme }) => theme.locationSearch.lineHeight};
  padding: ${({ theme }) => theme.locationSearch.padding};
  width: 100%;
  &:disabled {
    border: ${({ theme }) => theme.locationSearch.disabled.border};
    background-color: ${({ theme }) => theme.locationSearch.disabled.backgroundColor};
    color: ${({ theme }) => theme.locationSearch.disabled.fontColor};
    cursor: not-allowed;
  }
  &:focus {
    outline-color: ${({ theme }) => theme.locationSearch.focus.outlineColor};
  }
  &::placeholder {
    color: ${({ theme }) => theme.locationSearch.placeholder.fontColor};
    font-family: ${({ theme }) => theme.locationSearch.placeholder.fontFamily};
    font-size: ${({ theme }) => theme.locationSearch.placeholder.fontSize};
    font-style: ${({ theme }) => theme.locationSearch.placeholder.fontStyle};
  }
`;

const InputGroup = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

const InputInnerGroup = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

const InputLabel = styled.label`
  color: ${({ theme }) => theme.locationSearch.label.fontColor};
  font-family: ${({ theme }) => theme.locationSearch.label.fontFamily};
  font-size: ${({ theme }) => theme.locationSearch.label.fontSize};
  font-weight: 500;
`;

const ListItem = styled.div`
  color: ${({ theme }) => theme.locationSearch.listItem.fontColor};
  font-family: ${({ theme }) => theme.locationSearch.listItem.fontFamily};
  font-size: ${({ theme }) => theme.locationSearch.listItem.fontSize};
  padding: ${({ theme }) => theme.locationSearch.listItem.padding};

  &:hover {
    background-color: #ebebeb;
    cursor: pointer;
  }
`;

const ListItemContainer = styled.div`
  background: #fff;
  border: ${({ theme }) => theme.locationSearch.listContainer.border};
  padding: ${({ theme }) => theme.locationSearch.listContainer.padding};
  position: absolute;
  width: 100%;
  z-index: 1;
`;

const LocChipDiv = styled.div`
  padding-top: 20px;
`;

const Required = styled.span`
  color: ${({ theme }) => theme.defaults.errorColor};
  padding-left: 5px;
`;

const TooltipContainer = styled.span`
  padding-left: 5px;
`;

const Wrapper = styled.div`
  position: relative;
  width: 100%;
`;

const XButton = styled.span`
  all: unset;
  margin-left: 15px;
  color: black;
`;

export default function LocationSearch({
  onChange,
  disabled,
  setValue = [],
  selectHandler,
  defaultValue,
  displayFirst,
  value,
  toggle,
  errorMsg,
  icon,
  id,
  label,
  placeholder,
  required,
  tooltip,
  type = 'text',
  ...props
}) {
  const [address, setAddress] = useState('');
  const [searchOptions, setSearchOptions] = useState({});
  const [selectedLocations, setSelectedLocations] = useState(defaultValue || value || setValue);
  
  const [displayValues, setDisplayValues] = useState([]);
  const handleChange = address => {
    setAddress(address);
  };

  const handleRemoveValue = e => {
    let toDisplay = [];
    let updatedList = selectedLocations;
    if(updatedList?.length>0){
      updatedList = updatedList.filter((item)=>item.country!=='Unspecified Location')
    }
    if (!updatedList) return;

    updatedList.forEach(loc => {
      if (loc._destroy || (loc.google_place_id === e.google_place_id && loc.id === e.id)) {
        loc._destroy = true;
      } else {
        toDisplay.push(loc);
      }
    });

    onChange(updatedList);
    setDisplayValues(toDisplay);
    setSelectedLocations(updatedList);
    setAddress('');
  };

  const handleAddValue = e => {
    let updatedList = selectedLocations;
    updatedList.push(e);
    if(updatedList?.length>1){
      updatedList = updatedList.filter((item)=>item.country!=='Unspecified Location')
    }
    onChange(updatedList);
    setDisplayValues(updatedList);
    setAddress('');
  };

  const getPlace = async placeId => {
    return geocodeByPlaceId(placeId)
      .then(results => {
        let components = {};
        let formatAddress = {};
        components.formatted_address = results[0].formatted_address;
        _each(results[0].address_components, function(k, v1) {
          _each(results[0].address_components[v1].types, function(k2, v2) {
            components[`${results[0].address_components[v1].types[v2]}_long`] =
              results[0].address_components[v1].long_name;
            components[`${results[0].address_components[v1].types[v2]}_short`] =
              results[0].address_components[v1].short_name;
          });
        });

        formatAddress.google_place_id = placeId;
        formatAddress.alpha2 = _get(components, 'country_short') || '';
        formatAddress.city = _get(components, 'locality_long') || '';
        formatAddress.country_common_name = _get(components, 'country_long') || '';
        formatAddress.county_or_region = _get(components, 'administrative_area_level_2_long') || '';
        formatAddress.formatted_address = _get(components, 'formatted_address') || '';
        formatAddress.locality = _get(components, 'locality_long') || '';
        formatAddress.postal_code = _get(components, 'postal_code_long') || '';
        formatAddress.postal_code_suffix = _get(components, 'postal_code_suffix_long') || '';
        formatAddress.state_or_province = _get(components, 'administrative_area_level_1_long') || '';
        formatAddress.state_or_province_code = _get(components, 'administrative_area_level_1_short') || '';
        formatAddress.street = _get(components, 'route_long') || '';
        formatAddress.street_number = _get(components, 'street_number_long') || '';

        return formatAddress;
      })
      .catch(error => console.error('geocodeByPlaceId error', error));
  };

  const getGeocode = async address => {
    return geocodeByAddress(address)
      .then(results => {
        return getLatLng(results[0]);
      })
      .catch(error => console.error('Error', error));
  };

  const getTimeZone = async (lat, lng) => {
    const secondsSinceEpoch = new Date().getTime() / 1000;
    const path = `https://maps.googleapis.com/maps/api/timezone/json?location=${lat},${lng}&timestamp=${secondsSinceEpoch}&key=AIzaSyB25i20ioPnZAD_9JR4p_B0b4ONm6kZpGM`;
    return fetch(path, {
      method: 'GET',
    })
      .then(response => response.json())
      .then(response => {
        return response;
      })
      .catch(err => console.log(err));
  };

  const handleSelect = async (locValue, placeId) => {
    if (placeId && locValue) {
      const place_components = await getPlace(placeId);
      const place_geocode = await getGeocode(locValue);
      const place_timezone = await getTimeZone(place_geocode.lat, place_geocode.lng);
      const geoLat = _get(place_geocode, 'lat') || '';
      const geoLng = _get(place_geocode, 'lng') || '';
      const location = {
        ...place_components,
        loading: false,
        lat: geoLat,
        lng: geoLng,
        time_zone: _get(place_timezone, 'timeZoneId') || '',
        time_zone_offset: _get(place_timezone, 'rawOffset') || '',
        mapUrl: `https://www.google.com/maps/search/?api=1&query=${geoLat},${geoLng}&query_place_id=${placeId}`,
      };

      handleAddValue(location);
    }
  };

  useEffect(() => {
    if (!toggle && selectedLocations) {
      let displayLocations = [];
      selectedLocations.forEach(loc => {
        if (loc.country!=='Unspecified Location') {
          displayLocations.push(loc);
        }
      });
      setDisplayValues(displayLocations);
    }
    
  },[toggle])

  useEffect(() => {
    let displayLocations = [];
    if (props.searchType === 'city') {
      setSearchOptions({ types: ['(cities)'] });
    }

    if (selectedLocations) {
      selectedLocations.forEach(loc => {
        if (!loc._destroy) {
          displayLocations.push(loc);
        }
      });
    }

    setDisplayValues(displayLocations);
  }, []);

  return (
    <Wrapper>
      <PlacesAutocomplete
        debounce={700}
        value={address}
        onChange={handleChange}
        onSelect={handleSelect}
        searchOptions={searchOptions}
        // shouldFetchSuggestions={address.length > 2}
        ref={ref => {
          if (!ref) return;
          ref.handleInputOnBlur = () => {};
        }}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <>
            <InputGroup>
              <InputLabel htmlFor={id}>
                {label}
                {required && <Required>*</Required>}
                {tooltip && (
                  <TooltipContainer>
                    <Tooltip toolTipText={tooltip}>
                      <StyledIcon type="InformationCircle" size="14px" />
                    </Tooltip>
                  </TooltipContainer>
                )}
              </InputLabel>
              <InputInnerGroup>
                {icon && icon.length > 0 && (
                  <IconDiv>
                    <StyledIcon type={icon} size="16px" />
                  </IconDiv>
                )}
                <InputField
                  {...getInputProps({})}
                  aria-invalid={errorMsg && errorMsg.length > 0 ? 'true' : 'false'}
                  aria-label={label || 'Text input'}
                  errorMsg={errorMsg}
                  // defaultValue={value}
                  disabled={disabled}
                  id={id}
                  placeholder={placeholder}
                  type={type}
                  {...props}
                />
                {disabled && (
                  <DisabledIconDiv>
                    <StyledIcon type="LockClosed" size="16px" />
                  </DisabledIconDiv>
                )}
              </InputInnerGroup>
              {errorMsg && errorMsg.length > 0 && (
                <Error>
                  <StyledIcon type="Warning" size="16px" />
                  {errorMsg}
                </Error>
              )}
            </InputGroup>

            {!loading && suggestions.length > 0 && (
              <ListItemContainer>
                {suggestions.map(suggestion => {
                  const key = `${suggestion.placeId}-${Math.random()}`;
                  if (suggestion.placeId.length > 0 && suggestion.terms.length > 1) {
                    return (
                      <ListItem {...getSuggestionItemProps(suggestion, {})} key={key}>
                        <span>{suggestion.description}</span>
                      </ListItem>
                    );
                  }
                })}
              </ListItemContainer>
            )}
          </>
        )}
      </PlacesAutocomplete>
      <LocChipDiv>
        {displayValues &&
          displayValues.map((loc, index) => {
            let displayText = `${loc.city}, ${loc.country || loc.country_common_name}`;

            const city = loc.formatted_address ? loc.formatted_address.split(',')[0] : loc.city;
            const isUL = loc.alpha2 === 'UL';

            if (!loc.city || (loc.city && loc.city.length === 0)) {
              displayText = `${city}, ${loc.country || loc.country_common_name}`;
            }
            if (!loc.alpha2 || (loc.alpha2 && loc.alpha2.length === 0)) {
              displayText = !loc.city ? city : loc.city;
            }
            if (isUL) {
              displayText = loc.country || loc.country_common_name;
            }

            return (
              <React.Fragment key={loc.id || loc.google_place_id}>
                {!loc._destroy && (
                  <Chip
                    disabled={disabled}
                    onClick={() => !isUL && handleRemoveValue(loc)}
                    primary={isUL || (index === 0 && displayFirst)}
                    toggleOn={!isUL}
                  >
                    {displayText}
                    <XButton>{isUL ? <StyledIcon type="LockClosed" size="16px" /> : 'X'}</XButton>
                  </Chip>
                )}
              </React.Fragment>
            );
          })}
      </LocChipDiv>
    </Wrapper>
  );
}
