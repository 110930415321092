import fetch from 'node-fetch';
import token from '../shared/helpers/token';
import { keys } from '../config/keys';
import {
  SAVE_ALTERNATE_SETTINGS,
  ALTERNATE_LOADING,
  CLEAR_ALTERNATE_FLASH,
} from './types';

export const alternateLoading = dispatch => {
  dispatch({
    type: ALTERNATE_LOADING,
  });
};

export const saveAlternateSetting = data => dispatch => {
  alternateLoading(dispatch);
  let url = `${keys.baseUri}/api/client/alternate_settings`;
  let method = 'POST';
  if (data.alternate_setting.id) {
    url = `${keys.baseUri}/api/client/alternate_settings/${
      data.alternate_setting.id
    }`;
    method = 'PUT';
  }
  return fetch(url, {
    method: method,
    headers: token,
    body: JSON.stringify(data),
  })
    .then(res => res.json())
    .then(data =>
      dispatch({
        type: SAVE_ALTERNATE_SETTINGS,
        payload: data,
      }),
    );
};

export const fetchAlternateSetting = data => dispatch => {
  alternateLoading(dispatch);
  fetch(`${keys.baseUri}/api/client/alternate_settings/null  `, {
    headers: token,
  })
    .then(res => res.json())
    .then(data =>
      dispatch({
        type: SAVE_ALTERNATE_SETTINGS,
        payload: data,
      }),
    );
};

export const clearAlternateFlash = () => dispatch => {
  dispatch({
    type: CLEAR_ALTERNATE_FLASH,
  });
};
