import fetch from 'node-fetch';

import { keys } from '../../../config/keys';
import token from '../../../shared/helpers/token';

import {
  GET_PROGRAM_PREFERENCES,
  ADD_PROGRAM_MATCH_FORM_COMPLETED,
  ADD_PROGRAM_MATCH_FORM_LANGUAGES,
  ADD_PROGRAM_MATCH_FORM_PRIORITIES,
  ADD_PROGRAM_MATCH_FORM_PROGRAM_TYPE,
  ADD_PROGRAM_MATCH_FORM_PROGRAM_TERMS,
  ADD_PROGRAM_MATCH_FORM_SUBJECT_AREAS,
  ADD_PROGRAM_MATCH_FORM_PROGRAM_LENGTH,
  ADD_PROGRAM_MATCH_FORM_PROGRAM_COUNTRY,
  ADD_PROGRAM_MATCH_FORM_PROGRAM_HOUSING,
} from './types';

export function getProgramPreferences(userId) {
  return function(dispatch) {
    fetch(`${keys.baseUri}/api/traveler/intake_wizard/${userId}/get_program_preferences`, { headers: token })
      .then(res => res.json())
      .then(data =>
        dispatch({
          type: GET_PROGRAM_PREFERENCES,
          payload: data,
        }),
      );
  };
}

export function addProgramMatchSubjectAreas(userId, data) {
  return function(dispatch) {
    fetch(`${keys.baseUri}/api/traveler/intake_wizard/${userId}/update_subject_areas`, {
      method: 'PUT',
      body: JSON.stringify({ subject_areas: data }),
      headers: token,
    })
      .then(res => res.json())
      .then(res => {
        if (res[0].status == 200) {
          dispatch({
            type: ADD_PROGRAM_MATCH_FORM_SUBJECT_AREAS,
            payload: data,
          });
        }
      });
  };
}

export function addProgramMatchProgramCountry(userId, data) {
  return function(dispatch) {
    fetch(`${keys.baseUri}/api/traveler/intake_wizard/${userId}/update_preferred_program_countries`, {
      method: 'PUT',
      body: JSON.stringify({ program_countries: data }),
      headers: token,
    })
      .then(res => res.json())
      .then(res => {
        if (res[0].status == 200) {
          dispatch({
            type: ADD_PROGRAM_MATCH_FORM_PROGRAM_COUNTRY,
            payload: data,
          });
        }
      });
  };
}

export function addProgramMatchProgramHousing(userId, { housingData, otherText }) {
  return function(dispatch) {
    fetch(`${keys.baseUri}/api/traveler/intake_wizard/${userId}/update_preferred_program_housing`, {
      method: 'PUT',
      body: JSON.stringify({
        program_housing: housingData,
        other_program_housing_text: otherText,
      }),
      headers: token,
    })
      .then(res => res.json())
      .then(res => {
        if (res[0].status == 200) {
          dispatch({
            type: ADD_PROGRAM_MATCH_FORM_PROGRAM_HOUSING,
            payload: {
              otherText,
              housingData,
            },
          });
        }
      });
  };
}

export function addProgramMatchProgramLength(userId, { minWeeks, maxWeeks }) {
  return function(dispatch) {
    fetch(`${keys.baseUri}/api/traveler/intake_wizard/${userId}/update_program_length`, {
      method: 'PUT',
      body: JSON.stringify({
        program_length: {
          minimum_duration_weeks: minWeeks,
          maximum_duration_weeks: maxWeeks,
        },
      }),
      headers: token,
    })
      .then(res => res.json())
      .then(res => {
        if (res[0].status == 200) {
          dispatch({
            type: ADD_PROGRAM_MATCH_FORM_PROGRAM_LENGTH,
            payload: {
              minWeeks,
              maxWeeks,
            },
          });
        }
      });
  };
}

export function addProgramMatchProgramTerm(userId, data) {
  return function(dispatch) {
    fetch(`${keys.baseUri}/api/traveler/intake_wizard/${userId}/update_program_term`, {
      method: 'PUT',
      body: JSON.stringify({ traveler_terms: data }),
      headers: token,
    })
      .then(res => res.json())
      .then(res => {
        if (res[0].status == 200) {
          dispatch({
            type: ADD_PROGRAM_MATCH_FORM_PROGRAM_TERMS,
            payload: data,
          });
        }
      });
  };
}

export function addProgramMatchLanguages(userId, { languageImmersion, userLanguages }) {
  return function(dispatch) {
    fetch(`${keys.baseUri}/api/traveler/intake_wizard/${userId}/update_user_languages`, {
      method: 'PUT',
      body: JSON.stringify({
        language_immersion: languageImmersion,
        user_languages: userLanguages,
      }),
      headers: token,
    })
      .then(res => res.json())
      .then(res => {
        if (res[0].status == 200) {
          dispatch({
            type: ADD_PROGRAM_MATCH_FORM_LANGUAGES,
            payload: {
              userLanguages,
              languageImmersion,
            },
          });
        }
      });
  };
}

export function addProgramMatchProgramType(userId, { types, otherText }) {
  return function(dispatch) {
    fetch(`${keys.baseUri}/api/traveler/intake_wizard/${userId}/update_program_type`, {
      method: 'PUT',
      body: JSON.stringify({
        program_types: types,
        other_program_type_text: otherText,
      }),
      headers: token,
    })
      .then(res => res.json())
      .then(res => {
        if (res[0].status == 200) {
          dispatch({
            type: ADD_PROGRAM_MATCH_FORM_PROGRAM_TYPE,
            payload: {
              types,
              otherText,
            },
          });
        }
      });
  };
}

export function addProgramMatchPriorities(userId, data, reducerPayload) {
  return function(dispatch) {
    fetch(`${keys.baseUri}/api/traveler/intake_wizard/${userId}/update_program_priorities`, {
      method: 'PUT',
      body: JSON.stringify({ preferred_program_priorities: data }),
      headers: token,
    })
      .then(res => res.json())
      .then(res => {
        if (res[0].status == 200) {
          dispatch({
            type: ADD_PROGRAM_MATCH_FORM_PRIORITIES,
            payload: reducerPayload,
          });
        }
      });
  };
}

export function addProgramMatchComplete(userId, data) {
  return function(dispatch) {
    fetch(`${keys.baseUri}/api/traveler/intake_wizard/${userId}/update_traveler_info`, {
      method: 'PUT',
      body: JSON.stringify({ traveler_info: { program_match_complete: data } }),
      headers: token,
    })
      .then(res => res.json())
      .then(res => {
        if (res[0].status == 200) {
          dispatch({
            type: ADD_PROGRAM_MATCH_FORM_COMPLETED,
            payload: data,
          });
        }
      });
  };
}
