import React from 'react';
import Auxiliary from '../../hoc/Auxiliary';
import moment from 'moment-timezone';

export const MonthYearFormat = props => (
  <Auxiliary>
    {moment(new Date(props.startDate)).format('MMM [] YYYY')} -{' '}
    {props.addBr ? <br /> : null}
    {moment(new Date(props.endDate)).format('MMM [] YYYY')}
  </Auxiliary>
);
