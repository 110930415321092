import React, { useEffect, useState } from 'react';
import { compose } from 'recompose';
import propTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import _omit from 'lodash/omit';
import ViaIcon from '../../../../plans/components/ViaIcon';
import sStyles from './text.scss';

export const enhance = compose(withStyles(sStyles));

function V2InputText(props) {
  const [ariaLabel, setAriaLabel] = useState('');
  const isError = props.errorMessage.length > 0;

  useEffect(
    () => {
      const defineAria = props.arialLabel || props.placeholder || props.labelText || 'Text input';
      setAriaLabel(defineAria);
    },
    [props],
  );

  const cleanProps = {
    ..._omit(props, [
      'arialLabel',
      'autofocus',
      'disabled',
      'errorMessage',
      'helpText',
      'id',
      'isRequired',
      'name',
      'labelText',
      'placeholder',
    ]),
  };

  return (
    <div className={sStyles.wrapper}>
      {!!props.labelText && (
        <label className={sStyles.label} htmlFor={props.id}>
          {props.labelText}
        </label>
      )}
      <div className={sStyles.inputWrapper}>
        <input
          aria-label={ariaLabel}
          autoFocus={props.autofocus}
          className={sStyles.inputText}
          disabled={props.disabled}
          id="input"
          name="input"
          placeholder={props.placeholder}
          required={isError}
          type="text"
          {...cleanProps}
        />
        {isError && (
          <div className={sStyles.errorIcon}>
            <ViaIcon color="primary" name="warning" size="xsmall" />
          </div>
        )}
      </div>
      {isError && (
        <span className={sStyles.error}>{props.errorMessage}</span>
      )}
    </div>
  );
}


V2InputText.propTypes = {
  arialLabel: propTypes.string,
  autofocus: propTypes.bool,
  disabled: propTypes.bool,
  errorMessage: propTypes.string,
  helpText: propTypes.string,
  id: propTypes.string.isRequired,
  isRequired: propTypes.bool,
  name: propTypes.string,
  labelText: propTypes.string,
  placeholder: propTypes.string,
}

V2InputText.defaultProps = {
  arialLabel: '',
  autofocus: false,
  disabled: false,
  errorMessage: '',
  helpText: '',
  isRequired: false,
  name: '',
  labelText: '',
  placeholder: '',
}

export default enhance(V2InputText)
