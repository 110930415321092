import fetch from 'node-fetch';
import token from '../shared/helpers/token';
import { keys } from '../config/keys';
import _clone from 'lodash/clone';
import _isEmpty from 'lodash/isEmpty';
import {
  FETCH_TRAVELERS,
  FILTER_TRAVELERS,
  FETCH_INITIAL_TRAVELERS,
  FETCH_CLIENT_EVENTS,
  FETCH_CLIENT_FORMS,
  FETCH_CLIENT_APPLICATIONS,
  FETCH_CLIENT_PROGRAMS,
  FETCH_CLIENT_AUTHORIZED_PROGRAMS,
  FETCH_CLIENT_PROGRAM_ORGS,
  FETCH_CLIENT_ADMINS,
  FETCH_CUSTOM_FIELDS,
  CREATE_TRAVELER_BATCH_MESSAGE,
  UPDATE_CUSTOM_FIELDS_MODAL,
  FETCH_COLLEGE_OR_FACULTY,
  SELECT_TRAVELER,
  UNSELECT_TRAVELER,
  ADD_FILTER,
  APPLY_SEARCH_FILTER,
  CLEAR_SEARCH_FILTER_APPLIED,
  DIAL_FILTER,
  DELETE_SEARCH_FILTER,
  CLEAR_SEARCH_FILTER_CODE,
  LOADING_FILTER_DATA,
  SELECT_TRAVELERS,
  UNSELECT_TRAVELERS,
  FETCH_CLIENT_DASHBOARD_DETAILS,
} from '../actions/types';
import { dateInUTC, getDateFromOffsetDays } from '../shared/helpers/General';

export function fetchInitialTravelers(block) {
  return function(dispatch) {
    fetch(`${keys.baseUri}/api/client/travelers/get_travelers?block=${block}`, {
      headers: token,
    })
      .then(res => res.json())
      .then(data =>
        dispatch({
          type: FETCH_INITIAL_TRAVELERS,
          payload: data,
        }),
      )
      .catch(error => console.error(error));
  };
}

export function filterTravelers(data) {
  return function(dispatch) {
    dispatch({ type: LOADING_FILTER_DATA });
    fetch(`${keys.baseUri}/api/client/travelers/filter_travelers`, {
      method: 'POST',
      body: JSON.stringify({ filters: data }),
      headers: token,
    })
      .then(res => res.json())
      .then(res => {
        dispatch({
          type: FILTER_TRAVELERS,
          payload: res,
        });
      });
  };
}

export function fetchTravelers(params) {
  return function(dispatch) {
    fetch(`${keys.baseUri}/api/client/travelers/search?${params}`, {
      headers: token,
    })
      .then(res => res.json())
      .then(data =>
        dispatch({
          type: FETCH_TRAVELERS,
          payload: data,
        }),
      )
      .catch(error => console.error(error));
  };
}

export function toggleTraveler(travelerId, status) {
  if (status) {
    return {
      type: SELECT_TRAVELER,
      travelerId,
    };
  }
  return {
    type: UNSELECT_TRAVELER,
    travelerId,
  };
}

export function toggleAllTravelers(ids, status) {
  if (status) {
    return {
      type: SELECT_TRAVELERS,
      ids,
    };
  }
  return {
    type: UNSELECT_TRAVELERS,
    ids,
  };
}

export function fetchCustomFields(id) {
  return function(dispatch) {
    fetch(`${keys.baseUri}/api/client/client_account/${id}/get_custom_fields`, {
      headers: token,
    })
      .then(res => res.json())
      .then(data =>
        dispatch({
          type: FETCH_CUSTOM_FIELDS,
          payload: data,
        }),
      );
  };
}

export function fetchClientEvents(id) {
  return function(dispatch) {
    fetch(`${keys.baseUri}/api/client/events/${id}/events`, {
      headers: token,
    })
      .then(res => res.json())
      .then(data =>
        dispatch({
          type: FETCH_CLIENT_EVENTS,
          payload: data,
        }),
      )
      .catch(error => console.error(error));
  };
}

export function fetchClientDashboard(dayOffset = null) {
  let priorDate = null;
  if (dayOffset && !isNaN(dayOffset)) {
    const today = dateInUTC();
    priorDate = getDateFromOffsetDays(today, dayOffset);
  }

  const endDateParam = priorDate ? `?end_date=${priorDate}` : '';

  return function(dispatch) {
    fetch(`${keys.baseUri}/api/dashboard_cards${endDateParam}`, {
      method: 'GET',
      headers: token,
    })
      .then(res => res.json())
      .then(data =>
        dispatch({
          type: FETCH_CLIENT_DASHBOARD_DETAILS,
          payload: data.data,
        }),
      )
      .catch(error => console.error(error));
  };
}

export function fetchClientForms() {
  return function(dispatch) {
    fetch(`${keys.baseUri}/api/client/forms`, { headers: token })
      .then(res => res.json())
      .then(data =>
        dispatch({
          type: FETCH_CLIENT_FORMS,
          payload: data,
        }),
      )
      .catch(error => console.error(error));
  };
}

export function fetchClientApplications() {
  return function(dispatch) {
    fetch(`${keys.baseUri}/api/client/applications`, { headers: token })
      .then(res => res.json())
      .then(data =>
        dispatch({
          type: FETCH_CLIENT_APPLICATIONS,
          payload: data,
        }),
      )
      .catch(error => console.error(error));
  };
}

export function fetchClientPrograms() {
  return function(dispatch) {
    fetch(`${keys.baseUri}/api/client/programs`, { headers: token })
      .then(res => res.json())
      .then(data =>
        dispatch({
          type: FETCH_CLIENT_PROGRAMS,
          payload: data,
        }),
      )
      .catch(error => console.error(error));
  };
}

export function fetchClientAuthorizedPrograms(id) {
  return function(dispatch) {
    fetch(`${keys.baseUri}/api/client/programs/${id}/get_authorized_programs`, {
      headers: token,
    })
      .then(res => res.json())
      .then(data =>
        dispatch({
          type: FETCH_CLIENT_AUTHORIZED_PROGRAMS,
          payload: data,
        }),
      )
      .catch(error => console.error(error));
  };
}

export function fetchClientOrgs(id) {
  return function(dispatch) {
    fetch(`${keys.baseUri}/api/client/programs/${id}/get_program_organizations`, { headers: token })
      .then(res => res.json())
      .then(data =>
        dispatch({
          type: FETCH_CLIENT_PROGRAM_ORGS,
          payload: data,
        }),
      )
      .catch(error => console.error(error));
  };
}

export function fetchAdmins(id) {
  return function(dispatch) {
    fetch(`${keys.baseUri}/api/client/client_account/${id}/get_admins`, {
      headers: token,
    })
      .then(res => res.json())
      .then(data =>
        dispatch({
          type: FETCH_CLIENT_ADMINS,
          payload: data,
        }),
      )
      .catch(error => console.error(error));
  };
}

export function sendTravelerBatchMessage(data) {
  const formPayLoad = new FormData();
  formPayLoad.append('message[subject]', data.subject);
  formPayLoad.append('message[body]', data.body);
  formPayLoad.append('traveler_ids', data.traveler_ids);
  formPayLoad.append('message[attachment_count]', data.attachmentCount);
  !_isEmpty(data.attachments) &&
    data.attachments.forEach((attachment, index) => {
      formPayLoad.append(`message[attachment${index}]`, attachment);
    });

  let cloneToken = _clone(token);
  delete cloneToken['Accept'];
  delete cloneToken['Content-Type'];

  return function(dispatch) {
    fetch(`${keys.baseUri}/api/traveler_batch_actions/send_messages`, {
      method: 'POST',
      headers: cloneToken,
      body: formPayLoad,
    })
      .then(res => res.json())
      .then(data => {
        dispatch({
          type: CREATE_TRAVELER_BATCH_MESSAGE,
          payload: false,
          success: data.success,
        });

        setTimeout(() => {
          dispatch({
            type: CREATE_TRAVELER_BATCH_MESSAGE,
            payload: false,
            success: '',
          });
        }, 100);
      });
  };
}
export function addTravelersCustomFields(data) {
  return function(dispatch) {
    fetch(`${keys.baseUri}/api/client/custom_fields/update_travelers`, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: token,
    })
      .then(res => res.json())
      .then(res => {
        dispatch({
          type: UPDATE_CUSTOM_FIELDS_MODAL,
          payload: false,
        });
      });
  };
}

export function updateCustomFieldsModal(data) {
  return function(dispatch) {
    dispatch({
      type: UPDATE_CUSTOM_FIELDS_MODAL,
      payload: data,
    });
  };
}
export function toggleMessageModal(data) {
  return function(dispatch) {
    dispatch({
      type: CREATE_TRAVELER_BATCH_MESSAGE,
      payload: data,
    });
  };
}
export function fetchColleges(id) {
  return function(dispatch) {
    fetch(`${keys.baseUri}/api/client/client_account/${id}/get_college_or_faculty_options`, {
      headers: token,
    })
      .then(res => res.json())
      .then(data =>
        dispatch({
          type: FETCH_COLLEGE_OR_FACULTY,
          payload: data,
        }),
      )
      .catch(error => console.error(error));
  };
}
export function addFilter(data) {
  return function(dispatch) {
    fetch(`${keys.baseUri}/api/client/travelers/create_search_filter`, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: token,
    })
      .then(res => res.json())
      .then(res => {
        dispatch({
          type: ADD_FILTER,
          payload: res,
        });
      });
  };
}
export function applySearchFilter(params, appliedFilterId) {
  return function(dispatch) {
    fetch(`${keys.baseUri}/api/client/travelers/search?${params}`, {
      headers: token,
    })
      .then(res => res.json())
      .then(data =>
        dispatch({
          type: APPLY_SEARCH_FILTER,
          payload: data,
          appliedFilterId: appliedFilterId,
        }),
      )
      .catch(error => console.error(error));
  };
}
export function deleteSearchFilter(appliedFilterId) {
  return function(dispatch) {
    fetch(`${keys.baseUri}/api/client/travelers/delete_search_filter`, {
      method: 'POST',
      body: JSON.stringify({ applied_filter_id: appliedFilterId }),
      headers: token,
    })
      .then(res => res.json())
      .then(data =>
        dispatch({
          type: DELETE_SEARCH_FILTER,
          payload: {
            appliedFilterId: appliedFilterId,
            code: data.code,
            message: data.message,
          },
        }),
      )
      .catch(error => console.error(error));
  };
}

export const clearSearchFilterApplied = () => dispatch => {
  dispatch({
    type: CLEAR_SEARCH_FILTER_APPLIED,
  });
};

export const dialFilter = (dial_status, travelers) => dispatch => {
  dispatch({
    type: DIAL_FILTER,
    payload: { dial_status: dial_status, travelers: travelers },
  });
};

export const clearSearchFilterCode = () => dispatch => {
  dispatch({
    type: CLEAR_SEARCH_FILTER_CODE,
  });
};
