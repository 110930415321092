import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { fetchAuthorizedProgramSettings } from '../../../../actions/orgSettingsActions';
import propTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import CopyOpenUrlText from '../../../components/library/copyOpenUrlText';
import sGlobalSubHeader from './globalSubHeader.scss';

function GlobalSubHeader(props) {
  const dispatch = useDispatch();
  const customAliases = useSelector(state => state.profile.customAliases);
  const freemium = useSelector(state => state.profile.freemium);
  const authFlag = useSelector(state => state.orgSettings.authorized_program_settings.account);
  const userId = useSelector(state => state.profile.currentUser.id);
  const [visitorLink, setVisitorLink] = useState('');

  useEffect(() => {
    let unmounted = false;

    let url = new URL(window.location.href);
    setVisitorLink(`${url.origin}/visitor-programs`);

    if (!unmounted) {
      dispatch(fetchAuthorizedProgramSettings(userId));
    }
    return () => {
      unmounted = true;
    };
  }, []);

  return (
    <>
      <div className={sGlobalSubHeader.subheader}>
        {!props.traveler ? (
          <div className={sGlobalSubHeader['subheader-content']}>
            <div className={sGlobalSubHeader.item}>
              <NavLink
                className={sGlobalSubHeader.selection}
                to="/client/program-dashboard/internal"
                activeClassName={sGlobalSubHeader.selected}
              >
                Internal
              </NavLink>
            </div>
            {!freemium &&
            !!authFlag &&
            !!authFlag.authorized_program_settings &&
            authFlag.authorized_program_settings.allow ? (
              <div className={sGlobalSubHeader.item}>
                <NavLink
                  className={sGlobalSubHeader.selection}
                  to="/client/program-dashboard/authorized"
                  activeClassName={sGlobalSubHeader.selected}
                >
                  Authorized
                </NavLink>
              </div>
            ) : null}
            {!freemium &&
            !!authFlag &&
            !!authFlag.authorized_program_settings &&
            authFlag.authorized_program_settings.allow ? (
              <div className={sGlobalSubHeader.item}>
                <NavLink
                  className={sGlobalSubHeader.selection}
                  to="/client/program-dashboard/unauthorized"
                  activeClassName={sGlobalSubHeader.selected}
                >
                  Unauthorized
                </NavLink>
              </div>
            ) : null}
            <div className={sGlobalSubHeader.item}>
              <NavLink
                className={sGlobalSubHeader.selection}
                to="/client/program-dashboard/terms"
                activeClassName={sGlobalSubHeader.selected}
              >
                Internal Terms
              </NavLink>
            </div>
            {!freemium &&
            !!authFlag &&
            !!authFlag.authorized_program_settings &&
            authFlag.authorized_program_settings.allow ? (
              <div className={sGlobalSubHeader.item}>
                <NavLink
                  className={sGlobalSubHeader.selection}
                  to="/client/program-dashboard/terms-authorized"
                  activeClassName={sGlobalSubHeader.selected}
                >
                  Authorized Terms
                </NavLink>
              </div>
            ) : null}
            <div className={sGlobalSubHeader.itemLast}>
              <span className={sGlobalSubHeader.textInfo}>
                View & share visitor {customAliases.alias_programs.toLowerCase()}:
              </span>
              <CopyOpenUrlText linkUrl={visitorLink} />
            </div>
          </div>
        ) : (
          <div className={sGlobalSubHeader['subheader-content']} />
        )}
      </div>
    </>
  );
}

GlobalSubHeader.propTypes = {
  traveler: propTypes.bool.isRequired,
};

export default withStyles(sGlobalSubHeader)(GlobalSubHeader);
