import React from 'react';
import ProgramCardsGrid from './program-dashboard/ProgramCardsGrid';
import ProgramFiltersList from './program-dashboard/ProgramFiltersList';
import ProgramDashboardHeader from './program-dashboard/ProgramDashboardHeader';
import TakeProgramMatchBanner from './program-dashboard/TakeProgramMatchBanner';
import styled from 'styled-components';

const Container = styled.div`
  padding: 20px;
  background-color: #ffffff;
  border-radius : 10px;
`;

export default function TravelerProgramDashboard() {
  return (
    <Container>
      <ProgramDashboardHeader />
      <ProgramFiltersList />
      <ProgramCardsGrid />
      <TakeProgramMatchBanner />
    </Container>
  );
}
