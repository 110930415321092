import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { breakpoints } from '../../utils/breakpoints';
import ScreenReaderHeading from '../../../travelerProfile/components/base/ScreenReaderHeading.styled';
const QuestionOverline = styled.span`
  display: block;
  margin-bottom: 0.75rem;
  font-family: 'Open Sans', sans-serif;
  font-size: 0.75rem;
  color: #446372;
  line-height: 120%;
  letter-spacing: 0.005em;

  @media ${breakpoints.tablet} {
    margin-bottom: 2.6875rem;
    font-size: 1rem;
  }
`;

const QuestionHeading = styled.div`
  margin-bottom: 0.375rem;
  font-family: 'Nunito', sans-serif;
  font-size: 1rem;
  font-weight: 400;
  color: #373737;
  line-height: 120%;

  @media ${breakpoints.tablet} {
    font-size: 1.5625rem;
  }
`;

const QuestionSubheading = styled.p`
  font-family: 'Open Sans', sans-serif;
  font-size: 0.875rem;
  color: #474747;
  line-height: 120%;
  letter-spacing: 0.005em;

  @media ${breakpoints.tablet} {
    font-size: 1rem;
  }
`;

const RequiredField = styled.span`
  color: #770711;
  align-self: flex-start;
`;

export default function TravelerFormQuestion({ overline, heading, subheading, isRequired,isSubheadingRead }) {
  useEffect(()=>{
    document.getElementById("headingIntakeForm").focus();
  },[])
  return (
    <div>
      <ScreenReaderHeading  id="headingIntakeForm" tabIndex="0">
        {`${heading} ${isSubheadingRead ? subheading : ""} ${isSubheadingRead && isRequired ? "Required" : ""}`}
      </ScreenReaderHeading>
      <QuestionOverline>{overline}</QuestionOverline>
      <QuestionHeading>{heading}</QuestionHeading>
      <QuestionSubheading>
        {subheading}
        {isRequired && <RequiredField role="status" aria-label='Required'>&#42;</RequiredField>}
      </QuestionSubheading>
    </div>
  );
}

TravelerFormQuestion.propTypes = {
  isRequired: PropTypes.bool,
  heading: PropTypes.string.isRequired,
  overline: PropTypes.string.isRequired,
  subheading: PropTypes.string.isRequired,
};

TravelerFormQuestion.defaultProps = {
  isRequired: false,
};
