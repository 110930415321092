import React from 'react';
import { NavLink } from 'react-router-dom';
import propTypes from 'prop-types';
import { useSelector } from 'react-redux';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import sReportSubHeader from './subHeader.scss';

function ReportSubHeader(props) {
  const customAliases = useSelector(state => state.profile.customAliases);
  const featureList = useSelector(state => state.profile.clientFeatureList);
  const permissions = useSelector(state => state.profile.permissions);

  return (
    <>
      <div className={sReportSubHeader.subheader}>
        {!props.traveler ? (
          <div className={sReportSubHeader['subheader-content']}>
            <div className={sReportSubHeader.item}>
              <NavLink
                className={sReportSubHeader.selection}
                to="/reports/applications"
                activeClassName={sReportSubHeader.selected}
              >
                APPLICATIONS
              </NavLink>
            </div>
            <div className={sReportSubHeader.item}>
              <NavLink
                className={sReportSubHeader.selection}
                to="/reports/forms"
                activeClassName={sReportSubHeader.selected}
              >
                GENERAL FORMS
              </NavLink>
            </div>
            <div className={sReportSubHeader.item}>
              <NavLink
                className={sReportSubHeader.selection}
                to="/reports/programs"
                activeClassName={sReportSubHeader.selected}
              >
                {customAliases.alias_programs.toUpperCase()}
              </NavLink>
            </div>
            <div className={sReportSubHeader.item}>
              <NavLink
                className={sReportSubHeader.selection}
                to="/reports/travelers"
                activeClassName={sReportSubHeader.selected}
              >
                {customAliases.alias_travelers.toUpperCase()}
              </NavLink>
            </div>

            {!!featureList.risk_alerts && permissions.risk_alerts_settings.includes('view') && (
              <div className={sReportSubHeader.item}>
                <NavLink
                  className={sReportSubHeader.selection}
                  to="/reports/risk-alert-history"
                  activeClassName={sReportSubHeader.selected}
                >
                  {/* {customAliases.alias_travelers.toUpperCase()} */}
                  RISK ALERTS
                </NavLink>
              </div>
            )}

            {!!featureList.horizons && (
              <div className={sReportSubHeader.item}>
                <NavLink
                  className={sReportSubHeader.selection}
                  to="/reports/historic-data"
                  activeClassName={sReportSubHeader.selected}
                >
                  HISTORIC DATA
                </NavLink>
              </div>
            )}
            {!!featureList.sfsu_historic_data && (
              <div className={sReportSubHeader.item}>
                <NavLink
                  className={sReportSubHeader.selection}
                  to="/reports/sfsu-historic-data"
                  activeClassName={sReportSubHeader.selected}
                >
                  HISTORIC DATA
                </NavLink>
              </div>
            )}

            <div className={sReportSubHeader.item}>
              <NavLink
                className={sReportSubHeader.selection}
                to="/reports/downloads"
                activeClassName={sReportSubHeader.selected}
              >
                DOWNLOADS
              </NavLink>
            </div>
          </div>
        ) : (
          <div className={sReportSubHeader['subheader-content']} />
        )}
      </div>
    </>
  );
}

ReportSubHeader.propTypes = {
  traveler: propTypes.bool.isRequired,
};

export default withStyles(sReportSubHeader)(ReportSubHeader);
