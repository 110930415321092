import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import React, { useEffect, useState } from 'react';

import styled from 'styled-components';
import EventsList from '../events/EventsList';
import EventGroup from '../events/EventGroup.styled';
import EventsGroupHeading from '../events/EventsGroupHeading';
import EventRegistrationModal from '../modals/EventRegistrationModal';
import ToastMessage from '../../../viaGlobal/components/toastMessage';
import eventicon from '../../../../shared/images/eventicon.png'

import eventType from '../../types/event';
import groupEvents from '../../utils/events/groupEvents';
import { registerForEvent } from '../../actions/travelerEventsActions';

const EmptyEventContainer = styled.div`
    text-align: center;
    margin: 20vh 0 41vh 0;
`;
const EmaptyEventImage = styled.img`
    width: 92px;
    margin-bottom: 30px;
`;
const EmptyEventsText = styled.p`
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
    font-size: 1rem;
    color: #373737;
`;



export default function UpcomingEventsTab({ events }) {
  const dispatch = useDispatch();

  const [showToast, setShowToast] = useState(false);
  const [upcomingEvents, setUpcomingEvents] = useState({});

  useEffect(
    () => {
      if (events.length > 0) {
        const groupedEvents = groupEvents(events);

        setUpcomingEvents(groupedEvents);
      }
    },
    [events],
  );

  const handleRegister = activeEvent => {
    dispatch(registerForEvent(activeEvent.id));

    setShowToast(true);
  };

  return (
    <div>
      {events.length === 0 && 
       <EmptyEventContainer>
          <EmaptyEventImage src={eventicon} alt="No upcoming events" />
          <EmptyEventsText>
            You have no upcoming events.
          </EmptyEventsText>
        </EmptyEventContainer>
      }
      {events.length > 0 &&
        Object.keys(upcomingEvents).map(eventGroup => {
          const eventsArray = upcomingEvents[eventGroup];

          return (
            <EventGroup key={`event-group-${eventGroup}`}>
              <EventsGroupHeading text={eventGroup} />
              <EventsList events={eventsArray} category="upcoming" />
            </EventGroup>
          );
        })}
      <EventRegistrationModal handleRegister={handleRegister} />
      {showToast && (
        <ToastMessage
          message="Registration confirmed"
          isSuccess
          show={showToast}
          onClose={() => {
            setShowToast(false);
          }}
        />
      )}
    </div>
  );
}

UpcomingEventsTab.propTypes = {
  events: PropTypes.arrayOf(eventType).isRequired,
};
