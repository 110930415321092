import React, { useState } from 'react';
import sImageViewer from './ImageViewer.scss';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import CloseButton from '../CloseButton/CloseButton.js';
import CloseIcon from '../../sites/travelerProfile/components/icons/CloseIcon.js';
function ImageViewer(props) {
  const [show, setShow] = useState(true);
  const [isError, setIsError] = useState(false);
  const background = show ? sImageViewer.backgroundShow : sImageViewer.backgroundNone;
  const showHideClassName = show ? sImageViewer.modalDisplay : sImageViewer.modalDisplayNone;

  return (
    <div id={background}>
      <div id={showHideClassName} className={sImageViewer.modal}>
        <div className={sImageViewer.header}>
          <p>Preview</p>
          <button type="button" className={sImageViewer.closeButton} 
            onClick={()=>{
                props.handleClose();
                setShow(false);
              }
            }
          >
            <CloseIcon fillColor={`#9FABAE`} />
          </button>
          {/* <CloseButton
            isOnModal={true}
            clickHandler={() => {
              props.handleClose();
              setShow(false);
            }}
          /> */}
        </div>
        {!isError && (
          <div className={sImageViewer['react-pdf']}>
            <img src={props.file} className={sImageViewer.previewImg} onError={e=>setIsError(true)}/>
          </div>
        )}
        {isError && (
          <div className={sImageViewer['react-pdf']}>
            Unable to load image
          </div>
        )}

      </div>
    </div>
  );
}

export default withStyles(sImageViewer)(ImageViewer);
