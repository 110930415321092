import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import StyledIcon from '../styles/Icon.styled';
import { AccentButtonSpinner } from './spinner.styled';
import { checkEmail } from '../../actions/authenticationActions';
import { validateEmail, stripHtmlString } from '../../shared/helpers/General';

const openSansFont = css`
  font-family: ${({ theme }) => theme.textInput.fontFamilyOpenSans};
`;

const ButtonDiv = styled.div``;

const EmailCheckButton = styled.button.attrs(props => ({
  type: props.type || 'button',
}))`
  background-color: var(--theme_color_accent, ${({ theme }) => theme.emailCheck.button.backgroundColor});
  display: inline-block;
  border: 1px solid var(--theme_color_accent, ${({ theme }) => theme.emailCheck.button.backgroundColor});
  border-bottom-right-radius: ${({ theme }) => theme.emailCheck.button.borderRadius};
  border-top-right-radius: ${({ theme }) => theme.emailCheck.button.borderRadius};
  color: var(--theme_color_accent_font, ${({ theme }) => theme.button.fontColor});
  font-family: ${({ theme }) => theme.emailCheck.button.fontFamily};
  font-size: ${({ theme }) => theme.emailCheck.button.fontSize};
  font-weight: ${({ theme }) => theme.emailCheck.button.fontWeight};
  line-height: ${({ theme }) => theme.emailCheck.button.lineHeight};
  min-width: 85px;
  padding: ${({ theme }) => theme.emailCheck.button.padding};
  text-align: center;

  svg {
    align-self: center;
    display: inline-flex;
    margin-right: 5px;
    position: relative;
  }

  &:hover {
    cursor: pointer;
    opacity: 0.6;
  }

  &:active {
    opacity: 0.9;
  }

  &:disabled,
  &:disabled:active {
    opacity: ${props => (props.saving ? '.7' : '0.3')};
    cursor: not-allowed;
  }

  &:focus {
    border: ${({ theme }) => theme.emailCheck.button.focus.focusBorder};
    outline-color: ${({ theme }) => theme.emailCheck.button.focus.outlineColor};
  }
`;

const InputGroup = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

const InputInnerGroup = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
`;

const InputLabel = styled.label`
  color: ${({ theme }) => theme.textInput.label.fontColor};
  font-family: ${({ theme }) => theme.textInput.label.fontFamily};
  font-size: ${({ theme }) => theme.textInput.label.fontSize};
  font-weight: ${({ theme }) => theme.textInput.label.fontWeight};

  ${({ openSans }) => (openSans ? openSansFont : null)};
`;

const Instructions = styled.div`
  color: ${({ theme }) => theme.textInput.instructions.fontColor};
  font-family: ${({ theme }) => theme.textInput.instructions.fontFamily};
  font-size: ${({ theme }) => theme.textInput.instructions.fontSize};
  font-weight: ${({ theme }) => theme.textInput.instructions.fontWeight};
  padding-left: 5px;
  p {
    margin-bottom:0px;
  }
  ${({ openSans }) => (openSans ? openSansFont : null)};
`;

const InputField = styled.input`
  border: ${props =>
    props.errorMsg && props.errorMsg.length > 0
      ? ({ theme }) => theme.emailCheck.error.border
      : ({ theme }) => theme.emailCheck.border};
  border-bottom-left-radius: ${({ theme }) => theme.emailCheck.borderRadius};
  border-top-left-radius: ${({ theme }) => theme.emailCheck.borderRadius};
  color: ${({ theme }) => theme.emailCheck.fontColor};
  font-family: ${({ theme }) => theme.emailCheck.fontFamily};
  font-size: ${({ theme }) => theme.emailCheck.fontSize};
  height: ${({ theme }) => theme.emailCheck.height};
  margin-bottom: 0;
  line-height: ${({ theme }) => theme.emailCheck.lineHeight};
  padding: ${({ theme }) => theme.emailCheck.padding};
  width: ${({ theme }) => theme.emailCheck.width};

  &:disabled {
    border: ${({ theme }) => theme.emailCheck.disabled.border};
    background-color: ${({ theme }) => theme.emailCheck.disabled.backgroundColor};
    color: ${({ theme }) => theme.emailCheck.disabled.fontColor};
    cursor: not-allowed;
  }

  &:focus {
    outline-color: ${({ theme }) => theme.emailCheck.focus.outlineColor};
  }

  &::placeholder {
    color: ${({ theme }) => theme.emailCheck.placeholder.fontColor};
    font-family: ${({ theme }) => theme.emailCheck.placeholder.fontFamily};
    font-size: ${({ theme }) => theme.emailCheck.placeholder.fontSize};
    font-style: ${({ theme }) => theme.emailCheck.placeholder.fontStyle};
  }
`;

const Error = styled.span`
  color: ${({ theme }) => theme.emailCheck.error.fontColor};
  font-family: ${({ theme }) => theme.emailCheck.error.fontFamily};
  font-size: ${({ theme }) => theme.emailCheck.error.fontSize};
  position: relative;
  top: 5px;

  svg {
    justify-content: center;
    margin-right: 5px;
    position: relative;
    top: -2px;
  }
`;

const Required = styled.span`
  color: ${({ theme }) => theme.defaults.errorColor};
  padding-left: 5px;
`;

export default function EmailCheck({
  buttonText,
  customErrorMsg,
  id,
  onClick,
  placeholder,
  value,
  label,
  instructions,
  required,
  readOnly,
  ...props
}) {
  const dispatch = useDispatch();
  const emailCheck = useSelector(state => state.authentication.checkEmail);
  const buttonLabel = buttonText || 'Next';
  const [emailValue, setEmailValue] = useState('');
  const [loading, setLoading] = useState(false);
  const [clickButton, setClickButton] = useState(false);
  const [errorMsg, setErrorMsg] = useState(customErrorMsg || '');
  const [openSansFont, setOpenSansFont] = useState(props.openSans);

  useEffect(
    () => {
      if (clickButton) {
        if (emailCheck && !emailCheck.loading && emailCheck.error) {
          emailCheck.searchedEmail = emailValue;
          setTimeout(() => {
            setLoading(false);
            setClickButton(false);
            onClick(emailCheck);
          }, 750);
        }
        if (emailCheck && !emailCheck.loading && emailCheck.data) {
          emailCheck.searchedEmail = emailValue;
          setTimeout(() => {
            setLoading(false);
            setClickButton(false);
            onClick(emailCheck);
          }, 750);
        }
      }
    },
    [emailCheck],
  );

  useEffect(
    () => {
      setErrorMsg(customErrorMsg);
    },
    [customErrorMsg],
  );

  const buttonClick = () => {
    setClickButton(true);
    const validCheck = validateEmail(emailValue.trim(), 'Email is required');

    if (validCheck.valid) {
      setLoading(true);
      dispatch(checkEmail(emailValue.trim()));
    } else {
      setErrorMsg(validCheck.error);
      onClick('');
    }
  };

  let cleanseInstructions = null;
  if (instructions && stripHtmlString(instructions).trim().length > 0) {
    const updatedIntro = !!instructions ? instructions.replace(/<p><\/p>/g, '<br />') : '';
    cleanseInstructions = updatedIntro;
  }

  return (
    <InputGroup>
      {!!label &&
        label.length > 0 && (
          <InputLabel htmlFor={id}>
            {label}
            {required && <Required>*</Required>}
          </InputLabel>
        )}
      {cleanseInstructions &&
        cleanseInstructions.length > 0 && (
          <Instructions
            openSans={openSansFont}
            dangerouslySetInnerHTML={{
              __html: cleanseInstructions,
            }}
          />
        )}
      <InputInnerGroup>
        <InputField
          aria-invalid={errorMsg && errorMsg.length > 0 ? 'true' : 'false'}
          aria-label='Enter your email'
          errorMsg={errorMsg}
          id={id}
          onChange={value => {
            setErrorMsg('');
            setEmailValue(value.target.value);
          }}
          placeholder={placeholder}
          type={'text'}
          value={value}
          disabled={readOnly}
          {...props}
        />
        <ButtonDiv>
          <EmailCheckButton
            onClick={() => {
              buttonClick();
            }}
            disabled={loading || readOnly}
          >
            {loading ? <AccentButtonSpinner displayText='' /> : ` ${buttonLabel} `}
          </EmailCheckButton>
        </ButtonDiv>
      </InputInnerGroup>
      {errorMsg &&
        errorMsg.length > 0 && (
          <Error role="alert" aria-live="assertive">
            <StyledIcon type='Warning' size='16px' />
            {errorMsg}
          </Error>
        )}
    </InputGroup>
  );
}
