import React, { useState } from 'react';
import { compose } from 'recompose';
import propTypes from 'prop-types';
import moment from 'moment-timezone';
import _get from 'lodash/get';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import Headline from '../../../../components/library/headline';
import Paragraph from '../../../../components/library/paragraph';
import ViaModal from '../../../../components/library/viaModal';
import HousingForm from '../../forms/housingForm';
import RemoveDetail from '../../modals/removeDetail';
import ReinstateDetail from '../../modals/reinstateDetail';
import { getPlan } from '../../../../../actions/plans';
import sHousingItemView from './housingItemView.scss';
import ViaIcon from '../../ViaIcon';

export const enhance = compose(withStyles(sHousingItemView));

function HousingItemView(props) {
  const dispatch = useDispatch();
  const currentUser = useSelector(state => state.profile.currentUser);
  const permissions = useSelector(state => state.profile.permissions);
  const [showHousing, setShowHousing] = useState(false);
  const [removeDetail, setRemoveDetail] = useState(false);
  const [showReinstateDetail, setShowReinstateDetail] = useState(false);

  const housing = {
    tp_housing_type_id: props.detail.tp_housing_type_id,
    tp_housing_type_name: props.detail.tp_housing_type_name,
    name: props.detail.name,
    nickname: props.detail.nickname,
    room_number: props.detail.room_number,
    phone_number: props.detail.phone_number,
    email_address: props.detail.email_address,
    confirmation_number: props.detail.confirmation_number,
    website: props.detail.website,
    provider_contact_name: props.detail.provider_contact_name,
    arrival_date: moment(props.detail.arrival_date).format('MMM D, YYYY'),
    arrival_time: props.detail.arrival_time,
    arrival_tp_time_zone_id: '',
    departure_date: moment(props.detail.departure_date).format('MMM D, YYYY'),
    time_zone: '',
    departure_time: props.detail.departure_time,
    departure_tp_time_zone_id: '',
    notes: props.detail.notes,
    group: props.detail.group,
    removed: props.detail.removed,
    force_add: props.detail.force_add,
    display_address_lines: '',
    display_address_state: '',
    display_address_city: '',
    formatted_address: _get(props, 'detail.location.formatted_address') || '',
    timezone: _get(props, 'detail.location.time_zone') || '',
  };

  return (
    <>
      <div className={sHousingItemView['housing-item']}>
        <div className={sHousingItemView.soloGroupContainer}>
          <Paragraph>
            {housing.removed ? 'Removed ' : ''}
            {housing.group ? 'With the Group' : 'My Detail'}
          </Paragraph>
          <ViaIcon
            name={housing.group ? 'group' : 'solo'}
            color="darkText"
            size={housing.group ? 'smallPlusW10' : 'small'}
          />
        </div>
        <div className={housing.removed ? sHousingItemView.headerRemoved : sHousingItemView.headerActive}>
          <ViaIcon housingId={Number(housing.tp_housing_type_id)} />

          <div style={{
                          fontSize:"16px",
                          lineHeight : "24px",
                          fontFamily : "IntroBoldItalic,sans-serif",
                          color:"#fff",
                          flex : "1 1"
                      }}>
            {housing.nickname ? housing.nickname : housing.name}
          </div>
          <div className={sHousingItemView.action}>
            {!props.hideEdit && (
              <a
                href='#'
                role='button'
                aria-label='Edit'
                className={sHousingItemView['action-item']}
                onClick={(e) => {
                  e.preventDefault(); 
                  setShowHousing(true);
                }}
              >
                <FontAwesomeIcon icon="pencil-alt" color="white" size="sm" />
                <Paragraph>Edit</Paragraph>
              </a>
            )}

            {(currentUser['client_user_role?'] &&
              permissions.travel_plans_settings.includes('delete_travel_plan_and_itinerary')) ||
            currentUser['is_traveler?'] ? (
              !props.hideRemove ? (
                !housing.removed ? (
                  <a
                    href='#'
                    role='button'
                    aria-label='Remove'
                    className={sHousingItemView['action-item']}
                    onClick={(e) => {
                      e.preventDefault(); 
                      setRemoveDetail(true);
                    }}
                  >
                    <Paragraph> x Remove</Paragraph>
                  </a>
                ) : (
                  <a
                    href='#'
                    role='button'
                    aria-label='Reinstate'
                    className={sHousingItemView['action-item']}
                    onClick={(e) => {
                      e.preventDefault(); 
                      setShowReinstateDetail(true);
                    }}
                  >
                    <FontAwesomeIcon icon="plus" color="white" size="sm" />
                    <Paragraph>Reinstate</Paragraph>
                  </a>
                )
              ) : null
            ) : null}
          </div>
        </div>
        <div className={sHousingItemView.itineraryContainer}>
          <div className={sHousingItemView.column}>
            <div className={sHousingItemView.row}>
              <div className={sHousingItemView.item}>
                <Paragraph size="medium">Check-in Date </Paragraph>
                <div className={sHousingItemView.innerRow}>
                  <Paragraph size="small">{housing.arrival_date}</Paragraph>
                </div>
              </div>
            </div>
            <div className={sHousingItemView.row}>
              <div className={sHousingItemView.item}>
                <Paragraph size="medium">Check-in Time </Paragraph>
                <div className={sHousingItemView.innerRow}>
                  <Paragraph size="small">{`${housing.arrival_time} ${housing.timezone}`}</Paragraph>
                </div>
              </div>
            </div>
            <div className={sHousingItemView.row}>
              <div className={sHousingItemView.item}>
                <Paragraph size="medium">Notes</Paragraph>
                <div className={sHousingItemView.innerRow}>
                  <Paragraph size="small">{housing.notes}</Paragraph>
                </div>
              </div>
            </div>
          </div>

          <div className={sHousingItemView.borderedColumnLeft}>
            <div className={sHousingItemView.row}>
              <div className={sHousingItemView.item}>
                <Paragraph size="medium">Name </Paragraph>
                <div className={sHousingItemView.innerRow}>
                  <Paragraph size="small">{housing.name}</Paragraph>
                </div>
              </div>
            </div>
            <div className={sHousingItemView.row}>
              <div className={sHousingItemView.item}>
                <Paragraph size="medium">Confirmation #</Paragraph>
                <div className={sHousingItemView.innerRow}>
                  <Paragraph size="small">{housing.confirmation_number}</Paragraph>
                </div>
              </div>
            </div>
            <div className={sHousingItemView.row}>
              <div className={sHousingItemView.item}>
                <Paragraph size="medium">Room #</Paragraph>
                <div className={sHousingItemView.innerRow}>
                  <Paragraph size="small">{housing.room_number}</Paragraph>
                </div>
              </div>
            </div>
            <div className={sHousingItemView.row}>
              <div className={sHousingItemView.item}>
                <Paragraph size="medium">Phone #</Paragraph>
                <div className={sHousingItemView.innerRow}>
                  <Paragraph size="small">{housing.phone_number}</Paragraph>
                </div>
              </div>
            </div>
          </div>

          <div className={sHousingItemView.borderedColumnRight}>
            <div className={sHousingItemView.item}>
              <Paragraph size="medium">Address</Paragraph>
              <div className={sHousingItemView.innerRow}>
                <Paragraph size="small">{housing.formatted_address}</Paragraph>
              </div>
            </div>
            <div className={sHousingItemView.item}>
              <Paragraph size="medium">Website</Paragraph>
              <div className={sHousingItemView.innerRow}>
                <Paragraph size="small">{housing.website}</Paragraph>
              </div>
            </div>
            <div className={sHousingItemView.item}>
              <Paragraph size="medium">Email</Paragraph>
              <div className={sHousingItemView.innerRow}>
                <Paragraph size="small">{housing.email_address}</Paragraph>
              </div>
            </div>
          </div>
          <div className={sHousingItemView.column}>
            <div className={sHousingItemView.row}>
              <div className={sHousingItemView.item}>
                <Paragraph size="medium">Checkout Date</Paragraph>
                <div className={sHousingItemView.innerRow}>
                  <Paragraph size="small">{housing.departure_date}</Paragraph>
                </div>
              </div>
            </div>
            <div className={sHousingItemView.row}>
              <div className={sHousingItemView.item}>
                <Paragraph size="medium">Checkout Time</Paragraph>
                <div className={sHousingItemView.innerRow}>
                  <Paragraph size="small">{`${housing.departure_time} ${housing.timezone}`}</Paragraph>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ViaModal
        showing={showHousing}
        onClose={() => {
          dispatch(getPlan(props.planId));
          setShowHousing(false);
        }}
        headerText="Edit Housing"
      >
        <HousingForm
          onClose={() => {
            dispatch(getPlan(props.planId));
            setShowHousing(false);
          }}
          isEdit
          details={props.detail}
          itemId={props.detail.id}
          planId={props.planId}
          group={props.detail.group}
        />
      </ViaModal>
      <RemoveDetail
        show={removeDetail}
        onClose={() => setRemoveDetail(false)}
        detailId={props.detail.id.toString()}
        type="plan_housings"
        planId={props.planId}
        plans_user_id={props.plans_user_id}
        groupRemove={props.groupRemove}
      />
      <ReinstateDetail
        show={showReinstateDetail}
        onClose={() => setShowReinstateDetail(false)}
        detailId={props.detail.id.toString()}
        type="plan_housings"
        planId={props.planId}
        plans_user_id={props.plans_user_id}
        groupReinstate={props.groupReinstate}
      />
    </>
  );
}

HousingItemView.propTypes = {
  detail: propTypes.object.isRequired,
  planId: propTypes.string.isRequired,
  hideEdit: propTypes.bool,
  hideRemove: propTypes.bool,
  plans_user_id: propTypes.number,
  groupRemove: propTypes.bool,
  groupReinstate: propTypes.bool,
};

HousingItemView.defaultProps = {
  hideEdit: false,
  hideRemove: false,
  plans_user_id: null,
  groupRemove: false,
  groupReinstate: false,
};

export default enhance(HousingItemView);
