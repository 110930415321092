import * as yup from 'yup';
import _get from 'lodash/get';
import { hTag_To_pTag } from '../../../../../../shared/helpers/General';

export const FIELD_NAMES = {
  title: 'title',
  programInformation: 'program_highlight_attributes.text',
  programDescription: 'program_location_highlight_attributes.text',
  programLanguageLocationInformation: 'location_notes',
  housingInformation: 'housing_notes',
  courseInformation: 'pcourses',
  academicsInformation: 'academic_notes',
  hostOrganizationName: 'host_organization',
  hostInformation: 'host_organization_notes',
  activities: 'pactivities',
  excursions: 'pexcursions',
  addOns: 'padd_ons',
  activitiesInformation: 'activity_notes',
  eligibilityRequirements: 'program_eligibility_attributes.eligibility_info',
  scholarship: 'program_scholarship_info_attributes.scholarship_info',
  internalAdminNotes: 'notes',
  testimonialDetails: 'program_review_attributes.reviewer_details',
  testimonialName: 'program_review_attributes.reviewer_name',
  testimonialQuote: 'program_review_attributes.review_text',
  programType: 'program_type_connections_attributes',
  housingType: 'program_housings_attributes',
  isImmersion: 'language_immersion',
  displayLocMap: 'program_map_attributes.included',
  unspecifiedLocation: 'unspecified_location',
  imageRequested: 'image_requested',
  programLocations: 'program_locations_attributes',
  programLanguages: 'program_languages_attributes',
  programImage: 'program_images_attributes',
  isTestimonial: 'program_review_attributes.review_toggle',
  programSubjects: 'program_subjects_attributes',
  programAttachments: 'program_attachments_attributes',
  programAmenities: 'program_amenities_attributes',
  adminAttachments: 'admin_program_attachments_attributes',
  isAuthorizable: 'is_authorizable',
  programManagers: 'program_managers_attributes',
  programContact: 'program_contact_attributes.user_id',
  programStatus: 'status',
  private: 'private',
  access_code: 'access_code',
  accessCodeProtected: 'access_code_protected',
  external_application_url: 'external_application_url',
};

export const schema = yup.object().shape({
  title: yup
    .string()
    .max(60, 'Name must be 60 characters or less.')
    .required('Name is required'),
  // access_code: yup

  //   .string()
  //   .trim()
  //   .min(6, 'Access code must be at least 6 characters')
  //   .when('accessCodeProtected', {
  //     is: true,
  //     then: yup.string().required('Access code is required'),
  //   }),
});

export const schemaGrandfather = yup.object().shape({
  title: yup.string().required('Name is required'),
});

export const buildDefaultValues = defaultData => {
  const savedLanguages = _get(defaultData, ['program_languages_attributes']);
  const savedSubjects = _get(defaultData, ['program_subjects_attributes']);

  // Build out selected language options
  let newLangList = [];
  savedLanguages.forEach(lang => {
    newLangList.push({
      id: lang.id,
      iso_639_3: lang.iso_639_3,
      label: lang.name,
      value: lang.iso_639_3,
    });
  });

  // Build out selected subject options
  let newSubjectsList = [];
  savedSubjects.forEach(sub => {
    newSubjectsList.push({
      id: sub.id,
      included: sub.included,
      label: sub.subject_area_name,
      subject_area_id: parseInt(sub.subject_area_id),
      value: parseInt(sub.subject_area_id),
    });
  });

  // Check to see if there is a testimonial
  const reviewer_details = _get(defaultData.program_review_attributes, ['reviewer_details']) || '';
  const reviewer_name = _get(defaultData.program_review_attributes, ['reviewer_name']) || '';
  const review_text = _get(defaultData.program_review_attributes, ['review_text']) || '';
  const review_toggle = (reviewer_details + reviewer_name + review_text).length > 0;

  const values = {
    academic_notes: hTag_To_pTag(_get(defaultData, ['academic_notes']) || ''),
    activity_notes: hTag_To_pTag(_get(defaultData, ['activity_notes']) || ''),
    housing_notes: hTag_To_pTag(_get(defaultData, ['housing_notes']) || ''),
    host_organization: _get(defaultData, ['host_organization']) || '',
    host_organization_notes: hTag_To_pTag(_get(defaultData, ['host_organization_notes']) || ''),
    language_immersion: _get(defaultData, ['language_immersion']) || false,
    location_notes: hTag_To_pTag(_get(defaultData, ['location_notes']) || ''),
    notes: hTag_To_pTag(_get(defaultData, ['notes']) || ''),
    pactivities: hTag_To_pTag(_get(defaultData, ['pactivities']) || ''),
    padd_ons: hTag_To_pTag(_get(defaultData, ['padd_ons']) || ''),
    pcourses: hTag_To_pTag(_get(defaultData, ['pcourses']) || ''),
    pexcursions: hTag_To_pTag(_get(defaultData, ['pexcursions']) || ''),
    program_eligibility_attributes: {
      id: _get(defaultData.program_eligibility_attributes, ['id']) || '',
      eligibility_info: hTag_To_pTag(_get(defaultData.program_eligibility_attributes, ['eligibility_info']) || ''),
    },
    program_highlight_attributes: {
      id: _get(defaultData.program_highlight_attributes, ['id']) || '',
      text: hTag_To_pTag(_get(defaultData.program_highlight_attributes, ['text']) || ''),
    },
    program_housings_attributes: _get(defaultData, ['program_housings_attributes']) || [],
    program_location_highlight_attributes: {
      id: _get(defaultData.program_location_highlight_attributes, ['id']) || '',
      text: hTag_To_pTag(_get(defaultData.program_location_highlight_attributes, ['text']) || ''),
    },
    program_map_attributes: {
      id: _get(defaultData.program_map_attributes, ['id']) || '',
      included: _get(defaultData.program_map_attributes, ['included']) || false,
    },
    program_review_attributes: {
      id: _get(defaultData.program_review_attributes, ['id']) || '',
      reviewer_details: reviewer_details,
      reviewer_name: reviewer_name,
      review_text: review_text,
      review_toggle: review_toggle,
      _destroy: false,
    },
    program_scholarship_info_attributes: {
      id: _get(defaultData.program_scholarship_info_attributes, ['id']) || '',
      scholarship_info: hTag_To_pTag(
        _get(defaultData.program_scholarship_info_attributes, ['scholarship_info']) || '',
      ),
    },
    program_type_connections_attributes: _get(defaultData, ['program_type_connections_attributes']) || [],
    title: _get(defaultData, ['title']) || '',
    unspecified_location: _get(defaultData, ['unspecified_location']) || false,
    image_requested: _get(defaultData, ['image_requested']) || false,
    program_locations_attributes: _get(defaultData, ['program_locations_attributes']) || [],
    program_languages_attributes: newLangList,
    program_images_attributes: _get(defaultData, ['program_images_attributes']) || [],
    program_subjects_attributes: newSubjectsList,
    program_attachments_attributes: _get(defaultData, ['program_attachments_attributes']) || [],
    program_amenities_attributes: _get(defaultData, ['program_amenities_attributes']) || [],
    admin_program_attachments_attributes: _get(defaultData, ['admin_program_attachments_attributes']) || [],
    is_authorizable: _get(defaultData, ['is_authorizable']) || false,
    program_managers_attributes: _get(defaultData, ['program_managers_attributes']) || [],
    program_contact_attributes: {
      id: _get(defaultData.program_contact_attributes, ['id']) || '',
      user_id: _get(defaultData.program_contact_attributes, ['user_id']) || '',
    },
    status: _get(defaultData, ['status']) || '',
    private: _get(defaultData, ['private']) || false,
    access_code_protected: _get(defaultData, ['access_code_protected']) || false,
    access_code: _get(defaultData.access_code, ['access_code']) || '',
    external_application_url: _get(defaultData, ['external_application_url']) || '',
  };
  return values;
};
